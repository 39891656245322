import { React, bind, _, moment } from 'Imports';

import {
    TableBody,
    TableHead,
    Table,
    TableCell,
    TableRow,
    TablePagination,
    TableContainer,
    TableSortLabel,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    FormControlLabel,
    TextField,
    InputAdornment,
    Tooltip,
    styled,
} from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faSearch, faUserPlus, faEllipsisH, faTimes, faEllipsisV, faXmark } from '@fortawesome/pro-solid-svg-icons';
import * as DateFormatter from '$Components/Shared/DateFormatter';
import { SubscriberResponse } from '$Generated/api';

import {
    IEventNotificationSettingsServiceInjectedProps,
    EventNotificationSettingsService,
} from '$State/EventNotificationSettingsFreezerService';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';

import { SubscriberDialog } from './SubscriberDialog';
import StyledSwitch from '$Components/Shared/MaterialUIComponents/StyledSwitch';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface ISubscribersListBaseProps {
    refreshNotificationsTab: (forceRefresh: boolean) => void;
    refreshSubscribersTab: (forceRefresh: boolean) => void;
    forceRefresh: boolean;
}

type ISubscribersListProps = ISubscribersListBaseProps & IEventNotificationSettingsServiceInjectedProps & IConfigServiceInjectedProps;

interface ISubscribersListState {
    subscribersList?: SubscriberResponse[];
    subscribersListFiltered?: SubscriberResponse[];
    showSubscriberDialog: boolean;
    showDeleteDialog: boolean;
    editErrorMessage: string;
    selectedSubscriber?: SubscriberResponse;
    selectedSubscriberId: number;
    includeInactive: boolean;
    page: number;
    rowsPerPage: number;
    subscriberFilter: string;
    openTooltips: boolean[];
    isSearchBarFocused: boolean;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
}

const styles = require('./SubscribersList.scss') as {
    subscribersContainer: string;
    switchRoot: string;
    switchBase: string;
    switchChecked: string;
    switchTrack: string;
    switchThumb: string;
    iconButton: string;
    addSubscriberIcon: string;
    buttonContainer: string;
    dialogText: string;
    dialogActions: string;
    dialogTitle: string;
    cancelButton: string;
    deleteButton: string;
    iconDeleteMessage: string;
    dialogContent: string;
    dialogTitleRoot: string;
    switchLabel: string;
    switchTrackChecked: string;
    switchLabelFont: string;
    inputAdormentFilter: string;
    searchIcon: string;
    searchIconFocused: string;
    subscriberFilter: string;
    subscriberIntegrationFilter: string;
    tooltipPlacementTop: string;
    tooltip: string;
    addSubscriberTopButton: string;
    closeButtonSearchFilter: string;
    inputAdormentCloseFilter: string;
    searchBarInput: string;
    subscriptionTable: string;
    optionsColumn: string;
    centerText: string;
    dialogPaperWidth: string;
    closeIcon: string;
    inputFocused: string;
    optionsButton: string;
    iconButtonRoot: string;
    iconButtonActive: string;
    ellipseIcon: string;
    ellipseIconActive: string;
    encEllipseIcon: string;
    encIconButtonActive: string;
    encIconButton: string;
    disabledButton: string;
    toolTipButton: string;
};

const rowHeaders = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'phone',
        label: 'Phone Number',
    },
    {
        id: 'subscriptions',
        label: 'Subscriptions',
        style: styles.centerText,
    },
    {
        id: 'isActive',
        label: 'Status',
        style: styles.centerText,
    },
    {
        id: 'dateSubscribed',
        label: 'Date Subscribed',
        style: styles.centerText,
    },
];

const inputStyles = {
    borderRadius: '4px',
    backgroundColor: '#FFFFF',
    border: '1px solid #ced4da',
    boxShadow: 'inset 0px 3px 3px 0px #e6e6e6',
    padding: '0px 0px 0px 8px',
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -14],
                        },
                    },
                ],
            },
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: '#000',
        boxShadow: theme.shadows[2],
        margin: '0',
        padding: '0',
        aligntText: 'center',
        borderRadius: '4px',
    },
}));

class _SubscribersList extends React.Component<ISubscribersListProps, ISubscribersListState> {
    state: ISubscribersListState = {
        subscribersList: undefined,
        subscribersListFiltered: undefined,
        showSubscriberDialog: false,
        showDeleteDialog: false,
        editErrorMessage: '',
        selectedSubscriber: undefined,
        selectedSubscriberId: 0,
        includeInactive: false,
        page: 0,
        rowsPerPage: 10,
        subscriberFilter: '',
        openTooltips: [],
        isSearchBarFocused: false,
        sortBy: 'name',
        sortOrder: 'asc',
    };

    async componentDidMount(): Promise<void> {
        await this.getSubscribersList();
    }

    async componentDidUpdate(): Promise<void> {
        if (this.props.forceRefresh) {
            this.props.refreshSubscribersTab(false);
            await this.getSubscribersList();
        }
    }

    @bind
    async getSubscribersList(): Promise<void> {
        await this.props.eventNotificationSettings.getSubscribers();
        this.setState({ subscribersList: undefined, subscribersListFiltered: undefined });
        const { getSubscribersResults } = this.props.eventNotificationSettings.getState();

        if (this.state.includeInactive) {
            this.setState({
                subscribersList: getSubscribersResults.data || [],
                subscribersListFiltered: getSubscribersResults.data || [],
            });
        } else {
            this.setState({
                subscribersList: getSubscribersResults.data?.filter((value) => value.isActive == true) || [],
                subscribersListFiltered: getSubscribersResults.data?.filter((value) => value.isActive == true) || [],
            });
        }

        if (this.state.subscriberFilter) {
            this.handleSubscriberFilterChange(this.state.subscriberFilter);
        }
    }

    @bind
    async handleInactiveToggle(includeInactive: boolean): Promise<void> {
        try {
            this.setState({ includeInactive: includeInactive });

            await this.getSubscribersList();
            await this.handleRequestSort(this.state.sortBy, false);
        } catch (ex) {
            ex.text()
                .then(function (text: string) {
                    return text;
                })
                .then((error: string) => {
                    this.setState({ editErrorMessage: error });
                });
        }
    }

    @bind
    onClickEditSubscriber(subscriber: SubscriberResponse): void {
        this.closeTooltips();
        this.setState({ selectedSubscriber: subscriber, showSubscriberDialog: true });
    }

    @bind
    onClickDeleteSubscriber(subscriberId: number): void {
        this.closeTooltips();
        this.setState({ selectedSubscriberId: subscriberId, showDeleteDialog: true });
    }

    @bind
    closeTooltips(): void {
        let tooltips = _.clone(this.state.openTooltips);
        tooltips = tooltips.filter((t) => t).map((t) => false);
        this.setState({
            openTooltips: tooltips,
        });
    }

    @bind
    onCloseDialog(): void {
        this.setState({
            showSubscriberDialog: false,
            showDeleteDialog: false,
        });

        this.props.refreshNotificationsTab(true);
    }

    @bind
    async onDeleteSubscriber(): Promise<void> {
        await this.props.eventNotificationSettings.deleteSubscriber(this.state.selectedSubscriberId);
        await this.getSubscribersList();

        this.onCloseDialog();
    }

    @bind
    async refreshSubscribersList(): Promise<void> {
        await this.getSubscribersList();
    }

    @bind
    onClickAddSuibcriber(): void {
        this.setState({
            showSubscriberDialog: true,
            selectedSubscriber: undefined,
        });
    }

    @bind
    handleChangePage(event: any, newPage: number): void {
        this.setState({
            page: newPage,
        });
    }

    @bind
    handleChangeRowsPerPage(event: any): void {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        });
    }

    @bind
    handleSubscriberFilterChange(filter: string): void {
        this.setState({
            subscriberFilter: filter,
        });

        const filteredRows = this.state.subscribersList?.filter((row) => {
            return row?.name?.toLowerCase().includes(filter.toLowerCase());
        });

        this.setState({
            subscribersListFiltered: filteredRows,
        });
    }

    @bind
    handleClearSearchFilter(): void {
        this.setState({
            subscriberFilter: '',
        });

        this.setState({
            subscribersListFiltered: this.state.subscribersList,
        });
    }

    @bind
    handleOpenTooltip(idx: number): void {
        let tooltips = _.clone(this.state.openTooltips);

        if (tooltips[idx]) {
            tooltips[idx] = false;
        } else {
            tooltips = tooltips.filter((t) => t).map((t) => false);
            tooltips[idx] = tooltips[idx] ? false : true;
        }

        this.setState({
            openTooltips: tooltips,
        });
    }

    @bind
    onFocusSearchBar(isFocused: boolean): void {
        this.setState({
            isSearchBarFocused: isFocused,
        });
    }

    async handleRequestSort(field: string, changeSort: boolean = true): Promise<void> {
        const { sortBy, sortOrder, subscribersList, subscriberFilter } = this.state;
        let isDesc = false;
        if (changeSort) {
            isDesc = sortBy === field && sortOrder === 'asc';
        } else {
            isDesc = sortOrder === 'desc';
        }
        const sortItems = subscribersList?.sort((a, b) => {
            let res = 0;
            let aString = '';
            let bString = '';
            switch (field) {
                case 'name':
                case 'email':
                case 'phone':
                    aString = a[field] ? a[field]! : '';
                    bString = b[field] ? b[field]! : '';
                    res = aString.localeCompare(bString);
                    break;
                case 'subscriptions':
                    const aCount = a.subscriptionsCount;
                    const bCount = b.subscriptionsCount;
                    res = (aCount || 0) < (bCount || 0) ? -1 : (aCount || 0) > (bCount || 0) ? 1 : 0;
                    break;
                case 'isActive':
                    aString = a[field] ? 'Active' : 'Inactive';
                    bString = b[field] ? 'Active' : 'Inactive';
                    res = aString.localeCompare(bString);
                    break;
                case 'dateSubscribed':
                    const aDate = a.dateSubscribed ? moment(a.dateSubscribed) : moment();
                    const bDate = b.dateSubscribed ? moment(b.dateSubscribed) : moment();
                    const dateDiff = aDate.valueOf() - bDate.valueOf();
                    res = dateDiff > 0 ? 1 : dateDiff < 0 ? -1 : 0;
                    break;
                default:
            }

            return isDesc ? -1 * res : res;
        });

        await this.setState({
            sortBy: field,
            sortOrder: isDesc ? 'desc' : 'asc',
            subscribersList: sortItems,
        });

        this.handleSubscriberFilterChange(subscriberFilter);
    }

    render(): JSX.Element {
        const { editErrorMessage, showSubscriberDialog, showDeleteDialog, page, rowsPerPage, subscriberFilter, subscribersListFiltered } =
            this.state;
        const isEncompass = this.props.config.isIntegrationPlatformEncompass();
        const isRunningStandAlone = this.props.config.isRunningStandAlone();

        return (
            <div>
                <div className={styles.buttonContainer}>
                    <FormControlLabel
                        control={
                            <StyledSwitch
                                checked={this.state.includeInactive}
                                onChange={(e) => this.handleInactiveToggle(e.target.checked)}
                                size={'small'}
                            />
                        }
                        label={'Include Inactive'}
                        labelPlacement={isEncompass ? 'top' : 'end'}
                        classes={{ root: styles.switchLabel, label: styles.switchLabelFont }}
                    />

                    <Button
                        onClick={this.onClickAddSuibcriber}
                        variant="text"
                        title="Add subscriber"
                        className={styles.addSubscriberTopButton}
                        disableRipple={true}
                    >
                        {!isEncompass && <FontAwesomeIcon icon={faUserPlus} size={'xs'} className={styles.addSubscriberIcon} />}
                        Add Subscriber
                    </Button>
                </div>
                <div className={styles.subscribersContainer}>
                    <div className={isRunningStandAlone ? styles.subscriberFilter : styles.subscriberIntegrationFilter}>
                        <TextField
                            value={subscriberFilter}
                            onChange={(e) => this.handleSubscriberFilterChange(e.target.value)}
                            onFocus={() => this.onFocusSearchBar(true)}
                            onBlur={() => this.onFocusSearchBar(false)}
                            className={styles.searchBarInput}
                            placeholder={'Search for a subscriber...'}
                            InputProps={{
                                style: isEncompass ? inputStyles : {},
                                classes: {
                                    focused: styles.inputFocused,
                                },
                                disableUnderline: isEncompass,
                                startAdornment: isEncompass ? (
                                    this.state.isSearchBarFocused || subscriberFilter ? null : (
                                        <InputAdornment className={styles.inputAdormentFilter} position={'start'}>
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                className={this.state.isSearchBarFocused ? styles.searchIconFocused : styles.searchIcon}
                                            />
                                        </InputAdornment>
                                    )
                                ) : (
                                    <InputAdornment className={styles.inputAdormentFilter} position={'start'}>
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            className={this.state.isSearchBarFocused ? styles.searchIconFocused : styles.searchIcon}
                                        />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <Button
                                        onClick={this.handleClearSearchFilter}
                                        className={styles.closeButtonSearchFilter}
                                        hidden={subscriberFilter ? false : true}
                                    >
                                        <InputAdornment className={styles.inputAdormentCloseFilter} position={'end'}>
                                            <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />
                                        </InputAdornment>
                                    </Button>
                                ),
                            }}
                            InputLabelProps={{ style: { marginLeft: isEncompass ? 8 : 0 } }}
                        />
                    </div>
                    <TableContainer>
                        <Table className={styles.subscriptionTable}>
                            <TableHead>
                                <TableRow>
                                    {rowHeaders.map((head) => {
                                        return (
                                            <TableCell
                                                key={head.id}
                                                className={head.style}
                                                sortDirection={this.state.sortBy === head.id ? this.state.sortOrder : false}
                                            >
                                                <TableSortLabel
                                                    active={this.state.sortBy === head.id}
                                                    direction={this.state.sortOrder}
                                                    onClick={(): Promise<void> => this.handleRequestSort(head.id)}
                                                >
                                                    {head.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subscribersListFiltered
                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((subscriber: SubscriberResponse, idx) => {
                                        return (
                                            <TableRow key={idx} tabIndex={-1}>
                                                <TableCell>{subscriber.name}</TableCell>
                                                <TableCell>{subscriber.email}</TableCell>
                                                <TableCell>{subscriber.phone}</TableCell>
                                                <TableCell className={styles.centerText}>{subscriber.subscriptionsCount}</TableCell>
                                                <TableCell className={styles.centerText}>
                                                    {subscriber.isActive ? 'Active' : 'Inactive'}
                                                </TableCell>
                                                <TableCell className={styles.centerText}>
                                                    {DateFormatter.date(moment(subscriber.dateSubscribed) ?? null)}
                                                </TableCell>
                                                <TableCell className={styles.optionsColumn}>
                                                    <div>
                                                        {isEncompass ? (
                                                            <StyledTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <div
                                                                            className={styles.toolTipButton}
                                                                            onClick={() => this.onClickEditSubscriber(subscriber)}
                                                                        >
                                                                            Edit
                                                                        </div>
                                                                        <div
                                                                            className={styles.toolTipButton}
                                                                            onClick={() => this.onClickDeleteSubscriber(subscriber.id ?? 0)}
                                                                        >
                                                                            Delete
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                                placement="left-start"
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                open={this.state.openTooltips[idx] || false}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                            >
                                                                <IconButton
                                                                    className={
                                                                        this.state.openTooltips[idx]
                                                                            ? styles.encIconButtonActive
                                                                            : styles.encIconButton
                                                                    }
                                                                    onClick={() => {
                                                                        this.handleOpenTooltip(idx);
                                                                    }}
                                                                    disableRipple={true}
                                                                    disableFocusRipple={true}
                                                                    size={'small'}
                                                                >
                                                                    {this.state.openTooltips[idx] ? (
                                                                        <FontAwesomeIcon
                                                                            className={styles.ellipseIconActive}
                                                                            icon={faXmark}
                                                                            style={{ color: 'white' }}
                                                                        />
                                                                    ) : (
                                                                        <FontAwesomeIcon
                                                                            className={styles.encEllipseIcon}
                                                                            icon={faEllipsisH}
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </StyledTooltip>
                                                        ) : (
                                                            <StyledTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <div
                                                                            className={styles.toolTipButton}
                                                                            onClick={() => this.onClickEditSubscriber(subscriber)}
                                                                        >
                                                                            Edit
                                                                        </div>
                                                                        <div
                                                                            className={styles.toolTipButton}
                                                                            onClick={() => this.onClickDeleteSubscriber(subscriber.id ?? 0)}
                                                                        >
                                                                            Delete
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                                placement="left-start"
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                open={this.state.openTooltips[idx] || false}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                            >
                                                                <IconButton
                                                                    className={
                                                                        this.state.openTooltips[idx]
                                                                            ? styles.iconButtonActive
                                                                            : styles.iconButton
                                                                    }
                                                                    onClick={() => {
                                                                        this.handleOpenTooltip(idx);
                                                                    }}
                                                                    disableRipple={true}
                                                                    disableFocusRipple={true}
                                                                    size={'small'}
                                                                >
                                                                    {this.state.openTooltips[idx] ? (
                                                                        <FontAwesomeIcon
                                                                            className={styles.ellipseIconActive}
                                                                            icon={faXmark}
                                                                            style={{ color: 'white' }}
                                                                        />
                                                                    ) : (
                                                                        <FontAwesomeIcon
                                                                            className={styles.ellipseIcon}
                                                                            icon={faEllipsisV}
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </StyledTooltip>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        isEncompass ? (
                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[10, 20, 50]}
                                count={subscribersListFiltered?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                SelectProps={{
                                    MenuProps: {
                                        disableScrollLock: true,
                                        autoFocus: false,
                                        disableAutoFocusItem: true,
                                        disableEnforceFocus: true,
                                        disableAutoFocus: true,
                                    },
                                }}
                            />
                        ) : (
                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[10, 20, 50]}
                                count={subscribersListFiltered?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        )
                        //TODO: Implement Pagination component when Geotab to follow Figma design and removed TablePagination
                        // <Pagination
                        //     count={this.calculatePagesCount(rowsPerPage, eventNotificationsListFiltered?.length || 0)}
                        //     shape="rounded"
                        //     page={page == 0 ? page+1 : page}
                        //     onChange={this.handleChangePagination}
                        // />
                    }
                </div>

                {showSubscriberDialog && (
                    <SubscriberDialog
                        errorMessage={editErrorMessage}
                        onCancel={() => this.onCloseDialog()}
                        refreshEventNotificationsList={() => this.refreshSubscribersList()}
                        subscriber={this.state.selectedSubscriber}
                        eventTypeId={this.state.selectedSubscriber ? undefined : 0}
                    />
                )}

                <Dialog open={showDeleteDialog} onClose={() => this.onCloseDialog()} classes={{ paperWidthSm: styles.dialogPaperWidth }}>
                    <DialogTitle className={styles.dialogTitle}>
                        <div className={styles.dialogTitleRoot}>
                            Delete subscriber
                            {isEncompass && (
                                <span className={styles.closeIcon}>
                                    {' '}
                                    <FontAwesomeIcon icon={faTimes} onClick={() => this.onCloseDialog()} />
                                </span>
                            )}
                        </div>
                    </DialogTitle>
                    <DialogContent className={styles.dialogContent}>
                        <DialogContentText className={styles.dialogText}>
                            {isEncompass && (
                                <IconButton>
                                    <FontAwesomeIcon icon={faTimesCircle} size={'lg'} className={styles.iconDeleteMessage} />
                                </IconButton>
                            )}
                            {isEncompass
                                ? 'Are you sure? Deleting this subscriber will remove it permanently.'
                                : 'Are you sure you want to delete this subscriber?'}
                        </DialogContentText>
                    </DialogContent>
                    {isEncompass ? (
                        <DialogActions className={styles.dialogActions}>
                            <Button onClick={() => this.onCloseDialog()} className={styles.cancelButton}>
                                No, Go Back
                            </Button>
                            <Button onClick={() => this.onDeleteSubscriber()} className={styles.deleteButton}>
                                Yes, Delete
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions className={styles.dialogActions}>
                            <Button onClick={() => this.onDeleteSubscriber()} className={styles.deleteButton}>
                                Yes, Delete
                            </Button>
                            <Button onClick={() => this.onCloseDialog()} className={styles.cancelButton}>
                                No, Cancel
                            </Button>
                        </DialogActions>
                    )}
                </Dialog>
            </div>
        );
    }
}

export const SubscribersList = ConfigService.inject(EventNotificationSettingsService.inject(_SubscribersList));
