import { IIntegrationPartnerManager} from 'integrationPartner/integrationPartnerAbstraction'
import { VideoEventResponse } from '$Generated/api';
import { UserManagementService } from "$State/UserManagementFreezerService";
import { ConfigService } from '$State/ConfigFreezerService';
import { AuthProvider } from 'oauth/AuthProvider';
import jwtDecode , {JwtPayload} from 'jwt-decode'

const CompleteCredentialRenovationMessage = "setCompleteCredentialRenewal";
const TokenExpirationPeriodInSeconds = 240; //in seconds 4 minutes

export class EncompassManager implements IIntegrationPartnerManager {
    public configureApplicationForIntegratedMode(onDestroyCallback:() => void): void {
        console.log('iFrame Integration - initialize');

        // If we're on the error page, then something failed during authentication, & we don't want to wire up 
        if (!location.pathname.includes('/error')) {
            window.addEventListener('message', async (event: MessageEvent) => {
                if (!event || !event.data || !event.data.type || event.data.type !== 'videoprotects-login-message' ) {                    
                    return;
                }

                if (event.data.token == null) {
                    console.log(event.data, 'ERROR: event.data.token returned null');
                    return;
                }
                
                if (event.data.type === 'videoprotects-login-message') { 
                    UserManagementService.setUserCredential(event.data.token);
                    //Set credential renewal to false so we don't continue to request new tokens for each subsequent API call 
                    UserManagementService.setIsCredentialRenewalActive(false);
                    //Need to get the fleetId then re-render the application, in case the fleetId was not set already for Split.io
                    await UserManagementService.setupUserData();
                    console.log("iFrame Integration - Login Message Processed & Encompass Local Storage Created");
                }
            });

            window.parent.postMessage({ type: 'videoprotects-login-ready-message'}, '*');
            console.log('iFrame Integration - Sent Login Ready Message');
        }
    }

    public configureApplicationForStandAloneMode(): void {
        // Event listener created once to make sure we can setup isTokenRenovationActive to false when a token renewal happens
        window.addEventListener('message', (event: MessageEvent) => {
            if(event.data === CompleteCredentialRenovationMessage){
                UserManagementService.setIsCredentialRenewalActive(false);
            }
        });
    }

    public async handleCredentialExpiration(): Promise<void> {
        if (ConfigService.isRunningStandAlone()) {
            const authProvider = new AuthProvider();
            const user = await authProvider.getUser();
   
            // If user data has an access token already expired then call logout and ask the user to enter encompass credentials again
            if (user && user?.expired) {
              authProvider.logout();
            }
   
            // Expiration time value setup in Identity server is 20 minutes.
            // In order to call a token renewal we validate if the token expiration time is less than 10 minutes.
            // isTokenRenovationActive was created to call just one time to renew the token
            if (user?.expires_in && user?.expires_in < TokenExpirationPeriodInSeconds && !UserManagementService.getIsCredentialRenewalActive()) {
                UserManagementService.setIsCredentialRenewalActive(true);
                // Call Identity server to get redirection into an Iframe that oidc-client library create by its own.
                const data = await authProvider.renewToken();
                //New token saved to avoid error in the backend side using the old token.
                UserManagementService.setUserCredential(data.access_token);
            }
         }
         else {
           const jwtToken = jwtDecode<JwtPayload>(UserManagementService.getUserCredential());
           const expiration = jwtToken.exp;        
           if (expiration && !UserManagementService.getIsCredentialRenewalActive()) {
             const tokenExpirationPeriodInMilliSeconds = TokenExpirationPeriodInSeconds * 1000;
             const expirationInMilliSeconds = expiration * 1000;
             if ((Date.now() + tokenExpirationPeriodInMilliSeconds)  >= expirationInMilliSeconds) {
                UserManagementService.setIsCredentialRenewalActive(true);
               // Request a new token from encompass. 
               window.parent.postMessage({ type: 'videoprotects-login-ready-message'}, '*');
             }
           }
         }
    }

    public async tryLogin(): Promise<void> {
        if (ConfigService.isRunningStandAlone()) {
            const authProvider = new AuthProvider();
            if (location.pathname.includes('/loginResponse')) {
                // Complete login response here and save credentials before go to render Login Response component and do the redirection
                const data = await authProvider.loginCallback();        
                if(data && data?.access_token) {
                    UserManagementService.setUserCredential(data?.access_token);
                }
            }
            else {
                const authenticatedUser = await authProvider.getUser().catch(() => { location.href ="/error" });
  
                // Check if we already have an authenticated user and set userCredentials
                if(authenticatedUser && authenticatedUser?.access_token){
                    UserManagementService.setUserCredential(authenticatedUser.access_token);
                }
            }
        }
    }

    public logout(): void {
        if (ConfigService.isRunningStandAlone()) {
            const authProvider = new AuthProvider();
            authProvider.logout();
            // Note that navigation to the login page for Encompass will happen after Identity Server redirect to our "LogoutResponse.tsx"
        }
    }

    public getMapUrl(data:VideoEventResponse): any {
        return undefined;
    }

    public getLinkUrl(standaloneUrl: string): string {
        return standaloneUrl;
    }

    public static isIntegrated(): boolean {
        // Determine if we are running in an iFrame or not
        // https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    public static async completeCredentialRenewal() {
        const authProvider = new AuthProvider();
        await authProvider.signinSilentCallback();

        // We send the message from silent renewal Iframe to the current actual parent window to be able to complete the silent renewal and set isTokenRenovationActive to false
        window.parent.postMessage(CompleteCredentialRenovationMessage, location.origin);
      }
}