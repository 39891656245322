import { React, _, bind } from 'Imports';
import { FormControl, InputLabel, Input, Button, TextField } from 'MaterialUIComponents';
import { IntegrationPartnerDataService, IIntegrationPartnerDataInjectedProps } from '$State/IntegrationPartnerDataFreezerService';
import { GeotabManager } from 'integrationPartner/geotabManager';

interface IGeotabLoginProps {
    redirect: string;
}

interface IGeotabLoginState {
    email: string;
    password: string;
    server: string;
    database: string;
}

type GeotabLoginProps = IGeotabLoginProps & IIntegrationPartnerDataInjectedProps;

const styles = require('$Pages/GeotabLogin/GeotabLogin.scss') as {
    main: string;
    pageHeader: string;
    pageTitle: string;
};

export class _GeotabLogin extends React.Component<GeotabLoginProps, IGeotabLoginState> {
    state = {
        email: '',
        password: '',
        server: '',
        database: '',
    };

    @bind
    login(event: any) {
        event.preventDefault();
        // Authenticate locally
        const server = this.state.server;
        const username = this.state.email;
        const password = this.state.password;
        const database = this.state.database;

        const manager = this.props.integrationPartnerData.getIntegrationPartnerManager() as GeotabManager;
        manager.postAuthentication(username, server, database, password, this.props.redirect);
    }

    handleChange = (event: any, prop: 'email' | 'password' | 'server' | 'database') => {
        switch (prop) {
            case 'email':
                this.setState({ email: event.target.value });
                break;
            case 'password':
                this.setState({ password: event.target.value });
                break;
            case 'server':
                this.setState({ server: event.target.value });
                break;
            case 'database':
                this.setState({ database: event.target.value });
                break;
            default:
                break;
        }
    };

    render() {
        return (
            <div className={styles.main}>
                <form onSubmit={this.login}>
                    <h2>Login</h2>
                    <FormControl>
                        <TextField
                            id="email"
                            autoFocus={true}
                            type="email"
                            value={this.state.email}
                            onChange={(e) => this.handleChange(e, 'email')}
                            label="Email"
                        />
                    </FormControl>
                    <br />
                    <FormControl>
                        <TextField
                            id="password"
                            type="password"
                            value={this.state.password}
                            onChange={(e) => this.handleChange(e, 'password')}
                            label="Password"
                        />
                    </FormControl>
                    <br />
                    <FormControl>
                        <TextField
                            id="server"
                            type="text"
                            value={this.state.server}
                            onChange={(e) => this.handleChange(e, 'server')}
                            label="Server"
                        />
                    </FormControl>
                    <br />
                    <FormControl>
                        <TextField
                            id="database"
                            type="text"
                            value={this.state.database}
                            onChange={(e) => this.handleChange(e, 'database')}
                            label="Database"
                        />
                    </FormControl>
                    <br />

                    <Button type="submit">Login</Button>
                </form>
            </div>
        );
    }
}

export const GeotabLogin = IntegrationPartnerDataService.inject(_GeotabLogin);
