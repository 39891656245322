import { AgreementsService } from '$State/AgreementsFreezerService';
import { IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { Link, React } from 'Imports';
import { StyledPageTitle, StyledGoBackLink } from './PageHeaderStyles_Split_Off';
import { TypedSplitTreatments, getSplitUserAttributes, isSplitTreatmentOn, splitTreatmentNames } from '$Utilities/split-io-sdk';
import { VideoEventService } from '$State/VideoEventFreezerService';
import { TermsOfUseModal } from '$Components/TermsOfUse/TermsOfUseModal';

const styles = require('$Components/Shared/PageHeader_Split_Off.scss') as {
    main: string;
    pageTitle: string;
    pageBack: string;
    agreementLink: string;
    splitMessage: string;
};

interface IPageHeaderProps {
    pageTitle: string;
    navLinkDisplay?: string;
    onSelectVideoEvent?: (value: string, goBack: boolean) => void;
    children?: any;
    fixedHeight?: boolean;
}

export const PageHeader_Split_Off = (props: IPageHeaderProps) => {
    const navigateBack = (): void => {
        if (props.onSelectVideoEvent) {
            VideoEventService.clearVideoEvents();
            props.onSelectVideoEvent('', true);
        }
    };

    const viewAgreementsUrl = 'https://www.videoprotects.com/internet-terms-of-use';
    const noticeAtCollectionUrl = ' https://www.jjkeller.com/learn/notice-at-collection';
    const fixedHeight = props.fixedHeight === undefined ? true : props.fixedHeight;
    return (
        <StyledPageTitle className={styles.main} fixedHeight={fixedHeight}>
            <div>
                <div className={styles.pageTitle}>
                    {props.pageTitle}
                    {props.children}
                </div>
                {AgreementsService.getShowAgreements() ? (
                    <div className={styles.agreementLink}>
                        By logging into J. J. Keller VideoProtects, you agree to the{' '}
                        <a href={viewAgreementsUrl} target="_blank" rel="noreferrer">
                            Terms of Use.
                        </a>{' '}
                        For information about how we use any personal information we collect for our own purposes, please review our{' '}
                        <a href={noticeAtCollectionUrl} target="_blank" rel="noreferrer">
                            Notice at Collection.
                        </a>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div>
                <div className={styles.splitMessage}>
                    <TypedSplitTreatments names={[splitTreatmentNames.testEasterEgg]} attributes={getSplitUserAttributes()}>
                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                            return isReady || isTimedout ? (
                                isSplitTreatmentOn(treatments[splitTreatmentNames.testEasterEgg]) ? (
                                    <div>Split.io is ON</div>
                                ) : (
                                    <div></div>
                                )
                            ) : (
                                <div>Loading...</div>
                            );
                        }}
                    </TypedSplitTreatments>
                    <TypedSplitTreatments names={[splitTreatmentNames.testEasterEgg]} attributes={getSplitUserAttributes()}>
                        {({ isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                            return !isReady && isTimedout ? <div>Split.io has timed out</div> : <div></div>;
                        }}
                    </TypedSplitTreatments>
                </div>
                {props.navLinkDisplay ? (
                    <StyledGoBackLink className={styles.pageBack} onClick={(): void => navigateBack()}>
                        {props.navLinkDisplay}
                    </StyledGoBackLink>
                ) : (
                    ''
                )}
            </div>
        </StyledPageTitle>
    );
};
