import { calcRequestStatus } from '$Utilities/dataModelUtilities';
import { React } from 'Imports';
import { StyledFailedThumbnailPreview } from '$Components/Shared/VideoEventCardStyles';
import { VideoEventCardThumbnail } from '$Components/VideoEvents/VideoEventCardThumbnail/VideoEventCardThumbnail';
import { VideoEventResponse } from '$Generated/api';

interface IVideoEventCardThumbnailWithFallbackProps {
    previewUrl: string;
    thumbnailUrl: string;
    videoEvent: VideoEventResponse;
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    updateVideoEventWorkflowStatus: (eventId: number | undefined) => void;
}
type VideoEventCardThumbnailWithFallbackProps = IVideoEventCardThumbnailWithFallbackProps;

function VideoEventCardThumbnailWithFallback(props: VideoEventCardThumbnailWithFallbackProps) {
    const { videoEvent, previewUrl, thumbnailUrl } = props;

    const eventId = videoEvent.id ? videoEvent.id : 0;
    const displayEventid = videoEvent.displayId || '';

    return (
        <>
            {videoEvent.workflowStep !== 'Success' ? (
                <StyledFailedThumbnailPreview>
                    <div>{calcRequestStatus(videoEvent)}</div>
                </StyledFailedThumbnailPreview>
            ) : (
                <VideoEventCardThumbnail
                    eventId={eventId}
                    displayEventId={displayEventid}
                    thumbnailURL={thumbnailUrl}
                    videoURL={previewUrl}
                    showDetailsNavigator={videoEvent.workflowStep === 'Success'}
                    onSelectVideoEvent={props.onSelectVideoEvent}
                    handleVideoPlayed={props.updateVideoEventWorkflowStatus}
                />
            )}
        </>
    );
}

export { VideoEventCardThumbnailWithFallback };
