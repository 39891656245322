import { React, _ } from 'Imports';

import { Table, TableBody, TableCell, TableRow, styled } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAlphaDownAlt, faSortAlphaUp } from '@fortawesome/pro-solid-svg-icons';

import { DriverRanking } from '../../../generated/api';
import { WidgetCard, WidgetCardContent, WidgetCardHeader } from '../../../components/Dashboards/StyledComponents';
import { WidgetTooltip } from '../../../components/Dashboards/WidgetTooltip';
import { useEffect, useState } from 'react';

import * as scssStyles from '$CSS/settings.scss';

const styles = require('../styles/DriverRankingWidget.scss') as {
    cardContent: string;
    sortContainer: string;
    sortLabel: string;
    sortIcon: string;
    sortIconDown: string;
    scoreTable: string;
    scoreTableRow: string;
    driverRank: string;
    driverName: string;
    scores: string;
    scoreCurrent: string;
    scorePrevious: string;
    bars: string;
    barCurrent: string;
    barPrevious: string;
    legend: string;
    legendColor: string;
    legendLabel: string;
};

// asc/desc by driver (first) name
type DriverRankingSort = 'asc' | 'desc' | 'rank';

interface IDriverRankingWidgetData {
    rank: number;
    name: string;
    currentScore: number;
}

interface IDriverRankingWidgetState {
    data: IDriverRankingWidgetData[];
    sort: DriverRankingSort;
}

type DriverRankingWidgetProps = {
    driverRankingRequested: DriverRanking;
};

const ScoreTableCell = styled(TableCell)`
    border: none;
    padding: 0.5rem;
`;

const _DriverRankingWidget = (props: DriverRankingWidgetProps) => {
    const [driverRankingData, setDriverRankingData] = useState<IDriverRankingWidgetState>({ data: [], sort: 'rank' });
    const { driverRankingRequested } = props;

    const aggregateDriverRankingData = (data: DriverRanking) => {
        const ranking = Object.keys(data?.ranking || {}).map((x) => {
            return {
                currentScore: data?.ranking![x].score,
                name: data?.ranking![x].name,
            } as IDriverRankingWidgetData;
        });

        // pre-calculate rank by current score
        ranking
            .sort((a, b) => b.currentScore - a.currentScore)
            .forEach((x, index) => {
                // update rank inline - ties don't apply
                x.rank = index + 1;
            });

        setDriverRankingData({ data: ranking, sort: 'rank' });
    };

    const selectSort = (newSort: DriverRankingSort): void => {
        const newData = _.clone(driverRankingData.data);

        // toggle back to rank sort
        const actualSort = driverRankingData.sort === newSort ? 'rank' : newSort;

        if (actualSort === 'rank') {
            newData.sort((a, b) => a.rank - b.rank);
        } else {
            const order = newSort === 'desc' ? -1 : 1;

            newData.sort((a, b) => a.name.localeCompare(b.name) * order);
        }

        setDriverRankingData({
            data: newData,
            sort: actualSort,
        });
    };

    useEffect(() => {
        aggregateDriverRankingData(driverRankingRequested);
    }, [driverRankingRequested]);

    return (
        <>
            <WidgetTooltip
                prompt="How is driver ranking determined?"
                description="A driver’s rank is determined by the scored events over the time period selected above. Scores are added and/or deducted from the driver’s initial score of 99 to derive their score and place in the driver ranking for the time period selected."
            />

            <WidgetCard>
                <WidgetCardHeader title="Driver Ranking" />

                <WidgetCardContent className={styles.cardContent}>
                    <div className={styles.sortContainer}>
                        <div className={styles.sortLabel}>Sort</div>
                        <FontAwesomeIcon
                            icon={faSortAlphaUp}
                            className={styles.sortIcon}
                            onClick={() => selectSort('asc')}
                            style={driverRankingData.sort === 'asc' ? { color: scssStyles.darkTypography } : {}}
                            title="Sort ascending by name"
                        />
                        <FontAwesomeIcon
                            icon={faSortAlphaDownAlt}
                            className={styles.sortIconDown}
                            onClick={() => selectSort('desc')}
                            style={driverRankingData.sort === 'desc' ? { color: scssStyles.darkTypography } : {}}
                            title="Sort descending by name"
                        />
                    </div>

                    <div className={styles.scoreTable}>
                        <Table>
                            <TableBody>
                                {driverRankingData.data.map((x, idx) => {
                                    const currentWidth = `${x.currentScore < 0 ? 0 : x.currentScore > 100 ? 100 : x.currentScore}%`;

                                    return (
                                        <TableRow key={idx}>
                                            <ScoreTableCell className={styles.driverRank}>{x.rank}</ScoreTableCell>
                                            <ScoreTableCell className={styles.driverName}>{x.name}</ScoreTableCell>
                                            <ScoreTableCell className={styles.scores}>
                                                <div className={styles.scoreCurrent}>{x.currentScore}</div>
                                            </ScoreTableCell>
                                            <ScoreTableCell className={styles.bars}>
                                                <div className={styles.barCurrent} style={{ width: currentWidth }}></div>
                                            </ScoreTableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </WidgetCardContent>
            </WidgetCard>
        </>
    );
};

export const DriverRankingWidget = _DriverRankingWidget;
