import { React } from 'Imports';
import { useState } from 'react';
import { VideoRequestForm } from '$Components/VideoRecall/VideoRequestForm';
import SliderDialog from '$Components/Shared/MaterialUIComponents/SliderDialog';

export const styles = require('pages/DeviceAssociation/styles/VideoRequestDialog.scss') as {
    mainContainer: string;
    requestInfo: string;
    emphasizedCondition: string;
};

interface IVideoRequestDialogProps {
    open: boolean;
    onClose: () => void;
    config: any;
    device: any;
}

type VideoRequestDialogProps = IVideoRequestDialogProps;

export const VideoRequestDialog = (props: VideoRequestDialogProps) => {
    const [submitVideoRequest, setSubmitVideoRequest] = useState('');

    const title = 'Request a Video';
    const submitActionText = 'Request Video';
    const isRunningStandAlone = props.config?.isRunningStandAlone();

    const handleRequestVideo = () => {
        setSubmitVideoRequest('submit');
        setInterval(() => setSubmitVideoRequest(''), 600);
    };

    return (
        <SliderDialog
            open={props.open}
            title={title}
            onClose={props.onClose}
            onSubmit={handleRequestVideo}
            submitButtonText={submitActionText}
            isEncompass={props.config?.isIntegrationPlatformEncompass()}
            isRunningStandAlone={isRunningStandAlone}
        >
            <div className={styles.mainContainer}>
                <div id="video-request-info" className={styles.requestInfo}>
                    <p id="video-request-conditions">
                        The system will attempt to wake up the camera to recover the video. If voltage to the camera is not sufficient, the
                        video will recall the next time the camera is{' '}
                        <span id="emphasized-condition" className={styles.emphasizedCondition}>
                            Online
                        </span>
                        .
                    </p>
                    <p id="video-request-location-info">Location information not available for recall.</p>
                </div>
                <VideoRequestForm
                    submitRecall={submitVideoRequest}
                    vehicle={[props.device]}
                    toggleRequestVideo={false}
                    isMobileView={false}
                    onSuccess={props.onClose}
                    onSuccessMessage="Video has been requested and will appear on the Video Recall page."
                ></VideoRequestForm>
            </div>
        </SliderDialog>
    );
};
