import { _, FreezerService } from 'Imports';
import { AgreementsService } from '$State/AgreementsFreezerService';
import { ConfigService } from '$State/ConfigFreezerService';
import { IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { PricingPlanService } from '$State/PricingPlanFreezerService';

const InjectedPropName = 'userManagement';

export interface IUserManagementState {
    hasError: boolean;
    userCredential: string;
    isUserDataLoaded: boolean;
    isCredentialRenewalActive: boolean;
}

export interface IntegrationUserCredential {
    xIntegrationUserCredential: string;
}

class UserManagementFreezerService extends FreezerService<IUserManagementState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                hasError: false,
                userCredential: '',
                isUserDataLoaded: false,
                isCredentialRenewalActive: false,
            },
            InjectedPropName,
        );
    }

    public getUserCredential() {
        return this.freezer.get().userCredential;
    }

    public setUserCredential(value: string) {
        this.freezer.get().set({ userCredential: value });
    }

    public isLoggedIn() {
        return !_.isEmpty(this.getUserCredential());
    }

    public generateIntegrationUserParams(): IntegrationUserCredential {
        this.tryLogin(); // TODO:  MIGHT Need a better, async friendly, way to handle expired creds or pulling creds out of local storage automatically if needed.

        const credential: any = this.isLoggedIn() ? this.getUserCredential() : '';
        return { xIntegrationUserCredential: credential };
    }

    public getIsUserDataLoaded(): boolean {
        return this.freezer.get().isUserDataLoaded;
    }

    public setIsUserDataLoaded(value: boolean) {
        this.freezer.get().set({ isUserDataLoaded: value });
    }

    public getIsCredentialRenewalActive(): boolean {
        return this.freezer.get().isCredentialRenewalActive;
    }

    public setIsCredentialRenewalActive(isActive: boolean) {
        this.freezer.get().set({ isCredentialRenewalActive: isActive });
    }

    public async setupUserData() {
        this.setIsUserDataLoaded(false);
        try {
            if (AgreementsService.getShowAgreements() && AgreementsService.getState().signedAgreementsResults.data == null) {
                await AgreementsService.getSignedAgreements();
            }

            await ConfigService.getFleetId();
            if (ConfigService.isFleetIdValid()) {
                await IntegrationPartnerDataService.getUserRoles();
                await IntegrationPartnerDataService.getUser();
                await IntegrationPartnerDataService.getDrivers();
                await IntegrationPartnerDataService.getDevices();
                await PricingPlanService.getPricingPlan();
            }
        } catch (e) {
            //log any errors to the console, but still set isUserLoaded state to true to bypass the loading spinner and reroute as needed depending on the application state
            console.error('UserManagementFreezer.setupUserData(): ' + e);
        }
        this.setIsUserDataLoaded(true);
    }

    public async tryLogin() {
        await IntegrationPartnerDataService.getIntegrationPartnerManager().handleCredentialExpiration(); // Check to ensure Authentication is still valid

        if (!this.isLoggedIn()) {
            await IntegrationPartnerDataService.getIntegrationPartnerManager().tryLogin();
        }
    }

    public getRoles() {
        return IntegrationPartnerDataService.getUserRolesState();
    }

    public logout() {
        this.freezer.get().set({ userCredential: '' });
        this.setIsUserDataLoaded(false);
        IntegrationPartnerDataService.getIntegrationPartnerManager().logout();
    }
}

export const UserManagementService = new UserManagementFreezerService();
export type IUserManagementServiceInjectedProps = ReturnType<UserManagementFreezerService['getPropsForInjection']>;
