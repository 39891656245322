import { React, _, InputMask, bind, phone } from 'Imports';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextValidator, ValidatorForm, FormControlLabel, Switch, Checkbox, Typography, IconButton } from 'MaterialUIComponents';

import { SubscriberResponse, SubscriberRequest, EventNotificationResponse } from '$Generated/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';

import {
    IEventNotificationSettingsServiceInjectedProps,
    EventNotificationSettingsService,
} from '$State/EventNotificationSettingsFreezerService';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';

const styles = require('./SubscriberDialog.scss') as {
    container: string;
    errorMessages: string;
    saveButton: string;
    cancelButton: string;
    checkbox: string;
    checkboxChecked: string;
    checkboxLabel: string;
    checkboxDeactivateAccountLabel: string;
    checkboxDeactivateAccountLabelChecked: string;
    checkboxDeactivateAccount: string;
    checkboxDeactivateAccountChecked: string;
    deactivateCheckbox: string;
    required: string;
    switchRoot: string;
    switchBase: string;
    switchChecked: string;
    switchTrack: string;
    switchThumb: string;
    dialog: string;
    dialogTitle: string;
    dialogTitleRoot: string;
    dialogTitleRootIntegrated: string;
    dialogScrollPaper: string;
    dialogPaperWidth: string;
    dialogPaper: string;
    dialogActions: string;
    formLabel: string;
    subscription: string;
    subscriptionsContainer: string;
    subscriptionLabel: string;
    actionButtons: string;
    switchTrackChecked: string;
    textError: string;
    iconPreferredMessage: string;
    columnLeft: string;
    columnRight: string;
    requiredLabel: string;
    dialogContent: string;
    formLabelGeneral: string;
    formLabelSubscriptions: string;
    closeIcon: string;
};

type SubscriberFieldsType = 'name' | 'email' | 'phone';

interface ISubscriberDialogBaseProps {
    errorMessage: string;
    eventTypeId?: number;
    subscriber?: SubscriberResponse;
    eventTypeNotificationName?: string;
    onCancel: () => void;
    refreshEventNotificationsList: () => void;
    refreshSubscribersList?: () => void;
}

type ISubscriberDialogProps = ISubscriberDialogBaseProps & IEventNotificationSettingsServiceInjectedProps & IConfigServiceInjectedProps;

interface ISubscriberDialogState {
    subscriber: SubscriberRequest;
    eventNotificationsList: EventNotificationResponse[];
    eventTypeNotification: string;
    errorMessages: string[];
    showSubscriptions: boolean;
    showDeactivateAccount: boolean;
}

class _SubscriberDialog extends React.Component<ISubscriberDialogProps, ISubscriberDialogState> {
    state = {
        subscriber: {
            id: 0,
            name: '',
            email: '',
            phone: '',
            emailPreferred: false,
            textPreferred: false,
            subscriptionEventTypeIds: [],
            subscriptionsCount: 0,
            isActive: false,
            dateSubscribed: undefined,
        },
        showSubscriptions: false,
        showDeactivateAccount: false,
        eventTypeNotification: '',
        eventNotificationsList: [],
        errorMessages: [],
    };

    node: HTMLDivElement | null = null;

    async componentDidMount(): Promise<void> {
        const { subscriber, eventTypeId } = this.props;

        if (subscriber) {
            await this.setState({
                subscriber: subscriber as SubscriberRequest,
                showDeactivateAccount: true,
                showSubscriptions: true,
            });
        } else if (eventTypeId == 0) {
            await this.setState({
                showSubscriptions: true,
            });
        }

        ValidatorForm.addValidationRule('basicPhoneNumber', (value: string) => {
            const phoneNormal = phone.normalizePhoneNumber(value);

            if (value && phoneNormal === null) {
                return false;
            }

            return true;
        });

        await this.getEventNotificationsList();
    }

    @bind
    async getEventNotificationsList(): Promise<void> {
        if (this.state.showSubscriptions) {
            await this.props.eventNotificationSettings.getEventNotificationSettings();

            this.setState({ eventNotificationsList: [] });
            const { getEventNotificationSettingsResults } = this.props.eventNotificationSettings.getState();
            this.setState({ eventNotificationsList: getEventNotificationSettingsResults.data || [] });
        } else {
            let newSubscriber: SubscriberRequest = _.clone(this.state.subscriber);
            newSubscriber['subscriptionEventTypeIds'] = [this.props.eventTypeId ? this.props.eventTypeId : 0];

            await this.setState({
                subscriber: newSubscriber,
            });
        }
    }

    componentWillUnmount(): void {
        ValidatorForm.removeValidationRule('basicPhoneNumber');
    }

    @bind
    cancel(): void {
        this.props.onCancel();
    }

    @bind
    async onSave(): Promise<void> {
        this.validateRequiredFields();
        if (!this.state.errorMessages.length) {
            try {
                if (this.state.subscriber.id > 0) {
                    await this.props.eventNotificationSettings.updateSubscriber(this.state.subscriber.id, this.state.subscriber);
                } else {
                    await this.props.eventNotificationSettings.createSubscriber(this.state.subscriber);
                }

                await this.props.refreshEventNotificationsList();
                this.props.onCancel();
            } catch (ex) {
                ex.text()
                    .then(function (text: string) {
                        return text;
                    })
                    .then((error: string) => {
                        this.setState({ errorMessages: [error] });
                    });
            }
        }
    }

    @bind
    validateRequiredFields(): void {
        this.setState({ errorMessages: [] });

        if (!this.state.subscriber.textPreferred && !this.state.subscriber.emailPreferred) {
            this.setState({ errorMessages: ['A preferred method to receive notifications must be chosen in order to proceed.'] });
        }
    }

    @bind
    handleFieldChange(field: SubscriberFieldsType, event: React.ChangeEvent<HTMLInputElement>): void {
        let newSubscriber: SubscriberRequest = _.clone(this.state.subscriber);

        switch (field) {
            case 'name':
                newSubscriber[field] = event.target.value.trim().length == 0 ? '' : event.target.value;
                break;
            case 'email':
            case 'phone':
                newSubscriber[field] = event.target.value;
                break;
        }

        this.setState({ subscriber: newSubscriber });
    }

    @bind
    async handleEmailCheckbox(checked: boolean) {
        let newSubscriber: SubscriberRequest = _.clone(this.state.subscriber);

        newSubscriber['emailPreferred'] = checked;
        await this.setState({
            subscriber: newSubscriber,
        });
    }

    @bind
    async handlePhoneCheckbox(checked: boolean) {
        let newSubscriber: SubscriberRequest = _.clone(this.state.subscriber);

        newSubscriber['textPreferred'] = checked;
        await this.setState({
            subscriber: newSubscriber,
        });
    }

    @bind
    async handleDeactivateAccountCheckbox(checked: boolean) {
        let newSubscriber: SubscriberRequest = _.clone(this.state.subscriber);

        newSubscriber['isActive'] = !checked;
        await this.setState({
            subscriber: newSubscriber,
        });
    }

    @bind
    async handleSubscriptionToggle(checked: boolean, value: string) {
        let newSubscriber: SubscriberRequest = _.clone(this.state.subscriber);
        let eventTypeId = Number(value);
        let currentSubscriptions = newSubscriber?.subscriptionEventTypeIds ?? [];
        let eventTypeIds: number[];
        var eventType: EventNotificationResponse[] =
            this.state.eventNotificationsList?.filter((item: EventNotificationResponse) => item.eventTypeId == eventTypeId) ?? [];

        if (eventType[0].mergeableEventTypeIds?.length) {
            eventTypeIds = eventType[0].mergeableEventTypeIds;
        } else {
            eventTypeIds = [eventTypeId];
        }

        if (!checked) {
            newSubscriber['subscriptionEventTypeIds'] = currentSubscriptions?.filter(
                (item: number) => !(eventTypeIds.indexOf(item) !== -1),
            );
        } else {
            newSubscriber['subscriptionEventTypeIds'] = [...currentSubscriptions, ...eventTypeIds];
        }

        await this.setState({
            subscriber: newSubscriber,
        });
    }

    render(): JSX.Element {
        const { showDeactivateAccount, showSubscriptions, eventTypeNotification, eventNotificationsList } = this.state;
        const isEncompass = this.props.config.isIntegrationPlatformEncompass();
        const isRunningStandAlone = this.props.config.isRunningStandAlone();
        const errorMessages = this.state.errorMessages.length
            ? this.state.errorMessages
            : this.props.errorMessage
            ? [this.props.errorMessage]
            : [];

        return (
            <Dialog
                open={true}
                classes={{ scrollPaper: styles.dialogScrollPaper, paper: styles.dialogPaper, paperWidthSm: styles.dialogPaperWidth }}
            >
                <ValidatorForm
                    ref="form"
                    onSubmit={this.onSave}
                    onError={(errors: any[]) => {
                        this.setState({
                            errorMessages: errors.map((x) => {
                                return `${x.props.label}: ${x.getErrorMessage()}`;
                            }),
                        });
                    }}
                    className={styles.container}
                >
                    <DialogTitle classes={{ root: styles.dialogTitle }}>
                        <div className={!isEncompass && !isRunningStandAlone ? styles.dialogTitleRootIntegrated : styles.dialogTitleRoot}>
                            <span>{showDeactivateAccount ? 'Edit' : 'Add'} subscriber</span>
                            <span className={styles.closeIcon}>
                                <FontAwesomeIcon icon={faTimes} onClick={this.cancel} />
                            </span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {errorMessages.length ? (
                            <div className={styles.textError}>
                                <div className={styles.columnLeft}>
                                    <FontAwesomeIcon icon={isEncompass ? faTimesCircle : faTimes} className={styles.iconPreferredMessage} />
                                </div>
                                {errorMessages.map((x, index) => (
                                    <div className={styles.columnRight} key={index}>
                                        {x}
                                    </div>
                                ))}
                            </div>
                        ) : null}

                        <div className={styles.dialogContent}>
                            <div className={styles.formLabelGeneral}>General Information</div>
                            {isEncompass && <br></br>}
                            <div className={styles.formLabel}>
                                Name{isEncompass ? <span className={styles.requiredLabel}>Required</span> : ' *'}
                            </div>
                            <TextValidator
                                margin="dense"
                                fullWidth={true}
                                variant={isEncompass ? 'outlined' : 'standard'}
                                onChange={(e: any): void => this.handleFieldChange('name', e)}
                                name="firstName"
                                value={this.state.subscriber.name}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                required={true}
                                placeholder={'Enter name'}
                            />
                            <div className={styles.formLabel}>
                                Email address
                                {this.state.subscriber.emailPreferred && isEncompass && (
                                    <span className={styles.requiredLabel}>Required</span>
                                )}
                                {this.state.subscriber.emailPreferred && !isEncompass && ' *'}
                            </div>
                            <TextValidator
                                margin="dense"
                                fullWidth={true}
                                variant={isEncompass ? 'outlined' : 'standard'}
                                onChange={(e: any): void => this.handleFieldChange('email', e)}
                                name="emailAddress"
                                value={this.state.subscriber.email}
                                validators={['isEmail']}
                                errorMessages={['Email is not valid']}
                                required={this.state.subscriber.emailPreferred}
                                placeholder={'Enter email address'}
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={this.state.subscriber.emailPreferred ? styles.checkboxChecked : styles.checkbox}
                                        onChange={(e) => this.handleEmailCheckbox(e.target.checked)}
                                        checked={this.state.subscriber.emailPreferred}
                                        disableRipple={true}
                                    />
                                }
                                label={<Typography className={styles.checkboxLabel}>Preferred method to receive notifications</Typography>}
                            />

                            <div className={styles.formLabel}>
                                Phone number
                                {this.state.subscriber.textPreferred && isEncompass && (
                                    <span className={styles.requiredLabel}>Required</span>
                                )}
                                {this.state.subscriber.textPreferred && !isEncompass && ' *'}
                            </div>
                            <InputMask
                                maskChar=""
                                mask="999-999-9999"
                                onChange={(e: any): void => this.handleFieldChange('phone', e)}
                                name="phone"
                                value={this.state.subscriber.phone}
                                type="text"
                            >
                                {(inputProps: any) => (
                                    <TextValidator
                                        margin="dense"
                                        fullWidth={true}
                                        variant={isEncompass ? 'outlined' : 'standard'}
                                        validators={['basicPhoneNumber']}
                                        placeholder={isEncompass ? 'e.g. 999-999-9999' : 'Enter phone number'}
                                        required={this.state.subscriber.textPreferred}
                                        errorMessages={['Phone number is not valid']}
                                        {...inputProps}
                                    />
                                )}
                            </InputMask>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={this.state.subscriber.textPreferred ? styles.checkboxChecked : styles.checkbox}
                                        onChange={(e) => this.handlePhoneCheckbox(e.target.checked)}
                                        checked={this.state.subscriber.textPreferred}
                                        disableRipple={true}
                                    />
                                }
                                label={<Typography className={styles.checkboxLabel}>Preferred method to receive notifications</Typography>}
                            />
                        </div>
                        {showSubscriptions && (
                            <>
                                <div className={styles.formLabelSubscriptions}>Subscriptions</div>
                            </>
                        )}
                        {showSubscriptions && (
                            <div className={styles.subscriptionsContainer}>
                                {eventNotificationsList?.map((eventNotification: EventNotificationResponse, idx) => {
                                    return (
                                        <div className={styles.subscription} key={idx + 'div'}>
                                            <FormControlLabel
                                                key={idx + 'label'}
                                                control={
                                                    <Switch
                                                        key={eventNotification.eventTypeId}
                                                        checked={
                                                            this.state.subscriber?.subscriptionEventTypeIds?.filter(
                                                                (value) => value == eventNotification.eventTypeId,
                                                            ).length > 0
                                                        }
                                                        onChange={(e) => this.handleSubscriptionToggle(e.target.checked, e.target.value)}
                                                        value={eventNotification.eventTypeId}
                                                        size={'small'}
                                                        disableRipple={true}
                                                        classes={{
                                                            root: styles.switchRoot,
                                                            switchBase: styles.switchBase,
                                                            checked: styles.switchChecked,
                                                            track:
                                                                this.state.subscriber?.subscriptionEventTypeIds?.filter(
                                                                    (value) => value == eventNotification.eventTypeId,
                                                                ).length > 0
                                                                    ? styles.switchTrackChecked
                                                                    : styles.switchTrack,
                                                            thumb: styles.switchThumb,
                                                        }}
                                                    />
                                                }
                                                label={eventNotification.name}
                                                classes={{ label: styles.subscriptionLabel }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions className={styles.dialogActions}>
                        {showDeactivateAccount ? (
                            <span className={styles.deactivateCheckbox}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            className={
                                                this.state.subscriber.isActive
                                                    ? styles.checkboxDeactivateAccount
                                                    : styles.checkboxDeactivateAccountChecked
                                            }
                                            onChange={(e) => this.handleDeactivateAccountCheckbox(e.target.checked)}
                                            checked={!this.state.subscriber.isActive}
                                            disableRipple={true}
                                        />
                                    }
                                    label={
                                        <Typography
                                            className={
                                                this.state.subscriber.isActive
                                                    ? styles.checkboxDeactivateAccountLabel
                                                    : styles.checkboxDeactivateAccountLabelChecked
                                            }
                                        >
                                            Deactivate account
                                        </Typography>
                                    }
                                />
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className={styles.actionButtons}>
                            <Button onClick={this.cancel} variant="text" className={styles.cancelButton}>
                                Cancel
                            </Button>
                            <Button type="submit" className={styles.saveButton}>
                                {showDeactivateAccount ? 'Save' : 'Add'}
                            </Button>
                        </div>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }
}

export const SubscriberDialog = ConfigService.inject(EventNotificationSettingsService.inject(_SubscriberDialog));