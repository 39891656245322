import { React } from 'Imports';
import { RouteComponentProps, withRouter } from 'react-router';
import { Card, CardContent, Grid, Typography } from 'MaterialUIComponents';
import { StatisticsDashboardFilterModel, EventStatus, VideoEventWorkflowStatusIdEnum } from '../../../generated/api';
import {
    IIntegrationPartnerDataInjectedProps,
    IntegrationPartnerDataService,
} from '../../../state/IntegrationPartnerDataFreezerService';
import { IConfigServiceInjectedProps, ConfigService } from '../../../state/ConfigFreezerService';
import {
    IVideoEventFilterServiceMultiselectInjectedProps,
    VideoEventFilterServiceMultiselect,
} from '../../../state/VideoEventFilterFreezerServiceMultiselect';
import { useEffect, useState } from 'react';
import { WidgetTooltip } from '$Components/Dashboards/WidgetTooltip';

interface IEventStatusWidgetProps {
    eventStatusRequested: EventStatus | undefined;
    dateRange: StatisticsDashboardFilterModel;
    showDismissed: boolean;
}

type EventStatusWidgetProps = IEventStatusWidgetProps &
    IVideoEventFilterServiceMultiselectInjectedProps &
    IConfigServiceInjectedProps &
    IIntegrationPartnerDataInjectedProps &
    RouteComponentProps;

const styles = require('../styles/EventStatusWidget.scss') as {
    title: string;
    status: string;
    card: string;
    cardContent: string;
    statusLabel: string;
    statusCount: string;
    lastUpdated: string;
};
const _EventStatusWidget = (props: EventStatusWidgetProps) => {
    const [eventStatusData, setEventStatusData] = useState<EventStatus>();
    const { eventStatusRequested, dateRange, videoEventFilterMultiselect, history, integrationPartnerData, showDismissed } = props;

    useEffect(() => {
        setEventStatusData(eventStatusRequested);
    }, [eventStatusRequested]);

    const navigateToStatus = (statusKey?: VideoEventWorkflowStatusIdEnum): void => {
        // can't filter to "all time" - use 30 days
        const end = dateRange.endDate;
        const start = dateRange.startDate;

        statusKey != undefined
            ? videoEventFilterMultiselect.updateFilterOptions([], [], [], start, end, false, false, [statusKey])
            : videoEventFilterMultiselect.updateFilterOptions([], [], [], start, end, false, false);
        history.push(integrationPartnerData.getIntegrationPartnerManager().getLinkUrl('/videoevents'));
    };
    const dismissedStatus = 'Dismissed' as VideoEventWorkflowStatusIdEnum;
    return (
        <>
            <Grid container>
            <WidgetTooltip
                prompt="What do the statuses mean?"
                description={<div style={{width:'600px'}}><strong>New:</strong> A video event that has not been watched or shared.
                    <br/><strong>In Progress:</strong> A video event that has been watched by at least one user.
                    <br/><strong>Sent to Driver:</strong> A video event that has been shared and not accepted or disputed yet.
                    <br/><strong>Driver Accepted:</strong> A video event that has been shared and accepted by recipient.
                    <br/><strong>Driver Disputed:</strong> A video event that has been shared and disputed by recipient.
                    <br/><strong>No Response:</strong> A video event that has been shared and recipient has not responded in 12+ hours.
                    <br/><strong>Completed:</strong> A video event that was marked complete after an action was taken.
                    <br/><strong>Dismissed:</strong> A video event that was marked dismissed after determining no action was needed.</div>}
                contentPosition='bottomRight'
            />
                <Grid item xs={6}>
                    <Typography className={styles.title}>Event Status Queue</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent={'flex-start'}>
                {eventStatusData?.data
                    ?.filter((i) => showDismissed || i.statusKey != dismissedStatus)
                    .map((x, idx) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={idx} className={styles.status}>
                            <Card className={styles.card} onClick={() => navigateToStatus(x.statusKey)}>
                                <CardContent className={styles.cardContent}>
                                    <Typography className={styles.statusLabel}>{x.label}</Typography>

                                    <Typography className={styles.statusCount}>{x.count}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                ))}
            </Grid>
        </>
    );
};

export const EventStatusWidget = withRouter(
    ConfigService.inject(
        VideoEventFilterServiceMultiselect.inject(IntegrationPartnerDataService.inject(_EventStatusWidget)),
    ),
);
