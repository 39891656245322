import { createTheme } from 'MaterialUIComponents';

const theme = createTheme({
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
    },
});

export default theme;
