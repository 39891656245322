import { React, moment } from 'Imports';
import * as DateFormatter from '$Components/Shared/DateFormatter';

interface IVideoRecallRequestedByProps {
    requestedOn?: Date;
    requestedBy?: string;
    className?: string;
}

type VideoRecallRequestedByProps = IVideoRecallRequestedByProps;

export function VideoRecallRequestedBy(props: VideoRecallRequestedByProps) {
    const { className, requestedOn, requestedBy } = props;

    return (
        <>
            <div className={className}>
                Requested {DateFormatter.dateAndTimezone(moment(requestedOn) || DateFormatter.dateAndTimezone(moment()))} <br />
                by {requestedBy || 'Unknown'}
            </div>
        </>
    );
}
