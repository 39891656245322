import { Tooltip, styled } from 'MaterialUIComponents';
import { React } from 'Imports';
import { UserResponse } from '$Generated/api';
import { IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import * as _ from 'lodash';
import * as scssStyles from '$CSS/settings.scss';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface IVideoEventFlaggedByUsersCaptionProps {
    flaggedByUsers: Array<UserResponse>;
    tooltipPlacement?: TooltipProps['placement'];
    popperDisablePortal?: boolean;
}

const styles = require('$Components/Shared/VideoEventFlaggedByUsersCaption.scss') as {
    caption: string;
    blueCaption: string;
    numUsersDescription: string;
    tooltipPlacementTop: string;
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: scssStyles.lightGreyTypography,
            boxShadow: theme.shadows[1],
            textAlign: 'start',
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.white,
            '&:before': {
                boxShadow: theme.shadows[1],
            },
        },
    }),
);

export class VideoEventFlaggedByUsersCaption extends React.Component<IVideoEventFlaggedByUsersCaptionProps> {
    render(): JSX.Element {
        const eventIsFlaggedByCurrentUser =
            _.find(this.props.flaggedByUsers, (user: UserResponse) => {
                return user.integrationProviderSourceId === IntegrationPartnerDataService.getState().currentUserResult.data.id;
            }) !== undefined;
        const eventFlaggedByOtherUsersList = _.filter(this.props.flaggedByUsers, (user: UserResponse) => {
            return user.integrationProviderSourceId !== IntegrationPartnerDataService.getState().currentUserResult.data.id;
        });
        const numUsersDescription =
            eventFlaggedByOtherUsersList.length === 1
                ? eventIsFlaggedByCurrentUser
                    ? '1 other'
                    : '1 person'
                : `${eventFlaggedByOtherUsersList.length} ${eventIsFlaggedByCurrentUser ? 'others' : 'people'}`;
        let description = 'Flagged by ';
        if (eventIsFlaggedByCurrentUser) {
            description += 'you';
            if (eventFlaggedByOtherUsersList.length > 0) {
                description += ' and ';
            }
        }

        return (
            <div className={scssStyles.styleEnvironment === 'encompass' ? styles.caption : styles.caption + ' ' + styles.blueCaption}>
                {this.props.flaggedByUsers.length > 0 && (
                    <>
                        <span>{description}</span>
                        {eventFlaggedByOtherUsersList.length > 0 && (
                            <StyledTooltip
                                title={
                                    <React.Fragment>
                                        {eventFlaggedByOtherUsersList.map((user: UserResponse, index: number) => (
                                            <div key={index}>{user.displayName}</div>
                                        ))}
                                    </React.Fragment>
                                }
                                classes={{
                                    tooltipPlacementTop: styles.tooltipPlacementTop,
                                }}
                                arrow
                                placement={this.props.tooltipPlacement}
                                PopperProps={{
                                    disablePortal: this.props.popperDisablePortal,
                                }}
                            >
                                <span className={styles.numUsersDescription}>{numUsersDescription}</span>
                            </StyledTooltip>
                        )}
                    </>
                )}
            </div>
        );
    }
}
