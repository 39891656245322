import { CompleteForm } from '../../../../VideoEvents/VideoEventDetails/CompleteForm';
import { React } from '../../../../../Imports';
import { Section } from '../Section';
import { VideoEventResponse } from '$Generated/api';

const styles = require('./ActionTakenSection.scss') as {
    actionTakenSection: string;
    sectionContent: string;
};

interface IActionTakenSectionProps {
    canComplete: boolean;
    canEdit: boolean;
    event: VideoEventResponse;
    handleCompleteVideo: (eventId: number | undefined, action: string) => void;
    handleDismissVideo: (eventId: number | undefined) => void;
    handleReopened: (eventId: number | undefined) => void;
    prevNextSplitOn: boolean;
}

const _ActionTakenSection: React.FC<IActionTakenSectionProps> = ({
    canComplete,
    canEdit,
    event,
    handleCompleteVideo,
    handleDismissVideo,
    handleReopened,
    prevNextSplitOn,
}) => {
    return (
        <Section
            title="Action Taken"
            canEdit={canEdit}
            content={
                <div className={styles.actionTakenSection}>
                    <div className={styles.sectionContent}>
                        <CompleteForm
                            eventId={event.id}
                            actionTaken={event.adminResolution}
                            canComplete={canComplete}
                            handleDismiss={handleDismissVideo}
                            handleReopen={handleReopened}
                            handleComplete={handleCompleteVideo}
                            prevNextSplitOn={prevNextSplitOn}
                        />
                    </div>
                </div>
            }
        />
    );
};

export const ActionTakenSection = _ActionTakenSection;
