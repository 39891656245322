import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React } from '../../Imports';

import * as scssStyles from '../../css/settings.scss';

const styles = require('./TimeField.scss') as {
    main: string;
    fieldLabel: string;
    fieldLabelSmall: string;
    iconContainer: string;
    icon: string;
    fieldValue: string;
    fieldExtraInfo: string;
    container: string;
    valueContainer: string;
};

interface ITimeFieldProps {
    time?: string;
    ampm?: string;
    timeBefore?: number;
    timeBeforeText?: string;
    timeAfter?: number;
    timeAfterText?: string;
    timezone?: string;
    timezoneOffset?: number;
}

class _TimeField extends React.PureComponent<ITimeFieldProps> {
    render(): JSX.Element {
        const { time, ampm, timezone, timeBeforeText, timeAfterText } = this.props;
        const emptyField = typeof time === 'undefined';
        const eventTimeLabel = scssStyles.styleEnvironment === 'encompass' ? 'Time*' : 'Event Time*';
        const inputWidths = scssStyles.styleEnvironment === 'encompass' ? 220 : 180;

        return (
            <div className={styles.main} style={{ width: inputWidths }}>
                <div className={[styles.fieldLabel, !emptyField ? styles.fieldLabelSmall : ''].join(' ')}>{eventTimeLabel}</div>
                <div className={styles.container}>
                    <div className={styles.valueContainer}>
                        <div className={!emptyField ? styles.fieldValue : ''}>
                            {!emptyField && (
                                <span>
                                    {time} {ampm} {timezone}
                                </span>
                            )}
                        </div>
                        <div className={styles.iconContainer}>
                            <FontAwesomeIcon icon={faClock} className={styles.icon} />
                        </div>
                    </div>
                    {!emptyField && (
                        <div className={!emptyField ? styles.fieldExtraInfo : ''}>
                            <div>{timeBeforeText} before</div>
                            <div>{timeAfterText} after</div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const TimeField = _TimeField;
