import { React, bind, _ } from 'Imports';

import {
    TableBody,
    TableHead,
    Table,
    TableCell,
    TableRow,
    TableSortLabel,
    ButtonGroup,
    TablePagination,
    TableContainer,
    TextField,
    InputAdornment,
    Button,
    Typography
} from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';

import { UserRolesResponse } from '$Generated/api';
import { RolesEnum } from '$Externals/VerifyRole';

import {
    IUserRoleServiceInjectedProps,
    UserRoleService
} from '$State/UserRoleFreezerService';

import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';

interface IUserRolePermissionsBaseProps {
    refreshRoles: (email: string) => void;
}

type IUserRolePermissionsProps = IUserRolePermissionsBaseProps & IConfigServiceInjectedProps & IUserRoleServiceInjectedProps;

interface IUserRolePermissionsState {
    usersList?: UserRolesResponse[];
    usersListFiltered?: UserRolesResponse[];
    editErrorMessage: string;
    selectedUserId: number;
    page: number;
    rowsPerPage: number;
    userFilter: string;
    isSearchBarFocused: boolean;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    messageOnRow: {message: string, userId: string};
}

const styles = require('./UserRolePermissions.scss') as {
    descriptionContainer: string;
    iconButton: string;
    inputAdormentFilter: string;
    searchIcon: string;
    searchIconFocused: string;
    userFilter: string;
    userIntegrationFilter: string;
    usersTable: string;
    centerText: string;
    nameColumn: string;
    emailColumn: string;
    rolesColumn: string;
    closeButtonSearchFilter: string;
    inputAdormentCloseFilter: string;
    closeIcon: string;
    searchBarInput: string;
    inputFocused: string;
    roleButton: string;
    roleButtonSelected: string;
    roleButtonText: string;
    roleButtonSubtext: string;
    validationMessage: string;
};

const rowHeaders = [
    {
        id: 'name',
        label: 'User',
        style: styles.nameColumn,
        sortable: true
    },
    {
        id: 'email',
        label: 'Email Address',
        style: styles.emailColumn,
        sortable: true
    },
    {
        id: 'roles',
        label: 'Roles',
        style: styles.rolesColumn,
        sortable: false
    }
];

class _UserRolePermissions extends React.Component<IUserRolePermissionsProps, IUserRolePermissionsState> {
    state: IUserRolePermissionsState = {
        usersList: undefined,
        usersListFiltered: undefined,
        selectedUserId: 0,
        editErrorMessage: '',
        page: 0,
        rowsPerPage: 10,
        userFilter: '',
        isSearchBarFocused: false,
        sortBy: 'name',
        sortOrder: 'asc',
        messageOnRow: {message: '', userId: ''},
    };

    async componentDidMount(): Promise<void> {
        await this.getUsersList();
    }

    @bind
    async getUsersList(): Promise<void> {
        await this.props.userRoles.getUserRolePermissions();
        this.setState({ usersList: undefined, usersListFiltered: undefined })
        const { getUserRolePersmissionsResults } = this.props.userRoles.getState();

        if (this.state.userFilter) {
            this.setState({ 
                usersList: getUserRolePersmissionsResults.data || []
            });
            this.handleUserFilterChange(this.state.userFilter);
        } else {
            this.setState({ 
                usersList: getUserRolePersmissionsResults.data || [],
                usersListFiltered: getUserRolePersmissionsResults.data || []
            });
        }
    }

    @bind
    async handleChangePage(event: any, newPage: number): Promise<void> {
        this.setState({
            page: newPage
        });
    }

    @bind
    async handleChangePagination(event: any, newPage: number): Promise<void> {        
        this.setState({
            page: newPage - 1
        });
    }

    @bind
    handleChangeRowsPerPage(event: any): void {
        this.setState({
            rowsPerPage: event.target.value,
            page : 0
        });
    }

    @bind
    handleUserFilterChange(filter: string): void{
        this.setState({
            userFilter: filter
        });

        const filteredRows = this.state.usersList?.filter((row) => {
            return row?.name?.toLowerCase().includes(filter.toLowerCase());
        });

        this.setState({
            usersListFiltered: filteredRows
        });
    }
    
    @bind
    handleClearSearchFilter(): void{
        this.setState({
            userFilter: ''
        });

        this.setState({
            usersListFiltered: this.state.usersList
        });
    }

    @bind
    calculatePagesCount(pageSize: number, totalCount : number): number {
        // we suppose that if we have 0 items we want 1 empty page
        return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
    };

    async handleRequestSort(field: string, changeSort: boolean = true): Promise<void> {
        const { sortBy, sortOrder, usersList, userFilter } = this.state;

        let isDesc = false;
        if (changeSort) {
            isDesc = sortBy === field && sortOrder === 'asc';
        } else {
            isDesc = sortOrder === 'desc';
        }

        const sortItems = usersList?.sort((a, b) => {
            let res = 0;
            let aString = '';
            let bString = '';
            switch (field) {
                case 'name': 
                case 'email': 
                    aString = a[field] ? a[field]! : '';
                    bString = b[field] ? b[field]! : '';
                    res = aString.localeCompare(bString);
                    break;                            
                default:
            }

            return isDesc ? -1 * res : res;
        });

        await this.setState({
            sortBy: field,
            sortOrder: isDesc ? 'desc' : 'asc',
            usersList: sortItems
        })

        this.handleUserFilterChange(userFilter);
    }

    @bind
    onFocusSearchBar(isFocused: boolean): void{
        this.setState({
            isSearchBarFocused: isFocused
        })
    }

    @bind
    async onClickRoleButton(selectedRole: RolesEnum, userId: string, userEmail: string, roles: string[], rowIndex: number): Promise<void>{
        let roleIds = roles.map((id) => parseInt(id));  
        const selectedRoleId = selectedRole.toString();

        //First 3 conditions were created to allow multiple selection between VideoEventEdit and VideoRecallEdit
        if (selectedRole == RolesEnum.videoEventEdit && roles.indexOf(selectedRoleId) === -1 && roles.indexOf(RolesEnum.videoRecallEdit.toString()) !== -1) {
            roleIds.push(parseInt(selectedRoleId)); 
        } 
        else if (selectedRole == RolesEnum.videoRecallEdit && roles.indexOf(selectedRoleId) === -1 && roles.indexOf(RolesEnum.videoEventEdit.toString()) !== -1) {
            roleIds.push(parseInt(selectedRoleId));
        } 
        else if ((selectedRole == RolesEnum.videoEventEdit || selectedRole == RolesEnum.videoRecallEdit) && 
            roles.indexOf(RolesEnum.videoEventEdit.toString() && RolesEnum.videoRecallEdit.toString()) !== -1) {
            roleIds = selectedRole == RolesEnum.videoEventEdit ? [RolesEnum.videoRecallEdit] : [RolesEnum.videoEventEdit];
        }
        else if (roles.indexOf(selectedRoleId) !== -1) {
            roleIds = [];
        } else {
            roleIds = [parseInt(selectedRoleId)];
        }

        const userRolesUpdate = {
            "userId" : userId,
            "email": userEmail,
            "roles" : roleIds
        }
        try{
            await this.props.userRoles.updateUserRoles(userRolesUpdate);

            if (selectedRole == RolesEnum.videoSuperAdmin) {
                this.props.refreshRoles(userEmail);
            }
            await this.getUsersList();
            this.handleRequestSort(this.state.sortBy, false);
        } catch (ex){
            const errorMessage = await ex.text()
                .then(function (text: string) {
                    return text;
                });

            this.setState({messageOnRow: {message: errorMessage, userId: userId}});
            setTimeout(() => {this.setState({messageOnRow: {message: '', userId: ''}})}, 5000);
        }
    }

    render(): JSX.Element {
        const { editErrorMessage, usersListFiltered, page, rowsPerPage, userFilter } = this.state;
        const isRunningStandAlone = this.props.config.isRunningStandAlone();

        return (
            <div className={styles.descriptionContainer}>
                <div className={isRunningStandAlone ? styles.userFilter : styles.userIntegrationFilter}>
                    <TextField
                        value={userFilter}
                        onChange={(e) => this.handleUserFilterChange(e.target.value)}
                        onFocus={() => this.onFocusSearchBar(true)}
                        onBlur={() => this.onFocusSearchBar(false)}
                        className={styles.searchBarInput}
                        placeholder={'Search for a user...'}                     
                        InputProps={{
                            style: {},
                            classes: {
                                focused: styles.inputFocused
                            },
                            disableUnderline: false, 
                            startAdornment: 
                                <InputAdornment className={styles.inputAdormentFilter} position={'start'}>
                                    <FontAwesomeIcon icon={faSearch} className={this.state.isSearchBarFocused ? styles.searchIconFocused : styles.searchIcon} />
                                </InputAdornment>,
                            endAdornment: 
                                <Button onClick={this.handleClearSearchFilter} className={styles.closeButtonSearchFilter} hidden={userFilter ? false : true}>
                                    <InputAdornment className={styles.inputAdormentCloseFilter} position={'end'}>
                                        <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />
                                    </InputAdornment>
                                </Button>
                            
                        }}
                        InputLabelProps={{style: {marginLeft: 0}}}
                    />
                </div>
                <TableContainer>
                <Table className={styles.usersTable}>
                    <TableHead>
                        <TableRow>
                            {rowHeaders.map((head, idx) => {
                                return (
                                    head.sortable ? 
                                        <TableCell key={idx} className={head.style} sortDirection={this.state.sortBy === head.id ? this.state.sortOrder : false}>
                                            <TableSortLabel
                                                active={this.state.sortBy === head.id}
                                                direction={this.state.sortOrder}
                                                onClick={(): Promise<void> => this.handleRequestSort(head.id)}
                                            >
                                                {head.label}
                                            </TableSortLabel>
                                        </TableCell> : 
                                        <TableCell key={idx} className={head.style}>
                                            {head.label}
                                        </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersListFiltered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user: UserRolesResponse, idx) => {
                            return (
                                <TableRow key={idx} >
                                    <TableCell className={styles.nameColumn}>
                                        {user.name}
                                    </TableCell>
                                    <TableCell>
                                        {user.email}
                                    </TableCell>
                                    <TableCell className={styles.rolesColumn}>
                                        <ButtonGroup>
                                            <Button 
                                                onClick={() => this.onClickRoleButton(RolesEnum.videoEventView, user.userId ?? '', user.email ?? '', user.roleIds ?? [], idx)}
                                                className={[styles.roleButton, user.roleIds && user.roleIds.indexOf(RolesEnum.videoEventView.toString()) !== -1 ? styles.roleButtonSelected : '' ].join(' ')}
                                                size='medium'>
                                                <Typography className={styles.roleButtonText}>View Only</Typography>
                                                <Typography className={styles.roleButtonSubtext}>Video Events</Typography>
                                            </Button>
                                            <Button 
                                                onClick={() => this.onClickRoleButton(RolesEnum.videoEventEdit, user.userId ?? '', user.email ?? '', user.roleIds ?? [], idx)}
                                                className={[styles.roleButton, user.roleIds && user.roleIds.indexOf(RolesEnum.videoEventEdit.toString()) !== -1 ? styles.roleButtonSelected : '' ].join(' ')}
                                                size='medium'>
                                                <Typography className={styles.roleButtonText}>View + Edit</Typography>
                                                <Typography className={styles.roleButtonSubtext}>Video Events</Typography>
                                            </Button>
                                            <Button 
                                                onClick={() => this.onClickRoleButton(RolesEnum.videoRecallEdit, user.userId ?? '', user.email ?? '', user.roleIds ?? [], idx)}
                                                className={[styles.roleButton, user.roleIds && user.roleIds.indexOf(RolesEnum.videoRecallEdit.toString()) !== -1 ? styles.roleButtonSelected : '' ].join(' ')}
                                                size='medium'>
                                                <Typography className={styles.roleButtonText}>View + Edit</Typography>
                                                <Typography className={styles.roleButtonSubtext}>Video Recall</Typography>
                                            </Button>
                                            <Button 
                                                onClick={() => this.onClickRoleButton(RolesEnum.videoAdmin, user.userId ?? '', user.email ?? '', user.roleIds ?? [], idx)}
                                                className={[styles.roleButton, user.roleIds && (user.roleIds.indexOf(RolesEnum.videoAdmin.toString()) !== -1 && user.roleIds.indexOf(RolesEnum.videoSuperAdmin.toString()) == -1) ? styles.roleButtonSelected : '' ].join(' ')}
                                                size='medium'>
                                                <Typography className={styles.roleButtonText}>Admin</Typography>
                                            </Button>
                                            <Button 
                                                onClick={() => this.onClickRoleButton(RolesEnum.videoSuperAdmin, user.userId ?? '', user.email ?? '', user.roleIds ?? [], idx)}
                                                className={[styles.roleButton, user.roleIds && user.roleIds.indexOf(RolesEnum.videoSuperAdmin.toString()) !== -1 ? styles.roleButtonSelected : '' ].join(' ')}
                                                size='medium'>
                                                <Typography className={styles.roleButtonText}>Super Admin</Typography>
                                            </Button>
                                        </ButtonGroup>
                                        {this.state.messageOnRow.userId == user.userId &&
                                            <span className={styles.validationMessage}>{this.state.messageOnRow.message}</span>
                                        }
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table> 
                </TableContainer>   
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[10, 20, 50]}
                        count={usersListFiltered?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                />               
            </div>
        );
    }
}

export const UserRolePermissions = ConfigService.inject(UserRoleService.inject(_UserRolePermissions));
