import { moment } from "../../Imports";

export function dateAndTimezone(date: moment.Moment) {
    return date.tz(moment.tz.guess()).format("L h:mm A z");
}
export function dateAndTimezoneWithSeconds(date: moment.Moment) {
    return date.tz(moment.tz.guess()).format("L h:mm:ss A z");
}
export function date(date: moment.Moment) {
    return date.format("L")
}
export function timeOfDay(date: moment.Moment) {
    return moment.tz(date.toString(), moment.tz.guess()).format("h:mm A");
}
export function timeWithTimezone(date: moment.Moment) {
    return date.tz(moment.tz.guess()).format("h:mm A z");
}
export function timeWithSecondsAndTimezone(date: moment.Moment) {
    return date.tz(moment.tz.guess()).format("h:mm:ss A z");
}
export function getTime(date: moment.Moment) {
    return date.tz(moment.tz.guess()).format("h:mm A");
}
export function getUnixTimeSeconds(date: string | Date): number {
    return new Date(date).getTime() / 1000;
}
export function getUtcString(date: moment.Moment) {
    return date.utc().format();
}
export function formatCsvTime(date: moment.Moment, guessTimezone: boolean) {
    if (guessTimezone) {
        return moment(date).tz(moment.tz.guess()).format("YYYY-MM-DD hh:mm:ss z"); 
    }
    return moment(date).utc().format("YYYY-MM-DD hh:mm:ss");
}
export function getDaysAgo(fromDate: moment.Moment) {
    if (fromDate) {
        const date = moment(fromDate);
        const now = moment();
        let daysAgo = "";
        if (date.month() === now.month() && date.day() === now.day() && date.year() === now.year()) {
            daysAgo = "Today";
        } else {
            const dayNum = Math.ceil((now.valueOf() - date.valueOf()) / (24 * 3600 * 1000));
            daysAgo = dayNum + " day" + (dayNum > 1 ? "s" : "") + " ago";
        }
        return daysAgo;
    }
    return "";
}
export function dateWithMonthAbbr(date: moment.Moment) {
    return moment.tz(date.toString(), moment.tz.guess()).format("MMM DD, YYYY");
}
export function timeWithMilliseconds(date: moment.Moment) {
    return date.tz(moment.tz.guess()).format("h:mm:ss.SSS");
}

export function getTimezoneString(date: moment.Moment = moment()) {
    return date.tz(moment.tz.guess()).format("z"); 
}