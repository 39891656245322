import { React } from 'Imports';
import { faGrid3, faList } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleButton, ToggleButtonGroup } from 'MaterialUIComponents';

interface IToggleButtonProps {
    handleEventLayoutChange: (e: any, value: 'tile' | 'list') => void;
    videoEventViewType?: string;
}

const styles = require('$Components/Shared/GridListToggleButton.scss') as {
    viewTypeToggleContainer: string;
    viewTypeToggle: string;
    activeToggleIcon: string;
    inactiveToggleIcon: string;
    toggleButton: string;
};

export const GridListToggleButton = (props: IToggleButtonProps) => {
    const { videoEventViewType, handleEventLayoutChange } = props;

    return (
        <>
            <div className={styles.viewTypeToggleContainer}>
                <ToggleButtonGroup
                    exclusive={true}
                    className={styles.viewTypeToggle}
                    value={videoEventViewType}
                    onChange={(e, value) => handleEventLayoutChange(e, value)}
                >
                    <ToggleButton
                        value="tile"
                        title="Grid View"
                        className={[
                            styles.toggleButton,
                            videoEventViewType == 'tile' ? styles.activeToggleIcon : styles.inactiveToggleIcon,
                        ].join(' ')}
                        sx={{
                            '& svg': {
                                position: 'absolute',
                                top: '7px',
                            },
                            '& span': {
                                position: 'absolute',
                                bottom: '2px',
                            },
                        }}
                    >
                        <FontAwesomeIcon icon={faGrid3} size="lg" />
                        <span>Grid</span>
                    </ToggleButton>
                    <ToggleButton
                        value="list"
                        title="List View"
                        className={[
                            styles.toggleButton,
                            videoEventViewType == 'tile' ? styles.inactiveToggleIcon : styles.activeToggleIcon,
                        ].join(' ')}
                        sx={{
                            '& svg': {
                                position: 'absolute',
                                top: '7px',
                            },
                            '& span': {
                                position: 'absolute',
                                bottom: '2px',
                            },
                        }}
                    >
                        <FontAwesomeIcon icon={faList} size="lg" />
                        <span>List</span>
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </>
    );
};
