import { React, bind } from 'Imports';
import { Button, Grid, MobileStepper, Hidden } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/pro-solid-svg-icons';
import { EventData } from '$Components/VideoEvents/VideoEventDetails/EventData';
import { EventVideoPlyr } from '$Components/VideoEvents/VideoEventDetails/EventVideoPlyr';
import { EventVideoPlyrScrub } from '$Components/VideoEvents/VideoEventDetails/EventVideoPlyrScrub';
import { DetailsHeader } from '$Components/VideoEvents/VideoEventDetails/DetailsHeader';
import { DetailsSidePanel } from '$Components/VideoEvents/VideoEventDetails/DetailsSidePanel';
import { VideoEventHistoryDetails } from '$Components/VideoEvents/VideoEventDetails/VideoEventHistoryDetails';
import { PageHeader } from '$Components/Shared/PageHeader';
import * as MediaUrlBuilder from '$Components/Shared/MediaUrlBuilder';
import {
    VideoAssetResponse,
    VideoEventWorkflowStatusIdEnum,
    FileUploadTypeEnum,
    VideoAssetTypeEnum,
    VideoEventResponse,
} from '$Generated/api';
import { IPricingPlanServiceInjectedProps, PricingPlanService } from '$State/PricingPlanFreezerService';
import { IVideoEventServiceInjectedProps, VideoEventService } from '$State/VideoEventFreezerService';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { SpeedGraph } from '$Components/Charts/SpeedGraph';
import { canEditByRole, RolesEnum } from '$Externals/VerifyRole';
import * as scssStyles from '$CSS/settings.scss';
import SwipeableViews from 'react-swipeable-views';
import { Error } from '$Pages/Error/Error';

import { getSplitUserAttributes, isSplitTreatmentOn, TypedSplitTreatments, splitTreatmentNames } from '$Utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import * as _ from 'lodash';
import { PageHeader_Split_Off } from '$Components/Shared/PageHeader_Split_Off';

const styles = require('$Pages/VideoEventDetails/VideoEventDetails.scss') as {
    main: string;
    content: string;
    detailsContentAndSidePanel: string;
    detailsContent: string;
    detailsContentVideoRecall: string;
    playAllButton: string;
    playIcon: string;
    swipeableVideos: string;
    mobileStepper: string;
    swipeableViews: string;
    videoLayout: string;
    videoElementContainer: string;
    videoElementContainerChannel3: string;
    detailsHeaderContainer: string;
};

interface IVideoEventDetailsBaseProps {
    eventId: string;
    onSelectVideoEvent: (value: string, goBack: boolean) => void;
    isVideoRecall: boolean;
}

interface IVideoEventDetailsState {
    progress: number;
    maxHeight: number;
    data?: VideoEventResponse;
    hasCourtReporter: boolean;
    eventStatus: VideoEventWorkflowStatusIdEnum;
    activeStep: number;
    videoCount: number;
    currentCamera: FileUploadTypeEnum;
    keys: FileUploadTypeEnum[];
    lastVideoEnded: boolean;
    errors: boolean;
    playing: boolean;
    maxDuration: number;
    maxDurationVideoType?: FileUploadTypeEnum;
    allPlayed: boolean;
}

type IVideoEventDetailsProps = IVideoEventDetailsBaseProps &
    IPricingPlanServiceInjectedProps &
    IVideoEventServiceInjectedProps &
    IConfigServiceInjectedProps &
    IIntegrationPartnerDataInjectedProps;

type VideoAssetUrls = {
    type: FileUploadTypeEnum;
    videoUrl?: string;
    thumbnailURL: string;
    aspectRatio?: string;
    duration?: number;
};

class _VideoEventDetailsPage extends React.Component<IVideoEventDetailsProps, IVideoEventDetailsState> {
    constructor(props: IVideoEventDetailsProps) {
        super(props);
    }

    state = {
        progress: 0,
        maxHeight: 0,
        data: undefined,
        hasCourtReporter: false,
        eventStatus: 'New',
        activeStep: 0,
        videoCount: 0,
        currentCamera: 'FrontVideo',
        keys: [],
        lastVideoEnded: false,
        errors: false,
        playing: false,
        maxDuration: 0,
        maxDurationVideoType: undefined,
        allPlayed: false,
    } as IVideoEventDetailsState;

    videoEndedCounter: number = 0;

    public videoPlayerInfo: any = null;
    public scrubToVal: number = 0;

    secondaryVideoDiv: HTMLDivElement | null = null;

    async loadVideoEventInfo() {
        try {
            await this.props.videoEvents.getVideoEvent(this.props.eventId);
            const freezer = this.props.videoEvents.getState();
            const { selectedVideoEventResult } = freezer;
            const data = selectedVideoEventResult.data || undefined;

            const videos = this.getVideoAssetUrls(data);
            const numVideos = (_.filter(videos, (video) => video.videoUrl && data) as VideoAssetUrls[]).reduce(
                (acc: number, urls: VideoAssetUrls) => {
                    return acc + Number(urls.videoUrl !== undefined);
                },
                0,
            );

            this.setState({
                data: data,
                videoCount: numVideos,
                hasCourtReporter: await this.props.pricingPlan.hasFeatureType('CourtReporter'),
                eventStatus: data?.videoEventStatus || 'New',
                errors: false,
            });
        } catch (error) {
            this.setState({
                data: undefined,
                videoCount: 0,
                errors: true,
            });
        }
    }

    async componentDidUpdate(prevProps: Readonly<IVideoEventDetailsProps>): Promise<void> {
        if (this.props.eventId != prevProps.eventId) {
            this.setState({ data: undefined });
            await this.loadVideoEventInfo();
        }
    }

    async componentDidMount(): Promise<void> {
        window.addEventListener('popstate', this.handlePopstate, false);
        this.loadVideoEventInfo();
    }

    async componentWillUnmount(): Promise<void> {
        window.removeEventListener('popstate', this.handlePopstate, false);

        //stop all playing of videos and reset
        let videos = this.getVideoAssetUrls(this.state.data);
        videos.forEach((urls) => {
            if (urls.videoUrl) {
                this.props.videoEvents.resetVideoDetailsPlaying(urls.type);
            }
        });
    }

    routerWillLeave(): void {
        this.props.onSelectVideoEvent('', false);
    }

    @bind
    navigateBack(): void {
        this.props.onSelectVideoEvent('', false);
    }

    @bind
    scrubTo(value: number): void {
        this.props.videoEvents.scrubTo(value);
    }

    @bind
    setStatus(status: string | undefined) {
        const freezer = this.props.videoEvents.getState();
        const { selectedVideoEventResult } = freezer;
        const data = selectedVideoEventResult.data ? selectedVideoEventResult.data : {};
        if (data.videoEventStatus !== status) {
            this.props.videoEvents.updateLocalVideoEventStatus(status as VideoEventWorkflowStatusIdEnum, Number(data.id));
            this.setState({ eventStatus: status as VideoEventWorkflowStatusIdEnum });
        }
    }

    // centralized update of video event history to reduce feature enabled checks
    @bind
    async updateVideoEventHistory() {
        const id = this.state.data?.id;
        if (id && this.state.hasCourtReporter) {
            await this.props.videoEvents.getVideoEventHistoryTable(id);
        }
    }

    @bind
    async togglePlaying(
        videos: VideoAssetUrls[],
        fileUploadTypeId: FileUploadTypeEnum | null,
        isStarting: boolean,
        isEnding: boolean,
        isMobile: boolean = false,
    ) {
        const freezer = this.props.videoEvents;
        const id = this.state.data?.id || 0;
        const { selectedVideoEventResult } = freezer.getState();
        //console.log('isMobile', isMobile);

        if (isStarting) {
            //do this for both desktop and mobile
            // only update status from New
            if (selectedVideoEventResult.data?.videoEventStatus === 'New' && canEditByRole([RolesEnum.videoEventEdit])) {
                this.setStatus('In_Progress'); // ensure subsequent plays do not send duplicate status updates
                await freezer.updateVideoEventWorkflowStatus(id, 'In_Progress');
            }

            await freezer.addVideoEventActionRecord(id, 'PlayedVideo', `Videos started from video event details page`);
            await this.updateVideoEventHistory();
        } else if (isEnding && fileUploadTypeId === 'FrontVideo') {
            await freezer.addVideoEventActionRecord(id, 'VideosWatched', `Videos watched from video event details page`);
            await this.updateVideoEventHistory();
        }

        if (isMobile) {
            videos.forEach((urls) => {
                if (urls.videoUrl) {
                    this.props.videoEvents.toggleVideoDetailsPlaying(urls.type);
                }
            });
        } else {
            //this is all for control of multiple videos, do NOT do for mobile
            if (isEnding) {
                this.videoEndedCounter++;
            }

            //the last video has ended and lastVideoEnded needs to be toggled on
            if (this.videoEndedCounter == this.state.videoCount && !this.state.lastVideoEnded) {
                this.setState({ lastVideoEnded: true });
                this.videoEndedCounter = 0;
            } else {
                this.setState({ lastVideoEnded: false });
            }

            //only run this if no videos have ended or all videos have ended
            const shouldTogglePlaying = this.videoEndedCounter == 0 || this.videoEndedCounter == this.state.videoCount;
            if (shouldTogglePlaying) {
                videos.forEach((urls) => {
                    if (urls.videoUrl) {
                        this.props.videoEvents.toggleVideoDetailsPlaying(urls.type);
                    }
                });
            }
        }
    }

    async updateVideoState(isStarting: boolean, isEnding: boolean, fileUploadTypeId: FileUploadTypeEnum | null): Promise<any> {
        const freezer = this.props.videoEvents;
        const id = this.state.data?.id || 0;
        const { selectedVideoEventResult } = freezer.getState();

        if (isStarting) {
            //do this for both desktop and mobile
            // only update status from New
            if (selectedVideoEventResult.data?.videoEventStatus === 'New' && canEditByRole([RolesEnum.videoEventEdit])) {
                this.setStatus('In_Progress'); // ensure subsequent plays do not send duplicate status updates
                await freezer.updateVideoEventWorkflowStatus(id, 'In_Progress');
            }

            await freezer.addVideoEventActionRecord(id, 'PlayedVideo', `Videos started from video event details page`);
            await this.updateVideoEventHistory();
        } else if (isEnding && fileUploadTypeId === 'FrontVideo') {
            await freezer.addVideoEventActionRecord(id, 'VideosWatched', `Videos watched from video event details page`);
            await this.updateVideoEventHistory();
        }
    }

    @bind
    async togglePlayingByState(
        fileUploadTypeId: FileUploadTypeEnum | null,
        isStarting: boolean,
        isEnding: boolean,
        syncPlayingState?: boolean,
    ) {
        await this.updateVideoState(isStarting, isEnding, fileUploadTypeId);

        if (isStarting && this.state.maxDurationVideoType != fileUploadTypeId && syncPlayingState) {
            this.setState({ playing: true });
        } else if (isEnding && this.state.maxDurationVideoType == fileUploadTypeId) {
            this.setState({ playing: false });
            this.setState({ allPlayed: true });
        } else if (syncPlayingState != undefined) {
            const playing = !this.state.playing;
            this.setState({ playing });
        }

        this.setState({ allPlayed: false });
    }

    @bind
    setMaxDuration(duration: number, fileUploadTypeId: FileUploadTypeEnum) {
        if (this.state.maxDuration < duration) {
            this.setState({
                maxDuration: duration,
                maxDurationVideoType: fileUploadTypeId,
            });
        }
    }

    @bind
    findAsset(assets: VideoAssetResponse[], fileUploadType: FileUploadTypeEnum, type: VideoAssetTypeEnum): VideoAssetResponse | undefined {
        return assets.find(
            (asset) => typeof asset.fileUploadTypeId !== 'undefined' && asset.type === type && asset.fileUploadTypeId === fileUploadType,
        );
    }

    @bind
    getVideoAndThumbnail(assets: VideoAssetResponse[], fileUploadType: FileUploadTypeEnum, defaultThumb: string = ''): VideoAssetUrls {
        let videoAsset = this.findAsset(assets, fileUploadType, 'Transcode') || this.findAsset(assets, fileUploadType, 'Full');
        const url = MediaUrlBuilder.buildUrl(videoAsset, this.props.config.getBaseUrl());

        let thumbAsset = this.findAsset(assets, fileUploadType, 'Thumbnail');
        const thumb = thumbAsset ? MediaUrlBuilder.buildUrl(thumbAsset, this.props.config.getBaseUrl()) : defaultThumb;

        return { type: fileUploadType, videoUrl: url !== '' ? url : undefined, thumbnailURL: thumb, aspectRatio: videoAsset?.aspectRatio };
    }

    @bind
    updateCameraType(index: number) {
        this.setState({ currentCamera: this.state.keys[index] as FileUploadTypeEnum });
    }

    @bind
    setHeight(height: number) {
        if (this.state.maxHeight !== height) {
            this.setState({ maxHeight: height });
        }
    }

    @bind
    nextVideo() {
        let index = this.state.activeStep + 1;

        if (index <= this.state.videoCount - 1) {
            //prevent play until transition is complete
            //this.props.eventReview.togglePlayEnabled(false);
            this.setState({ activeStep: index });
            this.updateCameraType(index);
            this.updateProgress(0);
        }
    }

    @bind
    previousVideo() {
        let index = this.state.activeStep - 1;

        if (index >= 0) {
            //prevent play until transition is complete
            //this.props.eventReview.togglePlayEnabled(false);
            this.setState({ activeStep: index });
            this.updateCameraType(index);
            this.updateProgress(0);
        }
    }

    @bind
    videoWasSwiped(index: number, indexLatest: number) {
        if (index <= this.state.videoCount - 1) {
            //prevent play until transition is complete
            //this.props.eventReview.togglePlayEnabled(false);
            this.setState({ activeStep: index });
            this.updateCameraType(index);
            this.updateCameraType(index);
            this.updateProgress(0);
        }
    }

    @bind
    createVideoLayout(
        videos: VideoAssetUrls[],
        data: VideoEventResponse | undefined,
        isVideoRecall: boolean,
        videoScrubSplitOn: boolean,
    ): JSX.Element {
        const { videoPlayingStates, scrubTo } = this.props.videoEvents.getState();

        const map = data ? (
            <EventData
                mapUrl={this.props.integrationPartnerData.getIntegrationPartnerManager().getMapUrl(data)}
                event={data}
                latitude={data?.latitude || 0}
                longitude={data?.longitude || 0}
            />
        ) : undefined;

        const history = data ? <VideoEventHistoryDetails event={data} updateVideoEventHistory={this.updateVideoEventHistory} /> : undefined;

        const { videoCount } = this.state;
        const videoHasSpeedGraph = videos.some(
            (v) =>
                v.type === 'FrontVideo' &&
                data?.speedGraphPoints &&
                data?.speedGraphPoints?.length > 0 &&
                scssStyles.styleEnvironment !== 'encompass',
        );

        const videoElements = (
            _.filter(videos, (video) => {
                return video.videoUrl && data;
            }) as VideoAssetUrls[]
        ).map((urls, idx) => {
            if (urls.videoUrl && data) {
                return (
                    <div key={'video_' + idx}>
                        <div>
                            {videoScrubSplitOn ? (
                                <EventVideoPlyrScrub
                                    videoUrl={urls.videoUrl ?? ''}
                                    eventData={data}
                                    scrubToFunc={(e: number): void => this.scrubTo(e)}
                                    scrubTo={scrubTo}
                                    videoIsPlaying={this.state.playing}
                                    toggleVideoIsPlaying={(fileUploadTypeId, isStarting, isEnding, syncPlayingState) =>
                                        this.togglePlayingByState(fileUploadTypeId, isStarting, isEnding, syncPlayingState)
                                    }
                                    fileUploadTypeId={urls.type}
                                    updateProgress={urls.type === 'FrontVideo' ? this.updateProgress : undefined}
                                    updateVideoEventHistory={this.updateVideoEventHistory}
                                    setDuration={this.setMaxDuration}
                                    resetEnded={this.state.allPlayed}
                                />
                            ) : (
                                <EventVideoPlyr
                                    videoUrl={urls.videoUrl ?? ''}
                                    eventData={data}
                                    thumbUrl={urls.thumbnailURL}
                                    videoIsPlaying={videoPlayingStates[urls.type]}
                                    scrubToFunc={(e: number): void => this.scrubTo(e)}
                                    scrubTo={scrubTo}
                                    toggleVideoIsPlaying={(fileUploadTypeId, isStarting, isEnding) =>
                                        this.togglePlaying(videos, fileUploadTypeId, isStarting, isEnding)
                                    }
                                    fileUploadTypeId={urls.type}
                                    speedGraphPoints={urls.type === 'FrontVideo' ? data.speedGraphPoints : undefined}
                                    eventTypePairs={urls.type === 'FrontVideo' ? data.videoEventTypePairings : undefined}
                                    updateProgress={urls.type === 'FrontVideo' ? this.updateProgress : undefined}
                                    updateVideoEventHistory={this.updateVideoEventHistory}
                                    maxHeight={this.state.maxHeight}
                                />
                            )}
                        </div>
                    </div>
                );
            }
        });

        const swipeableVideoElements = (
            _.filter(videos, (video) => {
                return video.videoUrl && data;
            }) as VideoAssetUrls[]
        ).map((urls, idx) => {
            if (urls.videoUrl && data) {
                return (
                    <div key={'swipeableVideo_' + idx}>
                        <div>
                            {videoScrubSplitOn ? (
                                <EventVideoPlyrScrub
                                    videoUrl={urls.videoUrl ?? ''}
                                    eventData={data}
                                    scrubToFunc={(e: number): void => this.scrubTo(e)}
                                    scrubTo={scrubTo}
                                    videoIsPlaying={videoPlayingStates[urls.type]}
                                    toggleVideoIsPlaying={(fileUploadTypeId, isStarting, isEnding, syncPlayingState) =>
                                        this.togglePlayingByState(fileUploadTypeId, isStarting, isEnding, syncPlayingState)
                                    }
                                    fileUploadTypeId={urls.type}
                                    updateProgress={urls.type === 'FrontVideo' ? this.updateProgress : undefined}
                                    updateVideoEventHistory={this.updateVideoEventHistory}
                                    setDuration={this.setMaxDuration}
                                    resetEnded={this.state.allPlayed}
                                />
                            ) : (
                                <EventVideoPlyr
                                    videoUrl={urls.videoUrl ?? ''}
                                    eventData={data}
                                    thumbUrl={urls.thumbnailURL}
                                    videoIsPlaying={videoPlayingStates[urls.type]}
                                    scrubToFunc={(e: number): void => this.scrubTo(e)}
                                    scrubTo={scrubTo}
                                    toggleVideoIsPlaying={(fileUploadTypeId, isStarting, isEnding) =>
                                        this.togglePlaying([urls], fileUploadTypeId, isStarting, isEnding, true)
                                    }
                                    fileUploadTypeId={urls.type}
                                    speedGraphPoints={urls.type === 'FrontVideo' ? data.speedGraphPoints : undefined}
                                    eventTypePairs={urls.type === 'FrontVideo' ? data.videoEventTypePairings : undefined}
                                    updateProgress={this.updateProgress}
                                    updateVideoEventHistory={this.updateVideoEventHistory}
                                    maxHeight={this.state.maxHeight}
                                />
                            )}
                        </div>
                    </div>
                );
            }
        });

        return (
            <div className={styles.videoLayout}>
                <div>
                    <Hidden smDown>
                        <div>
                            {videoCount === 1 && videoElements[0]}
                            {videoCount === 2 && (
                                <Grid container spacing={1} justifyContent={'space-evenly'}>
                                    <Grid item xs={6}>
                                        {videoElements[0]}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {videoElements[1]}
                                    </Grid>
                                </Grid>
                            )}
                            {videoCount === 3 && (
                                <div>
                                    <Grid container spacing={1} justifyContent={'space-evenly'}>
                                        <Grid item xs={8}>
                                            {videoElements[0]}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={styles.videoElementContainer}>{videoElements[1]}</div>
                                            <div className={`${styles.videoElementContainer} ${styles.videoElementContainerChannel3}`}>
                                                {videoElements[2]}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                            {videoCount === 4 && (
                                <div>
                                    <Grid container spacing={1} justifyContent={'space-evenly'}>
                                        <Grid item xs={6}>
                                            {videoElements[0]}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {videoElements[1]}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} justifyContent={'space-between'}>
                                        <Grid item xs={6}>
                                            {videoElements[2]}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {videoElements[3]}
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </div>
                    </Hidden>
                    <Hidden mdUp>
                        <div className={styles.swipeableVideos}>
                            <SwipeableViews
                                enableMouseEvents
                                className={styles.swipeableViews}
                                containerStyle={{ height: '100%' }}
                                index={this.state.activeStep}
                                onChangeIndex={this.videoWasSwiped}
                            >
                                {swipeableVideoElements.map((videoElement) => {
                                    return videoElement;
                                })}
                            </SwipeableViews>
                            {videoCount > 1 && (
                                <MobileStepper
                                    className={styles.mobileStepper}
                                    variant="dots"
                                    steps={videoCount}
                                    position="static"
                                    activeStep={this.state.activeStep}
                                    nextButton={
                                        <Button
                                            size="small"
                                            disabled={this.state.activeStep === this.state.videoCount - 1}
                                            onClick={this.nextVideo}
                                        >
                                            next
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" disabled={this.state.activeStep === 0} onClick={this.previousVideo}>
                                            previous
                                        </Button>
                                    }
                                />
                            )}
                        </div>
                    </Hidden>
                </div>
                <div>
                    {videoHasSpeedGraph &&
                        data &&
                        data.speedGraphPoints !== undefined &&
                        data.speedGraphPoints.length > 0 &&
                        data.videoEventTypePairings !== undefined &&
                        data.videoEventTypePairings.length > 0 &&
                        scssStyles.styleEnvironment !== 'encompass' && (
                            <div>
                                <SpeedGraph
                                    width={800}
                                    height={122}
                                    speedGraphData={data.speedGraphPoints}
                                    eventStartDate={data.eventStartDate}
                                    eventTypeData={data.videoEventTypePairings}
                                    liveTime={this.state.progress}
                                />
                            </div>
                        )}
                    <Grid container spacing={1} justifyContent={'space-evenly'}>
                        <Grid item sm={12} md={6}>
                            {map}
                        </Grid>
                        <Grid item sm={12} md={6}>
                            {history}
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

    @bind
    calcVideosPlaying(): boolean {
        const { videoPlayingStates } = this.props.videoEvents.getState();
        return videoPlayingStates.FrontVideo || videoPlayingStates.RearVideo || videoPlayingStates.SideVideo || videoPlayingStates.Log;
    }

    calculateSecondaryVideoHeight(): void {
        if (this.secondaryVideoDiv) {
            const height = this.secondaryVideoDiv.offsetHeight;
            this.props.videoEvents.setSecondaryVideoEventHeight(height);
        }
    }

    @bind
    updateProgress(progress: number): void {
        this.setState({ progress });
    }

    @bind
    isStarting(): boolean {
        const { progress } = this.state;
        return progress === 0 || progress == 1;
    }

    @bind
    handlePopstate(event: PopStateEvent): void {
        const { onSelectVideoEvent, eventId } = this.props;
        if (event && eventId !== '') {
            VideoEventService.clearVideoEvents();
            onSelectVideoEvent('', false);
        }
    }

    renderEstructure(videoScrubSplitOn: boolean): JSX.Element {
        const { data, eventStatus, videoCount, errors, playing } = this.state;
        const { isVideoRecall } = this.props;
        const anyVideosPlaying = videoScrubSplitOn ? playing : this.calcVideosPlaying();
        const videos = this.getVideoAssetUrls(data);
        const videoLayout = this.createVideoLayout(videos, data, isVideoRecall, videoScrubSplitOn);
        const pageTitle = isVideoRecall ? 'Video Recall Details' : 'Video Event Details';
        const navLinkDisplay = isVideoRecall ? '< Back to All Video Recalls' : '< Back to All Videos';

        const sidePanel = (prevNextSplitOn: boolean, data: VideoEventResponse) => (
            <DetailsSidePanel
                eventStatus={eventStatus}
                event={data}
                onStatusChange={this.setStatus}
                updateVideoEventHistory={this.updateVideoEventHistory}
                onPlayButtonClick={() =>
                    videoScrubSplitOn
                        ? this.togglePlayingByState(null, this.isStarting(), false, true)
                        : this.togglePlaying(videos, null, this.isStarting(), false)
                }
                anyVideosPlaying={anyVideosPlaying}
                isStarting={this.isStarting()}
                videoCount={videoCount}
                onSelectVideoEvent={this.props.onSelectVideoEvent}
                prevNextSplitOn={prevNextSplitOn}
                toggleDeletePopup={() => {}}
            />
        );

        return (
            <>
                {errors ? (
                    <Error messages={['<b>Error:</b> Event ID not found.']} showGoBack={true} />
                ) : (
                    <div className={styles.main}>
                        <PageHeader_Split_Off
                            pageTitle={pageTitle}
                            navLinkDisplay={navLinkDisplay}
                            onSelectVideoEvent={() => this.props.onSelectVideoEvent('', true)}
                        />
                        {!data ? (
                            <div>Loading video event...</div>
                        ) : (
                            <div className={styles.content}>
                                {isVideoRecall && (
                                    <div className={styles.detailsHeaderContainer}>
                                        {videoCount > 1 && (
                                            <Button
                                                className={styles.playAllButton}
                                                variant="contained"
                                                onClick={() =>
                                                    videoScrubSplitOn
                                                        ? this.togglePlayingByState(null, this.isStarting(), false, true)
                                                        : this.togglePlaying(videos, null, this.isStarting(), false)
                                                }
                                                disableElevation
                                            >
                                                <FontAwesomeIcon icon={anyVideosPlaying ? faPause : faPlay} className={styles.playIcon} />
                                                {anyVideosPlaying ? 'Pause All' : 'Play All'}
                                            </Button>
                                        )}
                                        <DetailsHeader event={data} status={eventStatus} />
                                    </div>
                                )}
                                <div className={styles.detailsContentAndSidePanel}>
                                    <div className={!isVideoRecall ? styles.detailsContent : styles.detailsContentVideoRecall}>
                                        {videoLayout}
                                    </div>
                                    {!isVideoRecall && (
                                        <TypedSplitTreatments
                                            names={[splitTreatmentNames.videoDetailNextPrevWorkflow]}
                                            attributes={getSplitUserAttributes()}
                                        >
                                            {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                                return isReady || isTimedout
                                                    ? isSplitTreatmentOn(treatments[splitTreatmentNames.videoDetailNextPrevWorkflow])
                                                        ? sidePanel(true, data)
                                                        : sidePanel(false, data)
                                                    : sidePanel(false, data);
                                            }}
                                        </TypedSplitTreatments>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    }

    render(): JSX.Element {
        return (
            <TypedSplitTreatments names={[splitTreatmentNames.videoplayer]} attributes={getSplitUserAttributes()}>
                {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                    return isReady || isTimedout
                        ? isSplitTreatmentOn(treatments[splitTreatmentNames.videoplayer])
                            ? this.renderEstructure(true)
                            : this.renderEstructure(false)
                        : this.renderEstructure(false);
                }}
            </TypedSplitTreatments>
        );
    }

    private getVideoAssetUrls(data: VideoEventResponse | undefined): VideoAssetUrls[] {
        if (!data) {
            return [];
        }

        const assets: VideoAssetResponse[] = data?.videoAsset || [];
        let videoAssetUrls = [] as VideoAssetUrls[];
        const thumbnailVideo = assets.find((asset) => asset.type === 'Thumbnail');
        const primaryFileUploadType = thumbnailVideo?.fileUploadTypeId || 'FrontVideo';
        const defaultVideo = this.getVideoAndThumbnail(assets, primaryFileUploadType);
        const defaultThumb = defaultVideo.thumbnailURL || '';
        videoAssetUrls.push(defaultVideo);

        // Currently supporting 4 channels, that includes AltVideo.
        // Please add new channels coming.
        const fileUploadTypes = ['FrontVideo', 'RearVideo', 'SideVideo', 'AltVideo'];

        fileUploadTypes.map((asset) => {
            if (asset !== primaryFileUploadType) {
                videoAssetUrls.push(this.getVideoAndThumbnail(assets, asset as FileUploadTypeEnum, defaultThumb) as VideoAssetUrls);
            }
        });

        return videoAssetUrls;
    }
}

export const SplitOffVideoEventDetailsPage = PricingPlanService.inject(
    VideoEventService.inject(ConfigService.inject(IntegrationPartnerDataService.inject(_VideoEventDetailsPage))),
);
