import {
    React,
    _,
    bind
} from "../../Imports";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';

// state and props
interface IPageNumberBlockProps {
    display: string;
    current: number;
    goto: number;
    handlePageNumberChange: (newNum: number) => void;
}

const styles = require("./Paginator.scss") as {
    paginatorCurrentPage: string;
    paginatorButton: string;
    paginatorLink: string;
    paginatorText: string;
};

export class PageNumberBlock extends React.PureComponent<IPageNumberBlockProps, {}> {
    @bind
    renderSwitch(num: string) {
        switch (num) {
            case ("arrow_left"):
                return <FontAwesomeIcon icon={faCaretLeft} />;
            case ("arrow_right"):
                return <FontAwesomeIcon icon={faCaretRight} />;
            default:
                return num;
        }
    }

    render(): JSX.Element {
        const num: string = this.props.display;
        let currentBackground: string = styles.paginatorButton;

        if ((num !== "arrow_left" && num !== "arrow_right") && this.props.display === this.props.current.toString()) {
            currentBackground = styles.paginatorCurrentPage;
        }

        return (
            <div className={currentBackground}>
                {
                    ((num !== "arrow_left" && num !== "arrow_right") && (this.props.goto === this.props.current)) ? (
                        <span className={styles.paginatorText}>{num}</span>
                    ) : (

                            <a
                                className={styles.paginatorLink}
                                href="#"
                                onClick={(e) => { e.preventDefault(); this.props.handlePageNumberChange(this.props.goto); }}
                            >
                                {this.renderSwitch(num)}
                            </a>
                        )
                }
            </div>
        );
    }
}
