import { FreezerService, IAjaxState, managedAjaxUtil, moment } from 'Imports';
import { DashboardApiFactory, DashboardSummaryResponse, DashboardResponse, StatisticsDashboardFilterModel } from '$Generated/api';
import { UserManagementService } from '$State/UserManagementFreezerService';
import { DateRange } from '$Components/Dashboards/DateRange/DateRangeSelector';

interface IDashboardState {
    dashboardSummaryResults: IAjaxState<DashboardResponse>;
    dashboardLiveResults: IAjaxState<DashboardSummaryResponse>;
    hasError: boolean;
    selectedDateRange?: DateRange;
}

const InjectedPropName = 'dashboard';

class DashboardFreezerService extends FreezerService<IDashboardState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                dashboardSummaryResults: managedAjaxUtil.createInitialState(),
                dashboardLiveResults: managedAjaxUtil.createInitialState(),
                hasError: false,
                selectedDateRange: undefined,
            },
            InjectedPropName,
        );
    }

    public async getLiveDashboard(dateRange: StatisticsDashboardFilterModel): Promise<void | DashboardSummaryResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'dashboardLiveResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const dashboardApi = DashboardApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return dashboardApi.apiV1DashboardLivePost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                body: dateRange,
            },
            onOk: (data: DashboardSummaryResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({
                        hasError: false,
                        selectedDateRange: {
                            startDate: dateRange.startDate!,
                            endDate: dateRange.endDate!,
                        },
                    });
                }

                return data;
            },
            onError: (err, msg) => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public getSelectedDateRange(): DateRange | undefined {
        return this.freezer.get().selectedDateRange?.toJS();
    }
}

export const DashboardService = new DashboardFreezerService();
export type IDashboardServiceInjectedProps = ReturnType<DashboardFreezerService['getPropsForInjection']>;