import { React } from 'Imports';
import { ConfirmDialog } from './ConfirmDialog';
import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FileUploadTypeEnum } from '$Generated/api';
import { IVideoEventServiceInjectedProps, VideoEventService } from '$State/VideoEventFreezerService';
import { RolesEnum, canEditByRole } from '$Externals/VerifyRole';
import { StyledIconButton } from '$Components/Shared/MaterialUIComponents/StyledIconButton';
import { useRef, useState } from 'react';
import * as scssStyles from '../../css/settings.scss';

interface IVideoDownloadProps {
    videoUrl: string;
    eventId?: number;
    videoType?: FileUploadTypeEnum;
    widthOverride?: string;
    heightOverride?: string;
    updateVideoEventHistory?: () => void;
    transparent?: boolean;
    isDisabled?: boolean;
}

const styles = require('./VideoDownload.scss') as {
    confirmationDialog: string;
    dialogAction: string;
    confirmMessage: string;
    confirmButton: string;
    cancelButton: string;
    dialog: string;
};

type VideoDownloadProps = IVideoDownloadProps & IVideoEventServiceInjectedProps & IConfigServiceInjectedProps;

const _VideoDownload = (props: VideoDownloadProps) => {
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const isDisabled = props.isDisabled || !canEditByRole([RolesEnum.videoAdmin]);
    const anchorRef = useRef<HTMLAnchorElement>(null);

    const isEncompass = scssStyles.styleEnvironment === 'encompass';
    const acceptButton = isEncompass ? 'Yes, Download' : 'Download';
    const cancelButton = isEncompass ? 'No, Go back' : 'Cancel';
    const title = isEncompass ? 'Download Video' : 'Confirm Download';

    const showConfirmDialog = (e: any, show: boolean) => {
        e.stopPropagation();
        if (isDisabled) return;
        setIsConfirmDialogOpen(show);
    };

    const handleDownloadVideo = async (e: any) => {
        e.stopPropagation();
        if (anchorRef.current) {
            anchorRef.current.click();
            await props.videoEvents.downloadVideoEvent(props.eventId, props.videoType);

            if (props.eventId && props.updateVideoEventHistory) {
                props.updateVideoEventHistory();
            }
            showConfirmDialog(e, false);
        }
    };

    return (
        <>
            {!isDisabled && (
                <a
                    hidden
                    target="_blank"
                    href={props.videoUrl}
                    download={true}
                    onClick={(e) => e.stopPropagation()}
                    ref={anchorRef}
                    rel="noreferrer"
                />
            )}
            <StyledIconButton
                icon={faDownload}
                title={'Download Video'}
                isDisabled={isDisabled}
                handleOnClick={(e: any) => showConfirmDialog(e, true)}
                widthOverride={props.widthOverride}
                heightOverride={props.heightOverride}
                transparent={props.transparent}
            ></StyledIconButton>

            {!isDisabled && (
                <ConfirmDialog
                    isOpen={isConfirmDialogOpen}
                    title={title}
                    contentMessage={'Are you sure you want to download this video?'}
                    cancelButton={cancelButton}
                    acceptButton={acceptButton}
                    handleModalClosed={(e) => showConfirmDialog(e, false)}
                    handleSubmit={handleDownloadVideo}
                    isButtonAlert={false}
                />
            )}
        </>
    );
};

export const VideoDownload = VideoEventService.inject(ConfigService.inject(_VideoDownload));
