import { React, bind } from '../../Imports';
import { VPDropdown, IDropdownItem } from '$Components/FilterComponents/VPDropdown';
import { IIntegrationPartnerDataInjectedProps, IIntegrationPartnerDataState, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { SortDropdownItems } from '../../utilities/dataModelUtilities';
import { getSplitUserAttributes, isSplitTreatmentOnWithDefault, splitTreatmentNames, TypedSplitTreatments } from '$Utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';

interface IVehicleDropdownMultiselectProps {
    selectedVehicleIds: string[];
    onSelectedVehicleChange: (selectedVehicles: any[]) => void;
    width?: number;
    includeInactives?: boolean;
    onChangeIncludeInactives: (includeInactives?: boolean) => void;
}

interface IVehicleDropdownMultiselectState {
    vehicles: any[];
}

class _VehicleDropdownMultiselect extends React.PureComponent<
    IVehicleDropdownMultiselectProps & IIntegrationPartnerDataInjectedProps & IVehicleDropdownMultiselectState
> {
    state = {
        vehicles: [],
        includeInactives: false,
    };

    componentDidMount() {
        const { deviceResults } = this.props.integrationPartnerData.getState();
        this.setState({ vehicles: deviceResults.data });
        
        this.props.integrationPartnerData.on('update', (currentState: IIntegrationPartnerDataState) => {
            let devices = currentState.deviceResults.data || [];
            this.setState({ vehicles: devices });
        });
        this.props.integrationPartnerData.getDevices();
    }

    @bind
    mapDeviceList(devices: any[]) {
        const devicesList: IDropdownItem[] = devices?.map((d) => ({
            label: d.name,
            value: d.id,
            isActive: d.isActive,
        }));
        return devicesList ? SortDropdownItems(devicesList) : [];
    };

    @bind
    handleSelectedItemChange(selectedValue: any[]) {
        const selectedVehicleIds = selectedValue;
        const vehicles = this.state.vehicles;
        const selectedVehicles: any = vehicles
            .filter((vh: any) => selectedVehicleIds.indexOf(vh.id) > -1)
            .map((vehicle: any) => vehicle.id);
        this.props.onSelectedVehicleChange(selectedVehicles);
    }

    renderDropdown(filterInactives: boolean) {
        const { vehicles } = this.state;

        return (
            <VPDropdown
                id="select-vehicles"
                isCombined={true}
                name="Vehicles"
                items={this.mapDeviceList(vehicles)}
                selectedValues={this.props.selectedVehicleIds ? this.props.selectedVehicleIds : []}
                onSelectedItemChange={this.handleSelectedItemChange}
                filterInactives={filterInactives}
                includeInactives={this.props.includeInactives}
                onIncludeInactiveChange={this.props.onChangeIncludeInactives}
            />
        );
    }

    render(): JSX.Element {
        return (
            <TypedSplitTreatments names={[splitTreatmentNames.showInactiveVehicles]} attributes={getSplitUserAttributes()}>
                {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                    return isReady || isTimedout
                        ? isSplitTreatmentOnWithDefault(treatments[splitTreatmentNames.showInactiveVehicles], true)
                            ? this.renderDropdown(true)
                            : this.renderDropdown(false)
                        : this.renderDropdown(false);
                }}
            </TypedSplitTreatments>
        );
    }
}

export const VehicleDropdownMultiselect = IntegrationPartnerDataService.inject(_VehicleDropdownMultiselect);
