import { React } from '../../Imports';

import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { TooltipWedge } from '../Shared/TooltipWedge';
import * as scssStyles from '$CSS/settings.scss';

interface IWidgetTooltipProps {
    prompt: string | ReactJSXElement;
    description: ReactJSXElement;
    contentPosition: "topCenter" | "bottomRight";
}

type WidgetTooltipProps = IWidgetTooltipProps & IConfigServiceInjectedProps;

const styles = require('./WidgetTooltip.scss') as {
    container: string;
    target: string;
    content: string;
    contentGeotab: string;
    contentEncompass: string;
    wedge: string;
    prompt: string;
    contentAlignedRightBottom: string;
    contentCenteredAbove: string;
    wedgeCenteredAbove: string;
};

class _WidgetTooltip extends React.PureComponent<WidgetTooltipProps, {}> {
    render(): JSX.Element {
        const isEncompass = scssStyles.styleEnvironment === 'encompass';
        const envContentStyles = isEncompass ? styles.contentEncompass : styles.contentGeotab;
        const contentPositionStyle = () => {
            switch(this.props.contentPosition) {
                case "topCenter":
                    return styles.contentCenteredAbove;
                case "bottomRight":
                default:
                    return styles.contentAlignedRightBottom;
            }
        }
        const wedgePositionStyle = () => {
            switch(this.props.contentPosition) {
                case "topCenter":
                    return styles.wedgeCenteredAbove;
                case "bottomRight":
                default:
                    return styles.wedge;
            }
        } 

        return (
            <div className={styles.container}>
                <div className={styles.target}>
                    <div className={styles.prompt}>
                        {this.props.prompt}
                    </div>
                    <div className={styles.content + ' ' + envContentStyles + ' ' + contentPositionStyle()}>
                        <div className={wedgePositionStyle()}>
                            <TooltipWedge />
                        </div>
                        {this.props.description}
                    </div>
                </div>
            </div>
        );
    }
}

export const WidgetTooltip = ConfigService.inject(_WidgetTooltip);
