import { React } from 'Imports';
import * as d3 from 'd3';
import { cx } from '@videoplatform/css-helpers';

import { Table, TableBody, TableCell, TableHead, TableRow, styled } from 'MaterialUIComponents';

import { EventScore } from '../../../generated/api';
import { WidgetTooltip } from '../../../components/Dashboards/WidgetTooltip';
import { WidgetCard, WidgetCardContent, WidgetCardHeader } from '../../../components/Dashboards/StyledComponents';
import { PercentChangeTrend, PercentChangeTypeQualifier } from '../../../components/Shared/PercentChangeTrend';
import { useEffect, useState } from 'react';

import * as scssStyles from '$CSS/settings.scss';

interface IEventScoreData {
    label: string;
    description: string;
    color: string;
    currentPercent: number;
    changePercent: number;
    scoreIconClass: string;
    positiveQualifier?: PercentChangeTypeQualifier;
    negativeQualifier?: PercentChangeTypeQualifier;
}

interface IEventScoreWidgetState {
    data: IEventScoreData[];
}

type EventScoreWidgetProps = {
    eventScoreRequested: EventScore;
    eventScorePrevious: EventScore;
    shouldShowPercentage: boolean;
};

const styles = require('../styles/EventScoreWidget.scss') as {
    chart: string;
    chartLabel: string;
    table: string;
    labelEntry: string;
    labelIcon: string;
    labelText: string;
    percentEntry: string;
    percentCurrent: string;
    percentChange: string;
    trendIcon: string;
    percentHeader: string;
};

const scoreMap = new Map([
    ['+', { description: 'Positive Safety', color: '#0DA100' }],
    ['-1', { description: 'Low Risk', color: '#2C70BA' }],
    ['-2', { description: 'Moderate Risk', color: '#FFF500' }],
    ['-3', { description: 'High Risk', color: '#FF9900' }],
    ['-4', { description: 'Critical/Collision', color: '#EB3223' }],
]);

const ScoreTableHeadCell = styled(TableCell)`
    border-bottom: 2px solid ${scssStyles.darkTypography};
    padding: 0.25rem;
    font-weight: bold;
    font-size: 1rem;
    color: ${scssStyles.darkTypography} !important;
`;

const ScoreTableCell = styled(TableCell)`
    padding: 0.25rem 0.5rem;
    color: ${scssStyles.darkTypography}!important;
`;

const _EventScoreWidget = (props: EventScoreWidgetProps) => {
    const [eventScoreRequestedData, setEventScoreRequestedData] = useState<IEventScoreWidgetState>();
    const [eventScorePreviousData, setEventScorePreviousData] = useState<IEventScoreWidgetState>();
    const { eventScoreRequested, eventScorePrevious, shouldShowPercentage } = props;

    const getPieChart = (data: IEventScoreData[]) => {
        // setting to null sets D3 to render pie wedges in the order of the given dataset
        // starting at 270 degrees (12 o'clock) and moving clockwise
        const pie = d3.pie().sortValues(null);
        const arc = d3.arc().innerRadius(0).outerRadius(50);

        // re-order pie chart to show - negatives (-1, -2, -3, -4) before positive aggregate (sum of +1, +2, +3)
        const percents: number[] = data.map((x) => x.currentPercent);
        if (percents.length > 1) {
            percents.push(percents.shift() || 0); // moves positive to end of dataset
        }

        const pieData = pie(percents);

        // defined viewbox origin at (50, 50) and size (100x100) produces proper max-scaled chart (outer radius = 50)
        // without needing to translate (pie arcs center by default around the origin)
        return (
            <svg height="100%" width="100%" viewBox="-50 -50 100 100">
                <g>
                    {pieData.map((x, idx) => (
                        <path
                            key={idx}
                            d={arc(x as any) as any}
                            fill={data[(idx + 1) % 5].color} // shift indices to match correct color
                            stroke="#FFFFFF"
                            strokeWidth="1px"
                        />
                    ))}
                </g>
            </svg>
        );
    };

    const aggregateEventScoreData = (previous: EventScore, current: EventScore): void => {
        // formula for % change: (current count - prev count) / prev count
        // will result in undef / infinity if prev count is 0 - displaying as 0% change

        const currentTotal = current.totalCount || 0;
        const eventScoreData: IEventScoreData[] = [];
        let roundedPercentSum = 0;

        // aggregate all positives (+1, +2, +3) into one data record
        const positiveCurrentCount = current.data!['1'] + current.data!['2'] + current.data!['3'];
        const positivePreviousCount =
            previous.totalCount != (null || undefined)
                ? previous.totalCount > 0
                    ? previous.data!['1'] + previous.data!['2'] + previous.data!['3']
                    : 0
                : 0;
        const positiveMap = scoreMap.get('+');
        const positiveCurrentPercent = currentTotal > 0 ? (positiveCurrentCount / currentTotal) * 100 : 0;
        roundedPercentSum += Math.round(positiveCurrentPercent);

        eventScoreData.push({
            label: '+',
            description: positiveMap?.description || '-',
            color: positiveMap?.color || scssStyles.darkTypography,
            currentPercent: positiveCurrentPercent,
            changePercent: positivePreviousCount > 0 ? ((positiveCurrentCount - positivePreviousCount) / positivePreviousCount) * 100 : 0,
            scoreIconClass: cx(['score', 'score-icon', 'score-positive', styles.labelIcon]),
        });

        // negative score events
        ['-1', '-2', '-3', '-4'].forEach((score) => {
            const scorePreviousCount = previous.data != (null || undefined) ? previous.data![score] : 0;
            const negativeMap = scoreMap.get(score);
            const scoreCurrentPercent = currentTotal > 0 ? (current.data![score] / currentTotal) * 100 : 0;
            roundedPercentSum += Math.round(scoreCurrentPercent);

            eventScoreData.push({
                label: score,
                description: negativeMap?.description || '-',
                color: negativeMap?.color || scssStyles.darkTypography,
                currentPercent: scoreCurrentPercent,
                changePercent: scorePreviousCount > 0 ? ((current.data![score] - scorePreviousCount) / scorePreviousCount) * 100 : 0,
                scoreIconClass: cx(['score', 'score-icon', `score-negative${score}`, styles.labelIcon]),
                negativeQualifier: 'Positive', // drop in "negative" events = positive trend
                positiveQualifier: 'Negative',
            });
        });

        // sum of (rounded) percentages may be != 100%
        // e.g. 1/8 - 12.6666... -> 17%, * 6 = 102%, or 1/9 - 11.1111... -> 11% * 9 = 99%
        // maximum accumulated rounding error = 3%: 0.5%% (max round-to-nearest differential) * 6 (parts to summation)
        if (roundedPercentSum !== 100) {
            const roundingAdjustment = roundedPercentSum > 100 ? -1 : 1;
            let roundingError = Math.abs(100 - roundedPercentSum);

            // distribute accumulated error across multiple buckets to minimize aggregate impact
            let index = 0;
            while (index < eventScoreData.length && roundingError > 0) {
                // max error to distribute is 3 over 6 buckets, so shouldn't overflow array length
                if (eventScoreData[index].currentPercent > 0) {
                    eventScoreData[index].currentPercent += roundingAdjustment;
                    roundingError -= 1;
                }

                index += 1;
            }
        }

        setEventScoreRequestedData({ data: eventScoreData });
    };

    useEffect(() => {
        aggregateEventScoreData(eventScorePrevious, eventScoreRequested);
    }, [eventScoreRequested]);

    return (
        <>
            <WidgetTooltip
                prompt="How is the event score calculated?"
                description="Event score percentages are calculated using the score applied to each event over the time period selected above. Percentages in grey, green, or red indicate the % change from the previous time period. IE: Last 7 days compared to the 7 days before that period. If N/A, then we didn’t find a previous time period equal to the number of days selected in the time range above."
            />
            <WidgetCard>
                <WidgetCardHeader title="Events by Event Score" />

                <WidgetCardContent>
                    <div className={styles.chart}>{eventScoreRequestedData && getPieChart(eventScoreRequestedData.data)}</div>

                    <div className={styles.table}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <ScoreTableHeadCell>Event Scores</ScoreTableHeadCell>
                                    <ScoreTableHeadCell>
                                        <div className={styles.percentHeader}>% Per Date Range</div>
                                    </ScoreTableHeadCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {eventScoreRequestedData?.data?.map((x, idx) => (
                                    <TableRow key={idx}>
                                        <ScoreTableCell>
                                            <div className={styles.labelEntry}>
                                                <div className={x.scoreIconClass}>{x.label}</div>
                                                <div className={styles.labelText}>{x.description}</div>
                                            </div>
                                        </ScoreTableCell>
                                        <ScoreTableCell>
                                            <div className={styles.percentEntry}>
                                                <div className={styles.percentCurrent}>{x.currentPercent.toFixed(0)}%</div>
                                                <PercentChangeTrend
                                                    containerClassName={styles.percentChange}
                                                    iconClassName={styles.trendIcon}
                                                    value={x.changePercent}
                                                    places={0}
                                                    negativeQualifier={x.negativeQualifier}
                                                    positiveQualifier={x.positiveQualifier}
                                                    shouldShowPercentage={shouldShowPercentage}
                                                />
                                            </div>
                                        </ScoreTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </WidgetCardContent>
            </WidgetCard>
        </>
    );
};

export const EventScoreWidget = _EventScoreWidget;
