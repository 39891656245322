import { React } from '../../../Imports';
import * as scssStyles from '$CSS/settings.scss';
import { Switch, styled } from 'MaterialUIComponents';
import { switchClasses } from '@mui/material/Switch';
import { Box } from '@mui/material';

const styles = require('$Pages/VideoEvents/components/DropdownSwitch.scss') as {
    toggle: string;
    toggleText: string;
};

interface IDropdownSwitchProps {
    toggleText: string;
    onChange: () => void;
}

const DropdownSwitchGeotab = styled(Switch)(({ theme }) => ({
    width: 30,
    height: 16,
    padding: 0,
    [`& .${switchClasses.switchBase}`]: {
        padding: 0,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        [`&.${switchClasses.checked}`]: {
            transform: 'translateX(14px)',
            [`& + .${switchClasses.track}`]: {
                backgroundColor: '#0078D3',
                opacity: 2,
                border: 'none',
            },
        },
    },
    [`& .${switchClasses.thumb}`]: {
        width: 12,
        height: 12,
        transform: 'translateY(2px) translateX(2px)',
        marginRight: 2,
        color: theme.palette.common.white,
    },
    [`& .${switchClasses.track}`]: {
        width: 30,
        height: 16,
        borderRadius: 7,
        backgroundColor: '#99A3B4',
    },
}));

const DropdownSwitchEncompass = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 21,
    padding: 0,
    [`& .${switchClasses.switchBase}`]: {
        padding: 2,
        color: theme.palette.common.white,
        [`&.${switchClasses.checked}`]: {
            transform: 'translateX(19px)',
            color: theme.palette.common.white,
            [`& + .${switchClasses.track}`]: {
                backgroundColor: scssStyles.customColor1,
                opacity: 2,
                border: 'none',
            },
        },
    },
    [`& .${switchClasses.thumb}`]: {
        width: 17,
        height: 17,
    },
    [`& .${switchClasses.track}`]: {
        borderRadius: 26 / 2,
        border: `1px solid '#52d869'`,
        backgroundColor: scssStyles.lightGrey,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
}));

const DropdownSwitch = (props: IDropdownSwitchProps) => {
    return (
        <Box className={styles.toggle}>
            <Box>
                {scssStyles.styleEnvironment === 'encompass' ? (
                    <DropdownSwitchEncompass disableRipple {...props} />
                ) : (
                    <DropdownSwitchGeotab disableRipple {...props} />
                )}
            </Box>
            <Box className={styles.toggleText}>{props.toggleText}</Box>
        </Box>
    );
};

export default DropdownSwitch;
