import * as scssStyles from '$CSS/settings.scss';

export const buttonColors: { [key: string]: { background: string; color: string } } = {};
buttonColors['New'] = { background: '#EBE038', color: scssStyles.buttonDarkTypography };
buttonColors['Sent_To_Driver'] = { background: '#FF9100', color: scssStyles.buttonDarkTypography };
buttonColors['Dismissed'] = { background: scssStyles.buttonDarkBackground, color: '#FFF' };
buttonColors['Completed'] = { background: '#31D309', color: scssStyles.buttonDarkTypography };
buttonColors['Driver_Disputed'] = { background: '#D10018', color: '#FFF' };
buttonColors['Driver_Accepted'] = { background: '#C6D765', color: scssStyles.buttonDarkTypography };
buttonColors['In_Progress'] = { background: '#7C3F8D', color: '#FFF' };
buttonColors['No_Response'] = { background: '#B5B5B5', color: scssStyles.buttonDarkTypography };
