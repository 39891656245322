import { React, moment, bind } from '../../../Imports';
import * as DateFormatter from '../../Shared/DateFormatter';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons';
import { VideoEventResponse, VideoEventHistoryTableResponse } from '$Generated/api';
import { Button, Dialog, DialogActions, DialogContent, IconButton } from '../../../MaterialUIComponents';
import * as scssStyles from '../../../css/settings.scss';
import { canEditByRole, RolesEnum } from '../../../externals/VerifyRole';

interface IVideoEventHistoryExportProps {
    videoEvent: VideoEventResponse;
    data: VideoEventHistoryTableResponse[];
}

const styles = require('./VideoEventHistoryExport.scss') as {
    main: string;
    downloadBtn: string;
    downloadBtnEncompass: string;
    confirmationDialog: string;
    dialogAction: string;
    confirmMessage: string;
    confirmButton: string;
    cancelButton: string;
    disabledByRole: string;
    dialog: string;
};

const csvHeaders = [
    { label: 'Date/Time', key: 'Datetime' },
    { label: 'Created By', key: 'CreatedBy' },
    { label: 'Description', key: 'Description' },
];

interface IVideoEventHistoryExportState {
    isConfirmDialogOpen: boolean;
}

export class VideoEventHistoryExport extends React.PureComponent<IVideoEventHistoryExportProps, IVideoEventHistoryExportState> {
    state: IVideoEventHistoryExportState = {
        isConfirmDialogOpen: false,
    };

    private csvRef = React.createRef<HTMLSpanElement>();
    canEdit = canEditByRole([RolesEnum.videoEventEdit, RolesEnum.videoRecallEdit]);

    @bind
    showConfirmDialog(show: boolean) {
        if (!this.canEdit) return;
        this.setState({ isConfirmDialogOpen: show });
    }

    @bind
    async downloadCsv() {
        if (!this.canEdit) return;
        const csvLink = this.csvRef.current;

        this.showConfirmDialog(false);
        if (csvLink) {
            csvLink.click();
        }
    }

    formatDescription(description: string | undefined): string {
        if (typeof description == 'undefined') {
            return '';
        }
        description = description.replace(/\"/g, '""');
        return description;
    }

    render(): JSX.Element {
        const csvData: any[] = this.props.data.map((val) => {
            const eventDate = moment(val.actionDate) || moment();
            return {
                Datetime: DateFormatter.dateAndTimezone(eventDate),
                CreatedBy: val.createdByName,
                Description: this.formatDescription(val.actionDescription),
            };
        });
        return (
            <div className={styles.main}>
                <Button
                    className={
                        !this.canEdit
                            ? styles.disabledByRole
                            : scssStyles.styleEnvironment == 'encompass'
                            ? styles.downloadBtnEncompass
                            : styles.downloadBtn
                    }
                    onClick={() => this.showConfirmDialog(true)}
                    disabled={!this.canEdit}
                >
                    <FontAwesomeIcon icon={faFileExport} size="1x" />
                    &nbsp; Export Report
                </Button>
                <CSVLink data={csvData} headers={csvHeaders} filename={this.getHistoryDownloadFilename()}>
                    <span ref={this.csvRef}></span>
                </CSVLink>
                <Dialog open={this.state.isConfirmDialogOpen} className={`${styles.confirmationDialog} ${styles.dialog}`}>
                    <DialogContent className={styles.confirmMessage}>Are you sure you want to export this report?</DialogContent>
                    <DialogActions className={styles.dialogAction}>
                        <Button className={styles.confirmButton} onClick={() => this.downloadCsv()}>
                            Yes, Export
                        </Button>
                        <Button className={styles.cancelButton} onClick={() => this.showConfirmDialog(false)}>
                            No, Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    private getHistoryDownloadFilename(): string {
        const now = moment.tz(moment.tz.guess()).format('DDMMYYYY_HHmmz');

        return `${this.props.videoEvent.telematicDeviceName}_${this.props.videoEvent.videoEventTypeString}_${now}_CRFM.csv`;
    }
}
