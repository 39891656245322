import * as DateFormatter from '../../../../Shared/DateFormatter';
import { Grid } from '../../../../../MaterialUIComponents';
import { IVideoRecallServiceInjectedProps, VideoRecallService } from '$State/VideoRecallFreezerService';
import { React, moment } from '../../../../../Imports';
import { Section } from '../Section';
import { getStatusLabel } from '../../../../../utilities/videoEventStatusUtility';
import { VideoActions } from '../VideoActions';
import { VideoDelete } from '../../../../Shared/VideoDelete';
import { VideoEventDescription } from '../../../../Shared/VideoEventDescription';
import { VideoEventResponse, VideoEventWorkflowStatusIdEnum } from '$Generated/api';
import { VideoPromote } from '../../../../Shared/VideoPromote';
import { VideoEventService } from '$State/VideoEventFreezerService';

const styles = require('../VideoDetailsSection.scss') as {
    detailItem: string;
    detailItemName: string;
    detailItemValue: string;
    sectionContent: string;
    videoDetail: string;
};

interface IVideoRecallDetailsSectionBaseProps {
    event: VideoEventResponse;
    eventStatus: string | undefined;
    prevNextSplitOn: boolean;
    deleteVideoRecall: (eventId: number) => void;
    promoteVideoRecall: (eventId: number) => void;
    onSelectVideoEvent: (eventId: string, goBack: boolean, addHistoryState: boolean) => void;
    getFullListOfSucessRecallIds: () => void;
}

type IVideoRecallDetailsSectionProps = IVideoRecallDetailsSectionBaseProps & IVideoRecallServiceInjectedProps;

const _VideoRecallDetailsSection: React.FC<IVideoRecallDetailsSectionProps> = ({
    event,
    eventStatus,
    prevNextSplitOn,
    deleteVideoRecall,
    promoteVideoRecall,
    onSelectVideoEvent,
    getFullListOfSucessRecallIds,
}) => {
    const {
        eventStartDate,
        videoEventTypePairings,
        videoEventTypeString,
        displayId,
        vehicleDescription,
        eventDurationSeconds,
        requestedBy,
        requestedOn: requestedOnRawFormat,
    } = event;
    const statusLabelId = (eventStatus ?? '') as VideoEventWorkflowStatusIdEnum;
    const statusLabel = getStatusLabel(statusLabelId);
    const eventDate = moment(eventStartDate ?? undefined);
    const requestedOn = moment(requestedOnRawFormat ?? undefined);
    const completedOn = moment(event?.cameraRequest?.completionDate ?? undefined);

    const renderVideoRecallDetailsButtons = () => {
        const { id: eventId, displayId } = event;

        const { listofEventIdResult } = VideoEventService.getState();
        const allIds = listofEventIdResult?.data?.videoEventDisplayIdList ?? [];
        const currentId = displayId || '';

        const position = allIds.indexOf(currentId) ?? 0;

        const redirectNextRecall = async () => {
            await getFullListOfSucessRecallIds();

            if (position == allIds.length - 1) {
                onSelectVideoEvent('', true, true);
            } else {
                onSelectVideoEvent(allIds[position + 1], false, false);
            }
        };

        return (
            eventId !== undefined && (
                <VideoActions
                    actions={[
                        <VideoPromote
                            eventId={eventId}
                            promoteVideo={promoteVideoRecall}
                            redirectNextRecall={redirectNextRecall}
                            isDisabled={false}
                        />,
                        <VideoDelete
                            eventId={eventId}
                            deleteVideoRecall={deleteVideoRecall}
                            redirectNextRecall={redirectNextRecall}
                            isDisabled={false}
                        />,
                    ]}
                />
            )
        );
    };

    return (
        <Section
            title="Recall Details"
            actionButtons={renderVideoRecallDetailsButtons()}
            content={
                <div className={styles.sectionContent}>
                    <Grid container>
                        <Grid item sm={6} md={6} lg={12}>
                            <Grid container>
                                <Grid item sm={12} md={12} lg={8}>
                                    <div className={styles.detailItem}>
                                        <div className={styles.detailItemName}>Event Type</div>
                                        <div className={styles.detailItemValue}>
                                            <VideoEventDescription
                                                className={styles.videoDetail}
                                                videoEventTypePairings={videoEventTypePairings}
                                                videoEventTypeString={videoEventTypeString}
                                                videoEventFontSize="14px"
                                                isVideoEventDetails={false}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            {prevNextSplitOn && (
                                <div className={styles.detailItem}>
                                    <div className={styles.detailItemName}>ID</div>
                                    <div className={styles.detailItemValue}>{displayId}</div>
                                </div>
                            )}

                            <div className={styles.detailItem}>
                                <div className={styles.detailItemName}>Date/Time</div>
                                <div className={styles.detailItemValue}>
                                    {DateFormatter.date(eventDate)}
                                    <br />
                                    {DateFormatter.timeWithSecondsAndTimezone(eventDate)}
                                </div>
                            </div>

                            <div className={styles.detailItem}>
                                <div className={styles.detailItemName}>Event Status</div>
                                <div className={styles.detailItemValue}>{statusLabel}</div>
                            </div>
                        </Grid>
                        <Grid item sm={6} md={6} lg={12}>
                            <div className={styles.detailItem}>
                                <div className={styles.detailItemName}>Vehicle</div>
                                <div className={styles.detailItemValue}>{vehicleDescription || 'NA'}</div>
                            </div>

                            <div className={styles.detailItem}>
                                <div className={styles.detailItemName}>Duration</div>
                                <div className={styles.detailItemValue}>{eventDurationSeconds}s</div>
                            </div>

                            <div className={styles.detailItem}>
                                <div className={styles.detailItemName}>Requested By</div>
                                <div className={styles.detailItemValue}>{requestedBy}</div>
                            </div>

                            <div className={styles.detailItem}>
                                <div className={styles.detailItemName}>Requested On</div>
                                <div className={styles.detailItemValue}>
                                    {DateFormatter.date(requestedOn)}
                                    <br />
                                    {DateFormatter.timeWithSecondsAndTimezone(requestedOn)}
                                </div>
                            </div>

                            <div className={styles.detailItem}>
                                <div className={styles.detailItemName}>Completed On</div>
                                <div className={styles.detailItemValue}>
                                    {DateFormatter.date(completedOn)}
                                    <br />
                                    {DateFormatter.timeWithSecondsAndTimezone(completedOn)}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }
        />
    );
};

export const VideoRecallDetailsSection = VideoRecallService.inject(_VideoRecallDetailsSection);
