import { FreezerService, managedAjaxUtil, IAjaxState } from 'Imports';
import { AgreementsApiFactory, SignedAgreementHistoryResponse, SignedAgreementHistoryRequest } from '$Generated/api';
import { UserManagementService } from '$State/UserManagementFreezerService';

const InjectedPropName = 'agreements';

export interface IAgreementsState {
    signedAgreementsResults: IAjaxState<SignedAgreementHistoryResponse>;
    hasError: boolean;
    signAgreementsResults: IAjaxState<void>;
    showAgreements: boolean;
}

class AgreementsFreezerService extends FreezerService<IAgreementsState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                signedAgreementsResults: managedAjaxUtil.createInitialState(),
                hasError: false,
                signAgreementsResults: managedAjaxUtil.createInitialState(),
                showAgreements: true,
            },
            InjectedPropName,
        );
    }

    public setShowAgreements(showAgreements: boolean | undefined) {
        this.freezer.get().set({ showAgreements: showAgreements });
    }

    public getShowAgreements(): boolean {
        return this.freezer.get().showAgreements;
    }

    public async getSignedAgreements(): Promise<void | SignedAgreementHistoryResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'signedAgreementsResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AgreementsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1AgreementsHasSignedAgreementsGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
            },
            onOk: (data: SignedAgreementHistoryResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }
                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async signAgreements(agreementToSign: SignedAgreementHistoryRequest): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'signAgreementsResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AgreementsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1AgreementsSignAgreementsPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{},
                body: agreementToSign,
            },
            onOk: (data: any) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }
                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }
}

export const AgreementsService = new AgreementsFreezerService();
export type IAgreementsServiceInjectedProps = ReturnType<AgreementsFreezerService['getPropsForInjection']>;
