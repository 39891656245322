import { AgreementsService } from '$State/AgreementsFreezerService';
import { React } from 'Imports';
import { TypedSplitTreatments, splitTreatmentNames, getSplitUserAttributes, isSplitTreatmentOn } from '$Utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';

const styles = require('$Components/Shared/TermsOfUse.scss') as {
    agreementLink: string;
};
const viewAgreementsUrl = 'https://www.videoprotects.com/internet-terms-of-use';
const noticeAtCollectionUrl = ' https://www.jjkeller.com/learn/notice-at-collection';

export const TermsOfUse = () => {
    return (
        <TypedSplitTreatments names={[splitTreatmentNames.videoEventHeaderVerbiage]} attributes={getSplitUserAttributes()}>
            {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                return (
                    (isReady || isTimedout) &&
                    isSplitTreatmentOn(treatments[splitTreatmentNames.videoEventHeaderVerbiage]) && (
                        <div>
                            {AgreementsService.getShowAgreements() ? (
                                <div className={styles.agreementLink}>
                                    By logging into J. J. Keller VideoProtects, you agree to the{' '}
                                    <a href={viewAgreementsUrl} target="_blank" rel="noreferrer">
                                        Terms of Use.
                                    </a>{' '}
                                    For information about how we use any personal information we collect for our own purposes, please review
                                    our{' '}
                                    <a href={noticeAtCollectionUrl} target="_blank" rel="noreferrer">
                                        Notice at Collection.
                                    </a>
                                </div>
                            ) : null}
                        </div>
                    )
                );
            }}
        </TypedSplitTreatments>
    );
};
