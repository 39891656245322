import { React, bind, _ } from 'Imports';

import {
    TableBody,
    TableHead,
    Table,
    TableCell,
    TableRow,
    TableSortLabel,
    Switch,
    IconButton,
    TablePagination,
    TableContainer,
    TextField,
    InputAdornment,
    Button,
} from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';

import { EventNotificationResponse } from '$Generated/api';

import {
    IEventNotificationSettingsServiceInjectedProps,
    EventNotificationSettingsService,
} from '$State/EventNotificationSettingsFreezerService';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';

import { SubscriberDialog } from './SubscriberDialog';
interface IEventNotificationsListBaseProps {
    refreshSubscribersTab: (forceRefresh: boolean) => void;
    refreshNotificationsTab: (forceRefresh: boolean) => void;
    forceRefresh: boolean;
}

type IEventNotificationsListProps = IEventNotificationsListBaseProps &
    IEventNotificationSettingsServiceInjectedProps &
    IConfigServiceInjectedProps;

interface IEventNotificationsListState {
    eventNotificationsList?: EventNotificationResponse[];
    eventNotificationsListFiltered?: EventNotificationResponse[];
    showSubscriberDialog: boolean;
    editErrorMessage: string;
    selectedEventTypeId: number;
    page: number;
    rowsPerPage: number;
    notificationFilter: string;
    isSearchBarFocused: boolean;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
}

const styles = require('./EventNotificationsList.scss') as {
    descriptionContainer: string;
    switchRoot: string;
    switchBase: string;
    switchChecked: string;
    switchTrack: string;
    switchThumb: string;
    iconButton: string;
    addSubscriberIcon: string;
    switchTrackChecked: string;
    inputAdormentFilter: string;
    searchIcon: string;
    searchIconFocused: string;
    notificationFilter: string;
    notificationIntegrationFilter: string;
    addSubscriberButton: string;
    eventNotificationsTable: string;
    centerText: string;
    eventColumn: string;
    statusColumn: string;
    typeColumn: string;
    subscribersColumn: string;
    optionsColumn: string;
    closeButtonSearchFilter: string;
    inputAdormentCloseFilter: string;
    closeIcon: string;
    searchBarInput: string;
    inputFocused: string;
    notificationDescription: string;
};

const rowHeaders = [
    {
        id: 'name',
        label: 'Notifications',
        style: styles.eventColumn,
    },
    {
        id: 'status',
        label: 'Status',
        style: styles.statusColumn,
    },
    {
        id: 'type',
        label: 'Type',
        style: styles.typeColumn,
    },
    {
        id: 'subscribers',
        label: 'Subscribers',
        style: [styles.subscribersColumn, styles.centerText].join(' '),
    },
];

const inputStyles = {
    borderRadius: '3px',
    backgroundColor: '#FFFFF',
    border: '1px solid #D3D8DF',
    boxShadow: 'inset 0px 2px 3px rgba(0, 0, 0, 0.125)',
    padding: '0px 0px 0px 8px',
};

class _EventNotificationsList extends React.Component<IEventNotificationsListProps, IEventNotificationsListState> {
    state: IEventNotificationsListState = {
        eventNotificationsList: undefined,
        eventNotificationsListFiltered: undefined,
        showSubscriberDialog: false,
        selectedEventTypeId: 0,
        editErrorMessage: '',
        page: 0,
        rowsPerPage: 10,
        notificationFilter: '',
        isSearchBarFocused: false,
        sortBy: 'name',
        sortOrder: 'asc',
    };

    async componentDidMount(): Promise<void> {
        await this.getEventNotificationsList();
    }

    async componentDidUpdate(): Promise<void> {
        if (this.props.forceRefresh) {
            this.props.refreshNotificationsTab(false);
            await this.getEventNotificationsList();
        }
    }

    @bind
    async getEventNotificationsList(): Promise<void> {
        await this.props.eventNotificationSettings.getEventNotificationSettings();
        this.setState({ eventNotificationsList: undefined, eventNotificationsListFiltered: undefined });
        const { getEventNotificationSettingsResults } = this.props.eventNotificationSettings.getState();

        if (this.state.notificationFilter) {
            this.setState({
                eventNotificationsList: getEventNotificationSettingsResults.data || [],
            });
            this.handleNotificationFilterChange(this.state.notificationFilter);
        } else {
            this.setState({
                eventNotificationsList: getEventNotificationSettingsResults.data || [],
                eventNotificationsListFiltered: getEventNotificationSettingsResults.data || [],
            });
        }
    }

    @bind
    async handleStatusToggle(eventTypeId: string, isActive: boolean): Promise<void> {
        try {
            const eventNotificationId = Number(eventTypeId);

            const items = [...(this.state.eventNotificationsListFiltered ?? [])];
            const index = items.map((e) => e.eventTypeId).indexOf(eventNotificationId);
            const item = { ...items[index] };
            item.isActive = isActive;
            items[index] = item;
            this.setState({ eventNotificationsListFiltered: items });

            const result = await this.props.eventNotificationSettings.updateEventNotificationSettings(eventNotificationId, isActive);

            await this.getEventNotificationsList();
        } catch (ex) {
            ex.text()
                .then(function (text: string) {
                    return text;
                })
                .then((error: string) => {
                    this.setState({ editErrorMessage: error });
                });
        }
    }

    @bind
    onClickAddSubscriber(eventTypeId: number): void {
        this.setState({ selectedEventTypeId: eventTypeId, showSubscriberDialog: true });
    }

    @bind
    onCloseDialog(): void {
        this.setState({
            showSubscriberDialog: false,
        });
        this.props.refreshSubscribersTab(true);
    }

    @bind
    async refreshEventNotificationsList(): Promise<void> {
        await this.getEventNotificationsList();
    }

    @bind
    async handleChangePage(event: any, newPage: number): Promise<void> {
        this.setState({
            page: newPage,
        });
    }

    @bind
    async handleChangePagination(event: any, newPage: number): Promise<void> {
        this.setState({
            page: newPage - 1,
        });
    }

    @bind
    handleChangeRowsPerPage(event: any): void {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        });
    }

    @bind
    handleNotificationFilterChange(filter: string): void {
        this.setState({
            notificationFilter: filter,
        });

        const filteredRows = this.state.eventNotificationsList?.filter((row) => {
            return row?.name?.toLowerCase().includes(filter.toLowerCase());
        });

        this.setState({
            eventNotificationsListFiltered: filteredRows,
        });
    }

    @bind
    handleClearSearchFilter(): void {
        this.setState({
            notificationFilter: '',
        });

        this.setState({
            eventNotificationsListFiltered: this.state.eventNotificationsList,
        });
    }

    @bind
    calculatePagesCount(pageSize: number, totalCount: number): number {
        // we suppose that if we have 0 items we want 1 empty page
        return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
    }

    async handleRequestSort(field: string): Promise<void> {
        const { sortBy, sortOrder, eventNotificationsList, notificationFilter } = this.state;

        const isAsc = sortBy === field && sortOrder === 'asc';

        const sortItems = eventNotificationsList?.sort((a, b) => {
            let res = 0;
            let aString = '';
            let bString = '';
            switch (field) {
                case 'name':
                case 'type':
                    aString = a[field] ? a[field]! : '';
                    bString = b[field] ? b[field]! : '';
                    res = aString.localeCompare(bString);
                    break;
                case 'subscribers':
                    const aCount = a.subscribers;
                    const bCount = b.subscribers;
                    res = (aCount || 0) < (bCount || 0) ? -1 : (aCount || 0) > (bCount || 0) ? 1 : 0;
                    break;
                case 'status':
                    aString = a.isActive ? 'Active' : 'Inactive';
                    bString = b.isActive ? 'Active' : 'Inactive';
                    res = aString.localeCompare(bString);
                    break;
                default:
            }

            return isAsc ? -1 * res : res;
        });

        await this.setState({
            sortBy: field,
            sortOrder: isAsc ? 'desc' : 'asc',
            eventNotificationsList: sortItems,
        });

        this.handleNotificationFilterChange(notificationFilter);
    }

    @bind
    onFocusSearchBar(isFocused: boolean): void {
        this.setState({
            isSearchBarFocused: isFocused,
        });
    }

    render(): JSX.Element {
        const { editErrorMessage, eventNotificationsListFiltered, showSubscriberDialog, page, rowsPerPage, notificationFilter } =
            this.state;
        const isEncompass = this.props.config.isIntegrationPlatformEncompass();
        const isRunningStandAlone = this.props.config.isRunningStandAlone();

        return (
            <div className={styles.descriptionContainer}>
                <div className={isRunningStandAlone ? styles.notificationFilter : styles.notificationIntegrationFilter}>
                    <TextField
                        value={notificationFilter}
                        onChange={(e) => this.handleNotificationFilterChange(e.target.value)}
                        onFocus={() => this.onFocusSearchBar(true)}
                        onBlur={() => this.onFocusSearchBar(false)}
                        className={styles.searchBarInput}
                        placeholder={'Search for a notification...'}
                        InputProps={{
                            style: isEncompass ? inputStyles : {},
                            classes: {
                                focused: styles.inputFocused,
                            },
                            disableUnderline: isEncompass,
                            startAdornment: isEncompass ? (
                                this.state.isSearchBarFocused || notificationFilter ? null : (
                                    <InputAdornment className={styles.inputAdormentFilter} position={'start'}>
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            className={this.state.isSearchBarFocused ? styles.searchIconFocused : styles.searchIcon}
                                        />
                                    </InputAdornment>
                                )
                            ) : (
                                <InputAdornment className={styles.inputAdormentFilter} position={'start'}>
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        className={this.state.isSearchBarFocused ? styles.searchIconFocused : styles.searchIcon}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <Button
                                    onClick={this.handleClearSearchFilter}
                                    className={styles.closeButtonSearchFilter}
                                    hidden={notificationFilter ? false : true}
                                >
                                    <InputAdornment className={styles.inputAdormentCloseFilter} position={'end'}>
                                        <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />
                                    </InputAdornment>
                                </Button>
                            ),
                        }}
                        InputLabelProps={{ style: { marginLeft: isEncompass ? 8 : 0 } }}
                    />
                </div>
                <TableContainer>
                    <Table className={styles.eventNotificationsTable}>
                        <TableHead>
                            <TableRow>
                                {rowHeaders.map((head) => {
                                    return (
                                        <TableCell
                                            key={head.id}
                                            className={head.style}
                                            sortDirection={this.state.sortBy === head.id ? this.state.sortOrder : false}
                                        >
                                            <TableSortLabel
                                                active={this.state.sortBy === head.id}
                                                direction={this.state.sortOrder}
                                                onClick={(): Promise<void> => this.handleRequestSort(head.id)}
                                            >
                                                {head.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    );
                                })}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {eventNotificationsListFiltered
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((eventNotification: EventNotificationResponse, idx) => {
                                    return (
                                        <TableRow key={idx}>
                                            <TableCell className={styles.eventColumn}>
                                                {eventNotification.name}
                                                {eventNotification.eventTypeId == 5 ? (
                                                    <div className={styles.notificationDescription}>
                                                        Send a notification to the subscriber when a video recall they have requested is
                                                        complete.
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={eventNotification.isActive}
                                                    onChange={(e) => this.handleStatusToggle(e.target.value, e.target.checked)}
                                                    value={eventNotification.eventTypeId}
                                                    size={'small'}
                                                    classes={{
                                                        root: styles.switchRoot,
                                                        switchBase: styles.switchBase,
                                                        checked: styles.switchChecked,
                                                        track: eventNotification.isActive ? styles.switchTrackChecked : styles.switchTrack,
                                                        thumb: styles.switchThumb,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>{eventNotification.type}</TableCell>
                                            <TableCell className={styles.centerText}>{eventNotification.subscribers}</TableCell>
                                            <TableCell className={styles.optionsColumn}>
                                                <div>
                                                    {isEncompass ? (
                                                        <IconButton
                                                            onClick={() => this.onClickAddSubscriber(eventNotification.eventTypeId ?? 0)}
                                                            title="Add subscriber"
                                                            className={styles.iconButton}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faUserPlus}
                                                                size={'xs'}
                                                                className={styles.addSubscriberIcon}
                                                            />
                                                        </IconButton>
                                                    ) : (
                                                        <Button
                                                            onClick={() => this.onClickAddSubscriber(eventNotification.eventTypeId ?? 0)}
                                                            variant="text"
                                                            title="Add subscriber"
                                                            className={styles.addSubscriberButton}
                                                            disableRipple={true}
                                                        >
                                                            <FontAwesomeIcon icon={faUserPlus} className={styles.addSubscriberIcon} />
                                                            Add Subscriber
                                                        </Button>
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    isEncompass ? (
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[10, 20, 50]}
                            count={eventNotificationsListFiltered?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                MenuProps: {
                                    disableScrollLock: true,
                                    autoFocus: false,
                                    disableAutoFocusItem: true,
                                    disableEnforceFocus: true,
                                    disableAutoFocus: true,
                                },
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    ) : (
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[10, 20, 50]}
                            count={eventNotificationsListFiltered?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    )
                    //TODO: Implement Pagination component when Geotab to follow Figma design
                    // <Pagination
                    //     count={this.calculatePagesCount(rowsPerPage, eventNotificationsListFiltered?.length || 0)}
                    //     shape="rounded"
                    //     page={page == 0 ? page+1 : page}
                    //     onChange={this.handleChangePagination}
                    // />
                }
                {showSubscriberDialog && (
                    <SubscriberDialog
                        errorMessage={editErrorMessage}
                        onCancel={() => this.onCloseDialog()}
                        eventTypeId={this.state.selectedEventTypeId}
                        refreshEventNotificationsList={() => this.refreshEventNotificationsList()}
                    />
                )}
            </div>
        );
    }
}

export const EventNotificationsList = ConfigService.inject(EventNotificationSettingsService.inject(_EventNotificationsList));
