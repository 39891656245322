import * as scssStyles from '../../../css/settings.scss';
import styled from '@emotion/styled';
import { Button, Radio, DialogTitle, DialogActions, DialogContent } from '../../../MaterialUIComponents';

const GeotabStyledDialogTitle = `
    padding-bottom: 0px;
    padding-left: 24px;
    padding-right: 24px;
`;

const EncompassStyledDialogTitle = `
    padding-bottom: 14px;
    padding-left: 13px;
    padding-right: 80px;
    font-size: 18px; 
    font-weight: 400; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
`;

const GeotabStyledActions = `
    margin: 15px 30px 30px 30px;
    padding: 0;
    justify-content: space-between;    
`;
const GeotabStyledConfirmActions = `
    padding: 8px 24px;
    background-color: #f9fafb;
    border-top: 1px solid #dde2eb;
`;

const EncompassStyledActions = `
    margin: 5px 0 5px 0;
    padding: 15px 17px 16px 0;
    justify-content: flex-end;
    box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.15);
`;

const GeotabStyledButton = `
    width: 45%;
`;

const EncompassStyledDialogContent = `
    padding: 20px 15px;
    font-weight: 400;
    font-family: ${scssStyles.fontFamily};
    font-size: 18px;
`;

const GeotabStyledDialogContent = `
    padding-top: 8px !important;
    padding-right: 24px !important;
    padding-bottom: 24px !important;
    padding-left: 24px !important;
    font-weight: ${scssStyles.fontWeight};
    font-family: ${scssStyles.fontFamily};
    font-size: 20px;
`;

const GeotabStyledPlainButton = `
    width: auto !important;
    padding: 12px 16px 12px 12px;
    min-width: 85px;
`;
const GeotabStyledPlainCancelButton = `${GeotabStyledPlainButton}
    border: 0px solid;
    &:hover {
        border: 0px solid !important;
        background-color: transparent !important;
        color: #223E6E;
    }
`;

const EncompassStyledPlainButton = `
    border-width: 0px 0px 5px 0px;
    border-bottom: 5px solid #0b68a7;
    padding: 10px 15px 7px 15px;
    &:hover {
         border-bottom: 5px solid #0b68a7;
         padding: 10px 15px 7px 15px;
         border-width: 0px 0px 5px 0px;
    }
    min-width: 145px;
    height: 36px;
    font-size: 16px;
`;

const EncompassStyledButton = `
    padding: 10px 15px 10px 15px;
    font-size: 16px;
`;
const StyledDialogTitle = styled(DialogTitle)`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabStyledDialogTitle;
            case 'encompass':
                return EncompassStyledDialogTitle;
            default:
                return GeotabStyledDialogTitle;
        }
    }};

    & > h2 {
        font-size: 20px;
        font-weight: bold;
    }
`;
const StyledDialogContent = styled(DialogContent)`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabStyledDialogContent;
            case 'encompass':
                return EncompassStyledDialogContent;
            default:
                return GeotabStyledDialogContent;
        }
    }};
`;

const StyledDialogActions = styled(DialogActions)`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabStyledActions;
            case 'encompass':
                return EncompassStyledActions;
            default:
                return GeotabStyledActions;
        }
    }};
`;

const StyledConfirmDialogActions = styled(DialogActions)`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabStyledConfirmActions;
            case 'encompass':
                return EncompassStyledActions;
            default:
                return GeotabStyledActions;
        }
    }};
`;

const StyledBaseButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    line-height: 15px;
    font-size: 13px;
    font-weight: ${scssStyles.fontWeight};
    font-family: ${scssStyles.fontFamily};
`;
const StyledScoreButton = styled(StyledBaseButton)`
    background-color: ${scssStyles.buttonSubmitBackground};
    color: white;
    margin-right: 16px;
    &:hover {
        background-color: ${scssStyles.buttonHover};
    }
`;

const StyledSubmitButton = styled(StyledBaseButton)`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabStyledButton;
            case 'encompass':
                return EncompassStyledButton;
            default:
                return GeotabStyledButton;
        }
    }};
    background-color: ${scssStyles.buttonSubmitBackground};
    color: white;
    border: 1px solid ${scssStyles.buttonSubmitBackground};
    &:disabled {
        border: 1px solid #989898;
        color: #989898;
        border-radius: $border-radius-filter-button;
        background-color: white;
    }
    &:hover {
        background-color: ${scssStyles.buttonHover};
        border: 1px solid ${scssStyles.buttonHover};
    }
`;

const StyledCancelButton = styled(StyledBaseButton)`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabStyledButton;
            case 'encompass':
                return EncompassStyledButton;
            default:
                return GeotabStyledButton;
        }
    }};
    color: ${scssStyles.buttonCancelBackground};
    border: 1px solid ${scssStyles.buttonCancelBackground};
`;

const StyledRadio = styled(Radio)`
    color: ${scssStyles.radioButton};
    &.Mui-checked {
        color: ${scssStyles.radioButtonChecked};
    }
    &.Mui-checked ~ span {
        font-weight: ${scssStyles.fontWeight};
    }
`;

const StyledPlainSubmitButton = styled(StyledSubmitButton)`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabStyledPlainButton;
            case 'encompass':
                return EncompassStyledPlainButton;
            default:
                return GeotabStyledPlainButton;
        }
    }};
    text-transform: capitalize;
`;
const StyledPlainCancelButton = styled(StyledCancelButton)`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabStyledPlainCancelButton;
            case 'encompass':
                return EncompassStyledButton;
            default:
                return GeotabStyledPlainCancelButton;
        }
    }};
    text-transform: capitalize;
`;

export {
    StyledRadio,
    StyledScoreButton,
    StyledSubmitButton,
    StyledCancelButton,
    StyledDialogTitle,
    StyledDialogContent,
    StyledDialogActions,
    StyledConfirmDialogActions,
    StyledPlainSubmitButton,
    StyledPlainCancelButton,
};
