import { React } from '../../../Imports';
import styled from '@emotion/styled';
import { Alert } from '../../../MaterialUIComponents';

import { Collapse, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface IAlertProps {
    message: string;
    onClose: () => void;
    backgroundColor: string;
    textColor: string;
    isVisible: boolean;
    muiIcon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
    iconColor: string;
}

type AlertProps = IAlertProps;
const StyledAlert = (props: AlertProps) => {
    const { message, onClose, isVisible = false, muiIcon, textColor, backgroundColor, iconColor } = props;

    const AlertStyled = styled(Alert)`
        background-color: ${backgroundColor};
        color: ${textColor};
        font-weight: 600;
        border-radius: 0px;
    `;

    const StyledIcon = styled(muiIcon)`
        color: ${iconColor};
    `;

    return (
        <Collapse in={isVisible} timeout={{ exit: 200, enter: 200 }}>
            <AlertStyled onClose={onClose} icon={<StyledIcon />}>
                {message}
            </AlertStyled>
        </Collapse>
    );
};

export default StyledAlert;
