import { _, FreezerService, managedAjaxUtil, IAjaxState, moment } from 'Imports';
import {
    VideoEventResponseIEnumerablePagedResponseBase,
    VideoEventFilterRequest,
    VideoEventApiFactory,
    VideoEventResponse,
} from '$Generated/api';
import { UserManagementService } from '$State/UserManagementFreezerService';
import * as DateFormatter from '$Components/Shared/DateFormatter';
const InjectedPropName = 'videoRecall';

interface IVideoRecallState {
    videoRecallResults: IAjaxState<VideoEventResponseIEnumerablePagedResponseBase>;
    requestVideoRecallResult: IAjaxState<void>;
    hasError: boolean;
    hasInfiniteScrollError: boolean;
    videoRecallResultsFinal: VideoEventResponse[];
    videoRecallViewType: 'tile' | 'list';
    promoteVideoRecallResult: IAjaxState<void>;
    videosLoaded: boolean;
    includeInactiveVehicles: boolean;
}

class VideoRecallFreezerService extends FreezerService<IVideoRecallState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                videoRecallResults: managedAjaxUtil.createInitialState(),
                requestVideoRecallResult: managedAjaxUtil.createInitialState(),
                hasError: false,
                hasInfiniteScrollError: false,
                videoRecallResultsFinal: [],
                videoRecallViewType: 'tile',
                promoteVideoRecallResult: managedAjaxUtil.createInitialState(),
                videosLoaded: false,
                includeInactiveVehicles: false,
            },
            InjectedPropName,
        );
    }

    public async getFilteredVideoRecalls(
        filterOptions: VideoEventFilterRequest,
    ): Promise<void | VideoEventResponseIEnumerablePagedResponseBase> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'videoRecallResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                this.freezer.get().set({ videosLoaded: false });
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideorecallFilteredeventsPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{},
                body: filterOptions,
            },
            onOk: (data: VideoEventResponseIEnumerablePagedResponseBase) => {
                this.freezer.get().set({ videosLoaded: true });
                return this.transformVideoRecallResults(data);
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async requestVideoRecall(request: VideoEventResponse): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'requestVideoRecallVideoResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1RecallvideoPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{
                    integrationPartnerVehicleId: request.integrationPartnerVehicleId,
                    startDate: DateFormatter.getUtcString(moment(request.eventStartDate)),
                    endDate: DateFormatter.getUtcString(moment(request.eventEndDate)),
                },
            },
            onOk: () => {
                // noop, no data returned
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public clearVideoRecallVideoEvents(): void {
        this.freezer.get().videoRecallResults.set({ data: null });
        this.freezer.get().set({ videoRecallResultsFinal: [] });
    }

    public setVideoRecallViewType(type: 'tile' | 'list'): void {
        this.freezer.get().set({ videoRecallViewType: type });
    }

    public async deleteVideoRecall(eventId: number): Promise<void | VideoEventResponseIEnumerablePagedResponseBase> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'deleteVideoRecallResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideorecallDeletevideorecallPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ eventId: eventId },
            },
            onOk: () => {
                // no-op
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    private transformVideoRecallResults(data: VideoEventResponseIEnumerablePagedResponseBase): any {
        if (data.data !== undefined && data.success) {
            this.freezer.get().set({ hasError: false, hasInfiniteScrollError: false });
        } else {
            this.freezer.get().set({ hasError: true, hasInfiniteScrollError: true });
        }

        const freezer1 = this.freezer.get().videoRecallResultsFinal;

        let array: any = [];
        if (freezer1.length > 0) {
            array = _.clone(freezer1);
        }
        if (data.data) {
            for (const element of data.data) {
                array.push(element);
            }
            this.freezer.get().set({ videoRecallResultsFinal: array });
        }

        return data;
    }

    public async PromoteVideoRecall(eventId: number | undefined): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'promoteVideoRecallResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1PromotevideorecallPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ eventId: eventId },
            },
            onOk: () => {
                // noop, no data returned
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public getVideosLoaded(): boolean {
        return this.freezer.get().videosLoaded;
    }

    public setIncludeInactiveVehicles(includeInactiveVehicles?: boolean) {
        this.freezer.get().set({ includeInactiveVehicles });
    }

    public getIncludeInactiveVehicles(): boolean {
        return this.getState().includeInactiveVehicles;
    }
}

export const VideoRecallService = new VideoRecallFreezerService();
export type IVideoRecallServiceInjectedProps = ReturnType<VideoRecallFreezerService['getPropsForInjection']>;
