import { React, bind, _ } from 'Imports';
import { cx } from '@videoplatform/css-helpers';
import { Tabs, Tab, AppBar } from 'MaterialUIComponents';
import { TabProps } from '@mui/material';

import { PageHeader } from '$Components/Shared/PageHeader';
import { ConfigService } from '$State/ConfigFreezerService';
import { IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';

import { EventConfigurations } from './EventConfigurations/EventConfigurations';
import {
    getSplitUserAttributes,
    isSplitTreatmentOn,
    TypedSplitTreatments,
    splitTreatmentNames,
    splitComponentFactory,
} from '$Utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { EventNotificationsList } from './EventNotificationSettings/EventNotificationsList';
import { EventNotificationsListDescription } from './EventNotificationSettings/EventNotificationsListDescription';
import { SubscribersList } from './EventNotificationSettings/SubscribersList';
import { UserRolePermissions } from './UserSettings/UserRolePermissions';
import { RolesEnum, canAccessUserRolesPage } from 'externals/VerifyRole';
import { TermsOfUse } from '$Components/Shared/TermsOfUse';
import { PageHeader_Split_Off } from '$Components/Shared/PageHeader_Split_Off';

const styles = require('./Settings.scss') as {
    appBar: string;
    main: string;
    container: string;
    flexContainer: string;
    tab: string;
    usersTab: string;
    tabDisabled: string;
    tabSelected: string;
    tabIndicator: string;
    tabUsersIndicator: string;
    tabRoot: string;
    tabContentContainer: string;
    tabContentHidden: string;
    tabContentDisabled: string;
    tabHidden: string;
};

interface ISettingsProps {}

interface ISettingsState {
    tabValue: string;
    refreshSubscribers: boolean;
    refreshEventNotifications: boolean;
}

class TabDiv extends React.Component<TabProps> {
    render(): JSX.Element {
        return <div></div>;
    }
}

const EventConfigurationTab = splitComponentFactory(splitTreatmentNames.settingsEventConfiguration)(Tab, TabDiv);
const UsersTab = splitComponentFactory(splitTreatmentNames.settingsUsers)(Tab, TabDiv);

class _Settings extends React.Component<ISettingsProps, ISettingsState> {
    state: ISettingsState = {
        tabValue: 'Notifications',
        refreshSubscribers: false,
        refreshEventNotifications: false,
    };

    @bind
    handleTabChange(event: React.ChangeEvent<object>, value: any) {
        this.setState({ tabValue: value });
    }

    @bind
    refreshSubscribersTab(forceRefresh: boolean): void {
        this.setState({ refreshSubscribers: forceRefresh });
    }

    @bind
    refreshNotificationsTab(forceRefresh: boolean): void {
        this.setState({ refreshEventNotifications: forceRefresh });
    }

    @bind
    refreshUserRoles(email: string): void {
        const userName = IntegrationPartnerDataService.getUsername().toLowerCase();
        if (userName === email.toLowerCase()) {
            IntegrationPartnerDataService.getUserRoles();
            this.setState({ tabValue: 'Notifications' });
        }
    }

    render(): JSX.Element {
        const { tabValue } = this.state;

        return (
            <div className={styles.main}>
                <TermsOfUse />
                <TypedSplitTreatments names={[splitTreatmentNames.videoEventHeaderVerbiage]} attributes={getSplitUserAttributes()}>
                    {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                        return (isReady || isTimedout) && isSplitTreatmentOn(treatments[splitTreatmentNames.videoEventHeaderVerbiage]) ? (
                            <PageHeader pageTitle={'Settings'} />
                        ) : (
                            <PageHeader_Split_Off pageTitle={'Settings'} />
                        );
                    }}
                </TypedSplitTreatments>
                <div className={styles.container}>
                    <AppBar className={styles.appBar} elevation={0}>
                        <Tabs
                            value={tabValue}
                            TabIndicatorProps={{ className: tabValue == 'Users' ? styles.tabUsersIndicator : styles.tabIndicator }}
                            onChange={this.handleTabChange}
                            classes={{ flexContainer: styles.flexContainer, root: styles.tabRoot }}
                        >
                            <Tab
                                label="Notifications"
                                value="Notifications"
                                classes={{
                                    root: styles.tab,
                                    selected: styles.tabSelected,
                                }}
                            />

                            <Tab
                                label="Subscribers"
                                value="Subscribers"
                                classes={{
                                    root: styles.tab,
                                    selected: styles.tabSelected,
                                }}
                            />

                            {ConfigService.isIntegrationPlatformEncompass() ? (
                                <EventConfigurationTab
                                    label="Event Configurations"
                                    value="EventConfigurations"
                                    classes={{
                                        root: styles.tab,
                                        selected: styles.tabSelected,
                                    }}
                                />
                            ) : null}

                            {ConfigService.isIntegrationPlatformGeotab() && canAccessUserRolesPage([RolesEnum.videoSuperAdmin]) ? (
                                <UsersTab
                                    label="Users"
                                    value="Users"
                                    classes={{
                                        root: styles.usersTab,
                                        selected: styles.tabSelected,
                                    }}
                                />
                            ) : null}
                        </Tabs>
                    </AppBar>

                    <div className={cx([styles.tabContentContainer, tabValue !== 'Notifications' ? styles.tabContentHidden : undefined])}>
                        <EventNotificationsListDescription />
                        <EventNotificationsList
                            refreshSubscribersTab={this.refreshSubscribersTab}
                            refreshNotificationsTab={this.refreshNotificationsTab}
                            forceRefresh={this.state.refreshEventNotifications}
                        />
                    </div>

                    <div className={cx([styles.tabContentContainer, tabValue !== 'Subscribers' ? styles.tabContentHidden : undefined])}>
                        <SubscribersList
                            refreshNotificationsTab={this.refreshNotificationsTab}
                            refreshSubscribersTab={this.refreshSubscribersTab}
                            forceRefresh={this.state.refreshSubscribers}
                        />
                    </div>

                    {ConfigService.isIntegrationPlatformEncompass() && (
                        <TypedSplitTreatments
                            names={[splitTreatmentNames.settingsEventConfiguration]}
                            attributes={getSplitUserAttributes()}
                        >
                            {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                return isReady || isTimedout ? (
                                    isSplitTreatmentOn(treatments[splitTreatmentNames.settingsEventConfiguration]) ? (
                                        <div
                                            className={cx([
                                                styles.tabContentContainer,
                                                tabValue !== 'EventConfigurations' ? styles.tabContentHidden : undefined,
                                            ])}
                                        >
                                            <EventConfigurations />
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                ) : (
                                    <div></div>
                                );
                            }}
                        </TypedSplitTreatments>
                    )}

                    {ConfigService.isIntegrationPlatformGeotab() && (
                        <TypedSplitTreatments names={[splitTreatmentNames.settingsUsers]} attributes={getSplitUserAttributes()}>
                            {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                return isReady || isTimedout ? (
                                    isSplitTreatmentOn(treatments[splitTreatmentNames.settingsUsers]) ? (
                                        canAccessUserRolesPage([RolesEnum.videoSuperAdmin]) && (
                                            <div
                                                className={cx([
                                                    styles.tabContentContainer,
                                                    tabValue !== 'Users' ? styles.tabContentHidden : undefined,
                                                ])}
                                            >
                                                <UserRolePermissions refreshRoles={this.refreshUserRoles} />
                                            </div>
                                        )
                                    ) : (
                                        <div></div>
                                    )
                                ) : (
                                    <div></div>
                                );
                            }}
                        </TypedSplitTreatments>
                    )}
                </div>
            </div>
        );
    }
}

export const Settings = _Settings;
