import { canEditByRole, RolesEnum } from '$Externals/VerifyRole';
import { React } from 'Imports';
import { VideoDelete } from '$Components/Shared/VideoDelete';
import { VideoDownload } from '$Components/Shared/VideoDownload';
import { VideoPromote } from '$Components/Shared/VideoPromote';

const styles = require('./VideoRecallEventCardActionButtons.scss') as {
    buttonContainer: string;
};
interface IVideoEventCardActionButtonsProps {
    id: number;
    downloadUrl: string;
    promoteVideo: any;
    deleteVideoRecall?: any;
    workflowStep?: string;
    className?: string;
}
type VideoEventCardActionButtonsProps = IVideoEventCardActionButtonsProps;

export function VideoRecallEventCardActionButtons(props: VideoEventCardActionButtonsProps) {
    const { workflowStep, id, downloadUrl } = props;

    const deleteVideoRecall = () => {
        if (props.deleteVideoRecall) {
            props.deleteVideoRecall(id);
        }
    };

    const statusSuccess = workflowStep == 'Success';
    const downloadButtonDisabled = !(statusSuccess && canEditByRole([RolesEnum.videoAdmin]));
    const deleteButtonDisabled = !canEditByRole([RolesEnum.videoAdmin]);

    return (
        <div className={styles.buttonContainer}>
            <VideoPromote eventId={id || 0} promoteVideo={props.promoteVideo} isDisabled={!statusSuccess} />
            <VideoDownload videoUrl={downloadUrl} eventId={id} isDisabled={downloadButtonDisabled} />
            {id > 0 && <VideoDelete eventId={id} deleteVideoRecall={deleteVideoRecall} isDisabled={deleteButtonDisabled} />}
        </div>
    );
}
