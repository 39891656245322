import { _, FreezerService, IAjaxState, managedAjaxUtil } from 'Imports';
import { IntegrationPartnerDataApiFactory, UserRolesApiFactory } from '$Generated/api'
import { UserManagementService } from '$State/UserManagementFreezerService';
import { IIntegrationPartnerManager } from "integrationPartner/integrationPartnerAbstraction";

const InjectedPropName = 'integrationPartnerData';

export interface IIntegrationPartnerDataState {
    hasError: boolean;
    driversResults: IAjaxState<any[]>;
    deviceResults: IAjaxState<any[]>;
    currentUserResult: IAjaxState<any>;
    userRolesResult: IAjaxState<any[]>;
    updateUserRolesResult: any;
    integrationPartnerManager: any;
    canRender: boolean;
    groupsFilter: string;
}

class IntegrationPartnerDataFreezerService extends FreezerService<IIntegrationPartnerDataState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                hasError: false,
                driversResults: managedAjaxUtil.createInitialState(),
                deviceResults: managedAjaxUtil.createInitialState(),
                currentUserResult: managedAjaxUtil.createInitialState(),
                userRolesResult: managedAjaxUtil.createInitialState(),
                updateUserRolesResult: undefined,
                integrationPartnerManager: undefined,
                canRender: true,
                groupsFilter: '',
            },
            InjectedPropName,
        );
    }

    public async getDrivers() : Promise<void | any[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: "driversResults",
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
              if (params === undefined) {
                throw new Error("Parameters is undefined");
              }
              const IntegrationPartnerDataApi = IntegrationPartnerDataApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
              return IntegrationPartnerDataApi.apiV1IntegrationDriversGet(params, apiOptions.fetchOptions);
            },
            params: {
              ...UserManagementService.generateIntegrationUserParams(),
              ...{groupsFilter: this.getState().groupsFilter},
            },
            onOk: (data: any[]) => {
              return data;
            },
            onError: (err: any) => {
              console.error('error loading drivers', err);
              this.freezer.get().set({ hasError: true });
            }
          });
    }

    public async getDevices() : Promise<void | any[]> {
        return managedAjaxUtil.fetchResults({
          ajaxStateProperty: "deviceResults",
          freezer: this.freezer,
          onExecute: (apiOptions, params) => {
            if (params === undefined) {
              throw new Error("Parameters is undefined");
            }
            const IntegrationPartnerDataApi = IntegrationPartnerDataApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
            return IntegrationPartnerDataApi.apiV1IntegrationDevicesGet(params, apiOptions.fetchOptions);
          },
          params: {
            ...UserManagementService.generateIntegrationUserParams(),
            ...{groupsFilter: this.getState().groupsFilter},
          },
          onOk: (data: any[]) => {
            return data;
          },
          onError: (err: any) => {
            console.error('error loading devices', err);
            this.freezer.get().set({ hasError: true });
          }
        });
    }

    public async getUser() : Promise<void | any>{
      return managedAjaxUtil.fetchResults({
        ajaxStateProperty: "currentUserResult",
        freezer: this.freezer,
        onExecute: (apiOptions, params) => {       
          if (params === undefined) {
            throw new Error("Parameters is undefined");
          }
          const IntegrationPartnerDataApi = IntegrationPartnerDataApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return IntegrationPartnerDataApi.apiV1IntegrationUserGet(params, apiOptions.fetchOptions);
        },
        params: {
          ...UserManagementService.generateIntegrationUserParams(),
        },
        onOk: (data: any) => {
          if (data !== undefined) {
            this.freezer.get().set({ hasError: false });
          }
          return data;
        },
        onError: (err: any) => {
          console.error('error loading user', err);
          this.freezer.get().set({ hasError: true });
        }
      });
    }

    public async getUserRoles() : Promise<void | any>{
      return managedAjaxUtil.fetchResults({
        ajaxStateProperty: "userRolesResult",
        freezer: this.freezer,
        onExecute: (apiOptions, params) => {
          if (params === undefined) {
            throw new Error("Parameters is undefined");
          }
          const UserRoleApi = UserRolesApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return UserRoleApi.apiV1UserRolesGet(params, apiOptions.fetchOptions);
        },
        params: {
          ...UserManagementService.generateIntegrationUserParams(),
        },
        onOk: (data: any) => {
          if (data !== undefined) {
            this.freezer.get().set({ hasError: false });
          }
          return data;
        },
        onError: (err: any) => {
          console.error('error getting user roles from current user', err);
          this.freezer.get().set({ hasError: true });
        }
      });
    }

    public getUsername(): string {
      const currentUser = this.getState().currentUserResult;
      if (currentUser !== undefined) {
        return currentUser.data?.name;
      }
      return "";
    }

    public getUserIsMetric(): boolean {
      const currentUser = this.getState().currentUserResult;
      if (currentUser !== undefined && currentUser.data) {
        return currentUser.data.isMetric;
      }
      return false;
    }

    public getUserRolesState(): any {
      return this.getState().userRolesResult;
    }

    public getIntegrationPartnerManager(): IIntegrationPartnerManager {
      return this.freezer.get().integrationPartnerManager;
    }

    public setIntegrationPartnerManager(value:IIntegrationPartnerManager) {
      this.freezer.get().set({ integrationPartnerManager: value });
    }

    public getCanRender(): boolean {
      return this.freezer.get().canRender;
    }

    public setCanRender(value:boolean) {
      this.freezer.get().set({ canRender: value });
    }

    public setGroupsFilter(groupsFilter: string){
      this.freezer.get().set({ groupsFilter: groupsFilter});
    }

    public getGroupsFilter(): string {
      return this.getState().groupsFilter ?? '';
    }
}

export const IntegrationPartnerDataService = new IntegrationPartnerDataFreezerService();
export type IIntegrationPartnerDataInjectedProps = ReturnType<IntegrationPartnerDataFreezerService['getPropsForInjection']>;
