import { React, bind } from '../../../Imports';
import {
    Snackbar,
    Alert,
    RadioGroup,
    FormControlLabel,
    Radio,    
    TextValidator,
    ValidatorForm,
    Typography,
    styled
} from '../../../MaterialUIComponents';
import { withFeatureToggle } from '../../Shared/FeatureToggleHOC';
import { FeatureDisabledOverlay } from '../../Shared/FeatureDisabledOverlay';
import { VideoEventResponse } from '$Generated/api';
import { DriverNotificationService, IDriverNotificationServiceInjectedProps } from '../../../state/DriverNotificationFreezerService';
import * as DateFormatter from '../../Shared/DateFormatter';
import * as scssStyles from '../../../css/settings.scss';
import { canEditByRole, RolesEnum } from '../../../externals/VerifyRole';
import { RadioProps } from '@mui/material/Radio';

const InputMask = require('react-input-mask');

const StyledRadio = styled(({ ...props }: RadioProps) => <Radio size="small" color="default" {...props} />)(({ theme }) => ({
    color: scssStyles.customColor1,
    [`&.Mui-checked`]: {
        color: scssStyles.customColor1,
    },
}));

interface IDriverNotificationState {
    snackOpen: boolean;
    snackSuccess: boolean;
    snackMessage: string;
    email: string;
    phoneNumber: string;
    driverLinkToggle: 'email' | 'text';
    action: string;
    disableEmail: boolean;
    disableText: boolean;
    canEdit: boolean;
}

const styles = require('./DriverNotification.scss') as {
    main: string;
    notificationTextBox: string;
    flexEndJustify: string;
    sendButton: string;
    formLabel: string;
    driverMessageTypeRadioGroup: string;
    radioButton: string;
    formControlLabel: string;
    plainSendButton: string;
};

interface IDriverNotificationBaseProps {
    event: VideoEventResponse;
    handleSendNotification: () => void;
    driverEmail: string;
    driverPhoneNumber: string;
    disabled?: boolean;
    prevNextSplitOn?: boolean;
}

type IDriverNotificationProps = IDriverNotificationBaseProps & IDriverNotificationServiceInjectedProps;

const phoneNumberValidation = 'validPhoneNumber';

class _DriverNotification extends React.Component<IDriverNotificationProps, IDriverNotificationState> {
    emailForm: ValidatorForm | null = null;
    textForm: ValidatorForm | null = null;

    constructor(props: IDriverNotificationProps) {
        super(props);
    }

    state: IDriverNotificationState = {
        snackOpen: false,
        snackSuccess: false,
        email: this.props.driverEmail ?? '',
        phoneNumber: this.props.driverPhoneNumber ?? '',
        driverLinkToggle: 'email',
        snackMessage: '',
        action: '',
        disableEmail: false,
        disableText: false,
        canEdit: false,
    };

    componentDidMount(): void {
        ValidatorForm.addValidationRule(phoneNumberValidation, (value: any) => {
            const unmasked = this.unmask(value);
            if (unmasked.length !== 10) {
                return false;
            }
            return true;
        });
        const canEdit = canEditByRole([RolesEnum.videoEventEdit]);
        this.setState({ canEdit: canEdit });
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule(phoneNumberValidation);
    }

    componentDidUpdate() {
        const { phoneNumber, email } = this.state;
        if (this.textForm && this.unmask(phoneNumber).length === 0) {
            this.textForm.resetValidations();
        }

        if (this.emailForm && email?.length === 0) {
            this.emailForm.resetValidations();
        }
    }

    UNSAFE_componentWillReceiveProps(newProps: any) {
        if (this.props.driverEmail != newProps.driverEmail) {
            this.setState({ email: newProps.driverEmail });
        }
        if (this.props.driverPhoneNumber != newProps.driverPhoneNumber) {
            this.setState({ phoneNumber: newProps.driverPhoneNumber });
        }
    }

    @bind
    handleSnackClose(): void {
        this.setState({ snackOpen: false, snackMessage: '' });
    }

    @bind
    handleDriverMessageTypeChange(event: any): void {
        this.setState({ driverLinkToggle: event.target.value });
    }

    @bind
    handleEmailChange(event: any): void {
        const email = event.target.value;
        this.setState({ email });
        if (email.length == 0) {
            this.setState({ disableEmail: false });
        }
    }

    @bind
    handleEmailSubmit(): void {
        if (!this.state.canEdit) return;
        this.setState({ disableEmail: true });
        const timezoneOffsetHrs = new Date().getTimezoneOffset() / -60;
        const timezoneName = DateFormatter.getTimezoneString();
        this.props.driverNotifications
            .sendEmail(this.props.event.id ? this.props.event.id : 0, this.state.email, timezoneOffsetHrs, timezoneName)
            .then(() => {
                this.setState({
                    snackOpen: true,
                    snackMessage: 'Email sent successfully!',
                    snackSuccess: true,
                    email: '',
                    disableEmail: false,
                });
                this.props.handleSendNotification();
            })
            .catch(() => {
                this.setState({
                    snackOpen: true,
                    snackMessage: 'Email failed to send',
                    snackSuccess: false,
                    email: '',
                    disableEmail: false,
                });
            });
    }

    @bind
    async handleTextPhoneNumberChange(event: any): Promise<void> {
        const phoneNumber = event.target.value;
        this.setState({ phoneNumber });
        if (phoneNumber.length == 0) {
            this.setState({ disableText: false });
        }
    }

    @bind
    handleTextMessageSubmit(): void {
        if (!this.state.canEdit) return;
        this.setState({ disableText: true });
        const unmasked = this.unmask(this.state.phoneNumber);
        const timezoneOffsetHrs = new Date().getTimezoneOffset() / -60;
        const timezoneName = DateFormatter.getTimezoneString();
        this.props.driverNotifications
            .sendText(this.props.event.id ? this.props.event.id : 0, unmasked, timezoneOffsetHrs, timezoneName)
            .then(() => {
                //set the state separately here for phoneNumber to clear false bad validataion before success message pops up
                this.setState({ phoneNumber: '', disableText: false });
                this.setState({ snackOpen: true, snackMessage: 'Text sent successfully!', snackSuccess: true });
                this.props.handleSendNotification();
            })
            .catch(() => {
                this.setState({
                    snackOpen: true,
                    snackMessage: 'Text failed to send',
                    snackSuccess: false,
                    phoneNumber: '',
                    disableText: false,
                });
            });
    }

    @bind
    private unmask(value: any): string {
        if (typeof value === 'string') {
            return value.split('-').join('');
        }
        return '';
    }

    render(): JSX.Element {
        const isCompletedOrDismissed =
            this.props.event.videoEventStatus === 'Completed' || this.props.event.videoEventStatus === 'Dismissed';

        return (
            <div className={styles.main}>
                {this.props.disabled && <FeatureDisabledOverlay />}

                <div className={this.props.disabled ? 'feature-disabled' : undefined}>
                    <Snackbar
                        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                        open={this.state.snackOpen}
                        onClose={this.handleSnackClose}
                        autoHideDuration={4000}
                        style={{ position: 'absolute' }}
                    >
                        <div>
                            {this.state.snackSuccess && (
                                <Alert elevation={24} variant="filled" onClose={this.handleSnackClose} color="success" severity="success">
                                    {this.state.snackMessage}
                                </Alert>
                            )}
                            {!this.state.snackSuccess && (
                                <Alert elevation={24} variant="filled" onClose={this.handleSnackClose} color="error" severity="error">
                                    {this.state.snackMessage}
                                </Alert>
                            )}
                        </div>
                    </Snackbar>
                    <RadioGroup
                        row
                        aria-label="message type"
                        name="driverMessageType"
                        value={this.state.driverLinkToggle}
                        onChange={this.handleDriverMessageTypeChange}
                        className={styles.driverMessageTypeRadioGroup}
                    >
                        <FormControlLabel
                            className={styles.formControlLabel}
                            value="text"
                            control={<StyledRadio classes={{ root: styles.radioButton }} />}
                            label={<Typography className={styles.formLabel}>Text Message</Typography>}
                            disabled={isCompletedOrDismissed}
                        />
                        <FormControlLabel
                            className={styles.formControlLabel}
                            value="email"
                            control={<StyledRadio classes={{ root: styles.radioButton }} />}
                            label={<Typography className={styles.formLabel}>Email</Typography>}
                            disabled={isCompletedOrDismissed}
                        />
                    </RadioGroup>

                    {this.state.driverLinkToggle == 'email' ? (
                        <div>
                            <ValidatorForm
                                ref={(form) => (this.emailForm = form)}
                                onSubmit={this.handleEmailSubmit}
                                onError={(errors: any[]): void => console.log(errors)}
                            >
                                <TextValidator
                                    label="Driver Email"
                                    onChange={this.handleEmailChange}
                                    name="email"
                                    value={this.state.email}
                                    validators={['required', 'isEmail']}
                                    errorMessages={['This field is required', 'Email is not valid']}
                                    className={styles.notificationTextBox}
                                    disabled={isCompletedOrDismissed}
                                    validatorListener={(valid) => {
                                        if (this.state.email != '') {
                                            this.setState({ disableEmail: !valid });
                                        }
                                    }}
                                />
                                <div className={styles.flexEndJustify}>
                                    <input
                                        type="submit"
                                        value={'Send'}
                                        className={styles.plainSendButton}
                                        disabled={isCompletedOrDismissed || this.state.disableEmail || !this.state.canEdit}
                                    />
                                </div>
                            </ValidatorForm>
                        </div>
                    ) : (
                        <div>
                            <ValidatorForm
                                ref={(form) => (this.textForm = form)}
                                onSubmit={this.handleTextMessageSubmit}
                                onError={(errors: any[]): void => console.log(errors)}
                            >
                                <InputMask
                                    maskChar=""
                                    mask="999-999-9999"
                                    label="Driver Phone Number"
                                    onChange={this.handleTextPhoneNumberChange}
                                    name="phone"
                                    value={this.state.phoneNumber}
                                    validators={['required', phoneNumberValidation]}
                                    errorMessages={['This field is required', 'The phone number is not valid']}
                                    className={styles.notificationTextBox}
                                    disabled={isCompletedOrDismissed}
                                >
                                    {(inputProps: any) => (
                                        <TextValidator
                                            {...inputProps}
                                            validatorListener={(valid) => {
                                                if (this.state.phoneNumber != '') {
                                                    this.setState({ disableText: !valid });
                                                }
                                            }}
                                        />
                                    )}
                                </InputMask>
                                <div className={styles.flexEndJustify}>
                                    <input
                                        type="submit"
                                        value={'Send'}
                                        className={styles.plainSendButton}
                                        disabled={isCompletedOrDismissed || this.state.disableText || !this.state.canEdit}
                                    />
                                </div>
                            </ValidatorForm>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const DriverNotification = DriverNotificationService.inject(
    withFeatureToggle<IDriverNotificationBaseProps>(_DriverNotification, 'DriverCoachingWorkflowApp'),
);
