import { FreezerService, managedAjaxUtil, IAjaxState } from '../Imports';
import { UserManagementService } from '$State/UserManagementFreezerService';
import { CriticalSpeedApiFactory, CriticalSpeedResponse } from '$Generated/api';

const InjectedPropName = "CriticalSpeedSettings"

interface ICriticalSpeedSettingsState {
    getCriticalSpeedSettingsResults: IAjaxState<CriticalSpeedResponse>;
    updateCriticalSpeedSettingsResults: IAjaxState<void>;
    hasError: boolean;
}

class CriticalSpeedFreezerService extends FreezerService<ICriticalSpeedSettingsState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                getCriticalSpeedSettingsResults: managedAjaxUtil.createInitialState(),
                updateCriticalSpeedSettingsResults: managedAjaxUtil.createInitialState(),
                hasError: false
            },
            InjectedPropName
        )
    }

    public async getCriticalSpeedSettings(): Promise<CriticalSpeedResponse | void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'getCriticalSpeedSettingsResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const criticalSpeedAPI = CriticalSpeedApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return criticalSpeedAPI.apiV1CriticalSpeedGet(params);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams()
            },            
            onOk: (data: CriticalSpeedResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async updateCriticalSpeedSettings(speed: number): Promise<number | void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'updateCriticalSpeedSettingsResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const criticalSpeedAPI = CriticalSpeedApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return criticalSpeedAPI.apiV1CriticalSpeedPut(params);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{speedThreshold: speed}
            },            
            onOk: (data: number) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }
}

export const CriticalSpeedService = new CriticalSpeedFreezerService();
export type ICriticalSpeedServiceInjectedProps = ReturnType<
    CriticalSpeedFreezerService['getPropsForInjection']
>;
