import { Card, CardContent, CardHeader, styled } from 'MaterialUIComponents';
import * as scssStyles from '../../css/settings.scss';
import { cardHeaderClasses } from '@mui/material';

const WidgetCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
    position: 'relative',
    borderBottom: scssStyles.borderBottomCard,
}));

const WidgetCardHeader = styled(CardHeader)(({ theme }) => ({
    flex: '0 0 auto',
    padding: '0.75rem',
    [`& .${cardHeaderClasses.content}`]: {
        display: 'flex',
        alignItems: 'center',
    },
    [`& .${cardHeaderClasses.title}`]: {
        color: scssStyles.customColor2,
        display: 'inline-block',
        fontSize: '1.25rem',
        fontWeight: 'bold',
    },
    [`& .${cardHeaderClasses.subheader}`]: {
        color: scssStyles.dashboardRankingTitle,
        display: 'inline-block',
        fontSize: '1rem',
        marginLeft: '1rem',
    },
}));

const WidgetCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flex: '1 1',
    flexFlow: 'row nowrap',
    overflow: 'hidden',
}));

export { WidgetCard, WidgetCardHeader, WidgetCardContent };
