import { ConfigService, IConfigServiceInjectedProps } from "$State/ConfigFreezerService";
import { React } from "Imports";

const styles = require("pages/InactiveFleet/InactiveFleet.scss") as {
    appMessage: string;
    container: string;
    message: string;
    subMessage: string;
};

interface IInactiveFleetBaseProps {}

type IInactiveFleetProps = IInactiveFleetBaseProps & IConfigServiceInjectedProps;

export class _InactiveFleet extends React.Component<IInactiveFleetProps>{
    render() {
        return (
            <>
                <div className={styles.appMessage}>
                    <div className={styles.container}>
                        {this.props.config.isIntegrationPlatformGeotab() ?
                            (<span className={styles.message}>
                                This account is currently inactive.<br/>
                                <span className={styles.subMessage}>
                                    Please contact your support representative.<br/>
                                    (833) 933-0971
                                </span>
                            </span>)
                        :
                            (<span className={styles.message}>
                                This account is currently inactive.<br/>
                                <span className={styles.subMessage}>
                                    Please contact your support representative.<br/>
                                    1-800-327-1342
                                </span>
                            </span>)
                        }
                    </div>
                </div>
            </>
        );
    }
}

export const InactiveFleet = ConfigService.inject(_InactiveFleet);
