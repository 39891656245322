import { Card } from 'MaterialUIComponents';
import { React, moment } from 'Imports';
import { VideoEventCardThumbnailWithFallback } from '$Components/Shared/VideoEventCardThumbnailWithFallback';
import { VideoEventDescription } from '$Components/Shared/VideoEventDescription';
import { VideoEventResponse } from '$Generated/api';
import { VideoEventStatusIndicator } from '$Components/Shared/VideoEventStatusIndicator';
import { VideoPreviewUrls } from '$Components/Shared/VideoAssetsUrls';
import { VideoRecallEventCardActionButtons } from './VideoRecallEventCardActionButtons';
import { VideoRecallRequestedBy } from '$Components/VideoRecall/VideoRecallRequestedBy';
import VideoEventInfo from '$Components/VideoEvents/VideoEventInfo/VideoEventInfo';
import { VideoEventIdLabel } from '$Components/Shared/VideoEventIdLabel';

const styles = require('./VideoEventRecallCard.scss') as {
    indicator: string;
    card: string;
    cardBody: string;
    event: string;
    eventDescription: string;
    eventInfo: string;
    requestedBy: string;
    disabled: string;
    main: string;
};

interface IVideoEventCardProps {
    videoEvent: VideoEventResponse;
    videoEventUrls: VideoPreviewUrls;
    promoteVideo: (eventId: number) => void;
    deleteVideoRecall?: (eventId: number) => void;
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    updateVideoEventWorkflowStatus: (eventId: number | undefined) => void;
}

type VideoEventCardProps = IVideoEventCardProps;

export function VideoEventRecallCard(props: VideoEventCardProps) {
    const { DownloadUrl: downloadUrl, PreviewUrl: previewUrl, ThumbnailUrl: thumbnailUrl } = props.videoEventUrls;
    const canNavigateToDetails = props.videoEvent.workflowStep === 'Success';
    return (
        <div className={styles.main}>
            <VideoEventStatusIndicator
                className={styles.indicator}
                videoEventStatus={props.videoEvent.videoEventStatus}
                workflowStatus={props.videoEvent.workflowStep}
            ></VideoEventStatusIndicator>
            <Card className={styles.card}>
                <VideoEventCardThumbnailWithFallback
                    previewUrl={previewUrl}
                    thumbnailUrl={thumbnailUrl}
                    videoEvent={props.videoEvent}
                    onSelectVideoEvent={props.onSelectVideoEvent}
                    updateVideoEventWorkflowStatus={props.updateVideoEventWorkflowStatus}
                ></VideoEventCardThumbnailWithFallback>

                <div
                    className={`${styles.cardBody} ${canNavigateToDetails ? '' : styles.disabled}`}
                    onClick={(e) => {
                        if (canNavigateToDetails && props.videoEvent.displayId) props.onSelectVideoEvent(props.videoEvent.displayId, false);
                        else e.stopPropagation();
                    }}
                >
                    <div className={styles.event}>
                        <VideoEventDescription
                            videoEventTypePairings={props.videoEvent.videoEventTypePairings}
                            videoEventTypeString={props.videoEvent.videoEventTypeString}
                            className={styles.eventDescription}
                            isVideoEventDetails={false}
                        />
                        <div>
                            <VideoEventIdLabel displayEventId={props.videoEvent.displayId} />
                        </div>
                    </div>

                    <VideoRecallEventCardActionButtons
                        downloadUrl={downloadUrl}
                        id={props.videoEvent.id || 0}
                        workflowStep={props.videoEvent.workflowStep}
                        promoteVideo={props.promoteVideo}
                        deleteVideoRecall={props.deleteVideoRecall}
                    ></VideoRecallEventCardActionButtons>
                    <VideoEventInfo
                        event={props.videoEvent}
                        isPromotedVideoRecall={true}
                        isVideoRecallInfo={true}
                        eventDate={props.videoEvent.eventStartDate ? moment(props.videoEvent.eventStartDate) : moment()}
                        className={styles.eventInfo}
                    >
                        <VideoRecallRequestedBy
                            className={styles.requestedBy}
                            requestedBy={props.videoEvent.requestedBy}
                            requestedOn={props.videoEvent.requestedOn}
                        ></VideoRecallRequestedBy>
                    </VideoEventInfo>
                </div>
            </Card>
        </div>
    );
}
