import { React, bind, _, moment } from 'Imports';
import { IMultiselectFilterOptions } from '$Components/FilterComponents/MultiSelectFilterBar';
import * as DateFormatter from '$Components/Shared/DateFormatter';
import { IVideoEventServiceInjectedProps, VideoEventService } from '$State/VideoEventFreezerService';
import { VideoEventTypeResponse, VideoEventWorkflowStatusIdEnum } from '$Generated/api';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { getSplitUserAttributes, isSplitTreatmentOn, TypedSplitTreatments, splitTreatmentNames } from '$Utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { getStatusLabel } from '$Utilities/videoEventStatusUtility';
import { FilterChip } from '$Components/FilterComponents/FilterChip';

const styles = require('$Components/FilterComponents/FilterBreadcrumbsMultiselect.scss') as {
    main: string;
    filterbutton: string;
    closeIcon: string;
    closeIconEncompass: string;
};

interface IFilterBreadcrumbsMultiselectProps {
    filters: IMultiselectFilterOptions;
    onUpdateFilters: (filters: IMultiselectFilterOptions) => void;
}

class _FilterBreadcrumbsMultiselect extends React.Component<
    IFilterBreadcrumbsMultiselectProps &
    IIntegrationPartnerDataInjectedProps &
    IVideoEventServiceInjectedProps,
    object
> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
        this.props.videoEvents.getVideoEventTypes();
    }

    @bind
    onClickFilter(filterToDelete: 'Date' | 'FlaggedByUserOnly' | 'FlaggedOnly'): void {
        const filters: IMultiselectFilterOptions = _.clone(this.props.filters);
        switch (filterToDelete) {
            case 'Date':
                filters.startDate = new Date();
                filters.endDate = new Date();
                this.props.onUpdateFilters(filters);
                break;
            case 'FlaggedByUserOnly':
                filters.showFlaggedByUserOnly = false;
                this.props.onUpdateFilters(filters);
                break;
            case 'FlaggedOnly':
                filters.showFlaggedOnly = false;
                this.props.onUpdateFilters(filters);
        }
    }

    @bind
    onClickStatusFilter(filterToDelete: VideoEventWorkflowStatusIdEnum): void {
        const filters: IMultiselectFilterOptions = _.clone(this.props.filters);
        const statuses: VideoEventWorkflowStatusIdEnum[] = [];
        if (filters.status) {
            filters.status.forEach((status) => {
                if (status !== filterToDelete) {
                    statuses.push(status);
                }
            });
        }
        if (statuses.length == 0) {
            filters.status = undefined;
        } else {
            filters.status = statuses;
        }
        this.props.onUpdateFilters(filters);
    }

    @bind
    onClickEventType(filterToDelete: number): void {
        const filters: IMultiselectFilterOptions = _.clone(this.props.filters);
        const eventTypes: number[] = [];
        if (filters.eventTypes) {
            filters.eventTypes.forEach((eventType) => {
                if (eventType !== filterToDelete) {
                    eventTypes.push(eventType);
                }
            });
        }
        if (eventTypes.length == 0) {
            filters.eventTypes = [];
        } else {
            filters.eventTypes = eventTypes;
        }
        this.props.onUpdateFilters(filters);
    }

    @bind
    onClickVehicleId(filterToDelete: string): void {
        const filters: IMultiselectFilterOptions = _.clone(this.props.filters);
        const vehicles: string[] = [];
        if (filters.vehicleIds) {
            filters.vehicleIds.forEach((vehicleId) => {
                if (vehicleId !== filterToDelete) {
                    vehicles.push(vehicleId);
                }
            });
        }
        if (vehicles.length == 0) {
            filters.vehicleIds = [];
        } else {
            filters.vehicleIds = vehicles;
        }
        this.props.onUpdateFilters(filters);
    }

    @bind
    onClickDriverName(filterToDelete: string): void {
        const filters: IMultiselectFilterOptions = _.clone(this.props.filters);
        const drivers: string[] = [];
        if (filters.driverNames) {
            filters.driverNames.forEach((driverName) => {
                if (driverName !== filterToDelete) {
                    drivers.push(driverName);
                }
            });
        }
        if (drivers.length == 0) {
            filters.driverNames = [];
        } else {
            filters.driverNames = drivers;
        }
        this.props.onUpdateFilters(filters);
    }

    @bind
    formatDateFilter(): string {
        const start = DateFormatter.date(moment(this.props.filters.startDate));
        const end = DateFormatter.date(moment(this.props.filters.endDate));
        if (start === end) {
            return start;
        } else {
            return start + ' - ' + end;
        }
    }

    @bind
    formatEventType(types: VideoEventTypeResponse[], eventTypeId: number): string {
        let displayName: string | undefined = '';
        types.map((eventType) => {
            if (eventType.id === eventTypeId) {
                displayName = eventType.displayName;
            }
        });
        return displayName;
    }

    @bind
    formatDriverName(drivers: any[], driverId: string): string {
        let displayName: string | undefined = '';
        drivers.map((d) => {
            if (d.id === driverId) {
                displayName = d.firstName + ' ' + d.lastName;
            }
        });
        return displayName;
    }

    @bind
    formatVehicle(devices: any[], vehicleId: string): string {
        let displayName: string | undefined = '';
        devices.map((d) => {
            if (d.id === vehicleId) {
                displayName = d.name;
            }
        });
        return displayName;
    }

    render(): JSX.Element {        
        const { driversResults, deviceResults } = this.props.integrationPartnerData.getState();
        const drivers = driversResults.data ? driversResults.data : [];
        const devices = deviceResults.data ? deviceResults.data : [];

        const videoEventfreezer = this.props.videoEvents.getState();
        const { videoEventTypeResults } = videoEventfreezer;
        const videoEventTypes = videoEventTypeResults.data ? videoEventTypeResults.data : [];

        const isToday: boolean = this.formatDateFilter() === DateFormatter.date(moment());

        return (
            <div className={styles.main}>
                {this.props.filters.driverNames &&
                    this.props.filters.driverNames.map(
                        (d: string): JSX.Element => (
                            <FilterChip key={d} itemKey={d} onDelete={(): void => this.onClickDriverName(d)}>
                                Driver: {this.formatDriverName(drivers, d)}
                            </FilterChip>
                        )
                )}
                {this.props.filters.vehicleIds &&
                    this.props.filters.vehicleIds.map(
                        (v: string): JSX.Element => (
                            <FilterChip key={v} itemKey={v} onDelete={(): void => this.onClickVehicleId(v)}>
                                Vehicle: {this.formatVehicle(devices, v)}
                            </FilterChip>
                        )
                    )}
                {this.props.filters.eventTypes &&
                    this.props.filters.eventTypes.map(
                        (e: number): JSX.Element => (
                            <FilterChip key={e} itemKey={e} onDelete={(): void => this.onClickEventType(e)}>
                                Event Type: {this.formatEventType(videoEventTypes, e)}
                            </FilterChip>
                        )
                    )}
                {this.props.filters.status &&
                    this.props.filters.status.map(
                        (s: VideoEventWorkflowStatusIdEnum): JSX.Element => (
                            <FilterChip key={s} itemKey={s} onDelete={(): void => this.onClickStatusFilter(s)}>
                                Status: {getStatusLabel(s)}
                            </FilterChip>
                        )
                    )}
                {this.props.filters.startDate && (
                    <FilterChip onDelete={!isToday ? (): void => this.onClickFilter('Date') : undefined}>
                        Date: {this.formatDateFilter()}
                    </FilterChip>
                )}
                {this.props.filters.showFlaggedByUserOnly && (
                    <FilterChip onDelete={(): void => this.onClickFilter('FlaggedByUserOnly')}>
                        My Flagged Videos
                    </FilterChip>
                )}
                {this.props.filters.showFlaggedOnly && (
                    <FilterChip onDelete={(): void => this.onClickFilter('FlaggedOnly')}>
                        All Flagged Videos
                    </FilterChip>
                )}
            </div>
        );
    }
}

export const FilterBreadcrumbsMultiselect = VideoEventService.inject(
        IntegrationPartnerDataService.inject(
            _FilterBreadcrumbsMultiselect));