import { AuthProvider } from './AuthProvider';
import { React, _ } from '../Imports';
import { RouteComponentProps, withRouter } from "react-router";

const styles = require('./Auth.scss') as {
  main: string;
  authMessage: string;
};

interface IAuthServiceState {
  authProvider: AuthProvider;
}

interface ILogoutResponseProps {

}

export default class _LogoutResponse extends React.Component<ILogoutResponseProps & RouteComponentProps<string>, IAuthServiceState> {

  constructor(props: any) {
    super(props);
    this.state = {
      authProvider: new AuthProvider(),
    };
  }
  
  async componentDidMount():  Promise<void> {
    await this.state.authProvider.logoutCallback();

    // Redirect to Login page after removing access token to get started with login proccess again.
    this.props.history.push('/login');
  }

  render(){
      return (
        <div className={styles.main}>
          <div className={styles.authMessage}>
              <div>Response in process... Completing logout and redirecting...</div>
          </div>
        </div>
    );
  }
}

export const LogoutResponse = withRouter(_LogoutResponse);