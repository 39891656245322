import { React } from 'Imports';
import { AgreementsService } from '$State/AgreementsFreezerService';
import { Checkbox, FormControl, Grid, Paper } from '../../MaterialUIComponents';
import { useEffect, useState } from 'react';
import { faLoader } from '@fortawesome/pro-regular-svg-icons';

import * as _ from 'lodash';
import {
    StyledConfirmDialogActions,
    StyledDialogContent,
    StyledDialogTitle,
    StyledPlainCancelButton,
    StyledPlainSubmitButton,
} from '$Components/VideoEvents/VideoEventList/VideoEventListStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigService } from '$State/ConfigFreezerService';

type TermsOfUseProps = {
    isOpen?: boolean;
};

const styles = require('./TermsOfUseModal.scss') as {
    title: string;
    mainContainer: string;
};

export const TermsOfUseModal = (props: TermsOfUseProps) => {
    const [toogle, setToogle] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [agreementsVersionId, setAgreementsVersionId] = useState(0);

    useEffect(() => {
        const { signedAgreementsResults } = AgreementsService.getState();
        setAgreementsVersionId(signedAgreementsResults.data?.agreementsVersionId || 0);
    }, []);

    const handleOnSubmit = async () => {
        setDisabledSubmit(true);
        setShowLoader(true);
        await AgreementsService.signAgreements({
            agreementsVersionId,
        });
        await AgreementsService.getSignedAgreements();

        setTimeout(() => {
            if (ConfigService.isRunningGeotabIntegrated()) {
                window.location.reload();
            } else {
                window.location.replace(window.location.origin + '/');
            }
        }, 1000);
    };

    const handleOnClose = () => {
        history.back();
    };

    const contentMessage = () => (
        <FormControl>
            <Grid container style={{ width: '420px' }}>
                <Grid item xs={1}>
                    <Checkbox
                        style={{
                            transform: 'scale(0.75)',
                            marginRight: 8,
                            padding: 0,
                        }}
                        onChange={() => {
                            setToogle(!toogle);
                            setDisabledSubmit(toogle);
                        }}
                        checked={toogle}
                        disableRipple={true}
                    />
                </Grid>
                <Grid item xs={11}>
                    <div style={{ fontSize: '14px' }}>
                        I agree to the J. J. Keller&nbsp;
                        <a href="https://www.videoprotects.com/internet-terms-of-use" target="_blank" rel="noreferrer">
                            Terms of Use.
                        </a>
                        &nbsp;For information about how J. J. Keller uses any personal information it collects for its own purposes, please
                        review the&nbsp;
                        <a href="https://www.jjkeller.com/learn/notice-at-collection" target="_blank" rel="noreferrer">
                            Notice at Collection.
                        </a>
                    </div>
                </Grid>
            </Grid>
        </FormControl>
    );

    return (
        <>
            <div className={styles.mainContainer}>
                <Paper style={{ width: '470px', margin: 'auto' }}>
                    <StyledDialogTitle>
                        <p className={styles.title}> Terms of Use</p>
                    </StyledDialogTitle>

                    <StyledDialogContent>
                        <div>
                            <div style={{ fontSize: '16px' }}>{contentMessage()}</div>
                        </div>
                    </StyledDialogContent>
                    <StyledConfirmDialogActions>
                        <StyledPlainCancelButton variant={'outlined'} onClick={handleOnClose}>
                            Cancel
                        </StyledPlainCancelButton>
                        <StyledPlainSubmitButton
                            onClick={handleOnSubmit}
                            disabled={showLoader || disabledSubmit}
                            style={
                                showLoader
                                    ? {
                                          backgroundColor: '#0078d3',
                                          border: '1px solid ' + '#0078d3',
                                          color: '#ffffff',
                                      }
                                    : {}
                            }
                        >
                            {showLoader ? <FontAwesomeIcon icon={faLoader} fixedWidth /> : 'Accept'}
                        </StyledPlainSubmitButton>
                    </StyledConfirmDialogActions>
                </Paper>
            </div>
        </>
    );
};
