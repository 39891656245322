import { FreezerService, managedAjaxUtil, IAjaxState } from 'Imports';
import {
    VehicleCameraPairingResponse,
    VehicleCameraPairingRequest,
    VehicleRequest,
    AdministrativeApiFactory,
    CameraDeviceResponse,
    LiveVideoUrlResponse,
    CameraDeviceEarliestFootageResponse,
    EditVehicleCameraPairingRequest,
    LiveVideoStartResponse,
    LiveVideoStartTimeRequest,
    LiveVideoEndTimeUpdateRequest,
} from '$Generated/api';
import { UserManagementService } from '$State/UserManagementFreezerService';
import { IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';

const InjectedPropName = 'deviceAssociation';

interface IDeviceAssociationState {
    deviceAssociationResults: IAjaxState<VehicleCameraPairingResponse[]>;
    hasError: boolean;
    pairingConflict: IAjaxState<VehicleCameraPairingResponse>;
    cameraResults: IAjaxState<CameraDeviceResponse[]>;
    cameraLiveStream: IAjaxState<LiveVideoUrlResponse>;
    cameraEarliestFootage: IAjaxState<CameraDeviceEarliestFootageResponse>;
    cameraEarliestFootageDates: { [key: string]: string };
    liveVideoStart: IAjaxState<LiveVideoStartResponse>;
    liveVideoEnd: IAjaxState<void>;

}

class DeviceAssociationFreezerService extends FreezerService<IDeviceAssociationState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                deviceAssociationResults: managedAjaxUtil.createInitialState(),
                hasError: false,
                pairingConflict: managedAjaxUtil.createInitialState(),
                cameraResults: managedAjaxUtil.createInitialState(),
                cameraLiveStream: managedAjaxUtil.createInitialState(),
                cameraEarliestFootage: managedAjaxUtil.createInitialState(),
                cameraEarliestFootageDates: {},
                liveVideoStart: managedAjaxUtil.createInitialState(),
                liveVideoEnd: managedAjaxUtil.createInitialState(),
            },
            InjectedPropName,
        );
    }

    public async getDeviceAssociations(): Promise<void | VehicleCameraPairingResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'deviceAssociationResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceassociationsGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ groupsFilter: IntegrationPartnerDataService.getState().groupsFilter },
            },
            onOk: (data: VehicleCameraPairingResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async checkVehiclePairing(integrationPartnerVehicleId: string): Promise<void | VehicleCameraPairingResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'pairingConflict',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1ExistingDeviceAssociationIntegrationPartnerVehicleIdGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ integrationPartnerVehicleId: integrationPartnerVehicleId },
            },
            onOk: (data: VehicleCameraPairingResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getUnpairedCameraDevices(): Promise<void | CameraDeviceResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'cameraResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1UnpairedCamerasGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
            },
            onOk: (data: CameraDeviceResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async mapDeviceAssociations(vehiclesData: VehicleRequest[]): Promise<void | void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'mapDevice',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceassociationsmapPut(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{},
                body: vehiclesData,
            },
            onOk: () => {},
            onError: () => {},
        });
    }

    public async checkCameraPairing(cameraId: number): Promise<VehicleCameraPairingResponse | void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'pairingConflict',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1ExistingCameraAssociationCameraIdGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ cameraId: cameraId },
            },
            onOk: (data: VehicleCameraPairingResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async postVehicleCameraPairing(newPairing: VehicleCameraPairingRequest): Promise<void | VehicleCameraPairingResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'deviceAssociationResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceassociationsPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                body: newPairing,
            },
            onOk: (data: VehicleCameraPairingResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async putVehicleCameraPairing(
        editPairingRequest: EditVehicleCameraPairingRequest,
    ): Promise<void | VehicleCameraPairingResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'deviceAssociationResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceassociationsEditPut(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                body: editPairingRequest,
            },
            onOk: (data: VehicleCameraPairingResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async endVehicleCameraPairing(pairingId: number): Promise<void | VehicleCameraPairingResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'deviceAssociationResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceassociationsPairingIdDelete(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ pairingId: pairingId },
            },
            onOk: (data: VehicleCameraPairingResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getCameraLiveStream(vehicleCameraPairingId: number): Promise<LiveVideoUrlResponse | void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'cameraLiveStream',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1CameraDeviceLiveVideoUrlGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                vehicleCameraPairingId: vehicleCameraPairingId,
            },
            onOk: (data: LiveVideoUrlResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async saveLiveVideoStartTime(liveVideoStartRequest: LiveVideoStartTimeRequest): Promise<LiveVideoStartResponse | void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'liveVideoStart',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1CameraDeviceLiveVideoStartPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                body: liveVideoStartRequest,
            },
            onOk: (data: LiveVideoStartResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async saveLiveVideoEndTime(liveVideoEndRequest: LiveVideoEndTimeUpdateRequest): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'liveVideoStart',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1CameraDeviceLiveVideoEndPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                body: liveVideoEndRequest,
            },
            onOk: (data) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getCameraEarliestFootage(cameraSerial: string): Promise<CameraDeviceEarliestFootageResponse | void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'cameraEarliestFootage',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = AdministrativeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceassociationsEarliestFootageSerialNumberGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                serialNumber: cameraSerial,
            },
            onOk: (data: CameraDeviceEarliestFootageResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });

                    // Set this camera's earliest footage date in the freezer, as long as the data exists.
                    if (data.serialNumber && data.earliestFootageDate) {
                        const dateString = new Date(data.earliestFootageDate).toLocaleDateString();
                        this.freezer.get().set({
                            cameraEarliestFootageDates: {
                                ...this.getState().cameraEarliestFootageDates,
                                [data.serialNumber]: dateString,
                            },
                        });
                    } else {
                        // If the data was missing, set the camera's earliest footage date to Unknown
                        this.freezer.get().set({
                            cameraEarliestFootageDates: {
                                ...this.getState().cameraEarliestFootageDates,
                                [cameraSerial]: 'Unknown',
                            },
                        });
                    }
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });

                // Set this camera's earliest footage date to unknown
                this.freezer.get().set({
                    cameraEarliestFootageDates: {
                        ...this.getState().cameraEarliestFootageDates,
                        [cameraSerial]: 'Unknown',
                    },
                });
            },
        });
    }
}

export const DeviceAssociationService = new DeviceAssociationFreezerService();
export type IDeviceAssociationServiceInjectedProps = ReturnType<DeviceAssociationFreezerService['getPropsForInjection']>;
