import { bind, React,  _ } from '../../../Imports';
import { ICriticalSpeedServiceInjectedProps, CriticalSpeedService } from '$State/CriticalSpeedFreezerService'
import { Button, Snackbar, TextField, Alert, Card } from 'MaterialUIComponents';

interface IEventConfigurationProps {
}

interface IEventConfigurationState {
    speed: string;
    snackOpen: boolean;
    snackMessage: string;
    snackSuccess: boolean;
    saveEnabled: boolean;
}

const styles = require('./EventConfigurations.scss') as {
    speedValue: string,
    inlineInput: string,
    criticalSpeedingContent: string,
    criticalSpeedingInnerContent: string,
    criticalSpeedCardHr: string,
    card: string,
}

type  IEventConfigurationListProps = IEventConfigurationProps & ICriticalSpeedServiceInjectedProps;


class _EventConfigurations extends React.Component<IEventConfigurationListProps> {
    state: IEventConfigurationState = {
        speed: "",
        snackOpen: false,
        snackMessage: "",
        snackSuccess: false,
        saveEnabled: true,
    }
    
    async componentDidMount(): Promise<void> {
        await this.getSpeed();
    }
    
    async getSpeed(): Promise<void> {
        await this.props.CriticalSpeedSettings.getCriticalSpeedSettings();

        const { getCriticalSpeedSettingsResults } = this.props.CriticalSpeedSettings.getState();
        const speedData = getCriticalSpeedSettingsResults.data?.speedThreshold ? getCriticalSpeedSettingsResults.data.speedThreshold : 10;
        if (speedData != parseInt(this.state.speed))
            this.setState({ speed: speedData });
    } 

    updateSpeed(input: string) {
        input = input.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        input = input.slice(0,2);
        let speed = parseInt(input);
        speed = speed || 0;
        this.setState({speed: speed});
    }

    saveSpeed() {
        this.setState({saveEnabled : false});
        this.props.CriticalSpeedSettings.updateCriticalSpeedSettings(parseInt(this.state.speed)).then(
            () => {
                this.setState({ snackOpen: true, snackMessage: 'Setting saved successfully!', snackSuccess: true});
                this.setState({saveEnabled : true});
            }
        ).catch(
            () => {
                this.setState({ snackOpen: true, snackMessage: 'Setting failed to save', snackSuccess: false});
                this.setState({saveEnabled : true});
            }
        );
    }
    
    @bind
    handleSnackClose(): void {
        this.setState({ snackOpen: false });
    }


    render(): JSX.Element {
        return (
            <div>
                <Card id={"critical-speed-card"} variant="outlined">
                    <div className={styles.criticalSpeedingContent}>
                        <hr className={styles.criticalSpeedCardHr}/>
                        <div className={styles.criticalSpeedingInnerContent}>
                            <h4>Critical Speeding</h4>
                            <div>
                                Generate critical speeding event if the vehicle speed exceeds the posted speed limit sign by 
                                <span id="inline-input">
                                    <TextField className={styles.speedValue} variant="outlined" id="outlined-basic" value={this.state.speed} onChange={(e) => this.updateSpeed(e.target.value)}></TextField>
                                </span> 
                                mph or greater. 
                                <Snackbar
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={this.state.snackOpen}
                                    onClose={this.handleSnackClose}
                                    autoHideDuration={4000}
                                    style={{ position: 'absolute', width: '200px', right: '120px', top: '80px'}}
                                >
                                    <div>
                                        {this.state.snackSuccess &&
                                            <Alert elevation={24} variant="filled" onClose={this.handleSnackClose} color="success" severity="success">
                                                {this.state.snackMessage}
                                            </Alert>
                                        }
                                        {!this.state.snackSuccess &&
                                            <Alert elevation={24} variant="filled" onClose={this.handleSnackClose} color="error" severity="error">
                                                {this.state.snackMessage}
                                            </Alert>
                                        }
                                    </div>
                                </Snackbar>
                                <Button disabled={!this.state.saveEnabled} size="small" id={"small-contained-button"} onClick={() => this.saveSpeed()}>Save</Button>
                            </div>
                        </div>
                        <hr className={styles.criticalSpeedCardHr}/>
                    </div>
                </Card>
            </div>
        )
    }
} 


export const EventConfigurations = CriticalSpeedService.inject(_EventConfigurations)