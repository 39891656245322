import { React } from '../../Imports';
import { Button, Dialog, DialogActions, DialogTitle } from 'MaterialUIComponents';
import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons';
import { IVideoRecallServiceInjectedProps, VideoRecallService } from '$State/VideoRecallFreezerService';
import { StyledIconButton } from '$Components/Shared/MaterialUIComponents/StyledIconButton';
import { useState } from 'react';

interface IVideoPromoteProps {
    eventId: number;
    promoteVideo: (eventId: number) => void;
    isDisabled?: boolean;
    redirectNextRecall?: () => void;
}

const styles = require('./VideoPromote.scss') as {
    dialog: string;
};

type VideoPromoteProps = IVideoPromoteProps & IVideoRecallServiceInjectedProps & IConfigServiceInjectedProps;

const _VideoPromote = (props: VideoPromoteProps) => {
    const [isDisabled, setIsDisabled] = useState(props.isDisabled);
    const [showPromotePopup, setShowPromotePopup] = useState(false);

    const togglePromotePopup = (e: any) => {
        e.stopPropagation();
        setShowPromotePopup(!showPromotePopup);
    };

    const handleConfirmPromoteClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.promoteVideo(props.eventId);
        setShowPromotePopup(!showPromotePopup);
        setIsDisabled(true);
        props.redirectNextRecall && props.redirectNextRecall();
    };

    return (
        <>
            <StyledIconButton icon={faBullhorn}
                title={'Promote Video'}
                isDisabled={isDisabled || false}
                handleOnClick={togglePromotePopup} />
            <Dialog open={showPromotePopup}
                onClick={(e) => e.stopPropagation()}
                className={styles.dialog}>
                <DialogTitle>Promote Video Recall?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleConfirmPromoteClick}>Continue</Button>
                    <Button onClick={togglePromotePopup}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const VideoPromote = VideoRecallService.inject(ConfigService.inject(_VideoPromote));
