import { React, _ } from 'Imports';
import { RouteComponentProps, withRouter } from "react-router";
import { AuthProvider } from 'oauth/AuthProvider';
import { User } from 'oidc-client';

const styles = require('oauth/Auth.scss') as {
  main: string;
  authMessage: string;
};

interface IAuthServiceState {
  user: {};
  authProvider: AuthProvider;
}

interface IAuthServiceProps {

}

export class _AuthService extends React.Component<IAuthServiceProps & RouteComponentProps<string>, IAuthServiceState> {

  constructor(props: any) {
    super(props);
    this.state = {
      user: {},
      authProvider: new AuthProvider(),
    };
  }

  async componentDidMount(): Promise<void> {
    const user = await this.getUser();
    if (user && user?.access_token) {
      this.props.history.push('/videoevents');
    }
    else {
      await this.state.authProvider.login();
    }
  }

  private getUser() : Promise<User | null> {
    return this.state.authProvider.getUser();
  };

  render(){
    return (
      <></>
    );
  }
}

export const AuthService = withRouter(_AuthService);