import { FreezerService, managedAjaxUtil, IAjaxState } from 'Imports';
import { UserRolesResponse, UserRolesApiFactory } from '$Generated/api';
import { UserManagementService } from '$State/UserManagementFreezerService'; 

const InjectedPropName = 'userRoles';

interface IUserRoleState {
    getUserRolePersmissionsResults: IAjaxState<UserRolesResponse[]>;
    updateUserRolePermissionsResults: IAjaxState<void>;
    hasError: boolean;
}

class UserRoleFreezerService extends FreezerService<IUserRoleState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                getUserRolePersmissionsResults: managedAjaxUtil.createInitialState(),
                updateUserRolePermissionsResults: managedAjaxUtil.createInitialState(),
                hasError: false
            },
            InjectedPropName
        )
    }

    public async getUserRolePermissions(): Promise<void | UserRolesResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'getUserRolePersmissionsResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const UserRolesApi = UserRolesApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return UserRolesApi.apiV1UsersRolesByFleetGet(params);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{},
            },            
            onOk: (data: UserRolesResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async updateUserRoles(userRolesUpdate: any) : Promise<void | any>{
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: "updateUserRolePermissionsResults",
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
            if (params === undefined) {
                throw new Error("Parameters is undefined");
            }
            const UserRoleApi = UserRolesApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
            return UserRoleApi.apiV1UserRolesPut(params, apiOptions.fetchOptions);
            },
            params: {
            ...UserManagementService.generateIntegrationUserParams(),
            body: userRolesUpdate
            },
            onOk: (data: any) => {
            if (data !== undefined) {
                this.freezer.get().set({ hasError: false });
            }
            return data;
            },
            onError: (err: any) => {
            console.error('error loading user', err);
            this.freezer.get().set({ hasError: true });
            }
        });
    }
}

export const UserRoleService = new UserRoleFreezerService();
export type IUserRoleServiceInjectedProps = ReturnType<
    UserRoleFreezerService['getPropsForInjection']
>;