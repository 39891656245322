import * as scssStyles from '$CSS/settings.scss';
import styled from '@emotion/styled';

const GeotabStyledEventType = `
    line-height: ${scssStyles.textCardDescriptionLineHeight};
`;

const EncompassStyledEventType = (fontSize: string) => {
    return `
    line-height: 1.1em;
    font-size: ${fontSize};
    height: 1.6em;
    margin-bottom: -3px;
`;
};

type StyledEventProps = {
    fontSize?: string;
};

const StyledEventType = styled.div<StyledEventProps>`
    ${(props) => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabStyledEventType;
            case 'encompass':
                const fontSize = props.fontSize || '15px';
                return EncompassStyledEventType(fontSize);
            default:
                return GeotabStyledEventType;
        }
    }};
`;

export { StyledEventType };
