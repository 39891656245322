import { VideoEventResponse } from '$Generated/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { React, bind } from '../../../Imports';
import { IConfigServiceInjectedProps, ConfigService } from '../../../state/ConfigFreezerService';

const styles = require('./EventData.scss') as {
    main: string;
    blueText: string;
    map: string;
    placeIcon: string;
    flexColumn: string;
    addressStreetName: string;
    mapUnavailable: string;
};

interface IEventDataProps {
    event: VideoEventResponse;
    mapUrl: string;
    latitude: number;
    longitude: number;
}

interface IEventDataState {
    mapWidth: number;
}

class _EventData extends React.Component<IEventDataProps & IConfigServiceInjectedProps, IEventDataState> {
    state = {
        mapWidth: 355,
    };

    componentDidMount(): void {
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.onWindowResize);
    }

    @bind
    onWindowResize(): void {
        const mapWidth = document.getElementById('mapContainer')?.offsetWidth || 355;
        this.setState({ mapWidth });
    }

    render() {
        const region = this.props.event.addressRegion ?? 'Unknown';
        const { mapWidth } = this.state;
        const { latitude, longitude, config } = this.props;
        const mapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&markers=${latitude},${longitude}&markers=size:tiny&zoom=10&size=${mapWidth}x355&key=${config.getGoogleMapsAPIKey()}`;

        const hasValidLocation = latitude !== 0 && longitude !== 0 && region !== 'Unknown';

        let map: JSX.Element;
        if (hasValidLocation) {
            map = (
                <div className={styles.map} id="mapContainer">
                    <a href={this.props.mapUrl}>
                        <img src={mapURL} width="100%" />
                    </a>
                </div>
            );
        } else {
            map = <div className={styles.mapUnavailable}>Map currently unavailable</div>;
        }

        return (
            <div className={styles.main}>
                <div className={styles.addressStreetName}>
                    <div className={styles.flexColumn}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.placeIcon} size="lg" />
                    </div>
                    <div className={styles.flexColumn}>
                        <div className={styles.blueText}>{this.props.event.addressStreetName || 'Unknown'}</div>
                        <div className={styles.blueText}>
                            {this.props.event.addressCityName || 'Unknown'}, {region}
                        </div>
                    </div>
                </div>

                {map}
            </div>
        );
    }
}

export const EventData = ConfigService.inject(_EventData);
