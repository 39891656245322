import { React } from '../../../Imports';
import { Backdrop } from '../../../MaterialUIComponents';
import Loader from '../Loader';
import styled from '@emotion/styled';

interface IBackdropProps {
    isVisible: boolean;
    backDropColor?: string;
    isFullViewHeight?: boolean;
    children: React.ReactNode;
}

const BackdropStyled = styled(Backdrop)`
    z-index: 1000;
    position: absolute;
    opacity: 0.8;
`;

type BackdropProps = IBackdropProps;
const StyledLoader = (props: BackdropProps) => {
    const { isVisible = true, children, isFullViewHeight = true, backDropColor = undefined } = props;

    const style = {
        ...(backDropColor !== undefined && { backgroundColor: backDropColor }),
        ...(isFullViewHeight && { height: '100vh' }),
    };

    return (
        <>
            {children}
            <BackdropStyled style={style} open={isVisible}>
                <div style={{ marginBottom: '10%' }}>
                    <Loader />
                </div>
            </BackdropStyled>
        </>
    );
};

export default StyledLoader;
