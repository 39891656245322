import { React } from 'Imports';
import { Checkbox, FormControl, Grid } from 'MaterialUIComponents';;
import { ConfirmDialog } from '$Components/Shared/ConfirmDialog';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import * as _ from 'lodash';
import * as scssStyles from '$CSS/settings.scss';

const styles = require('../styles/ConfirmLiveVideo.scss') as {
    containerMessage: string;
    alertIcon: string;
    warning: string;
    checktext: string;
    questionContainer: string;
};

type ConfirmLiveVideoProps = {
    isOpen: boolean;
    handleShowLiveVideo: (showLiveVideo: boolean, alertDriver: boolean) => void;
    handleClose: (show: boolean, pairing: number) => void;
};

export const ConfirmLiveVideo = (props: ConfirmLiveVideoProps) => {
    const { isOpen, handleShowLiveVideo, handleClose } = props;
    const [alertDriver, setAlertDriver] = useState(true);

    const isEncompass = scssStyles.styleEnvironment == 'encompass';
    const confirmButton = isEncompass ? 'Yes, Continue' : 'Continue';
    const cancelButton = isEncompass ? 'No, Go Back' : 'Cancel';

    const handleOnSubmit = async () => {
        handleShowLiveVideo(true, alertDriver);
    };

    const handleOnClose = () => {
        handleClose && handleClose(false, 0);
    };

    const contentMessage = () => (
        <FormControl>
            <Grid container>
                <Grid item xs={12}>
                    <div className={styles.warning} style={isEncompass ? { padding: '10px 15px 7px 15px' } : {}}>
                        <div className={styles.questionContainer}>
                            {isEncompass && <FontAwesomeIcon className={styles.alertIcon} icon={faExclamationCircle} />}
                            Are you sure you want to initiate live video?
                        </div>
                        <div className={styles.checktext} onClick={() => setAlertDriver(!alertDriver)}>
                            <Checkbox
                                style={{
                                    transform: 'scale(0.75)',
                                    marginLeft: -7,
                                    padding: 0,
                                }}
                                onChange={() => setAlertDriver(!alertDriver)}
                                checked={alertDriver}
                                disableRipple={true}
                            />{' '}
                            Alert driver when live video streaming has started and ended.
                        </div>
                    </div>
                </Grid>
            </Grid>
        </FormControl>
    );

    return (
        <ConfirmDialog
            isOpen={isOpen}
            title={`Live View Request`}
            contentMessage={contentMessage()}
            cancelButton={cancelButton}
            acceptButton={confirmButton}
            handleModalClosed={handleOnClose}
            handleSubmit={handleOnSubmit}
        />
    );
};
