import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React } from '../../../../Imports';
import { Tooltip } from 'MaterialUIComponents';

const styles = require('./NavigationSection.scss') as {
    disabled: string;
    icon: string;
    left: string;
    main: string;
    right: string;
};

interface INavigationProps {
    isLeftDisabled?: boolean;
    isRightDisabled?: boolean;
    leftTitle: string;
    leftTooltip?: string;
    onLeftClick: () => void;
    onRightClick: () => void;
    rightTitle: string;
    rightTooltip?: string;
}

const NavigationSection: React.FC<INavigationProps> = ({
    isLeftDisabled,
    isRightDisabled,
    leftTitle,
    leftTooltip,
    onLeftClick,
    onRightClick,
    rightTitle,
    rightTooltip,
}) => {
    const handleLeftClick = (event: React.MouseEvent) => {
        if (!isLeftDisabled) {
            onLeftClick();
        }
    };

    const handleRightClick = (event: React.MouseEvent) => {
        if (!isRightDisabled) {
            onRightClick();
        }
    };

    const renderButton = (tooltip: string | undefined, content: JSX.Element) => {
        return tooltip ? (
            <Tooltip title={tooltip} placement="bottom">
                {content}
            </Tooltip>
        ) : (
            content
        );
    };

    return (
        <div className={styles.main}>
            {renderButton(
                leftTooltip,
                <span className={`${styles.left} ${isLeftDisabled ? styles.disabled : ''}`} onClick={handleLeftClick}>
                    <FontAwesomeIcon icon={faArrowLeft} className={styles.icon} />
                    {leftTitle}
                </span>,
            )}
            {renderButton(
                rightTooltip,
                <span className={`${styles.right} ${isRightDisabled ? styles.disabled : ''}`} onClick={handleRightClick}>
                    {rightTitle}
                    <FontAwesomeIcon icon={faArrowRight} className={styles.icon} />
                </span>,
            )}
        </div>
    );
};

export default NavigationSection;
