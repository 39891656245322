import { React } from '../../Imports';
import * as scssStyles from '$CSS/settings.scss';

export const TooltipWedge = () => {
    const isEncompass = scssStyles.styleEnvironment === 'encompass';
    return (
        <svg width="18" height="10" viewBox="0 0 268 149" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M98.0571 18.2555C115.886 -5.51583 151.543 -5.51583 169.371 18.2555L267.428 148.998H0L98.0571 18.2555Z" 
                fill={isEncompass ? scssStyles.customColor2 : '#3C5063'}
            />
        </svg>
    );
}
