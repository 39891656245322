declare global {
    interface Window {   
      pendo: any;
    }
  }
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
export class Pendo {
    public static initializePendo() { 
        var apiKey =  ConfigService.getPendoApiKey();    
        (function(apiKey: string) {
            (function(p: any, e: any, n: string, d: string, o: any) {
                let v: string[], w: number, x: number, y: any, z: any;
                o = p[d] = p[d] || {};
                o._q = o._q || [];
                v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
                for (w = 0, x = v.length; w < x; ++w) {
                    (function(m: string) {
                        o[m] = o[m] || function() {
                            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                        };
                    })(v[w]);
                }
                y = e.createElement(n);
                y.async = true;
                y.src = `https://content.pendo.kellerencompass.com/agent/static/${apiKey}/pendo.js`;
                z = e.getElementsByTagName(n)[0];
                z.parentNode.insertBefore(y, z);
            })(window, document, 'script', 'pendo', '');
        })(apiKey);
       
    }

    public static UpdatePendo(userInfo: any, env: string, integrationplatform:string,fleetid:string) {
        window.pendo.initialize();

        var geotabUserCreds= JSON.parse(userInfo);
        const sessionId = geotabUserCreds ? geotabUserCreds.SessionId : '';
        const username = geotabUserCreds ? geotabUserCreds.Name : '';
        const database = geotabUserCreds ? geotabUserCreds.Database : '';
        if(username != undefined)  
        {
        window.pendo.updateOptions({
            visitor: {
                id: `${env}_${database}_${username}` ,// Use the visitor ID from userInfo
                integrationplatform:`${integrationplatform}`, 
                env:`${env}`,
                email: `${username}`,
                usersession:`${sessionId}`
                
            },
            account: {
                id:`${integrationplatform}_${env}_${fleetid}`, // Use the account ID from userInfo
                database:database
            },
        });
    }
    }
}
