import { React } from 'Imports';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { RolesEnum, canEditByRole } from '$Externals/VerifyRole';
import { StyledIconButton } from './MaterialUIComponents/StyledIconButton';
import { TypedSplitTreatments, splitTreatmentNames, getSplitUserAttributes, isSplitTreatmentOn } from '$Utilities/split-io-sdk';

interface IDeleteButtonProps {
    eventIds?: number;
    isDisabled?: boolean;
    toggleDeletePopup: (selectedId?: number) => void;
    widthOverride?: string;
    heightOverride?: string;
}

type DeleteButtonProps = IDeleteButtonProps;

export const DeleteButton = (props: DeleteButtonProps) => {
    const { eventIds = 0, toggleDeletePopup } = props;

    const isDisabled = props.isDisabled || !canEditByRole([RolesEnum.videoAdmin]);

    const handleToggleDeletePopup = (e: any) => {
        e.stopPropagation();
        if (isDisabled) return;

        toggleDeletePopup(eventIds);
    }

    return (
        <TypedSplitTreatments names={[splitTreatmentNames.videoEventDelete]} attributes={getSplitUserAttributes()}>
            {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                return (
                    (isReady || isTimedout) &&
                    isSplitTreatmentOn(treatments[splitTreatmentNames.videoEventDelete]) && (
                        <StyledIconButton
                            handleOnClick={handleToggleDeletePopup}
                            title="Delete Video"
                            icon={faTrashCan}
                            isDisabled={isDisabled}
                            heightOverride={props.heightOverride}
                            widthOverride={props.widthOverride}
                        />
                    )
                );
            }}
        </TypedSplitTreatments>
    );
};
