import { Autocomplete, DialogContent, FormControl, TextField } from 'MaterialUIComponents';
import { faAngleDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React = require('react');

export const styles = require('./AutoCompleteControl.scss') as {
    pickerContent: string;
    select: string;
    clearIcon: string;
    dropdownIcon: string;
    formLabel: string;
    requiredLabel: string;
};

interface IAutoCompleteControlProps {
    required: boolean;
    enabled: boolean;
    options: any;
    optionLabelKey: string;
    findValue: any;
    label: string;
    handleOnChange: any;
    isEncompass: boolean;
    isIntegrated: boolean;
}
type AutoCompleteControlProps = IAutoCompleteControlProps;
export const AutoCompleteControl = (props: AutoCompleteControlProps) => {
    const variant = props.isEncompass ? 'outlined' : 'standard';
    const label = props.isEncompass ? `Enter ${props.label.toLowerCase()}` : props.label;
    const iconFontSize = props.isIntegrated && !props.isEncompass ? '20' : '18';
    const iconFontSizeStyle = { fontSize: iconFontSize };

    return (
        <>
            <DialogContent className={styles.pickerContent}>
                {props.isEncompass && props.required && (
                    <div className={styles.formLabel}>
                        {props.label}
                        {<span className={styles.requiredLabel}>Required</span>}
                    </div>
                )}
                <FormControl className={styles.select} required={props.required}>
                    <Autocomplete
                        disabled={!props.enabled}
                        options={props.options}
                        getOptionLabel={(option: any) => option[props.optionLabelKey] || ''}
                        style={{ paddingTop: 4 }}
                        onChange={props.handleOnChange}
                        value={props.findValue || null}
                        clearIcon={<FontAwesomeIcon icon={faTimes} className={styles.clearIcon} style={iconFontSizeStyle} fixedWidth />}
                        popupIcon={
                            <FontAwesomeIcon icon={faAngleDown} className={styles.dropdownIcon} style={iconFontSizeStyle} fixedWidth />
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                variant={variant}
                                required={props.required}
                                InputLabelProps={{ required: !props.isEncompass }}
                            />
                        )}
                    />
                </FormControl>
            </DialogContent>
        </>
    );
};
