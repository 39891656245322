import { React } from 'Imports';
import { AlertTypes } from '$Utilities/Enums/AlertTypes';
import { AlertMessage } from './AlertMessage';
import { ConfirmDialog } from './ConfirmDialog';
import { useState } from 'react';
import { VideoEventService } from '$State/VideoEventFreezerService';
import * as scssStyles from '../../css/settings.scss';
import { VideoEventFilterServiceMultiselect } from '$State/VideoEventFilterFreezerServiceMultiselect';

interface IDeleteConfirmationProps {
    eventIds?: number[];
    showDeletePopup: boolean;
    toggleDeletePopup: (selectedId?: number) => void;
    changeToDisabled: (disabled: boolean) => void;
    refreshVideoEvent?: () => void;
    isBulk?: boolean;
}

export const DeleteConfirmation = (props: IDeleteConfirmationProps) => {
    const { eventIds = [], showDeletePopup, isBulk, changeToDisabled, toggleDeletePopup, refreshVideoEvent } = props;
    const [resultMessage, setResultMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [undoDelete, setUndoDelete] = useState(false);

    const isEncompass = scssStyles.styleEnvironment === 'encompass';
    const isAlert = isEncompass;
    const [alertType, setAlertType] = useState(AlertTypes.Alert);
    const acceptButton = isEncompass ? 'Yes, Delete' : 'Delete';
    const cancelButton = isEncompass ? 'No, Go back' : 'Cancel';
    const showIcon = isEncompass;
    const numEvents = eventIds.length;
    const contentMessage = `Are you sure you want to delete ${!isBulk ? 'this' : isEncompass ? 'these' : numEvents} video event${
        numEvents > 1 ? 's' : ''
    }?`;

    const handleToggleDeletePopup = (e: any) => {
        e.stopPropagation();

        if (toggleDeletePopup) toggleDeletePopup();
    };

    const handleSubmit = async (e: any, undo: boolean): Promise<void> => {
        e.stopPropagation();
        setOpenSnackbar(false);

        const body = {
            videoEventIdList: eventIds,
        };

        const deleteResult = undo ? await VideoEventService.restoreVideoEvent(body) : await VideoEventService.deleteVideoEvent(body);

        if (deleteResult) {
            setResultMessage(`Event(s) ${undo ? 'restored' : 'deleted'} successfully.`);
            setAlertType(AlertTypes.Success);
            changeToDisabled(!undo);
            setUndoDelete(undo);

            const videoEventFilter = VideoEventFilterServiceMultiselect.getVideoEventFilter();
            await VideoEventService.getListOfEventIds(videoEventFilter);

            setTimeout(() => {
                if (refreshVideoEvent) {
                    refreshVideoEvent();
                    changeToDisabled(false);
                }
            }, 2000);
        } else {
            setResultMessage(`Unable to ${undo ? 'restore' : 'delete'} event(s).`);
            setAlertType(AlertTypes.Fail);
            changeToDisabled(undo);
        }

        if (toggleDeletePopup && !undo) toggleDeletePopup();
        setOpenSnackbar(true);
    }

    const handleSnackClose = () => setOpenSnackbar(false);

    const defineTitle = () =>
        isBulk && isEncompass
            ? `Delete ${numEvents} Video Event${numEvents > 1 ? 's' : ''}?`
            : isEncompass
            ? `Delete Video Event`
            : `Confirm Delete`;

    return (
        <>
            <ConfirmDialog
                isOpen={showDeletePopup}
                title={defineTitle()}
                contentMessage={contentMessage}
                cancelButton={cancelButton}
                acceptButton={acceptButton}
                isAlert={isAlert}
                alertType={AlertTypes.Alert}
                showIcon={showIcon}
                handleModalClosed={(e) => handleToggleDeletePopup(e)}
                handleSubmit={(e) => handleSubmit(e, false)}
                isButtonAlert={true}
            />

            {openSnackbar && (
                <AlertMessage
                    openSnackbar={openSnackbar}
                    alertType={alertType}
                    message={resultMessage}
                    showIcon={isEncompass}
                    isUndo={undoDelete}
                    handleSnackClose={handleSnackClose}
                    handleSubmit={(e, undo) => handleSubmit(e, undo)}
                />
            )}
        </>
    );
};
