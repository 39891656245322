import { React, moment, bind } from '../../../Imports';
import { VideoEventResponse, VideoEventWorkflowStatusIdEnum } from '$Generated/api';
import * as DateFormatter from '../../Shared/DateFormatter';
import { getStatusLabel } from '../../../utilities/videoEventStatusUtility';
import { VideoEventIdLabel } from '$Components/Shared/VideoEventIdLabel';

interface IDetailsHeaderProps {
    event: VideoEventResponse;
    status: string | undefined;
}

const styles = require('./DetailsHeader.scss') as {
    main: string;
    eventType: string;
    eventTypeIcon: string;
    date: string;
    download: string;
    boldText: string;
    playAllButton: string;
};

class _DetailsHeader extends React.Component<IDetailsHeaderProps, {}> {
    @bind
    getVideoEventType(type: string | undefined): string {
        if (type === undefined) {
            return 'Unknown';
        }

        const toReturn = type === 'PromotedVideoRecall' ? 'VideoRecall' : type;

        return String(toReturn).replace(/([^A-Z])([A-Z])/g, '$1 $2');
    }

    render(): JSX.Element {
        const eventDate = this.props.event.eventStartDate ? moment(this.props.event.eventStartDate) : moment();
        const eventType = this.getVideoEventType(this.props.event.videoEventTypeString);
        const eventTypeIconNames =
            this.props.event.videoEventTypePairings?.map((eventType) => eventType.videoEventTypeIconName || '') || [];
        let statusLabelId = (this.props.status !== undefined ? this.props.status : '') as VideoEventWorkflowStatusIdEnum;
        let statusLabel = getStatusLabel(statusLabelId);
        return (
            <div className={styles.main}>
                <div className={styles.eventType}>{eventType}</div>
                <div className={styles.date}>{DateFormatter.dateAndTimezoneWithSeconds(eventDate)}</div>
                <div className={styles.date}>
                    Status <span className={styles.boldText}>{statusLabel}</span>
                </div>
                <div className={styles.date}>
                    <VideoEventIdLabel displayEventId={this.props.event.displayId} type={'detailsRecall'} />
                </div>
            </div>
        );
    }
}

export const DetailsHeader = _DetailsHeader;
