import { Button, Grid, Hidden } from '../../../../MaterialUIComponents';
import { faPlay, faPause } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React } from '../../../../Imports';
import { VideoEventIdLabel } from '$Components/Shared/VideoEventIdLabel';
import { VideoEventResponse } from '$Generated/api';

const styles = require('./PlayAll.scss') as {
    containerEventId: string;
    playAllButton: string;
    playAllButtonSpace: string;
    playIcon: string;
    responsiveGrid: string;
};

interface PlayAllButtonProps {
    anyVideosPlaying: boolean;
    onPlayButtonClick: () => void;
}

const PlayAllButton: React.FC<PlayAllButtonProps> = ({ anyVideosPlaying, onPlayButtonClick }) => {
    const icon = anyVideosPlaying ? faPause : faPlay;

    return (
        <Button className={styles.playAllButton} variant="contained" onClick={onPlayButtonClick} disableElevation>
            <FontAwesomeIcon icon={icon} className={styles.playIcon} />
            {anyVideosPlaying ? 'Pause All' : 'Play All'}
        </Button>
    );
};

interface RenderPlayAllProps {
    anyVideosPlaying: boolean;
    event: VideoEventResponse;
    onPlayButtonClick: () => void;
    videoCount: number;
}

const PlayAll: React.FC<RenderPlayAllProps> = ({ anyVideosPlaying, event, onPlayButtonClick, videoCount }) => {
    return (
        <Grid className={styles.responsiveGrid} item sm={12} md={6} lg={12}>
            {videoCount > 1 && (
                <Hidden mdDown>
                    <PlayAllButton onPlayButtonClick={onPlayButtonClick} anyVideosPlaying={anyVideosPlaying} />
                </Hidden>
            )}
            {videoCount === 1 && (
                <Hidden mdDown>
                    <div className={styles.playAllButtonSpace}></div>
                </Hidden>
            )}
            <div className={styles.containerEventId}>
                <VideoEventIdLabel displayEventId={event.displayId} />
            </div>
        </Grid>
    );
};

export { PlayAll, PlayAllButton };
