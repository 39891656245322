import { ActionTakenSection } from './ActionTakenSection';
import { DetailsSection } from '../../../VideoEventDetails/DetailsSection';
import { EditDriverDialog } from './EditDriverDialog';
import { Grid } from '../../../../../MaterialUIComponents';
import { IVideoEventServiceInjectedProps, VideoEventService } from '../../../../../state/VideoEventFreezerService';
import { PlayAll } from '../PlayAll';
import { React } from '../../../../../Imports';
import { RolesEnum, canEditByRole } from '../../../../../externals/VerifyRole';
import { SendToDriverSection } from './SendToDriverSection';
import { useEffect, useState } from 'react';
import { VideoEventDetailsSection } from './VideoEventDetailsSection';
import { VideoEventPrevNextSection } from '../../../VideoEventDetails/VideoEventPrevNextSection';
import { VideoEventResponse } from '$Generated/api';

const styles = require('./VideoEventsSidePanel.scss') as {
    cardContent: string;
    responsiveGrid: string;
};

interface IVideoEventsSidePanelBaseProps {
    anyVideosPlaying: boolean;
    event: VideoEventResponse;
    eventStatus: string | undefined;
    onPlayButtonClick: () => void;
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    onStatusChange: (status: string) => void;
    prevNextSplitOn: boolean;
    updateVideoEventHistory: () => void;
    videoCount: number;
    width: any;
}
type IVideoEventsSidePanelProps = IVideoEventsSidePanelBaseProps & IVideoEventServiceInjectedProps;

const _VideoEventsSidePanel: React.FC<IVideoEventsSidePanelProps> = ({
    anyVideosPlaying,
    event,
    eventStatus,
    onPlayButtonClick,
    onSelectVideoEvent,
    onStatusChange,
    prevNextSplitOn,
    updateVideoEventHistory,
    videoCount,
    videoEvents,
    width,
}) => {
    const [canComplete, setCanComplete] = useState(false);
    const [isDismissed, setIsDismissed] = useState(false);
    const [canRate, setCanRate] = useState(false);
    const [selectedRating, setSelectedRating] = useState<number | null>(event.score || null);
    const [showEditDriver, setShowEditDriver] = useState(false);
    const [currentDriverEmail, setCurrentDriverEmail] = useState(event.driverEmail || '');
    const [currentDriverPhoneNumber, setCurrentDriverPhoneNumber] = useState(event.driverPhoneNumber || '');
    const [currentDriverName, setCurrentDriverName] = useState(event.driverName || '');

    const canEdit = canEditByRole([RolesEnum.videoEventEdit]);

    useEffect(() => {
        setCanComplete(event.videoEventStatus !== 'Completed' && event.videoEventStatus !== 'Dismissed');
        setCanRate(event.videoEventStatus !== 'Dismissed' && event.videoEventStatus !== 'Completed');
        setIsDismissed(event.videoEventStatus === 'Dismissed');
    }, [event.videoEventStatus]);

    const handleDismissVideo = async (eventId: number | undefined) => {
        if (!canEdit) return;
        await videoEvents.dismissVideoEvent(eventId);

        setCanComplete(false);
        setIsDismissed(true);
        setSelectedRating(null);
        setCanRate(false);
        changeStatusAndUpdateVideoEventHistory('Dismissed');
    };

    const handleReopened = async (eventId: number | undefined) => {
        await videoEvents.reopenEvent(eventId);

        setCanComplete(true);
        setIsDismissed(false);
        setCanRate(true);
        changeStatusAndUpdateVideoEventHistory('In_Process');
    };

    const handleCompleteVideo = async (eventId: number | undefined, action: string) => {
        if (!canEdit) return;

        if (action === 'Dismissed') {
            handleDismissVideo(eventId);
            return;
        }
        await videoEvents.completeVideoEvent(eventId, action);

        setCanComplete(false);
        setIsDismissed(false);
        setCanRate(false);
        changeStatusAndUpdateVideoEventHistory('Completed');
    };

    const changeStatusAndUpdateVideoEventHistory = (newStatus: string) => {
        onStatusChange(newStatus);
        updateVideoEventHistory();
    };

    return (
        <div className={styles.cardContent}>
            {prevNextSplitOn ? (
                <VideoEventPrevNextSection eventId={event.displayId} onSelectVideoEvent={onSelectVideoEvent} />
            ) : (
                <PlayAll videoCount={videoCount} event={event} onPlayButtonClick={onPlayButtonClick} anyVideosPlaying={anyVideosPlaying} />
            )}
            <DetailsSection width={width}>
                <Grid key={`details-section-video-events-details-section`} className={styles.responsiveGrid} item sm={12} md={6} lg={12}>
                    <VideoEventDetailsSection
                        anyVideosPlaying={anyVideosPlaying}
                        changeStatusAndUpdateVideoEventHistory={changeStatusAndUpdateVideoEventHistory}
                        currentDriverName={currentDriverName}
                        event={event}
                        eventStatus={eventStatus}
                        handleDismissVideo={handleDismissVideo}
                        handleReopened={handleReopened}
                        onPlayButtonClick={onPlayButtonClick}
                        prevNextSplitOn={prevNextSplitOn}
                        setCanComplete={setCanComplete}
                        setShowEditDriver={setShowEditDriver}
                        showEditDriver={showEditDriver}
                        updateVideoEventHistory={updateVideoEventHistory}
                        videoCount={videoCount}
                        isDismissed={isDismissed}
                        canRate={canRate}
                        selectedRating={selectedRating}
                        setSelectedRating={setSelectedRating}
                        setIsDismissed={setIsDismissed}
                        setCanRate={setCanRate}
                    />

                    <SendToDriverSection
                        canEdit={canEdit}
                        driverEmail={currentDriverEmail}
                        driverPhoneNumber={currentDriverPhoneNumber}
                        event={event}
                        onStatusChange={onStatusChange}
                        prevNextSplitOn={prevNextSplitOn}
                        updateVideoEventHistory={updateVideoEventHistory}
                    />

                    <ActionTakenSection
                        canComplete={canComplete}
                        canEdit={canEdit}
                        event={event}
                        handleCompleteVideo={handleCompleteVideo}
                        handleDismissVideo={handleDismissVideo}
                        handleReopened={handleReopened}
                        prevNextSplitOn={prevNextSplitOn}
                    />
                </Grid>
            </DetailsSection>

            <EditDriverDialog
                event={event}
                showEditDriver={showEditDriver}
                setShowEditDriver={setShowEditDriver}
                canEdit={canEdit}
                updateVideoEventHistory={updateVideoEventHistory}
                setCurrentDriverEmail={setCurrentDriverEmail}
                setCurrentDriverPhoneNumber={setCurrentDriverPhoneNumber}
                setCurrentDriverName={setCurrentDriverName}
            />
        </div>
    );
};

export const VideoEventsSidePanel = VideoEventService.inject(_VideoEventsSidePanel);
