import { ConfigService, IConfigServiceInjectedProps } from '../../../state/ConfigFreezerService';
import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons';
import { FileUploadTypeEnum, SpeedGraphPointResponse, VideoEventResponse, VideoEventTypePairingResponse } from '$Generated/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React, bind } from '../../../Imports';
import { Slider } from '../../../MaterialUIComponents';
import { VideoDownload } from '../../Shared/VideoDownload';
import * as Plyr from 'plyr';
// https://github.com/sampotts/plyr

interface IEventVideoBaseProps {
    videoUrl: string;
    eventData: VideoEventResponse;
    thumbUrl: string;
    toggleVideoIsPlaying: (fileUploadTypeId: FileUploadTypeEnum, isStarting: boolean, isEnding: boolean) => void;
    videoIsPlaying: boolean;
    scrubToFunc: (e: number) => void;
    scrubTo: number;
    fileUploadTypeId: FileUploadTypeEnum;
    speedGraphPoints?: SpeedGraphPointResponse[];
    eventTypePairs?: VideoEventTypePairingResponse[];
    updateProgress?: (progress: number) => void;
    maxHeight?: number;
    setHeight?: (height: number) => void;
    updateVideoEventHistory?: () => void;
}

interface IEventVideoState {
    displayControls: boolean;
    videoLoaded: boolean;
    progress: number;
    displayPlay: boolean;
    width: number;
    height: number;
    refLoaded: boolean;
}

const styles = require('./EventVideoPlyr.scss') as {
    main: string;
    videoContainer: string;
    hideVideo: string;
    iconOverlay: string;
    bigPlayPauseIcons: string;
    controlOverlay: string;
    controlBar: string;
    videoControlsPlayPause: string;
    smallPlayPauseIcons: string;
    videoSeek: string;
    loadingOverlay: string;
    thumbnail: string;
    downloadOverlay: string;
    download: string;
    speedGraph: string;
    speedGraphOffset: string;
    videoPlayer: string;
};

type IEventVideoProps = IEventVideoBaseProps & IConfigServiceInjectedProps;

class _EventVideo extends React.Component<IEventVideoProps, IEventVideoState> {
    state = {
        displayPlay: true,
        displayControls: false,
        videoLoaded: false,
        progress: 0,
        width: 0,
        height: 0,
        refLoaded: false,
    };

    ourID: string = 'ourVideo';
    ourPlayer: Plyr | null = null;
    ref: HTMLDivElement | null = null;

    constructor(props: any) {
        super(props);
    }

    @bind
    handlePlayPause(isEnding: boolean): void {
        //starting from the beginning or starting from fully played
        const isStarting = this.state.progress === 0 || (this.state.progress === 1 && !isEnding);
        this.props.toggleVideoIsPlaying(this.props.fileUploadTypeId, isStarting, isEnding);

        this.setState({
            displayControls: true,
            displayPlay: !this.state.displayPlay,
        });
    }

    @bind
    videoLoaded(): void {
        this.setState({
            videoLoaded: true,
        });
    }

    @bind
    handleProgressUpdate(playerState: { played: number }): void {
        this.setState({
            progress: playerState.played,
        });
        if (this.props.updateProgress) {
            this.props.updateProgress(playerState.played);
        }
    }

    @bind
    handleProgressChange(_event: any, value: number | number[]): void {
        const numVal = value as number;
        this.setState({
            progress: numVal,
        });
        this.props.scrubToFunc(numVal);
        if (this.props.updateProgress) {
            this.props.updateProgress(numVal);
        }
    }

    @bind
    handleShowControls(): void {
        if (this.state.videoLoaded) {
            this.setState({
                displayControls: true,
            });
        }
    }

    @bind
    handleHideControls(): void {
        this.setState({
            displayControls: false,
        });
    }

    componentDidUpdate(prevProps: IEventVideoProps): void {
        if (this.props.videoIsPlaying !== prevProps.videoIsPlaying) {
            if (this.ourPlayer) {
                if (!prevProps.videoIsPlaying) {
                    this.ourPlayer.play();
                } else {
                    this.ourPlayer.pause();
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.props.videoIsPlaying) {
            //stop playing
            this.props.toggleVideoIsPlaying(this.props.fileUploadTypeId, false, false);
        }
        //reset progress
        this.setState({ progress: 0 });
        if (this.props.updateProgress) {
            this.props.updateProgress(0);
        }
        this.props.scrubToFunc(0);

        this.ourPlayer?.destroy();
    }

    componentDidMount() {
        const ourOptions = {
            ratio: '16:9',
            controls: [],
            volume: 0,
        } as Plyr.Options;
        this.ourPlayer = new Plyr(`#${this.ourID}`, ourOptions);
        this.ourPlayer.source = {
            type: 'video',
            sources: [
                {
                    src: this.props.videoUrl,
                },
            ],
        } as Plyr.SourceInfo;

        this.ourPlayer.on('ready', (event: Plyr.PlyrEvent) => {
            this.videoLoaded();
        });

        this.ourPlayer.on('ended', (event: Plyr.PlyrEvent) => {
            this.handlePlayPause(true);
        });

        this.ourPlayer.on('timeupdate', (event: Plyr.PlyrEvent) => {
            let duration = this.ourPlayer?.duration;
            if (duration == undefined || duration == 0) {
                duration = 1;
            }
            this.handleProgressUpdate({ played: event.detail.plyr.currentTime / duration });
        });
    }

    render(): JSX.Element {
        const { videoIsPlaying, videoUrl, thumbUrl, eventData } = this.props;
        let currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        let currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;

        return (
            <div ref={(ref) => (this.ref = ref)}>
                <div className={styles.main} onMouseEnter={this.handleShowControls} onMouseLeave={this.handleHideControls}>
                    <div className={styles.videoContainer}>
                        <video id={this.ourID} playsInline controls>
                            <source src={videoUrl} type="video/mp4" />
                        </video>
                    </div>

                    {this.state.displayControls && (
                        <div className={styles.controlOverlay}>
                            <div className={styles.controlBar}>
                                <div className={styles.videoControlsPlayPause} onClick={() => this.handlePlayPause(false)}>
                                    {videoIsPlaying ? (
                                        <FontAwesomeIcon icon={faPause} className={styles.smallPlayPauseIcons} />
                                    ) : (
                                        <FontAwesomeIcon icon={faPlay} className={styles.smallPlayPauseIcons} />
                                    )}
                                </div>
                                <div className={styles.videoSeek}>
                                    <Slider
                                        onChange={this.handleProgressChange}
                                        value={this.state.progress}
                                        min={0}
                                        max={1}
                                        step={0.05}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {this.state.videoLoaded && (
                        <div className={styles.iconOverlay}>
                            <div className={styles.bigPlayPauseIcons}>
                                {!this.props.videoIsPlaying && (
                                    <FontAwesomeIcon
                                        icon={faPlayCircle}
                                        className={styles.bigPlayPauseIcons}
                                        onClick={() => this.handlePlayPause(false)}
                                    />
                                )}
                                {this.props.videoIsPlaying && this.state.displayControls && (
                                    <FontAwesomeIcon
                                        icon={faPause}
                                        className={styles.bigPlayPauseIcons}
                                        onClick={() => this.handlePlayPause(false)}
                                    />
                                )}
                            </div>
                        </div>
                    )}

                    {this.state.displayControls && (
                        <div className={styles.downloadOverlay}>
                            <div className={styles.download}>
                                <VideoDownload
                                    videoUrl={this.props.videoUrl}
                                    eventId={eventData.id}
                                    videoType={this.props.fileUploadTypeId}
                                    updateVideoEventHistory={this.props.updateVideoEventHistory}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const EventVideoPlyr = ConfigService.inject(_EventVideo);
