import { React } from 'Imports'

const styles = require('./Loader.scss') as {
    loader: string,
    container: string,
    dot: string,
    dotEmpty: string,
    main: string
}

const Loader = () : JSX.Element => {
    return (
        <div className={styles.loader}>
            <div className={styles.container}>
                <div className={styles.dot}></div>
            </div>
            <div className={styles.container}>
                <div className={styles.dot}></div>
            </div>
            <div className={styles.container}>
                <div className={styles.dot}></div>
            </div>
            <div className={styles.container}>
                <div className={styles.dot}></div>
            </div>
            <div className={styles.container}>
                <div className={styles.dot}></div>
            </div>
            <div className={styles.container}>
                <div className={styles.dot}></div>
            </div>
            <div className={styles.container}>
                <div className={styles.dot}></div>
            </div>
        </div>
        
    );
}

export default Loader;