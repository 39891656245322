import { React, bind } from '../../Imports';
import { TextField, Autocomplete, Checkbox, Switch, Typography } from 'MaterialUIComponents';
import { createFilterOptions } from '@mui/material/Autocomplete';
import * as scssStyles from '../../css/settings.scss';
import { CutText } from '../../utilities/dataModelUtilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faTimes, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { forEach } from 'lodash';
import CustomPopper from '../Shared/CustomPopper';

interface IAutocompleteDropdownProps {
    name: string;
    items: any[];
    selectedValues: any[] | undefined;
    onSelectedItemChange: (value: unknown[]) => void;
    error?: boolean;
    width?: number;
    id?: string;
    filterInactives?: boolean;
    includeInactives?: boolean;
    onIncludeInactiveChange?: (inactives: boolean) => void;
}

const styles = require('./VPAutocompleteMultiselect.scss') as {
    menuItem: string;
    optionsBackground: string;
    tagItem: string;
    preventOverflow: string;
    inputItem: string;
    inputImg: string;
    dropdownIcon: string;
    checkbox: string;
    clearIcon: string;
    switchRoot: string;
    switchBase: string;
    switchChecked: string;
    switchThumb: string;
    switchTrackChecked: string;
    switchTrack: string;
    switchTrackDisabled: string;
    inactiveLabel: string;
    inactiveSwitchContainer: string;
    onlineIndicator: string;
};

const inputStyles = {
    borderRadius: '4px',
    backgroundColor: '#FFFFF',
    border: '1px solid #ced4da',
    boxShadow: 'inset 0px 3px 3px 0px #e6e6e6',
    padding: '0px 8px',
    verticalAlign: 'baseline',
    fontFamily: scssStyles.fontFamily,
};

const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: any) => option.label,
});

export default class VPAutocompleteMultiSelect extends React.PureComponent<IAutocompleteDropdownProps> {
    state = {
        selectedItems: [] as any[],
        inputValue: '' as string,
        isComponentFocused: false,
        allSelected: false,
        includeInactives: false,
        items: [...this.props.items],
    };

    @bind
    handleChange(values: any): void {
        const { onSelectedItemChange, onIncludeInactiveChange } = this.props;

        const filteredValues = values.map((item: any) => item.value);

        onSelectedItemChange(filteredValues);
        onIncludeInactiveChange && onIncludeInactiveChange(this.state.includeInactives);
    }

    @bind
    toggleAll(): any {
        // this checks if the current state of allSelected, set in the Autocomplete's onChange. Ensures that either all items are selected or none are.
        this.setState({ selectedItems: this.state.allSelected ? this.state.items : [] }, () => {
            this.handleChange(this.state.selectedItems);
        });
    }

    componentDidUpdate(prevProps: Readonly<IAutocompleteDropdownProps>): void {
        const { items } = this.props;

        if (this.props.selectedValues !== prevProps.selectedValues) {
            if (!this.props.selectedValues || this.props.selectedValues.length === 0) {
                this.setState({ selectedItems: [] });
            }

            // string items are being passed in for props.selectedValues and we need to find the respective objects located in
            // this.props.items in order to correctly select items for the dropdown
            if (this.props.items.length != 0 && this.props.selectedValues?.length != 0) {
                //find the item(s) that match the selectedValues and set to the selectedItems in the dropdown
                const items: any[] = [];
                const propsItems = this.props.items;
                const includeInactives = this.props.includeInactives;
                forEach(this.props.selectedValues, function (sv) {
                    forEach(propsItems, function (i) {
                        //check this.props.items for a matching object and push to items
                        if (i.value == sv) {
                            items.push(i);
                        }
                    });
                });
                this.setState({
                    selectedItems: items, //these objects are now the selectedItems
                    allSelected:
                        propsItems.filter((s) => s.isActive !== false || includeInactives).length == this.props.selectedValues?.length,
                    includeInactives,
                });
            }
        }

        if (items !== prevProps.items) {
            this.setState({ items: [...items.filter((s) => s.isActive !== false || this.props.includeInactives)] });
        }
    }

    printLabel = (option: any) => (
        <>
            <span>{CutText(option.label, 50)}</span>
            {option.icon && <img src={option.icon} />}
        </>
    );

    inactiveSwitch = (option: any) => (
        <div className={styles.inactiveSwitchContainer} onClick={() => this.handleSwitch(!this.state.includeInactives)}>
            <Switch
                size={'small'}
                checked={this.props.includeInactives}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {}}
                classes={{
                    root: styles.switchRoot,
                    switchBase: styles.switchBase,
                    checked: styles.switchChecked,
                    track: this.props.includeInactives ? styles.switchTrack : styles.switchTrackDisabled,
                }}
            />
            <span className={styles.inactiveLabel}>{option.label}</span>
        </div>
    );

    handleSwitch = (selected: boolean) => {
        this.setState({ items: [...this.props.items.filter((s) => s.isActive || selected)], includeInactives: selected }, () => {
            const selectedItems = this.state.selectedItems.filter((item) => item.isActive == true || selected);
            this.handleChange(selectedItems);
        });
    };

    render(): JSX.Element {
        // somewhat janky - but for consistent styling and to make sure the event occurs within the state management system for the Autocomplete component, I have appended the "select all" into the options
        const multiSelectOptions = [{ label: 'Select/Deselect All', value: 'toggle' }, ...this.state.items];

        if (this.props.filterInactives) {
            multiSelectOptions.unshift({ label: 'Include Inactive', value: 'inactives' });
        }

        return (
            <Autocomplete
                multiple={true}
                disableCloseOnSelect
                disableClearable
                disableListWrap
                clearOnBlur={true}
                openOnFocus={true}
                classes={{
                    option: styles.optionsBackground,
                }}
                onOpen={() => this.setState({ isComponentFocused: true })} // I did this because the focus state for autocomplete is usable within certain props. in this case, renderTagProps does not have an onFocus true/false value in it's typing, so I am writing my own control for it here.
                onClose={() => this.setState({ isComponentFocused: false })}
                limitTags={2}
                PopperComponent={CustomPopper}
                id={this.props.name}
                options={multiSelectOptions}
                getOptionLabel={(option): string => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(event: any, newValue: any, reason): void => {
                    newValue.some((val: any) => val.value === 'toggle')
                        ? // this checks if the user has selected the Select/Deselect All option, which has "toggle" as its value.
                          this.setState({ allSelected: !this.state.allSelected }, () => {
                              this.toggleAll(); //toggle is executed synchronously through the callback function so that the allSelected state has time to toggle before it is used in a condition.
                          })
                        : // Do not do anything if the check is selected
                        newValue.some((val: any) => val.value === 'inactives')
                        ? {}
                        : // the condition in allSelected ensures that if all of the available options are clicked manually, the "Select All" checkbox becomes selected - and vice versa, for when a user clicks "Select All" and then manually unclicks one of the options.
                          this.setState({ selectedItems: newValue, allSelected: this.props.items.length === newValue.length }, () =>
                              this.handleChange(newValue),
                          );
                }}
                style={{
                    width: this.props.width ? this.props.width : 200,
                }}
                inputValue={this.state.isComponentFocused ? this.state.inputValue : ''}
                onInputChange={(event, value, reason) => {
                    // this, combined with inputValue, separates the state of the value property of the input field and the value property of the autocomplete component (i.e. the state of what the user types vs. the state of the values already selected by the user)
                    if (reason !== 'reset') {
                        this.setState({
                            inputValue: value,
                        });
                    }
                }}
                value={this.state.selectedItems}
                className={styles.preventOverflow}
                clearIcon={<FontAwesomeIcon icon={faTimes} className={styles.clearIcon} />}
                popupIcon={<FontAwesomeIcon icon={faAngleDown} className={styles.dropdownIcon} />}
                renderTags={(value, getTagProps) => {
                    const renderTagValues = value
                        .map((option: any) => {
                            return ` ${option.label}`;
                        })
                        .join(',');

                    return this.state.isComponentFocused ? (
                        ''
                    ) : (
                        <Typography style={{ width: 600, textAlign: 'left' }} noWrap={true}>
                            {renderTagValues}
                        </Typography>
                    ); //This is done because the autocomplete parent elements are flex elements, and do not support overflow styles. This fits all of the tags into a typography box - so that it's technically one tag with a fixed width, allowing us use of the ellipsis overflow style.
                }}
                filterOptions={filterOptions}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={props.id}>
                        <div className={styles.menuItem}>
                            {option.value === 'inactives' ? (
                                this.inactiveSwitch(option)
                            ) : (
                                <>
                                    <Checkbox
                                        style={{ marginRight: 8, padding: 0 }}
                                        className={styles.checkbox}
                                        checked={option.value === 'toggle' ? this.state.allSelected : selected}
                                    />
                                    {this.printLabel(option)}
                                </>
                            )}
                        </div>
                    </li>
                )}
                renderInput={(params): JSX.Element => {
                    return (
                        <TextField
                            {...params}
                            label={this.props.name}
                            placeholder={this.state.isComponentFocused ? 'Search' : ''}
                            id={this.props.id}
                            variant="standard"
                            InputProps={{
                                style: scssStyles.styleEnvironment == 'encompass' ? inputStyles : { fontFamily: scssStyles.fontFamily },
                                disableUnderline: scssStyles.styleEnvironment == 'encompass',
                                ...params.InputProps,
                                inputProps: {
                                    ...params.inputProps, //params must be passed down here as well. InputProps and inputProps are NOT the same thing. see https://stackoverflow.com/questions/69870930/inputprops-vs-inputprops-in-mui-textfield
                                    maxLength: 20,
                                },
                                className: '', //Without this empty class here when selecting an item when an icon is present causes the whole input to shift up a few pixels
                            }}
                        />
                    );
                }}
            />
        );
    }
}
