import { _, FreezerService, moment } from 'Imports';
import { VideoEventFilterRequest } from '$Generated/api';

interface IFilterState extends VideoEventFilterRequest {}

const InjectedPropName = 'videoRecallEventFilter';

class VideoRecallEventFilterFreezerService extends FreezerService<IFilterState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                eventType: undefined,
                driverName: [],
                startDate: moment().subtract(29, 'day').startOf('day').toDate(), // Creates date in client timezone without time
                endDate:  moment().startOf('day').toDate(), // Creates date in client timezone without time
                vehicleId: undefined,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'date',
                sortAsc: false,
            },
            InjectedPropName,
        );
    }

    public getVideoRecallEventFilter(): IFilterState {
        //clone so that the object can be manipulated by the caller
        //Otherwise the state object is immutable(read-only)
        return _.clone(this.getState());
    }

    public setPageNumber(pageNumber: number): void {
        this.freezer.get().set({ currentPage: pageNumber });
    }

    public setSortFields(sortBy: string, sortAsc: boolean): void {
        this.freezer.get().set({ sortBy: sortBy, sortAsc: sortAsc });
    }

    public updateFilterOptions(
        eventTypeId?: number,
        selectedDriverId?: string[],
        selectedVehicleId?: string[],
        startDate?: Date,
        endDate?: Date,
        groupsFilter?: string,
    ): void {
        const currentFilter = this.getState();

        const updatedFilter: IFilterState = {
            eventType: eventTypeId,
            driverName: selectedDriverId ? selectedDriverId : [],
            vehicleId: selectedVehicleId ? selectedVehicleId : [],
            startDate: startDate,
            endDate: endDate,
            currentPage: 1, //reset to 1 after changing filters
            itemsPerPage: 25,
            sortAsc: currentFilter.sortAsc,
            sortBy: currentFilter.sortBy,
            groupsFilter: groupsFilter,
        };

        this.freezer.get().set(updatedFilter);
    }
}

export const VideoRecallFilterService = new VideoRecallEventFilterFreezerService();
export type IVideoRecallFilterServiceInjectedProps = ReturnType<VideoRecallEventFilterFreezerService['getPropsForInjection']>;
