import { React, moment, bind, unit } from '../../../Imports';
import * as DateFormatter from '../../Shared/DateFormatter';
import { CSVLink } from 'react-csv';
import { Button, Dialog, DialogActions, DialogContent, IconButton } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons';
import * as scssStyles from '../../../css/settings.scss';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '../../../state/IntegrationPartnerDataFreezerService';
import { IVideoEventServiceInjectedProps, VideoEventService } from '../../../state/VideoEventFreezerService';
import {
    IVideoEventFilterServiceMultiselectInjectedProps,
    VideoEventFilterServiceMultiselect,
} from '../../../state/VideoEventFilterFreezerServiceMultiselect';
import { getStatusLabel } from '../../../utilities/videoEventStatusUtility';
import { canEditByRole, RolesEnum } from '../../../externals/VerifyRole';

interface IVideoEventExportBaseProps {
    csvTitle: string;
}
type IVideoEventExportProps = IVideoEventExportBaseProps &
    IVideoEventServiceInjectedProps &
    IIntegrationPartnerDataInjectedProps &
    IVideoEventFilterServiceMultiselectInjectedProps;

const styles = require('./VideoEventExport.scss') as {
    fileDownload: string;
    downloadBtn: string;
    downloadIcon: string;
    downloadBtnEncompass: string;
    confirmationDialog: string;
    dialogAction: string;
    confirmMessage: string;
    confirmButton: string;
    cancelButton: string;
    disabled: string;
    dialog: string;
};

interface IVideoEventExportState {
    isConfirmDialogOpen: boolean;
    data?: any[];
    canEdit: boolean;
}

class _VideoEventExport extends React.PureComponent<IVideoEventExportProps, IVideoEventExportState> {
    state: IVideoEventExportState = {
        isConfirmDialogOpen: false,
        canEdit: false,
    };

    private csvRef = React.createRef<HTMLSpanElement>();
    canEdit = false;

    componentDidMount(): void {
        this.canEdit = canEditByRole([RolesEnum.videoEventView, RolesEnum.videoEventEdit]);
        this.setState({ canEdit: this.canEdit });
    }

    @bind
    showConfirmDialog(show: boolean) {
        if (!this.state.canEdit) return;
        this.setState({ isConfirmDialogOpen: show });
    }

    @bind
    async downloadCsv(isEncompass: boolean, isMetric: boolean) {
        if (!this.state.canEdit) return;
        const csvLink = this.csvRef.current;
        var filter = this.props.videoEventFilterMultiselect.getVideoEventFilter();
        filter.itemsPerPage = undefined;
        this.showConfirmDialog(false);
        await this.props.videoEvents.getEventExportDataMultiSelect(filter);

        this.setDataState(isEncompass, isMetric);

        if (csvLink) {
            csvLink.click();
        }
    }

    @bind
    formatEventTypeToCommaSeperatedValue(eventTypes: string[] | undefined): string {
        //if blank or unknown return unknown.
        if (eventTypes === undefined || eventTypes === null || !eventTypes.length) {
            return 'Unknown';
        }

        const eventTypeLabelArray = eventTypes?.map((x) => {
            if (['forwardcollisionwarning', 'forward collision warning'].indexOf(x.toLowerCase()) !== -1) {
                // Forward Collision Warning and Headway Monitoring are also too long for the title card
                return 'FCW';
            }

            if (x.includes('Gforce')) {
                // Cleaning Gforce reference that comes from VideoEventTypeEnum
                x = x.replace('Gforce', '');
            }

            if (x?.includes('RightTurn')) {
                x = x.replace('RightTurn', 'Cornering');
            }

            x = String(x)
                .replace(/([A-Z])/g, ' $1')
                .trim();
            return x;
        });

        return eventTypeLabelArray?.join(', ');
    }

    @bind
    setDataState(isEncompass: boolean, isMetric: boolean): void {
        const freezer = this.props.videoEvents.getState();
        const { videoEventExportResults } = freezer;

        const csvData: any[] = videoEventExportResults.map((val) => {
            const eventTypes = val.videoEventTypePairings?.map((eventType) => {
                return eventType?.videoEventTypeString ?? '';
            });
            const displayId = val?.displayId ?? '';
            const eventTypeLabel = this.formatEventTypeToCommaSeperatedValue(eventTypes);
            const eventStatus = val.videoEventStatus !== undefined ? getStatusLabel(val.videoEventStatus) : 'Unknown';
            const fullName = val.driverName === '' || val.driverName === ' ' ? 'Unknown' : val.driverName;
            const nameArray =
                val.driverName === '' || val.driverName === ' ' || !val.driverName ? ['Unknown', 'Unknown'] : val.driverName?.split(' ');
            const firstName = nameArray[0];
            //Grabs last item in array sequence to account for Middle name, JR or other potential words in between first & last name
            const lastName = nameArray[nameArray.length - 1];
            const vehicle = val.vehicleDescription || 'Unknown';
            const eventDate = DateFormatter.date(moment(val.eventStartDate) || moment()) + ' ';
            const eventTime = DateFormatter.timeWithSecondsAndTimezone(moment(val.eventStartDate) || moment()) + ' ';
            const streetNumber = val.addressStreetNumber ? val.addressStreetNumber + ' ' : '';
            const streetName = val.addressStreetName ? val.addressStreetName + ' ' : '';
            const city = val.addressCityName ? val.addressCityName + ' ' : '';
            const region = val.addressRegion ? val.addressRegion + ' ' : '';
            const eventScore = val.score ? val.score : '';
            const flagged = val.isFlagged ? 'Flagged' : 'Not Flagged';
            const speedStart = unit.getSpeedValue(val.kphStart, isMetric);
            const isDismissed = val.videoEventStatus !== 'Dismissed' || val.videoEventStatus == null ? 'False' : 'True';

            if (isEncompass) {
                return {
                    EventType: eventTypeLabel,
                    DisplayId: displayId,
                    Status: eventStatus,
                    FullName: fullName,
                    FirstName: firstName,
                    LastName: lastName,
                    Vehicle: vehicle,
                    EventDate: eventDate,
                    EventTime: eventTime,
                    StreetNumber: streetNumber,
                    StreetName: streetName,
                    City: city,
                    Region: region,
                    Start: unit.formatSpeedString(speedStart),
                    EventScore: eventScore,
                    Flagged: flagged,
                    Dismissed: isDismissed,
                };
            } else {
                const speedEnd = unit.getSpeedValue(val.kphEnd, isMetric);
                const change = Math.abs(Math.round(speedEnd) - Math.round(speedStart));
                return {
                    EventType: eventTypeLabel,
                    DisplayId: displayId,
                    Status: eventStatus,
                    FullName: fullName,
                    FirstName: firstName,
                    LastName: lastName,
                    Vehicle: vehicle,
                    EventDate: eventDate,
                    EventTime: eventTime,
                    StreetNumber: streetNumber,
                    StreetName: streetName,
                    City: city,
                    Region: region,
                    Start: unit.formatSpeedString(speedStart),
                    End: unit.formatSpeedString(speedEnd),
                    Change: change,
                    EventScore: eventScore,
                    Flagged: flagged,
                    Dismissed: isDismissed,
                };
            }
        });
        this.setState({ data: csvData });
    }

    @bind
    getExportHeaders(isEncompass: boolean, isMetric: boolean): { key: string; label: string }[] {
        if (isEncompass) {
            let speedLabel = isMetric ? 'Kilometers Per Hour' : 'Miles Per Hour';
            const csvHeaders = [
                { label: 'Event Type', key: 'EventType' },
                { label: 'ID', key: 'DisplayId' },
                { label: 'Status', key: 'Status' },
                { label: 'Driver Full Name', key: 'FullName' },
                { label: 'Driver First Name', key: 'FirstName' },
                { label: 'Driver Last Name', key: 'LastName' },
                { label: 'Vehicle', key: 'Vehicle' },
                { label: 'Event Date', key: 'EventDate' },
                { label: 'Event Time', key: 'EventTime' },
                { label: 'Street Number', key: 'StreetNumber' },
                { label: 'Street Name', key: 'StreetName' },
                { label: 'City', key: 'City' },
                { label: 'Region', key: 'Region' },
                { label: speedLabel, key: 'Start' },
                { label: 'Event Score', key: 'EventScore' },
                { label: 'Flagged', key: 'Flagged' },
                { label: 'Dismissed', key: 'Dismissed' },
            ];
            return csvHeaders;
        } else {
            let speedLabel = isMetric ? 'KPH' : 'MPH';

            const csvHeaders = [
                { label: 'Event Type', key: 'EventType' },
                { label: 'ID', key: 'DisplayId' },
                { label: 'Status', key: 'Status' },
                { label: 'Driver Full Name', key: 'FullName' },
                { label: 'Driver First Name', key: 'FirstName' },
                { label: 'Driver Last Name', key: 'LastName' },
                { label: 'Vehicle', key: 'Vehicle' },
                { label: 'Event Date', key: 'EventDate' },
                { label: 'Event Time', key: 'EventTime' },
                { label: 'Street Number', key: 'StreetNumber' },
                { label: 'Street Name', key: 'StreetName' },
                { label: 'City', key: 'City' },
                { label: 'Region', key: 'Region' },
                { label: speedLabel + ' Start', key: 'Start' },
                { label: speedLabel + ' End', key: 'End' },
                { label: (speedLabel = ' Change'), key: 'Change' },
                { label: 'Event Score', key: 'EventScore' },
                { label: 'Flagged', key: 'Flagged' },
                { label: 'Dismissed', key: 'Dismissed' },
            ];
            return csvHeaders;
        }
    }

    render(): JSX.Element {
        const isMetric = this.props.integrationPartnerData.getUserIsMetric();
        const isEncompass = scssStyles.styleEnvironment === 'encompass';
        const csvHeaders = this.getExportHeaders(isEncompass, isMetric);

        return (
            <span>
                <span className={styles.fileDownload} onClick={() => this.showConfirmDialog(true)}>
                    <IconButton
                        className={
                            !this.state.canEdit
                                ? styles.disabled
                                : scssStyles.styleEnvironment == 'encompass'
                                ? styles.downloadBtnEncompass
                                : styles.downloadBtn
                        }
                        title={'Export'}
                        disabled={!this.state.canEdit}
                    >
                        <FontAwesomeIcon icon={faFileExport} size="lg" />
                    </IconButton>
                </span>
                <CSVLink
                    data={this.state.data ? this.state.data : []}
                    headers={csvHeaders}
                    className={styles.fileDownload}
                    filename={this.props.csvTitle + '.csv'}
                    hidden
                >
                    <span ref={this.csvRef}></span>
                </CSVLink>
                <Dialog open={this.state.isConfirmDialogOpen} className={`${styles.confirmationDialog} ${styles.dialog}`}>
                    <DialogContent className={styles.confirmMessage}>Are you sure you want to export these events?</DialogContent>
                    <DialogActions className={styles.dialogAction}>
                        <Button className={styles.confirmButton} onClick={() => this.downloadCsv(isEncompass, isMetric)}>
                            Yes, Export
                        </Button>
                        <Button className={styles.cancelButton} onClick={() => this.showConfirmDialog(false)}>
                            No, Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        );
    }
}

export const VideoEventExport = VideoEventFilterServiceMultiselect.inject(
    IntegrationPartnerDataService.inject(VideoEventService.inject(_VideoEventExport)),
);
