import { React } from '../../Imports';
import { getSplitUserAttributes, TypedSplitTreatments, splitTreatmentNames, isSplitTreatmentOn } from '../../utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import * as _ from 'lodash';

interface IVideoEventIdProps {
    displayEventId?: string;
    type?: 'detailsRecall';
}

const styles = require('./VideoEventIdLabel.scss') as {
    labelEventId: string;
    size: string;
    recallSize: string;
    labelFormat: string;
};

type VideoEventIdProps = IVideoEventIdProps;

export const VideoEventIdLabel = ({ displayEventId, type }: VideoEventIdProps) => {
    return (
        <TypedSplitTreatments names={[splitTreatmentNames.videoeventId]} attributes={getSplitUserAttributes()}>
            {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                return isReady || isTimedout ? (
                    isSplitTreatmentOn(treatments[splitTreatmentNames.videoeventId]) ? (
                        <label className={`${styles.labelEventId} ${type ? styles.recallSize : styles.labelFormat}`}>
                            <span>
                                ID: <b>{displayEventId}</b>
                            </span>
                        </label>
                    ) : (
                        <></>
                    )
                ) : (
                    <></>
                );
            }}
        </TypedSplitTreatments>
    );
};
