import { faCalendarDay, faMapMarkerAlt, faTruck, faUser } from '@fortawesome/pro-solid-svg-icons';
import { faTimer } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Moment } from 'moment';
import { React } from 'Imports';
import { VideoEventResponse } from '$Generated/api';
import * as DateFormatter from '$Components/Shared/DateFormatter';
import * as scssStyles from '../../../css/settings.scss';
import moment = require('moment');

const styles = require('./VideoEventInfo.scss') as {
    driver: string;
    flexColumn: string;
    displayIcons: string;
    infoText: string;
    date: string;
    duration: string;
    vehicleDescription: string;
    addressStreetName: string;
    videoEventInfo: string;
    scrollDesignEncompass: string;
    scrollDesignGeotab: string;
    scrollBarBottomVideoRecall: string;
};

const InfoItem = (props: any) => {
    return (
        <div className={props.className}>
            <div className={styles.flexColumn}>
                <FontAwesomeIcon icon={props.icon} color={scssStyles.customColor2} className={styles.displayIcons} />
            </div>
            <div className={styles.flexColumn}>
                {props.info.map((i: string, index: number) => (
                    <div className={styles.infoText} key={index}>
                        {i}
                    </div>
                ))}
            </div>
        </div>
    );
};

interface IVideoEventInfoProps {
    event: VideoEventResponse;
    eventDate: Moment;
    isPromotedVideoRecall: boolean;
    isVideoRecallInfo?: boolean;
    className?: string;
    children?: any;
}

type VideoEventInfoProps = IVideoEventInfoProps;

const VideoEventInfo = (props: VideoEventInfoProps) => {
    function getDurationText(duration: number) {
        const minutes = Math.floor(duration / 60);
        const remainingSeconds = duration % 60;
        let text = '';
        if (minutes > 0) {
            text = `${minutes} minutes`;
        }
        if (remainingSeconds > 0) {
            text = `${text} ${remainingSeconds} seconds`;
        }
        return text;
    }

    const start = moment(props.event.eventStartDate);
    const end = moment(props.event.eventEndDate);
    const duration = end && start ? moment.duration(end.diff(start)).as('seconds') : 0;
    const durationText = getDurationText(duration);
    const scrollBarDesignStyle = scssStyles.styleEnvironment === 'encompass' ? styles.scrollDesignEncompass : styles.scrollDesignGeotab;
    const scrollBarStyles = `${scrollBarDesignStyle} ${props.isVideoRecallInfo ? styles.scrollBarBottomVideoRecall : ''}`;

    return (
        <div className={`${styles.videoEventInfo} ${props.className}`}>
            {!props.isVideoRecallInfo && (
                <InfoItem icon={faUser} info={[props.event.driverName?.trim() || 'Unknown']} className={styles.driver}></InfoItem>
            )}
            <InfoItem
                icon={faTruck}
                info={[props.event.vehicleDescription?.trim() || props.event.integrationPartnerVehicleId || 'Unknown']}
                className={styles.vehicleDescription}
            ></InfoItem>
            <InfoItem
                icon={faCalendarDay}
                info={[DateFormatter.dateAndTimezoneWithSeconds(props.eventDate)]}
                className={styles.date}
            ></InfoItem>

            {!props.isPromotedVideoRecall && (
                <InfoItem
                    icon={faMapMarkerAlt}
                    info={[
                        (props.event.addressStreetNumber || '') + ' ' + (props.event.addressStreetName || ''),
                        (props.event.addressCityName || 'Unknown') + ' ' + (props.event.addressRegion || 'Unknown'),
                    ]}
                    className={styles.addressStreetName}
                ></InfoItem>
            )}
            {props.isVideoRecallInfo && <InfoItem icon={faTimer} info={[durationText]} className={styles.duration}></InfoItem>}
            <div className={scrollBarStyles}></div>
            {props.children}
        </div>
    );
};

export default VideoEventInfo;
