import { faArrowLeft, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React } from '../../Imports';
import { StyledGoBackButton } from './ErrorStyles';
import { useEffect } from 'react';
import * as scssStyles from '$CSS/settings.scss';

const styles = require('./Error.scss') as {
    main: string;
    pageHeader: string;
    pageTitle: string;
};

interface IErrorProps {
    messages?: string[];
    showGoBack?: boolean;
}

type ErrorProps = IErrorProps;

export const Error = (props: ErrorProps) => {
    const defaultMessages = [
        'Oops... an error has occurred loading this page. Please refresh your browser.',
        'If the problem persists, please close your browser completely and try logging in again.',
    ];
    const messages = props?.messages?.length ? props.messages : defaultMessages;
    function _renderMessage() {
        messages.forEach((message, index) => {
            const divEleMessages = document.getElementById(`message_container_${index}`);
            if (divEleMessages) {
                divEleMessages.innerHTML = message;
            }
        });
    }

    useEffect(() => {
        _renderMessage();
    }, []);

    const handleBack = () => {
        history.back();
    };

    return (
        <>
            {messages.map((message, index) => (
                <div id={`message_container_${index}`} key={index} className={styles.main}></div>
            ))}
            {props.showGoBack && (
                <StyledGoBackButton onClick={handleBack}>
                    {scssStyles.styleEnvironment !== 'encompass' && <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}
                    <span className='button-text'>Go Back</span>
                </StyledGoBackButton>
            )}
        </>
    );
};
