import * as React from "react";
import * as _ from "lodash";

export type ReactComponentLike<T> =
    React.ComponentClass<T> |
    React.StatelessComponent<T>
    ;

type ComponentWrapper<
    IOriginalProps extends Object,
    IInjectedProps extends Object,
    > = (Component: ReactComponentLike<IOriginalProps & IInjectedProps>) => React.SFC<IOriginalProps>;


export function withInjectedContext<
    IOriginalProps extends Object,
    ContextPayload,
    IPropsWithContext extends Object,
    >(
        Component: ReactComponentLike<IOriginalProps & IPropsWithContext>,
        context: React.Context<ContextPayload>,
        contextTransformer: (contextData: ContextPayload) => IPropsWithContext
    ): React.SFC<IOriginalProps> {
    return function BoundComponent(props: IOriginalProps) {
        return (
            <context.Consumer>
                {value => {
                    let injectedProps = contextTransformer(value);

                    let combinedProps = _.extend({}, props, injectedProps);

                    return (
                        <Component {...combinedProps} />
                    )
                }}
            </context.Consumer>
        )
    }
}