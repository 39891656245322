import { React } from '../../../../Imports';

const styles = require('./Section.scss') as {
    disabled: string;
    disabledOverlay: string;
    section: string;
    sectionContent: string;
    sectionHeader: string;
    sectionTitle: string;
};

interface IContentProps {
    actionButtons?: React.ReactNode;
    canEdit?: boolean;
    content: React.ReactNode;
    title: string;
}

const _Section: React.FC<IContentProps> = ({ actionButtons, canEdit = true, content, title }) => {
    const stylesSection = canEdit ? styles.section : `${styles.section} ${styles.disabled}`;

    return (
        <div className={stylesSection}>
            <div style={{ position: 'relative' }}>
                {!canEdit && <div className={styles.disabledOverlay}></div>}

                <div className={styles.sectionHeader}>
                    <span className={styles.sectionTitle}>{title}</span>
                    {actionButtons && actionButtons}
                </div>
                <div className={styles.sectionContent}>{content}</div>
            </div>
        </div>
    );
};

export const Section = _Section;
