import * as scssStyles from '../../css/settings.scss';
import styled from '@emotion/styled';

const StyledBaseFailedThumbnailPreview = styled.div`
    text-align: center;
    width: 100%;
    height: 120px;

    > div {
        padding: 0 24px;
        height: 120px;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${scssStyles.inactiveThumbnailBackgroundColor};
        color: ${scssStyles.inactiveThumbnailTextColor};
    }
`;

const GeotabStyledThumbnailFailedBackground = `
    > div {
        font-size: 16px;
        font-weight: 600;
    }
`;

const EncompassStyledThumbnailFailedBackground = `
    > div {
        font-size: 14px;
        font-weight: 350;
    }
    
`;

const StyledFailedThumbnailPreview = styled(StyledBaseFailedThumbnailPreview)`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabStyledThumbnailFailedBackground;
            case 'encompass':
                return EncompassStyledThumbnailFailedBackground;
            default:
                return GeotabStyledThumbnailFailedBackground;
        }
    }};
`;

export { StyledFailedThumbnailPreview };
