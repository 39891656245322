import { React } from "../../Imports";

const styles = require("./MissingFleet.scss") as {
    appMessage: string;
    container: string;
    message: string;
    subMessage: string;
};

export class _MissingFleet extends React.Component{
    render() {
        return (
            <>
                <div className={styles.appMessage}>
                    <div className={styles.container}>
                        <span className={styles.message}>
                            No fleet found.<br/>
                            <span className={styles.subMessage}>
                                Video has not yet been setup for this user.
                            </span>
                        </span>
                    </div>
                </div>
            </>
        );
    }
}

export const MissingFleet = _MissingFleet;