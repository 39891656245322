import { FileUploadTypeEnum, VideoAssetTypeEnum, VideoAssetResponse } from "$Generated/api";
import * as MediaUrlBuilder from '$Components/Shared/MediaUrlBuilder';

const FrontVideo = 'FrontVideo';
const RearVideo = 'RearVideo';
const SideVideo = 'SideVideo';
const AltVideo = 'AltVideo';
const Full = 'Full';
const Thumbnail = 'Thumbnail';
const Transcode = 'Transcode';
const Preview = 'Preview';

function findAssets(
    assets: VideoAssetResponse[],
    fileUploadType: FileUploadTypeEnum,
    specificTypes?: VideoAssetTypeEnum[]
): VideoAssetResponse | undefined {
    const foundAssets = assets.filter(
        (asset) =>
            typeof asset.fileUploadTypeId !== 'undefined' &&
            fileUploadType == asset.fileUploadTypeId
    );
    let result;

    if (specificTypes && foundAssets) {
        specificTypes.find(type =>
            foundAssets.find(asset => {
                if (asset.type === type) {
                    result = asset;
                    return true;
                }
            })
        );
    }

    return result;
}

function findUrl(
    assets: VideoAssetResponse[],
    baseUrl: string,
    fileUploadType: FileUploadTypeEnum,
    type?: VideoAssetTypeEnum[]
): string {
    const asset = findAssets(assets, fileUploadType, type);
    const url = MediaUrlBuilder.buildUrl(asset, baseUrl);

    return url;
}

export const findPreviewUrls: (assets: VideoAssetResponse[] | [], baseUrl: string) => VideoPreviewUrls = (
    assets: VideoAssetResponse[],
    baseUrl: string
) => {
    // Find thumbnail in assets if not exist we go we front or rear video
    const defaultThumbnail = assets.find((asset) => asset.type === Thumbnail);
    const previewFileUploadTypes = [defaultThumbnail?.fileUploadTypeId || FrontVideo, RearVideo];
    const previewAssetTypes = [Preview, Transcode, Full] as VideoAssetTypeEnum[];

    const uploadType = previewFileUploadTypes.find(type => findAssets(assets, type as FileUploadTypeEnum, previewAssetTypes)) as FileUploadTypeEnum;

    const previewUrl = findUrl(assets, baseUrl, uploadType, previewAssetTypes);
    const thumbanilUrl = findUrl(assets, baseUrl, uploadType, [Thumbnail]) || MediaUrlBuilder.buildUrl(defaultThumbnail, baseUrl);
    const downloadUrl = findUrl(assets, baseUrl, uploadType, [Transcode, Full]);

    return {
        ThumbnailUrl: thumbanilUrl,
        PreviewUrl: previewUrl,
        DownloadUrl: downloadUrl
    };
}

export interface VideoPreviewUrls {
    ThumbnailUrl: string;
    PreviewUrl: string;
    DownloadUrl: string;
}
