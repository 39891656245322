import { React } from '../../../Imports';
import { useEffect, useState } from 'react';
import { BulkRequest } from '$Generated/api';
import { ConfirmDialog } from '$Components/Shared/ConfirmDialog';
import { loaderDetailInfo, progressLoaderEnum } from '$Components/Shared/MaterialUIComponents/StyledProgressLoader';
import * as scssStyles from '$CSS/settings.scss';


type VideoEventListBulkDismissModalProps = {
    isOpen: boolean;
    handleModalClosed: () => void;
    selectedEventsCount: number;
    handleSubmit: (body: BulkRequest | undefined) => Promise<boolean>;
    getSelectedEvents: () => Promise<number[]>;
    refreshVideoEventsList: () => void;
};

const _VideoEventListBulkDismissModal = (props: VideoEventListBulkDismissModalProps) => {
    const { isOpen, handleModalClosed, selectedEventsCount, handleSubmit, getSelectedEvents, refreshVideoEventsList } = props;
    const [showLoader, setShowLoader] = useState(false);
    const [loaderDetail, setLoaderDetail] = useState<loaderDetailInfo>({
        message: 'Please wait while all events are being dismissed. \n This could take a few minutes',
        color: 'black',
        progressLoaderState: progressLoaderEnum.loading,
    });
    const isEncompass = scssStyles.styleEnvironment === 'encompass';
    
    const contentMessage = `Are you sure you want to dismiss ${isEncompass ? 'these' : selectedEventsCount} video event${ selectedEventsCount > 1 ? 's' : ''}?`;
    const title = isEncompass ? `Dismiss ${selectedEventsCount} video event${selectedEventsCount > 1 ? 's' : ''}?` : 'Confirm Dismiss';    
    const acceptButton = isEncompass ? 'Yes, Dismiss' : 'Dismiss';
    const cancelButton = isEncompass ? 'No, Go back' : 'Cancel';

    const handleOnClose = () => {
        if (loaderDetail.progressLoaderState === progressLoaderEnum.success) {
            refreshVideoEventsList();
        }
        handleModalClosed();
    };

    const handleOnSubmit = async () => {
        setLoaderDetail({
            message: 'Please wait while all events are being dismissed. \n This could take a few minutes',
            color: 'black',
            progressLoaderState: progressLoaderEnum.loading,
        });
        setShowLoader(true);
        const videoEventIdList = await getSelectedEvents();
        if (videoEventIdList.length == 0) {
            setLoaderDetail({
                message: "There's been an issue dismissing the events. \n Please select a shorter date range and try again.",
                color: isEncompass ? '#F8333C' : '#DE3030',
                progressLoaderState: progressLoaderEnum.error,
            });

            return;
        }
        const callSuccess = await handleSubmit({
            videoEventIdList: videoEventIdList,
        });

        if (callSuccess) {
            setLoaderDetail({
                message: 'All events have been dismissed.',
                color: isEncompass ? '#00A878' : '#4CAF50',
                progressLoaderState: progressLoaderEnum.success,
            });

            setTimeout(() => {
                refreshVideoEventsList();
                handleOnClose();
            }, 5000);
        } else {
            setLoaderDetail({
                message: "There's been an issue dismissing the events. \n Please select a shorter date range and try again.",
                color: isEncompass ? '#F8333C' : '#DE3030',
                progressLoaderState: progressLoaderEnum.error,
            });
        }
    };

    useEffect(() => {
        if (isOpen) setShowLoader(false);
    }, [isOpen]);

    return (
        <ConfirmDialog
            isOpen={isOpen}
            title={title}
            contentMessage={contentMessage}
            isAlert={isEncompass}
            isLoader={showLoader}
            loaderContainerWidth={'400px'}
            loaderDetail={loaderDetail}
            cancelButton={cancelButton}
            acceptButton={acceptButton}
            handleModalClosed={handleOnClose}
            handleSubmit={handleOnSubmit}
        />
    );
};

export const VideoEventListBulkDismissModal = _VideoEventListBulkDismissModal;
