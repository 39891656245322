import { Popover, PopoverOrigin } from 'MaterialUIComponents';
import { React } from 'Imports';
import ReactDateRange = require('react-date-range');
import styled from '@emotion/styled';

const StyledPopover = styled(Popover)`
    display: flex;
    max-width: 364px !important;
    .rdrCalendarWrapper {
        .rdrMonthAndYearWrapper {
            padding-top: 0px;
        }
        .rdrMonth {
            padding: 16px;
        }
    }
`;

const styles = require('./PopoverCalendar.scss') as {
    earliestFootageLabel: string;
    earliestFootageLabelDate: string;
    earliestFootageLabelVerbiage: string;
};

interface IPopoverCalendarProps {
    handleDatePickerChange: (date: Date | null) => void;
    maxDate: Date;
    date: Date | null;
    elementRef: any;
    open: boolean;
    isMobileView: boolean;
    isVehicle: boolean;
    earliestFootageDate?: string;
}

type PopoutCalendaProps = IPopoverCalendarProps;

export const PopoverCalendar = ({
    handleDatePickerChange,
    date,
    maxDate,
    elementRef,
    open,
    isMobileView,
    isVehicle,
    earliestFootageDate,
}: PopoutCalendaProps) => {
    const anchorOrigin = { vertical: isMobileView ? 'bottom' : 'top', horizontal: 'left' } as PopoverOrigin;
    const transformOrigin = { vertical: 'bottom', horizontal: isMobileView ? 'right' : 'left' } as PopoverOrigin;

    if (isVehicle) {
        anchorOrigin.vertical = 'bottom';
        transformOrigin.vertical = 'top';
        transformOrigin.horizontal = 'left';
    }

    const handleOnClose = (event: any, reason: any) => {
        if (reason == 'backdropClick') {
            handleDatePickerChange(null);
        }
    };

    return (
        <StyledPopover
            anchorEl={elementRef}
            open={open}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            marginThreshold={85}
            onClose={handleOnClose}
            slotProps={{paper: {style: {maxWidth: '364px'}}}}
        >
            <ReactDateRange.Calendar onChange={handleDatePickerChange} date={date} maxDate={maxDate} />
            <div className={styles.earliestFootageLabel}>
                Earliest Footage Available: <span className={styles.earliestFootageLabelDate}>{earliestFootageDate}</span>
            </div>
            <div className={styles.earliestFootageLabelVerbiage}>
                Video footage availability may vary depending on selected time of recall.
            </div>
        </StyledPopover>
    );
};
