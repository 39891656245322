import {
    VideoEventApiFactory,
    VideoEventResponse,
    VideoEventResponseIEnumerablePagedResponseBase,
    VideoEventTypeResponse,
    VideoEventStatisticsResponse,
    VideoEventCommentResponse,
    VideoEventCommentApiFactory,
    VideoEventHistoryTableResponseIEnumerableResponseBase,
    VideoEventWorkflowStatusIdEnum,
    VideoEventActionTypeEnum,
    FileUploadTypeEnum,
    VideoEventFilterMultiselectRequest,
    VideoEventReportResponseIEnumerableResponseBase,
    BulkRequest,
    VideoEventFullListResponse,
    VideoEventScoreResponse,
    BulkRatingRequest,
    VideoEventDriverRequest,
    VideoEventDeletionResponse,
    VideoCommentRequest,
} from '$Generated/api';
import { _, FreezerService, IAjaxState, managedAjaxUtil } from 'Imports';
import { UserManagementService } from '$State/UserManagementFreezerService';
import { VideoEventFilterServiceMultiselect } from '$State/VideoEventFilterFreezerServiceMultiselect';
import { IErrorMessage } from '../modules/managedAjaxUtil';

const InjectedPropName = 'videoEvents';

type VideoPlayingStates = {
    [key in FileUploadTypeEnum]: boolean;
};

export interface IVideoEventState {
    videoEventResults: IAjaxState<VideoEventResponseIEnumerablePagedResponseBase>;
    videoEventResultsFinal: VideoEventResponse[];
    videoEventExportResults: IAjaxState<VideoEventReportResponseIEnumerableResponseBase>;
    videoEventStatisticsResults: IAjaxState<VideoEventStatisticsResponse[]>;
    videoEventTypeResults: IAjaxState<VideoEventTypeResponse[]>;
    selectedVideoEventResult: IAjaxState<VideoEventResponse>;
    selectedVideoEventCommentsResult: IAjaxState<VideoEventCommentResponse[]>;
    downloadVideoEventResult: IAjaxState<void>;
    flagVideoEventResult: IAjaxState<void>;
    rateVideoEventResult: IAjaxState<void>;
    clearRatingResult: IAjaxState<void>;
    dismissVideoEventResult: IAjaxState<void>;
    dismissBulkVideoEventResult: IAjaxState<void>;
    listofEventIdResult: IAjaxState<VideoEventFullListResponse>;
    clearDismissResult: IAjaxState<void>;
    completeVideoEventResult: IAjaxState<void>;
    currentEventFilters: VideoEventFilterMultiselectRequest;
    hasError: boolean;
    hasInfiniteScrollError: boolean;
    videosLoaded: boolean;
    videoEventViewType: 'tile' | 'list';
    videoPlayingStates: VideoPlayingStates;
    scrubTo: number;
    videoEventHistoryTableResults: IAjaxState<VideoEventHistoryTableResponseIEnumerableResponseBase>;
    videoEventWorkflowStatusResults: IAjaxState<void>;
    secondaryVideoEventHeight: number;
    videoEventRatings: IAjaxState<VideoEventScoreResponse[]>;
    bulkScoreVideoEventRatings: IAjaxState<void>;
    deleteVideoEventResult: IAjaxState<VideoEventDeletionResponse>;
    restoreVideoEventResult: IAjaxState<VideoEventDeletionResponse>;
    includeInactiveDrivers: boolean;
    includeInactiveVehicles: boolean;
}

class VideoEventFreezerService extends FreezerService<IVideoEventState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                videoEventResults: managedAjaxUtil.createInitialState(),
                videoEventResultsFinal: [],
                videoEventExportResults: managedAjaxUtil.createInitialState(),
                videoEventStatisticsResults: managedAjaxUtil.createInitialState(),
                videoEventTypeResults: managedAjaxUtil.createInitialState(),
                selectedVideoEventResult: managedAjaxUtil.createInitialState(),
                selectedVideoEventCommentsResult: managedAjaxUtil.createInitialState(),
                downloadVideoEventResult: managedAjaxUtil.createInitialState(),
                flagVideoEventResult: managedAjaxUtil.createInitialState(),
                rateVideoEventResult: managedAjaxUtil.createInitialState(),
                clearRatingResult: managedAjaxUtil.createInitialState(),
                dismissVideoEventResult: managedAjaxUtil.createInitialState(),
                dismissBulkVideoEventResult: managedAjaxUtil.createInitialState(),
                listofEventIdResult: managedAjaxUtil.createInitialState(),
                clearDismissResult: managedAjaxUtil.createInitialState(),
                completeVideoEventResult: managedAjaxUtil.createInitialState(),
                currentEventFilters: {},
                hasError: false,
                hasInfiniteScrollError: false,
                videosLoaded: false,
                videoEventViewType: 'tile',
                videoPlayingStates: { FrontVideo: false, RearVideo: false, SideVideo: false, Log: false, AltVideo: false },
                scrubTo: 0,
                videoEventHistoryTableResults: managedAjaxUtil.createInitialState(),
                videoEventWorkflowStatusResults: managedAjaxUtil.createInitialState(),
                secondaryVideoEventHeight: 0,
                videoEventRatings: managedAjaxUtil.createInitialState(),
                bulkScoreVideoEventRatings: managedAjaxUtil.createInitialState(),
                deleteVideoEventResult: managedAjaxUtil.createInitialState(),
                restoreVideoEventResult: managedAjaxUtil.createInitialState(),
                includeInactiveDrivers: false,
                includeInactiveVehicles: false,
            },
            InjectedPropName,
        );
    }

    public async getVideoEvents(currentPage: number, itemsPerPage: number): Promise<void | VideoEventResponseIEnumerablePagedResponseBase> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'videoEventResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                this.freezer.get().set({ videosLoaded: false });
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                params.currentPage = currentPage;

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideoEventsGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{
                    currentPage: currentPage,
                    itemsPerPage: itemsPerPage,
                },
            },
            onOk: (data: VideoEventResponseIEnumerablePagedResponseBase) => {
                this.freezer.get().set({ videosLoaded: true });

                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getVideoEventRatings(): Promise<void | VideoEventScoreResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'videoEventRatings',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1ControlsVideoEventRatingsGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
            },
            onOk: (data: VideoEventScoreResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    private async _postVideoEventRatings(body: BulkRatingRequest): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'bulkScoreVideoEventRatings',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1RateMultipleVideoPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                body: body,
            },
            onOk: (data: any) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getVideoEventTypes(): Promise<void | VideoEventTypeResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'videoEventTypeResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventTypeApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventTypeApi.apiV1VideoEventTypesGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
            },
            onOk: (data: VideoEventTypeResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getVideoEventStatisticsMultiselect(
        filterOptions: VideoEventFilterMultiselectRequest,
    ): Promise<void | VideoEventStatisticsResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'videoEventStatisticsResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideoEventStatisticsMultiselectPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{},
                body: filterOptions,
            },
            onOk: (data: VideoEventStatisticsResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getVideoEvent(eventId: string): Promise<void | VideoEventResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'selectedVideoEventResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideoEventIdGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ id: eventId },
            },
            onOk: (data: VideoEventResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getVideoEventComments(eventId: number): Promise<void | VideoEventCommentResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'selectedVideoEventCommentsResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventCommentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideoCommentEventIdGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ eventId: eventId },
            },
            onOk: (data: VideoEventCommentResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async postVideoEventComment(eventId: number, videoComment: VideoCommentRequest): Promise<void | VideoEventCommentResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'selectedVideoEventCommentsResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventCommentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideoCommentEventIdPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ eventId: eventId },
                body: videoComment,
            },
            onOk: (data: VideoEventCommentResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async putVideoEventComments(
        eventId: number,
        commentId: number,
        videoComment: VideoCommentRequest,
    ): Promise<void | VideoEventCommentResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'selectedVideoEventCommentsResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventCommentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideoCommentEventIdCommentIdPut(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{
                    eventId: eventId,
                    commentId: commentId,
                },
                body: videoComment,
            },
            onOk: (data: VideoEventCommentResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async deleteVideoEventComments(eventId: number, deleteId: number): Promise<void | VideoEventCommentResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'selectedVideoEventCommentsResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventCommentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideoCommentEventIdCommentIdDelete(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{
                    eventId: eventId,
                    commentId: deleteId,
                },
            },
            onOk: (data: VideoEventCommentResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getFilteredEventsMultiselect(
        filterOptions: VideoEventFilterMultiselectRequest,
    ): Promise<void | VideoEventResponseIEnumerablePagedResponseBase> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'videoEventResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                this.freezer.get().set({ videosLoaded: false });
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideoEventsFilteredEventsMultiselectPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{},
                body: filterOptions,
            },
            onOk: (data: VideoEventResponseIEnumerablePagedResponseBase) => {
                if (data.data !== undefined && data.success) {
                    this.freezer.get().set({ hasError: false, hasInfiniteScrollError: false });
                } else {
                    this.freezer.get().set({ hasError: true, hasInfiniteScrollError: true });
                }

                const freezer1 = this.freezer.get().videoEventResultsFinal;

                let array: any = [];
                if (freezer1.length > 0) {
                    array = _.clone(freezer1);
                }
                if (data.data) {
                    for (const element of data.data) {
                        array.push(element);
                    }
                    this.freezer.get().set({ videoEventResultsFinal: array });
                }

                this.freezer.get().set({ videosLoaded: true });
                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getEventExportDataMultiSelect(
        filterOptions: VideoEventFilterMultiselectRequest,
    ): Promise<void | VideoEventReportResponseIEnumerableResponseBase> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'videoEventExportResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideoEventsFilteredEventsReportMultiselectPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                body: filterOptions,
            },
            onOk: (data: VideoEventReportResponseIEnumerableResponseBase) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async downloadVideoEvent(eventId: number | undefined, videoType?: FileUploadTypeEnum): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'downloadVideoEventResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1DownloadvideoPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{
                    eventId: eventId,
                    videoType: this.GetUploadTypeString(videoType),
                },
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async flagVideoEvent(eventId: number | undefined): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'flagVideoEventResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1FlagvideoPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ eventId: eventId },
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async rateVideoEvent(eventId: number | undefined, rating: number): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'rateVideoEventResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1RateVideoPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{
                    eventId: eventId,
                    rating: rating,
                },
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async clearRating(eventId: number | undefined): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'clearRatingResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1ClearRatingPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ eventId: eventId },
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async reopenEvent(eventId: number | undefined): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'reopenEventResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1ReopeneventPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ eventId: eventId },
            },
            onFetching: () => {
                this.updateLocalVideoEventStatus('In_Progress', eventId);
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getVideoEventHistoryTable(eventId: number): Promise<void | VideoEventHistoryTableResponseIEnumerableResponseBase> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'videoEventHistoryTableResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1GetvideoeventhistoryGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ eventId: eventId },
            },
            onOk: (data: VideoEventHistoryTableResponseIEnumerableResponseBase) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async dismissVideoEvent(eventId: number | undefined): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'dismissVideoEventResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1DismissVideoPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ eventId: eventId },
            },
            onOk: (data: any) => {
                this.updateLocalVideoEventStatus('Dismissed', eventId);
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    private async _dismissBulkVideoEvent(body: BulkRequest | undefined): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'dismissBulkVideoEventResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1DismissMultipleVideoEventPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ body: body },
            },
            onOk: (e: any) => {},
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    private async _getListOfEventIds(
        filterOptions: VideoEventFilterMultiselectRequest | undefined,
    ): Promise<void | VideoEventFullListResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'listofEventIdResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1GetFullVideoEventIdListPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ body: filterOptions },
            },
            onOk: (data: VideoEventFullListResponse) => {
                // this any shouldn't be here. there was no corresponding model generated in api.ts - will need to be reviewed.
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }
            },
            onError: (err: IErrorMessage<any>, errorString: string) => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    private async _deleteVideoEvent(body?: BulkRequest | undefined): Promise<void | VideoEventDeletionResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'deleteVideoEventResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }
                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideoEventDeleteDelete(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ body: body },
            },
            onOk: (e: any) => {},
            onError: () => {
                console.log('error');
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    private async _restoreVideoEvent(body?: BulkRequest | undefined): Promise<void | VideoEventDeletionResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'restoreVideoEventResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }
                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1VideoEventRestorePut(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ body: body },
            },
            onOk: (e: any) => {},
            onError: () => {
                console.log('error');
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async completeVideoEvent(eventId: number | undefined, actionTaken: string): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'completeVideoEventResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1CompletevideoPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{
                    eventId: eventId,
                    actionTaken: actionTaken,
                },
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async updateVideoEventWorkflowStatus(eventId: number | undefined, status: VideoEventWorkflowStatusIdEnum): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'videoEventWorkflowStatusResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1UpdatevideoeventPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{
                    eventId: eventId,
                    status: status,
                },
            },
            onOk: (data: any) => {
                this.updateLocalVideoEventStatus('In_Progress', eventId);
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async addVideoEventActionRecord(
        eventId: number,
        videoEventHistoryAction: VideoEventActionTypeEnum,
        actionDescription: string,
    ): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'addVideoEventActionRecordResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1AddvideoeventactionrecordPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{
                    eventId: eventId,
                    videoEventHistoryAction: videoEventHistoryAction,
                    actionDescription: actionDescription,
                },
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async updateDriver(eventId: number | undefined, driverInfo: VideoEventDriverRequest | undefined): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'updateDriverResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1UpdatedriverPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                eventId: eventId,
                body: driverInfo,
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async removeDriver(eventId: number): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'removeDriverResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const VideoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return VideoEventApi.apiV1RemovedriverPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ eventId: eventId },
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public updateLocalVideoEventStatus(status: VideoEventWorkflowStatusIdEnum, eventId: number | undefined) {
        const freezer = this.freezer.get().videoEventResultsFinal;
        //find eventId in results and update it's status manually until a refresh occurs
        if (freezer && freezer !== null) {
            for (let i = 0; i < freezer.length; i++) {
                if (freezer[i].id === eventId) {
                    if (freezer[i].videoEventStatus) {
                        freezer[i].set({ videoEventStatus: status });
                        break;
                    }
                }
            }
        }

        //check the selected videoEventResult for an eventId match and update
        const selectedVideoEvent = this.freezer.get().selectedVideoEventResult.data;
        if (selectedVideoEvent) {
            if (eventId === selectedVideoEvent.id) {
                selectedVideoEvent.set({ videoEventStatus: status });
            }
        }
    }

    public setVideoEventViewType(type: 'tile' | 'list'): void {
        this.freezer.get().set({ videoEventViewType: type });
    }

    public toggleVideoDetailsPlaying(video?: FileUploadTypeEnum): void {
        if (video) {
            const videoPlayingStates = this.freezer.get().videoPlayingStates;
            const newStates: VideoPlayingStates = {
                FrontVideo: videoPlayingStates.FrontVideo,
                RearVideo: videoPlayingStates.RearVideo,
                SideVideo: videoPlayingStates.SideVideo,
                AltVideo: videoPlayingStates.AltVideo,
                Log: videoPlayingStates.Log,
            };
            newStates[video] = !newStates[video];
            this.freezer.get().set({ videoPlayingStates: newStates });
        }
    }

    public resetVideoDetailsPlaying(video?: FileUploadTypeEnum): void {
        if (video) {
            const newStates: VideoPlayingStates = {
                FrontVideo: false,
                RearVideo: false,
                SideVideo: false,
                AltVideo: false,
                Log: false,
            };
            this.freezer.get().set({ videoPlayingStates: newStates });
        }
    }

    public scrubTo(value: number): void {
        this.freezer.get().set({ scrubTo: value });
    }

    public clearVideoEvents(): void {
        this.freezer.get().videoEventResults.set({ data: null });
        this.freezer.get().set({ videoEventResultsFinal: [] });
    }

    public refreshVideoEvents(): void {
        VideoEventFilterServiceMultiselect.setPageNumber(1);
        const eventFilter = VideoEventFilterServiceMultiselect.getVideoEventFilter();
        VideoEventService.clearVideoEvents();
        this.getFilteredEventsMultiselect(eventFilter);
    }

    public refreshVideoEventStatistics(): void {
        const eventFilter = VideoEventFilterServiceMultiselect.getVideoEventFilter();
        VideoEventService.clearVideoEvents();
        this.getVideoEventStatisticsMultiselect(eventFilter);
    }

    public GetUploadTypeString(uploadType?: FileUploadTypeEnum): string {
        if (!uploadType) {
            return 'Video';
        }
        switch (uploadType) {
            case 'FrontVideo':
                return 'Front Video';
            case 'RearVideo':
                return 'Rear Video';
            case 'SideVideo':
                return 'Side Video';
            case 'AltVideo':
                return 'Alt Video';
            case 'Log':
                return 'Log';
        }
        return '';
    }

    public setSecondaryVideoEventHeight(height: number): void {
        this.freezer.get().set({ secondaryVideoEventHeight: height });
    }

    public getVideosLoaded(): boolean {
        return this.freezer.get().videosLoaded;
    }

    public async getListOfEventIds(filterOptions: VideoEventFilterMultiselectRequest | undefined): Promise<number[]> {
        try {
            await this._getListOfEventIds(filterOptions);
            const eventIdsFromState = this.freezer.get().listofEventIdResult.data?.videoEventIdList;
            return Promise.resolve(eventIdsFromState?.toJS()!);
        } catch (e) {
            return Promise.resolve([]);
        }
    }

    public async postVideoEventRatings(body: BulkRatingRequest): Promise<boolean> {
        try {
            await this._postVideoEventRatings(body);
            return Promise.resolve(true);
        } catch (e) {
            return Promise.resolve(false);
        }
    }

    public async dismissBulkVideoEvent(body: BulkRequest | undefined): Promise<boolean> {
        try {
            await this._dismissBulkVideoEvent(body);
            return Promise.resolve(true);
        } catch (e) {
            return Promise.resolve(false);
        }
    }

    public async deleteVideoEvent(body: BulkRequest | undefined): Promise<boolean> {
        try {
            await this._deleteVideoEvent(body);
            return Promise.resolve(true);
        } catch (e) {
            console.log(e);
            return Promise.resolve(false);
        }
    }

    public async restoreVideoEvent(body: BulkRequest | undefined): Promise<boolean> {
        try {
            await this._restoreVideoEvent(body);
            return Promise.resolve(true);
        } catch (e) {
            console.log(e);
            return Promise.resolve(false);
        }
    }

    public setIncludeInactiveDrivers(includeInactiveDrivers?: boolean) {
        this.freezer.get().set({ includeInactiveDrivers });
    }

    public getIncludeInactiveDrivers(): boolean {
        return this.getState().includeInactiveDrivers;
    }

    public setIncludeInactiveVehicles(includeInactiveVehicles?: boolean) {
        this.freezer.get().set({ includeInactiveVehicles });
    }

    public getIncludeInactiveVehicles(): boolean {
        return this.getState().includeInactiveVehicles;
    }
}

export const VideoEventService = new VideoEventFreezerService();
export type IVideoEventServiceInjectedProps = ReturnType<VideoEventFreezerService['getPropsForInjection']>;
