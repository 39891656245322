import Freezer = require("freezer-js");
import { FreezerService } from './externals/FreezerService'
import { bind } from "bind-decorator";
import * as _ from "lodash";
import * as moment from 'moment-timezone';
import * as React from "react";
import { Route, Redirect } from "react-router";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import * as managedAjaxUtil from "./modules/managedAjaxUtil";
import { IAjaxState } from "./modules/managedAjaxUtil";
import * as s from "underscore.string";
import * as unit from "./modules/unitConverter";
import * as phone from "./modules/phoneHelper";

const InputMask = require("react-input-mask");

export { React, Router, Route, InputMask, Link, bind, _, FreezerService, moment, IAjaxState, managedAjaxUtil, s, unit, phone, Redirect };
