import { buttonColors } from '$Components/Shared/ButtonColors';
import { VideoEventPipelineStepEnum, VideoEventWorkflowStatusIdEnum } from '$Generated/api';
import { getStatusLabel } from '$Utilities/videoEventStatusUtility';
import { React } from 'Imports';

interface IVideoEventStatusIndicatorProps {
    videoEventStatus?: VideoEventWorkflowStatusIdEnum;
    workflowStatus?: VideoEventPipelineStepEnum;
    className?: string;
}

type VideoEventStatusIndicatorProps = IVideoEventStatusIndicatorProps;

function VideoEventStatusIndicator(props: VideoEventStatusIndicatorProps) {
    const { videoEventStatus, workflowStatus, className } = props;

    const getIndicatorColor = (): { background: string; color: string } => {
        if (videoEventStatus != undefined) {
            return buttonColors[videoEventStatus];
        } else {
            return { background: '', color: '' };
        }
    };

    const isInvalidStatus = (indicatorText: string): boolean => {
        let result = false;
        switch (workflowStatus) {
            case 'Success':
                result = false;
                break;
            default:
                result = true;
        }

        return result;
    };

    const indicatorColor = getIndicatorColor();
    if (indicatorColor.background === '') return null;

    let indicatorText = getStatusLabel(videoEventStatus as VideoEventWorkflowStatusIdEnum).toUpperCase();

    if (isInvalidStatus(indicatorText)) return null;

    return (
        <div className={className} style={{ backgroundColor: indicatorColor.background, color: indicatorColor.color }}>
            {indicatorText}
        </div>
    );
}

export { VideoEventStatusIndicator };
