import { cx } from '@videoplatform/css-helpers';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFleetSettingsServiceInjectedProps, FleetSettingsService } from '$State/FleetSettingsFreezerService';
import { isUndefined } from 'lodash';
import * as scssStyles from '$CSS/settings.scss';
import { React, bind, _ } from '../../Imports';
import { Select, MenuItem, Button, createTheme, ThemeProvider } from 'MaterialUIComponents';
import * as DateFormatter from '$Components/Shared/DateFormatter';

const styles = require('./TimeSelector.scss') as {
    main: string;
    container: string;
    inputContainer: string;
    input: string;
    inputLabel: string;
    sectionText: string;
    sectionTitle: string;
    select: string;
    durationSelect: string;
    icon: string;
    actions: string;
    inputError: string;
    cancelButton: string;
    confirmButton: string;
    dropdownOptions: string;
    smallPadding: string;
    labelError: string;
};

const theme = createTheme({
    palette: {
        primary: {
            main: scssStyles.mainBlue,
        },
    },
});

interface ITimeSelectorBaseProps {
    onCancel: any;
    onConfirm: any;
    time?: string;
    ampm?: string;
    timeBefore?: number;
    timeAfter?: number;
    timezone?: string;
    hideActionButtons?: boolean;
    triggerValidation?: boolean;
}

type ITimeSelectorProps = ITimeSelectorBaseProps & IFleetSettingsServiceInjectedProps;

interface ITimeSelectorState {
    hour?: number;
    minute?: number;
    second?: number;
    ampm: string;
    timezone: string;
    timeBefore: number;
    timeAfter: number;
    maxDuration: number;
    isValidForm: boolean;
    formValidationResult: IFormValidationResult;
    validationWasTriggered: boolean;
}

interface ITimezone {
    abbreviation: string;
    name: string;
    utcOffset: number;
}

const TimeDirection = {
    Before: 'Before',
    After: 'After',
};

interface IFormValidationResult {
    hour: IValidationResult;
    minute: IValidationResult;
    second: IValidationResult;
}

const initialValidationState: IFormValidationResult = {
    hour: { error: false },
    minute: { error: false },
    second: { error: false },
};

interface IValidationResult {
    error: boolean;
    errorMessage?: string;
}

const timeZoneOptions: ITimezone[] = [
    {
        abbreviation: 'AST',
        name: 'Atlantic Standard Time',
        utcOffset: -4,
    },
    {
        abbreviation: 'EST',
        name: 'Eastern Standard Time',
        utcOffset: -5,
    },
    {
        abbreviation: 'EDT',
        name: 'Eastern Daylight Time',
        utcOffset: -4,
    },
    {
        abbreviation: 'CST',
        name: 'Central Standard Time',
        utcOffset: -6,
    },
    {
        abbreviation: 'CDT',
        name: 'Central Daylight Time',
        utcOffset: -5,
    },
    {
        abbreviation: 'MST',
        name: 'Mountain Standard Time',
        utcOffset: -7,
    },
    {
        abbreviation: 'MDT',
        name: 'Mountain Daylight Time',
        utcOffset: -6,
    },
    {
        abbreviation: 'PST',
        name: 'Pacific Standard Time',
        utcOffset: -8,
    },
    {
        abbreviation: 'PDT',
        name: 'Pacific Daylight Time',
        utcOffset: -7,
    },
    {
        abbreviation: 'AKST',
        name: 'Alaska Time',
        utcOffset: -9,
    },
    {
        abbreviation: 'AKDT',
        name: 'Alaska Daylight Time',
        utcOffset: -8,
    },
    {
        abbreviation: 'HST',
        name: 'Hawaii Standard Time',
        utcOffset: -10,
    },
];

const ampmOptions: string[] = ['AM', 'PM'];

let durationOptions: any[] = [];
let timeBeforeOptions: any[] = [];
let timeAfterOptions: any[] = [];

class _TimeSelector extends React.PureComponent<ITimeSelectorProps, ITimeSelectorState> {
    state: ITimeSelectorState = {
        ampm: 'AM',
        timezone: 'CST',
        timeBefore: 0,
        timeAfter: 0,
        isValidForm: false,
        formValidationResult: _.cloneDeep(initialValidationState),
        maxDuration: 0,
        validationWasTriggered: false,
    };

    async componentDidMount() {
        const currentTimezone = DateFormatter.getTimezoneString();

        if (timeZoneOptions.findIndex((i: ITimezone) => i.abbreviation.toUpperCase() == currentTimezone.toUpperCase()) > -1) {
            this.setState({ timezone: currentTimezone.toUpperCase() });
        }
        
        const timeBefore = this.props.timeBefore ?? 15; 
        const timeAfter  = this.props.timeAfter ?? 15;

        if (
            this.props.time &&
            this.props.ampm &&
            this.props.timezone
        ) {
            const timeSplit = this.props.time.split(':');
            this.setState(
                {
                    hour: parseInt(timeSplit[0]),
                    minute: parseInt(timeSplit[1]),
                    second: parseInt(timeSplit[2]),
                    ampm: this.props.ampm,
                    timeBefore,
                    timeAfter,
                    timezone: this.props.timezone,
                    formValidationResult: _.cloneDeep(initialValidationState),
                },
                this.validateForm,
            );
        }

        await this.props.FleetSettings.getFleetSettingsByKeyResults('default_recall_max');
        const { getFleetSettingsByKeyResults } = this.props.FleetSettings.getState();

        // Set 0 in case of invalid result
        const settingValue = getFleetSettingsByKeyResults.data?.value ? parseInt(getFleetSettingsByKeyResults.data.value) : 0;

        // Max value 300 seconds (5 minutes)
        this.setState({
            maxDuration: settingValue <= 300 ? settingValue : 300,
            timeBefore,
            timeAfter,
        });
    }

    @bind
    defineDurationOptions() {

        durationOptions = [];
        const totalMin = Math.floor(this.state.maxDuration / 60);

        let progression = 0;
        while(progression <= this.state.maxDuration)
        {
            let minute = Math.floor(progression / 60);
            let minLabel =  `${(minute) ? minute + ' min ' : ''} ${((minute >= totalMin && (progression % 60 != 0)) ||  progression < 60 ) ? progression - (minute*60) + ' sec' : '' }`;
            durationOptions = [...durationOptions, {name: minLabel, value: progression}];
  
            progression += (progression < 60 || minute >= totalMin) ? 15 : 60;
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<ITimeSelectorProps>, nextContext: any): Promise<void> {
        if (this.props.triggerValidation !== nextProps.triggerValidation && nextProps.triggerValidation) {
            this.handleConfirm();
            this.validateForm();
            this.setState({ validationWasTriggered: true });
        }
    }

    @bind
    handleHourChange(e: any): void {
        let value = e.target.value;

        if (value.toString().length > 2 && value.toString()[0] !== '0') {
            return;
        }

        const { formValidationResult } = this.state;

        if (value === '') {
            value = undefined;
        }

        if (isUndefined(value) || isNaN(value) || value <= 0 || value > 12) {
            formValidationResult.hour.error = true;
        } else if (formValidationResult.hour.error) {
            formValidationResult.hour.error = false;
        }

        this.setState({ hour: value, formValidationResult }, this.validateForm);
    }

    @bind
    handleMinuteChange(e: any): void {
        const value = e.target.value;

        if (value.toString().length > 2 && value.toString()[0] !== '0') {
            return;
        }

        const { formValidationResult } = this.state;

        if (isUndefined(value) || isNaN(value) || value < 0 || value > 59) {
            formValidationResult.minute.error = true;
        } else if (formValidationResult.minute.error) {
            formValidationResult.minute.error = false;
        }

        this.setState({ minute: value, formValidationResult }, this.validateForm);
    }

    @bind
    handleSecondChange(e: any): void {
        const value = e.target.value;

        if (value.toString().length > 2 && value.toString()[0] !== '0') {
            return;
        }

        const { formValidationResult } = this.state;

        if (isUndefined(value) || isNaN(value) || value < 0 || value > 59) {
            formValidationResult.second.error = true;
        } else if (formValidationResult.second.error) {
            formValidationResult.second.error = false;
        }

        this.setState({ second: value, formValidationResult }, this.validateForm);
    }

    @bind
    handleAmPmChange(e: any): void {
        this.setState({ ampm: e.target.value });
    }

    @bind
    handleTimezoneChange(e: any): void {
        this.setState({ timezone: e.target.value });
    }

    @bind
    handleTimeBeforeChange(e: any): void {
        this.setState({ timeBefore: e.target.value }, this.validateForm);
    }

    @bind
    handleTimeAfterChange(e: any): void {
        this.setState({ timeAfter: e.target.value }, this.validateForm);
    }

    @bind
    handleConfirm(): void {
        const { hour, minute, second, ampm, timeBefore, timeAfter, timezone } = this.state;

        if (!isUndefined(hour) && !isUndefined(minute) && !isUndefined(second)) {
            const time: string = `${this.padStart(hour)}:${this.padStart(minute)}:${this.padStart(second)}`;
            const timezoneOffset = timeZoneOptions.find((t) => t.abbreviation === timezone)?.utcOffset;
            const timeBeforeText = timeBeforeOptions.find((d) => d.value === timeBefore).name;
            const timeAfterText = timeAfterOptions.find((d) => d.value === timeAfter).name;
            this.props.onConfirm({ time, ampm, timeBefore, timeBeforeText, timeAfter, timeAfterText, timezone, timezoneOffset });
        }
    }

    padStart(n: number): string {
        return n <= 99 ? `0${n}`.slice(-2) : n.toString();
    }

    validateForm(): void {
        const { formValidationResult, hour, minute, second, timeAfter, timeBefore } = this.state;
        let isValidForm: boolean = true;

        if (
            formValidationResult.hour.error ||
            formValidationResult.minute.error ||
            formValidationResult.second.error ||
            isUndefined(hour) ||
            isUndefined(minute) ||
            isUndefined(second) ||
            (timeBefore === 0 && timeAfter === 0)
        ) {
            isValidForm = false;
        }

        if (this.props.hideActionButtons) {
            formValidationResult.hour.error = isUndefined(hour);
            formValidationResult.minute.error = isUndefined(minute);
            formValidationResult.second.error = isUndefined(second);
            this.setState({ formValidationResult: formValidationResult });
        }

        this.setState({ isValidForm });
    }

    getDurationText() {
        const minutes = Math.floor(this.state.maxDuration / 60);
        const remainingSeconds = this.state.maxDuration % 60;
        let text = '';
        if (minutes > 0) {
            text = `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }
        if (remainingSeconds > 0) {
            if (minutes > 0) {
                text = `${text} and `;
            }
            text = `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
        }
        return text;
    }

    formatedOption(durationSeconds: number) {
        const minutes = Math.floor( durationSeconds / 60 );
            const seconds = durationSeconds % 60;
    
            const item = {
                name: `${(minutes) ? minutes + ' min ' : ''} ${seconds} sec`,
                value: durationSeconds
            };
            return item;
    }

    filterDurationOptions(selectedTime: number, timeDirection: any): any
    {
        let options = durationOptions.filter(
            (i) => (timeDirection == TimeDirection.Before && (i.value + this.state.timeAfter <= this.state.maxDuration || (0 && i.value + this.state.timeAfter > 0)))
                || (timeDirection == TimeDirection.After && (i.value + this.state.timeBefore <= this.state.maxDuration || (0 && i.value + this.state.timeBefore > 0)))
        );

        const lastSelected = (timeDirection == TimeDirection.Before) ? this.state.timeBefore : this.state.timeAfter;
        const existSelected = options.some(s => s.value == lastSelected);
        options =  (!existSelected) ? [...options, this.formatedOption(lastSelected)] : options;

        const remanent = this.state.maxDuration - selectedTime;
        const exist = options.some(s => s.value == remanent);        
        options =  (!exist) ? [...options, this.formatedOption(remanent)] : options;
        
        return  options.sort((a, b) => (a.value < b.value ? -1 : 1));
    }

    render(): JSX.Element {
        
        const { formValidationResult, timeAfter, timeBefore } = this.state;
        
        this.defineDurationOptions();

        timeBeforeOptions = this.filterDurationOptions(timeAfter, TimeDirection.Before);
        timeAfterOptions = this.filterDurationOptions(timeBefore, TimeDirection.After);

        const durationText = this.getDurationText();
        return (
            <div className={`${styles.main} ${this.props.hideActionButtons && styles.smallPadding}`}>
                <div className={styles.sectionTitle}>Select the Time{this.props.hideActionButtons ? '*' : ':'}</div>
                <div className={styles.container}>
                    <div className={styles.inputContainer}>
                        <label className={styles.inputLabel}>Hour</label>
                        <br />
                        <input
                            className={[styles.input, formValidationResult.hour.error ? styles.inputError : ''].join(' ')}
                            type="text"
                            placeholder="00"
                            value={this.state.hour && this.state.hour >= 1 ? this.padStart(this.state.hour) : ''}
                            onChange={this.handleHourChange}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.inputLabel}>Min</label>
                        <br />
                        <input
                            className={[styles.input, formValidationResult.minute.error ? styles.inputError : ''].join(' ')}
                            type="text"
                            placeholder="00"
                            value={!isUndefined(this.state.minute) && this.state.minute >= 0 ? this.padStart(this.state.minute) : ''}
                            onChange={this.handleMinuteChange}
                            onKeyDown={(e) =>
                                e.key === 'Backspace' || e.key === 'Delete' ? this.setState({ minute: undefined }, this.validateForm) : ''
                            }
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.inputLabel}>Sec</label>
                        <br />
                        <input
                            className={[styles.input, formValidationResult.second.error ? styles.inputError : ''].join(' ')}
                            type="text"
                            placeholder="00"
                            value={!isUndefined(this.state.second) && this.state.second >= 0 ? this.padStart(this.state.second) : ''}
                            onChange={this.handleSecondChange}
                            onKeyDown={(e) =>
                                e.key === 'Backspace' || e.key === 'Delete' ? this.setState({ second: undefined }, this.validateForm) : ''
                            }
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.inputLabel}></label>
                        <br />
                        <Select
                            value={this.state.ampm}
                            className={styles.select}
                            onChange={this.handleAmPmChange}
                            disableUnderline={true}
                            IconComponent={(props) => <FontAwesomeIcon {...props} className={styles.icon} icon={faAngleDown} />}
                            MenuProps={{
                                className: styles.input,
                                classes: { paper: styles.dropdownOptions },
                            }}
                        >
                            {ampmOptions.map((item, i) => (
                                <MenuItem key={i} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.inputLabel}></label>
                        <br />
                        <Select
                            value={this.state.timezone}
                            className={styles.select}
                            onChange={this.handleTimezoneChange}
                            disableUnderline={true}
                            IconComponent={(props) => <FontAwesomeIcon {...props} className={styles.icon} icon={faAngleDown} />}
                            MenuProps={{
                                className: styles.input,
                                classes: { paper: styles.dropdownOptions },
                            }}
                        >
                            {timeZoneOptions.map((item, i) => (
                                <MenuItem key={i} value={item.abbreviation}>
                                    {item.abbreviation}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                {this.state.validationWasTriggered && this.props.hideActionButtons && !this.state.isValidForm && (
                    <>
                        <p className={styles.labelError}>Please enter the time correctly.</p>
                    </>
                )}
                <br />
                <div className={styles.sectionTitle}>
                    Select the Duration{this.props.hideActionButtons ? '*' : ':'}
                    <div className={styles.sectionText}>
                        A combined total of {durationText} of video from the selected time above is available for recall.
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={styles.inputContainer}>
                        <label className={styles.inputLabel}>Time before</label>
                        <br />
                        <Select
                            value={this.state.timeBefore}
                            className={cx([styles.select, styles.durationSelect])}
                            onChange={this.handleTimeBeforeChange}
                            disableUnderline={true}
                            IconComponent={(props) => <FontAwesomeIcon {...props} className={styles.icon} icon={faAngleDown} />}
                            MenuProps={{
                                className: styles.input,
                                classes: { paper: styles.dropdownOptions },
                            }}
                        >
                            {timeBeforeOptions.map((item, i) => (
                                <MenuItem key={i} value={item.value}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>{' '}
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.inputLabel}>Time after</label>
                        <br />
                        <Select
                            value={this.state.timeAfter}
                            className={cx([styles.select, styles.durationSelect])}
                            onChange={this.handleTimeAfterChange}
                            disableUnderline={true}
                            IconComponent={(props) => <FontAwesomeIcon {...props} className={styles.icon} icon={faAngleDown} />}
                            MenuProps={{
                                className: styles.input,
                                classes: { paper: styles.dropdownOptions },
                            }}
                        >
                            {timeAfterOptions.map((item, i) => (
                                <MenuItem key={i} value={item.value}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <br />
                {!this.props.hideActionButtons && (
                    <div className={styles.actions}>
                        <ThemeProvider theme={theme}>
                            <Button className={styles.cancelButton} variant="text" onClick={this.props.onCancel}>
                                Cancel
                            </Button>
                            <Button
                                className={styles.confirmButton}
                                variant="text"
                                disabled={!this.state.isValidForm}
                                onClick={this.handleConfirm}
                                color="primary"
                            >
                                Confirm
                            </Button>
                        </ThemeProvider>
                    </div>
                )}
            </div>
        );
    }
}

export const TimeSelector = FleetSettingsService.inject(_TimeSelector);
