import { React, bind, _, moment } from '../../Imports';
import { IFilterOptions } from './FilterBar';
import { FilterChip } from './FilterChip';
import * as DateFormatter from '../Shared/DateFormatter';
import { IVideoEventServiceInjectedProps, VideoEventService } from '$State/VideoEventFreezerService';
import { VideoEventTypeResponse, VideoEventWorkflowStatusIdEnum } from '$Generated/api';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { getStatusLabel } from '$Utilities/videoEventStatusUtility';
const styles = require('./FilterBreadcrumbs.scss') as {
    main: string;
};

interface IFilterBreadcrumbsProps {
    filters: IFilterOptions;
    onUpdateFilters: (filters: IFilterOptions) => void;
}

class _FilterBreadcrumbs extends React.Component<
    IFilterBreadcrumbsProps & IIntegrationPartnerDataInjectedProps & IVideoEventServiceInjectedProps,
    {}
> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
        this.props.videoEvents.getVideoEventTypes();
    }

    @bind
    onClickFilter(filterToDelete: 'EventType' | 'DriverName' | 'VehicleId' | 'Date'): void {
        const filters: IFilterOptions = _.clone(this.props.filters);
        switch (filterToDelete) {
            case 'EventType':
                filters.eventType = undefined;
                this.props.onUpdateFilters(filters);
                break;
            case 'DriverName':
                filters.driverName = undefined;
                this.props.onUpdateFilters(filters);
                break;
            case 'VehicleId':
                filters.vehicleId = [];
                this.props.onUpdateFilters(filters);
                break;
            case 'Date':
                filters.startDate = new Date();
                filters.endDate = new Date();
                this.props.onUpdateFilters(filters);
                break;
        }
    }

    @bind
    onClickStatusFilter(filterToDelete: VideoEventWorkflowStatusIdEnum): void {
        const filters: IFilterOptions = _.clone(this.props.filters);
        const statuses: VideoEventWorkflowStatusIdEnum[] = [];
        if (filters.status) {
            filters.status.forEach((status) => {
                if (status !== filterToDelete) {
                    statuses.push(status);
                }
            });
        }
        if (statuses.length == 0) {
            filters.status = undefined;
        } else {
            filters.status = statuses;
        }
        this.props.onUpdateFilters(filters);
    }

    @bind
    formatDateFilter(): string {
        const start = DateFormatter.date(moment(this.props.filters.startDate));
        const end = DateFormatter.date(moment(this.props.filters.endDate));
        if (start === end) {
            return start;
        } else {
            return start + ' - ' + end;
        }
    }

    @bind
    formatEventType(types: VideoEventTypeResponse[]): string {
        let displayName: string | undefined = '';
        types.map((eventType) => {
            if (eventType.id === this.props.filters.eventType) {
                displayName = eventType.displayName;
            }
        });
        return displayName;
    }

    @bind
    formatDriverName(drivers: any[]): string {
        let displayName: string | undefined = '';
        let driver = '';
        if (this.props.filters.driverName === undefined) {
            return displayName;
        } else {
            driver = this.props.filters.driverName[0];
        }
        drivers.map((d) => {
            if (d.id === driver) {
                displayName = d.firstName + ' ' + d.lastName;
            }
        });
        return displayName;
    }

    @bind
    formatVehicle(devices: any[]): string {
        let displayName: string | undefined = '';
        let vehicle = '';
        if (this.props.filters.vehicleId === undefined) {
            return displayName;
        } else {
            vehicle =
                this.props.filters.vehicleId && this.props.filters.vehicleId[0] !== null && this.props.filters.vehicleId[0] !== undefined
                    ? this.props.filters.vehicleId[0]
                    : displayName;
        }
        devices.map((d) => {
            if (d.id === vehicle) {
                displayName = d.name;
            }
        });
        return displayName;
    }

    render(): JSX.Element {
        const { driversResults, deviceResults } = this.props.integrationPartnerData.getState();
        const drivers = driversResults.data ? driversResults.data : [];
        const devices = deviceResults.data ? deviceResults.data : [];

        const videoEventfreezer = this.props.videoEvents.getState();
        const { videoEventTypeResults } = videoEventfreezer;
        const videoEventTypes = videoEventTypeResults.data ? videoEventTypeResults.data : [];

        const driver: string = this.formatDriverName(drivers);
        const vehicle: string = this.formatVehicle(devices);
        const isToday: boolean = this.formatDateFilter() === DateFormatter.date(moment());

        return (
            <div className={styles.main}>
                {this.props.filters.eventType !== undefined && this.props.filters.eventType !== undefined && (
                    <FilterChip onDelete={(): void => this.onClickFilter('EventType')}>
                        Event Type: {this.formatEventType(videoEventTypes)}
                    </FilterChip>
                )}
                {driver !== '' && driver !== undefined && (
                    <FilterChip onDelete={(): void => this.onClickFilter('DriverName')}>Driver: {driver}</FilterChip>
                )}
                {vehicle !== '' && vehicle !== undefined && (
                    <FilterChip onDelete={(): void => this.onClickFilter('VehicleId')}>Vehicle: {vehicle}</FilterChip>
                )}
                {this.props.filters.status &&
                    this.props.filters.status.map(
                        (s: VideoEventWorkflowStatusIdEnum): JSX.Element => (
                            <FilterChip key={s} onDelete={(): void => this.onClickStatusFilter(s)}>
                                Status: {getStatusLabel(s)}
                            </FilterChip>
                        ),
                    )}
                {this.props.filters.startDate && (
                    <FilterChip onDelete={!isToday ? (): void => this.onClickFilter('Date') : undefined}>
                        Date: {this.formatDateFilter()}
                    </FilterChip>
                )}
            </div>
        );
    }
}

export const FilterBreadcrumbs = VideoEventService.inject(IntegrationPartnerDataService.inject(_FilterBreadcrumbs));
