import { ConfigService, IConfigServiceInjectedProps } from '../../../state/ConfigFreezerService';
import { FileUploadTypeEnum, VideoEventResponse } from '$Generated/api';
import { React, bind } from '../../../Imports';
import { VideoDownload } from '../../Shared/VideoDownload';
import * as Plyr from 'plyr';
// https://github.com/sampotts/plyr

interface IEventVideoPlayerProps {
    videoUrl: string;
    eventData: VideoEventResponse;
    toggleVideoIsPlaying: (fileUploadTypeId: FileUploadTypeEnum, isStarting: boolean, isEnding: boolean, syncPlayingState?: boolean) => void;
    scrubToFunc: (e: number) => void;
    scrubTo: number;
    fileUploadTypeId: FileUploadTypeEnum;
    updateVideoEventHistory?: () => void;
    updateProgress?: (progress: number) => void;
    videoIsPlaying: boolean;
    setDuration?: (duration: number, fileUploadTypeId: FileUploadTypeEnum) => void;
    resetEnded?: boolean;
}

interface IEventVideoState {
    displayControls: boolean;
    progress: number;
    videoIsPlaying: boolean;
    scrubTo: number;
    downloadOver: boolean;
    isSeeking: boolean;
    cachedState?: boolean;
    isEnded?: boolean;
}

const styles = require('./EventVideoPlyrScrub.scss') as {
    main: string;
    videoContainer: string;
    downloadOverlay: string;
    download: string;
    videoPlayer: string;
};

type IEventVideoProps = IEventVideoPlayerProps & IConfigServiceInjectedProps;

class _EventVideoPlyrScrub extends React.Component<IEventVideoProps, IEventVideoState> {
    state = {
        displayControls: true,
        progress: 0,
        videoIsPlaying: false,
        scrubTo: 0,
        downloadOver: false,
        isSeeking: false,
        cachedState: undefined,
        isEnded: false,
    };

    ourID: string = 'ourVideo';
    ourPlayer: Plyr | null = null;
    ref: HTMLDivElement | null = null;
    statusPromise: any;

    constructor(props: any) {
        super(props);
    }

    @bind
    handlePlayPause(isEnding: boolean, syncPlayingState?: boolean): void {
        const isStarting = this.state.progress === 0 || (this.state.progress === 1 && !isEnding);

        if (this.state.isEnded && isStarting) {
            this.props.scrubToFunc(0);
            this.setState({isEnded: false});
        }

        this.props.toggleVideoIsPlaying(this.props.fileUploadTypeId, isStarting, isEnding, syncPlayingState);
    }
    
    @bind
    handleProgressUpdate(progress: number): void {
        this.setState({ progress },
                () =>  this.props.updateProgress && this.props.updateProgress(progress) );
    }

    @bind
    handleSeek(scrubTo: number): void {
        this.setState({ scrubTo });
        setTimeout(() => {
            this.props.scrubToFunc(this.state.scrubTo);
            this.setState({ isSeeking: false });
        }, 10);
    }

    async componentDidUpdate(prevProps: IEventVideoProps): Promise<any> {

        if (this.ourPlayer) {
           if (this.props.videoIsPlaying !== prevProps.videoIsPlaying) {

                if (this.state.cachedState == undefined)
                {
                    this.setState({cachedState: prevProps.videoIsPlaying}, () => {
                        setTimeout( () => {
                            if (!prevProps.videoIsPlaying && !this.state.isEnded && !this.state.isSeeking) {
                                this.statusPromise = this.ourPlayer?.play();
                                this.statusPromise.then(() => { this.statusPromise = null;});
                            } else if( this.statusPromise == null) {
                                this.ourPlayer?.pause();
                            }
                            this.setState({cachedState: undefined});
                        },10);
                    });
                }
            }
            if (this.props.scrubTo !== prevProps.scrubTo) {
                if (this.props.scrubTo == this.ourPlayer.duration) {
                    this.setState({isEnded: true});
                    this.ourPlayer?.pause();
                }
                if (this.ourPlayer.duration > this.props.scrubTo) {
                    this.ourPlayer.currentTime = this.props.scrubTo;
                    this.setState({isEnded: false});
                    if (this.props.videoIsPlaying && !this.state.isEnded) {
                        this.ourPlayer.play();
                    }

                } else {
                    this.ourPlayer.currentTime = this.ourPlayer.duration - 0.01;
                    this.setState({isEnded: true});
                }
            }

            if (this.props.resetEnded != prevProps.resetEnded) {
                if (this.props.resetEnded) {
                    this.setState({isEnded: false});
                }
            }
        }
    }

    componentWillUnmount() {       
        if (this.props.videoIsPlaying) {
            this.props.toggleVideoIsPlaying(this.props.fileUploadTypeId, false, false);
        }

        //reset progress
        this.handleProgressUpdate(0);
        this.props.scrubToFunc(0);
        this.ourPlayer?.destroy();
        this.statusPromise = null;
    }

    componentDidMount() {

        const ourOptions = {
            ratio: '16:9',
            controls: ['play-large','play', 'progress', 'current-line', 'current-time', 'duration', 'download'],
            volume: 0,
            tooltips: {seek: true, controls: false},
            listeners: {
                ['download']: (event: Plyr.PlyrEvent) => { event.stopPropagation();},
                ['play']: (event: Plyr.PlyrEvent) => {
                    this.handlePlayPause(false, !this.ourPlayer?.playing);
                },
                ['seek']: (event: Plyr.PlyrEvent) => {
                    this.setState( {isSeeking: true});
                 },
            },
            fullscreen: {enabled: false},
        } as Plyr.Options;
        
        this.ourPlayer = new Plyr(`#${this.ourID}`, ourOptions);
        this.ourPlayer.source = {
            type: 'video',
            sources: [
                {
                    src: this.props.videoUrl,
                },
            ],
        } as Plyr.SourceInfo; 

        this.ourPlayer.on('ended', (event: Plyr.PlyrEvent) => {
            this.setState({isEnded: true});
            this.props.toggleVideoIsPlaying(this.props.fileUploadTypeId, false, true);
        });

        this.ourPlayer.on('play', (event: Plyr.PlyrEvent) => {
            this.setState({isEnded: false});
            this.props.setDuration && this.props.setDuration(event.detail.plyr.duration, this.props.fileUploadTypeId);

            this.defineTooltips();
        });

        this.ourPlayer.on('pause', (event: Plyr.PlyrEvent) => {
            this.defineTooltips();
        });

        this.ourPlayer.on('ready', (event: Plyr.PlyrEvent) => {
            this.defineTooltips();
        });

        this.ourPlayer.on('timeupdate', (event: Plyr.PlyrEvent) => {            
            let duration = this.ourPlayer?.duration;
            if (duration == undefined || duration == 0) {
                duration = 1;
            }

            this.handleProgressUpdate(event.detail.plyr.currentTime / duration );
        });

        this.ourPlayer.on('controlshidden', (event: Plyr.PlyrEvent) => {
            if (this.state.downloadOver) {
                this.ourPlayer?.toggleControls(true);
            } else {
                this.setState({displayControls: false});
            }
        });
        
        this.ourPlayer.on('controlsshown', (event: Plyr.PlyrEvent) => {
            this.setState({displayControls: true});
        });

        this.ourPlayer.on("seeked", (event: Plyr.PlyrEvent) => {
            if (this.state.isSeeking) {
                const scrubTo = event.detail.plyr.currentTime ?? 0;
                this.handleSeek(scrubTo);
            }
        });
    }

    defineTooltips() {
        const setLabel = (control: Element) => {
            const label = control.getAttribute('aria-label') || '';
            control.setAttribute('title', label);
        }

        document.querySelectorAll('.plyr__controls button').forEach(setLabel);
        document.querySelectorAll('.plyr__control--overlaid').forEach(setLabel);
    }

    handleDownloadVisibility(onMouse: boolean) {
        this.setState({downloadOver: onMouse});
    }

    render(): JSX.Element {
        const { videoUrl, eventData } = this.props;

        return (
            <div ref={(ref) => (this.ref = ref)}>
                <div className={styles.main}>
                    <div className={styles.videoContainer}>
                        <video id={this.ourID} playsInline controls>
                            <source src={videoUrl} type="video/mp4" />
                        </video>
                    </div>

                    {this.state.displayControls && (
                        <div className={styles.download} 
                        onMouseEnter={(e) => {this.handleDownloadVisibility(true)}}
                        onMouseLeave={(e) => this.handleDownloadVisibility(false)}
                        >
                            <VideoDownload
                                videoUrl={this.props.videoUrl}
                                eventId={eventData.id}
                                videoType={this.props.fileUploadTypeId}
                                updateVideoEventHistory={this.props.updateVideoEventHistory}
                                widthOverride={'40px'}
                                heightOverride={'40px'}
                                transparent={true}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const EventVideoPlyrScrub = ConfigService.inject(_EventVideoPlyrScrub);
