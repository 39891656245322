export function getEventTypeLabel(value: string): string {
    if (value.includes('CollisionDetected')) {
        return 'Collision/Impact';
    }

    return value.replace(/([A-Z])/g, ' $1').trim();
}

export function getEventTypeLabels(labelArray: (string | undefined)[] | undefined): (string | undefined)[] {
    let labelName = [],
        labelCount = [],
        prev;

    if (labelArray) {
        // https://stackoverflow.com/questions/54839677/fast-way-to-count-and-remove-duplicates-from-an-array
        // count the dups, remove the dups and add the number of occurences for each
        labelArray.sort();
        for (var i = 0; i < labelArray.length; i++) {
            if (labelArray[i] !== prev) {
                labelName.push(labelArray[i]);
                labelCount.push(1);
            } else {
                labelCount[labelCount.length - 1]++;
            }
            prev = labelArray[i];
        }
    }
    let labels = [];
    for (var i = 0; i < labelName.length; i++) {
        if (labelCount[i] != 1) labels.push(labelName[i] + ' (' + labelCount[i] + ')');
        else labels.push(labelName[i]);
    }
    return labels;
}
