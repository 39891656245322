import { React } from '../../../Imports';
import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import * as scssStyles from '$CSS/settings.scss';

export enum progressLoaderEnum {
    success = 'SUCCESS',
    loading = 'LOADING',
    error = 'ERROR',
}

export type loaderDetailInfo = {
    message?: string;
    color?: string;
    progressLoaderState: progressLoaderEnum;
};

const ProgressLoader = (loaderState: progressLoaderEnum) => {
    switch (loaderState) {
        case progressLoaderEnum.success:
            return (
                <div style={{ paddingBottom: '10%' }}>
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        size={'6x'}
                        style={{ fontWeight: '100', color: scssStyles.styleEnvironment === 'encompass' ? '#00A878' : '#4CAF50' }}
                    />
                </div>
            );
        case progressLoaderEnum.loading:
            return (
                <div style={{ paddingBottom: '18%' }}>
                    <Loader />
                </div>
            );
        case progressLoaderEnum.error:
            return (
                <div style={{ paddingBottom: '10%' }}>
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        size={'6x'}
                        style={{ fontWeight: '100', color: scssStyles.styleEnvironment === 'encompass' ? '#F8333C' : '#DE3030' }}
                    />
                </div>
            );
        default:
            return (
                <div style={{ paddingBottom: '18%' }}>
                    <Loader />
                </div>
            );
    }
};

interface IStyledProgressLoaderProps {
    isVisible: boolean;
    loaderContainerWidth?: string;
    loaderDetail: loaderDetailInfo;
    handleModalClose: (e: any | undefined) => void;
}

type StyledProgressLoaderProps = IStyledProgressLoaderProps;
const StyledProgressLoader = (props: StyledProgressLoaderProps) => {
    const {
        isVisible = true,
        loaderContainerWidth,
        loaderDetail = {
            message: '',
            color: '',
            progressLoaderState: progressLoaderEnum.loading,
        },
        handleModalClose,
    } = props;

    return (
        <>
            <div
                style={{
                    width: loaderContainerWidth ? loaderContainerWidth : 'auto',
                    display: isVisible ? 'flex' : 'none',
                    height: '300px',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexGrow: 3,
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignContent: 'center',
                    }}
                >
                    {ProgressLoader(loaderDetail.progressLoaderState)}
                </div>
                {loaderDetail.message && (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                            width: '100%',
                            flexGrow: 1,
                            fontSize: '16px',
                        }}
                    >
                        <p
                            style={{
                                color: loaderDetail.color ? loaderDetail.color : 'black',
                            }}
                        >
                            {loaderDetail.message}
                        </p>
                        {(loaderDetail.progressLoaderState === progressLoaderEnum.error ||
                            loaderDetail.progressLoaderState === progressLoaderEnum.success) && (
                            <p onClick={handleModalClose} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'black' }}>
                                close
                            </p>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default StyledProgressLoader;
