import { FreezerService, managedAjaxUtil, IAjaxState } from 'Imports';
import { InstallerUserResponse, InstallerUserRequest, InstallersApiFactory, InstallerUserDatesUpdateRequest } from '$Generated/api';
import { UserManagementService } from '$State/UserManagementFreezerService';

const InjectedPropName = 'installers';

interface IInstallersState {
    getInstallersResults: IAjaxState<InstallerUserResponse[]>;
    createInstallerResult: IAjaxState<void>;
    updateInstallerDatesResult: IAjaxState<void>;
    resendEmailResult: IAjaxState<void>;
    hasError: boolean;
}

class InstallersFreezerService extends FreezerService<IInstallersState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                getInstallersResults: managedAjaxUtil.createInitialState(),
                createInstallerResult: managedAjaxUtil.createInitialState(),
                updateInstallerDatesResult: managedAjaxUtil.createInitialState(),
                resendEmailResult: managedAjaxUtil.createInitialState(),
                hasError: false
            },
            InjectedPropName
        )
    }

    public async getInstallers(): Promise<void | InstallerUserResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'getInstallersResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const installerApi = InstallersApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return installerApi.apiV1InstallersGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
            },            
            onOk: (data: InstallerUserResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async createInstaller(model: InstallerUserRequest): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'createInstallerResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const installerApi = InstallersApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return installerApi.apiV1InstallersPost(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{},
                body: model,
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: (err, msg) => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async updateInstallerDates(id: string, model: InstallerUserDatesUpdateRequest): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'updateInstallerDatesResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const installerApi = InstallersApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return installerApi.apiV1InstallersIdPut(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ id: id },
                body: model,
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: (err, msg) => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async resendEmail(id: string): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'resendEmailResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const installerApi = InstallersApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return installerApi.apiV1InstallersIdEmailGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ id: id },
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: (err, msg) => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }
}

export const InstallersService = new InstallersFreezerService();
export type IInstallersServiceInjectedProps = ReturnType<
    InstallersFreezerService['getPropsForInjection']
>;