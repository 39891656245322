import { Badge } from 'MaterialUIComponents';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { React } from 'Imports';
import { RolesEnum, canEditByRole } from '$Externals/VerifyRole';
import { StyledIconButton } from '$Components/Shared/MaterialUIComponents/StyledIconButton';
import { UserResponse } from '$Generated/api';
import * as _ from 'lodash';
interface IFlagButtonProps {
    eventId: number | undefined;
    flaggedByUsers: Array<UserResponse>;
    onFlagVideoEvent: (eventId: number | undefined) => void;
    customBadgeClass?: string;
    customActiveClass?: string;
    widthOverride?: string;
    heightOverride?: string;
}

const styles = require('$Components/Shared/FlagButton.scss') as {
    active: string;
    badge: string;
    disabledByRoleContainer: string;
    flagButton: string;
    flagIcon: string;
};

export const FlagButton = (props: IFlagButtonProps) => {
    let canEdit = false;

    const onFlagEvent = async (e: any): Promise<void> => {
        e.stopPropagation();
        return await props.onFlagVideoEvent(props.eventId);
    };

    const isFlaggedByCurrentUser = (): boolean => {
        return (
            _.find(props.flaggedByUsers, (user: UserResponse) => {
                return user.integrationProviderSourceId === IntegrationPartnerDataService.getState().currentUserResult.data.id;
            }) !== undefined
        );
    };

    const eventFlaggedByOtherUsersList = _.filter(props.flaggedByUsers, (user: UserResponse) => {
        return user.integrationProviderSourceId !== IntegrationPartnerDataService.getState().currentUserResult.data.id;
    });

    const getComplementaryDescription = () => {
        const numberFlaggedByOthers = eventFlaggedByOtherUsersList.length;
        const singularDescription = (isFlaggedByCurrentUser: boolean) => (isFlaggedByCurrentUser ? 'other' : 'person');
        const pluralDescription = (isFlaggedByCurrentUser: boolean) => (isFlaggedByCurrentUser ? 'others' : 'people');
        const description = (numberFlaggedBy: number, isFlaggedByCurrentUser: boolean) =>
            numberFlaggedBy > 1 ? pluralDescription(isFlaggedByCurrentUser) : singularDescription(isFlaggedByCurrentUser);
        return `${numberFlaggedByOthers} ${description(numberFlaggedByOthers, isFlaggedByCurrentUser())}`;
    };

    const getDescription = (): string | undefined => {
        let description = undefined;
        if (props.flaggedByUsers.length > 0) {
            if (isFlaggedByCurrentUser()) {
                description = 'Flagged by you';
                if (eventFlaggedByOtherUsersList.length > 0) {
                    description += ` and ${getComplementaryDescription()}`;
                }
            } else {
                description = `Flagged by ${getComplementaryDescription()}`;
            }
        }

        return description;
    };

    canEdit = canEditByRole([RolesEnum.videoEventEdit]);
    const description = getDescription();

    return (
        <div
            className={
                !canEdit
                    ? styles.disabledByRoleContainer
                    : isFlaggedByCurrentUser()
                    ? [styles.active, props.customActiveClass || ''].join(' ')
                    : ''
            }
        >
            <Badge
                classes={{ badge: [styles.badge, props.customBadgeClass || ''].join(' ') }}
                badgeContent=""
                variant="dot"
                invisible={eventFlaggedByOtherUsersList.length === 0 || (props.flaggedByUsers.length === 1 && isFlaggedByCurrentUser())}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <StyledIconButton
                    icon={faFlag}
                    iconStyles={styles.flagIcon}
                    className={styles.flagButton}
                    title={description ? description : 'Not Flagged'}
                    isDisabled={!canEdit || false}
                    handleOnClick={onFlagEvent}
                    heightOverride={props.heightOverride}
                    widthOverride={props.widthOverride}
                ></StyledIconButton>
            </Badge>
        </div>
    );
};
