import { _, FreezerService } from 'Imports';
import { VideoEventFilterMultiselectRequest, VideoEventWorkflowStatusIdEnum } from '$Generated/api';
import { getVideoEventStatusDefaults } from '$Utilities/videoEventStatusUtility';

type IFilterState = VideoEventFilterMultiselectRequest;

const InjectedPropName = 'videoEventFilterMultiselect';

class VideoEventFilterFreezerServiceMultiselect extends FreezerService<IFilterState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                eventTypes: [],
                driverNames: [],
                startDate: new Date(new Date().toDateString()), // Creates date in client timezone without time
                endDate: new Date(new Date().toDateString()), // Creates date in client timezone without time
                vehicleIds: [],
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'date',
                sortAsc: false,
                showFlaggedOnly: false,
                showFlaggedByUserOnly: false,
                showVideoPipelineSuccessOnly: true,
                status: getVideoEventStatusDefaults(),
            },
            InjectedPropName,
        );
    }

    public getVideoEventFilter(): IFilterState {
        //clone so that the object can be manipulated by the caller
        //Otherwise the state object is immutable(read-only)
        return _.clone(this.getState());
    }

    public setPageNumber(pageNumber: number): void {
        this.freezer.get().set({ currentPage: pageNumber });
    }

    public setSortFields(sortBy: string, sortAsc: boolean): void {
        this.freezer.get().set({ sortBy: sortBy, sortAsc: sortAsc });
    }

    public toggleShowOnlySuccessfulVideoEvents() {
        this.freezer.get().showVideoPipelineSuccessOnly;
        this.freezer.get().set({ showVideoPipelineSuccessOnly: !this.freezer.get().showVideoPipelineSuccessOnly });
    }

    public updateFilterOptions(
        eventTypeIds?: number[],
        selectedDriverIds?: string[],
        selectedVehicleIds?: string[],
        startDate?: Date,
        endDate?: Date,
        showFlaggedOnly?: boolean,
        showFlaggedByUserOnly?: boolean,
        status?: VideoEventWorkflowStatusIdEnum[],
        groupsFilter?: string,
    ): void {
        const currentFilter = this.getState();

        const updatedFilter: IFilterState = {
            eventTypes: eventTypeIds,
            driverNames: selectedDriverIds,
            vehicleIds: selectedVehicleIds,
            startDate: startDate,
            endDate: endDate,
            currentPage: 1, //reset to 1 after changing filters
            itemsPerPage: 25,
            sortAsc: currentFilter.sortAsc,
            sortBy: currentFilter.sortBy,
            showFlaggedOnly: showFlaggedOnly,
            showFlaggedByUserOnly: showFlaggedByUserOnly,
            status: status,
            groupsFilter: groupsFilter,
        };

        this.freezer.get().set(updatedFilter);
    }
}

export const VideoEventFilterServiceMultiselect = new VideoEventFilterFreezerServiceMultiselect();
export type IVideoEventFilterServiceMultiselectInjectedProps = ReturnType<
    VideoEventFilterFreezerServiceMultiselect['getPropsForInjection']
>;
