import { React } from '../../../../Imports';

const styles = require('./VideoActions.scss') as {
    button: string;
    buttons: string;
};

interface VideoActionsProps {
    actions: React.ReactNode[];
}

const _VideoActions: React.FC<VideoActionsProps> = ({ actions }) => {
    return (
        <div className={styles.buttons}>
            {actions.map((Action, index) => (
                <div key={`video-action-${index}`} className={styles.button}>
                    {Action}
                </div>
            ))}
        </div>
    );
};

export const VideoActions = _VideoActions;
