import { React, bind, moment, phone } from 'Imports';

import {
    TableBody,
    TableCell,
    TableHead,
    Table,
    TableRow,
    TableSortLabel,
    Button,
    Card,
    FormControlLabel,
    IconButton,
    Popover,
    Alert,
    Tooltip,
    styled,
} from 'MaterialUIComponents';
import { TooltipProps, tooltipClasses, type AlertColor } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faXmark, faEllipsisH } from '@fortawesome/pro-solid-svg-icons';

import { PageHeader } from '$Components/Shared/PageHeader';
import { filterPageData } from '$Components/Shared/Paginator';
import * as DateFormatter from '$Components/Shared/DateFormatter';
import { IInstallersServiceInjectedProps, InstallersService } from '$State/InstallersFreezerService';

import { EditInstallerDialog } from './EditInstallerDialog';
import { InstallerUserDatesUpdateRequest, InstallerUserResponse, InstallerUserRequest } from '$Generated/api';
import * as scssStyles from '../../css/settings.scss';
import StyledSwitch from '$Components/Shared/MaterialUIComponents/StyledSwitch';
import { TermsOfUse } from '$Components/Shared/TermsOfUse';
import { PageHeader_Split_Off } from '$Components/Shared/PageHeader_Split_Off';
import { TypedSplitTreatments, splitTreatmentNames, getSplitUserAttributes, isSplitTreatmentOn } from '$Utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';

const InfiniteScroll: any = require('react-infinite-scroller');

interface IInstallersBaseProps {}

type IInstallersProps = IInstallersBaseProps & IInstallersServiceInjectedProps;
interface IInstallersState {
    disableEmail: boolean;
    includeInactive: boolean;
    sort: string;
    sortOrder: 'asc' | 'desc';
    currentPageNumber: number;
    pageItems: InstallerUserResponse[];
    allItems: InstallerUserResponse[];
    selectedInstaller?: InstallerUserResponse;
    showEditDialog: boolean;
    editErrorMessage: string;
    displayEmailPopover: boolean;
    emailPopoverMessage: string;
    emailPopoverSeverity: AlertColor;
    deactivate: boolean;
    isOpen: number;
}

const styles = require('./Installers.scss') as {
    main: string;
    buttonContainer: string;
    addButton: string;
    card: string;
    sortLabel: string;
    pickerContent: string;
    pickerLabel: string;
    select: string;
    scrollWindow: string;
    installerTable: string;
    headerRow: string;
    bodyRow: string;
    bodyCellIcon: string;
    inactiveButton: string;
    buttonContainerEncompass: string;
    headerCell: string;
    bodyCell: string;
    encIconButtonActive: string;
    encIconButton: string;
    encEllipseIcon: string;
    iconButton: string;
    iconButtonActive: string;
    toolTipButton: string;
    ellipseIcon: string;
    ellipseIconActive: string;
    ellipsisAlign: string;
    disabledButton: string;
};

const pageItemCount = 20;

const rowHeaders = [
    {
        id: 'userName',
        label: 'Name',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'phoneNumber',
        label: 'Phone Number',
    },
    {
        id: 'startDate',
        label: 'Start Date',
    },
    {
        id: 'endDate',
        label: 'End Date',
    },
    {
        id: 'isActive',
        label: 'Status',
    },
];
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -14],
                        },
                    },
                ],
            },
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        right: scssStyles.styleEnvironment === 'encompass' ? '-45px' : 'unset',
        position: 'relative',
        backgroundColor: '#fff',
        color: '#000',
        boxShadow: theme.shadows[1],
        padding: '0',
        aligntText: 'center',
        borderRadius: '4px',
    },
}));
class _Installers extends React.Component<IInstallersProps, IInstallersState> {
    state: IInstallersState = {
        disableEmail: false,
        includeInactive: false,
        sort: 'startDate',
        sortOrder: 'asc',
        currentPageNumber: 1,
        pageItems: [],
        allItems: [],
        selectedInstaller: undefined,
        showEditDialog: false,
        editErrorMessage: '',
        displayEmailPopover: false,
        emailPopoverMessage: '',
        emailPopoverSeverity: 'info',
        deactivate: false,
        isOpen: -1,
    };

    private scrollParentRef2: any;
    private headerRef = React.createRef<HTMLSpanElement>();

    async componentDidMount(): Promise<void> {
        await this.loadInstallersList();
    }

    async loadInstallersList(): Promise<void> {
        await this.props.installers.getInstallers();

        this.setPagedItems(this.state.includeInactive);
    }

    setPagedItems(includeInactive: boolean): void {
        const now = moment.utc();
        const freezer = this.props.installers.getState();
        const { getInstallersResults } = freezer;

        const items = getInstallersResults.data ? getInstallersResults.data : [];
        const filteredItems = items.filter((x) => {
            return includeInactive || (now.isSameOrAfter(moment.utc(x.startDate)) && now.isSameOrBefore(moment.utc(x.endDate)));
        });

        this.setState({
            pageItems: filterPageData(1, pageItemCount, filteredItems),
            allItems: filteredItems,
            currentPageNumber: 1,
        });
    }

    handleRequestSort(field: string): void {
        const { sort, sortOrder, allItems } = this.state;
        const isAsc = sort === field && sortOrder === 'asc';

        const sortItems = allItems.sort((a, b) => {
            let res = 0;
            let aString = '';
            let bString = '';
            switch (field) {
                case 'userName':
                    aString = `${a.firstName} ${a.lastName}`;
                    bString = `${b.firstName} ${b.lastName}`;
                    res = aString.localeCompare(bString);
                    break;
                case 'email':
                case 'phoneNumber':
                    aString = a[field] ? a[field]! : '';
                    bString = b[field] ? b[field]! : '';
                    res = aString.localeCompare(bString);
                    break;
                case 'startDate':
                case 'endDate':
                    const aDate = a[field] ? moment(a[field]) : moment();
                    const bDate = b[field] ? moment(b[field]) : moment();
                    const dateDiff = aDate.valueOf() - bDate.valueOf();
                    res = dateDiff > 0 ? 1 : dateDiff < 0 ? -1 : 0;
                    break;
                case 'isActive':
                    const now = moment();
                    const isActiveA = (a.startDate && a.endDate && now.isBetween(a.startDate, a.endDate)) || false;
                    const isActiveB = (b.startDate && b.endDate && now.isBetween(b.startDate, b.endDate)) || false;
                    res = isActiveA === isActiveB ? 0 : isActiveA ? -1 : 1;
                    break;
                default:
            }
            return isAsc ? -1 * res : res;
        });

        this.setState({
            sort: field,
            sortOrder: isAsc ? 'desc' : 'asc',
            pageItems: filterPageData(1, pageItemCount, sortItems),
            allItems: sortItems,
            currentPageNumber: 1,
        });
    }

    @bind
    getPage(page: number): void {
        const nextPage = this.state.currentPageNumber + 1;
        this.setState({
            pageItems: this.state.pageItems.concat(filterPageData(nextPage, pageItemCount, this.state.allItems)),
            currentPageNumber: nextPage,
        });
    }

    @bind
    handleInactiveToggle(): void {
        this.setState({
            includeInactive: !this.state.includeInactive,
        });

        this.setPagedItems(!this.state.includeInactive);
    }

    @bind
    handleNoPopupOnDeactivate(): void {
        this.setState({
            deactivate: true,
        });
    }

    @bind
    toggleEditDialog(installer?: InstallerUserResponse): void {
        this.setState({
            editErrorMessage: '',
            showEditDialog: !this.state.showEditDialog,
            selectedInstaller: installer,
        });
    }

    @bind
    async onSaveInstaller(installer: InstallerUserRequest): Promise<boolean> {
        try {
            if (!installer.id) {
                if (!this.state.deactivate) {
                    this.setState(
                        {
                            disableEmail: true,
                            displayEmailPopover: true,
                            emailPopoverMessage: 'Sending Email',
                            emailPopoverSeverity: 'info',
                        },
                        () => setTimeout(this.handlePopoverClose, 5000),
                    );

                    this.executeDisableEmailTimer();
                }

                await this.props.installers.createInstaller(installer);

                if (!this.state.deactivate) {
                    this.setState(
                        {
                            displayEmailPopover: true,
                            emailPopoverMessage: 'Email sent successfully!',
                            emailPopoverSeverity: 'success',
                        },
                        () => setTimeout(this.handlePopoverClose, 5000),
                    );
                }
            } else {
                // only send start date if it was changed
                const updateModel: InstallerUserDatesUpdateRequest = {
                    startDate: this.state.selectedInstaller?.startDate !== installer.startDate ? installer.startDate : undefined,
                    endDate: installer.endDate,
                };

                if (!this.state.deactivate) {
                    this.setState(
                        {
                            disableEmail: true,
                            displayEmailPopover: true,
                            emailPopoverMessage: 'Sending Email',
                            emailPopoverSeverity: 'info',
                        },
                        () => setTimeout(this.handlePopoverClose, 5000),
                    );

                    this.executeDisableEmailTimer();
                }
                await this.props.installers.updateInstallerDates(installer.id, updateModel);

                if (
                    !this.state.deactivate &&
                    (updateModel.startDate !== undefined || this.state.selectedInstaller?.endDate !== installer.endDate)
                ) {
                    this.setState(
                        {
                            displayEmailPopover: true,
                            emailPopoverMessage: 'Email sent successfully!',
                            emailPopoverSeverity: 'success',
                        },
                        () => setTimeout(this.handlePopoverClose, 5000),
                    );
                }
            }

            await this.loadInstallersList();

            this.toggleEditDialog();
            return true;
        } catch (ex) {
            ex.text()
                .then(function (text: string) {
                    return text;
                })
                .then((error: string) => {
                    this.setState({ editErrorMessage: error });
                });
        } finally {
            this.setState({
                deactivate: false,
            });
            return false;
        }
    }

    @bind
    onSendEmail(installer: InstallerUserResponse) {
        if (!this.state.disableEmail) {
            this.setState(
                {
                    disableEmail: true,
                    displayEmailPopover: true,
                    emailPopoverMessage: 'Sending Email',
                    emailPopoverSeverity: 'info',
                },
                () => setTimeout(this.handlePopoverClose, 5000),
            );

            this.executeDisableEmailTimer();
            this.props.installers.resendEmail(installer.id!);

            this.setState(
                {
                    displayEmailPopover: true,
                    emailPopoverMessage: 'Email sent successfully!',
                    emailPopoverSeverity: 'success',
                },
                () => setTimeout(this.handlePopoverClose, 5000),
            );
        } else {
            this.setState(
                {
                    displayEmailPopover: true,
                    emailPopoverMessage: 'Email sent in past 30 seconds.  Please wait.',
                    emailPopoverSeverity: 'error',
                },
                () => setTimeout(this.handlePopoverClose, 5000),
            );
        }
    }

    executeDisableEmailTimer = () => {
        setTimeout(() => {
            this.setState({ disableEmail: false });
        }, 5000);
    };

    handlePopoverClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway' || reason === 'backdropClick') {
            return;
        }

        this.setState({
            displayEmailPopover: false,
        });
    };

    render(): JSX.Element {
        const { showEditDialog, pageItems, selectedInstaller, editErrorMessage, includeInactive, disableEmail } = this.state;
        const now = moment();
        const scssEnvironment = scssStyles.styleEnvironment;
        return (
            <div>
                <div className={styles.main}>
                    <span ref={this.headerRef}>
                        <TermsOfUse />
                        <TypedSplitTreatments names={[splitTreatmentNames.videoEventHeaderVerbiage]} attributes={getSplitUserAttributes()}>
                            {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                return (isReady || isTimedout) &&
                                    isSplitTreatmentOn(treatments[splitTreatmentNames.videoEventHeaderVerbiage]) ? (
                                    <PageHeader pageTitle={'Installers'} />
                                ) : (
                                    <PageHeader_Split_Off pageTitle={'Installers'} />
                                );
                            }}
                        </TypedSplitTreatments>
                    </span>

                    <div className={scssEnvironment == 'encompass' ? styles.buttonContainerEncompass : styles.buttonContainer}>
                        <Popover
                            open={this.state.displayEmailPopover}
                            onClose={this.handlePopoverClose}
                            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                            transformOrigin={{ horizontal: 'center', vertical: 'center' }}
                            anchorEl={this.headerRef.current}
                        >
                            <Alert onClose={this.handlePopoverClose} severity={this.state.emailPopoverSeverity} variant="filled">
                                {this.state.emailPopoverMessage}
                            </Alert>
                        </Popover>

                        <FormControlLabel
                            value="Include Inactive"
                            control={
                                <StyledSwitch
                                    name="includeInactive"
                                    title="Include Inactive Installers"
                                    checked={includeInactive}
                                    onChange={this.handleInactiveToggle}
                                />
                            }
                            label="Include Inactive"
                            labelPlacement="top"
                            {...(scssEnvironment === 'encompass' && { className: styles.inactiveButton })}
                        />

                        <Button onClick={() => this.toggleEditDialog(undefined)} className={styles.addButton}>
                            Add
                        </Button>
                    </div>
                    {scssEnvironment === 'encompass' ? (
                        <div className={styles.scrollWindow} ref={(ref): any => (this.scrollParentRef2 = ref)}>
                            <InfiniteScroll
                                pageStart={this.state.currentPageNumber}
                                loadMore={this.getPage}
                                hasMore={this.state.pageItems.length < this.state.allItems.length}
                                loader={
                                    <div className="loader" key={0}>
                                        Loading ...
                                    </div>
                                }
                                useWindow={false}
                                getScrollParent={(): void => this.scrollParentRef2}
                            >
                                <Table className={styles.installerTable}>
                                    <TableHead>
                                        <TableRow className={styles.headerRow}>
                                            {rowHeaders.map((head) => {
                                                return (
                                                    <TableCell
                                                        key={head.id}
                                                        className={styles.headerCell}
                                                        sortDirection={this.state.sort === head.id ? this.state.sortOrder : false}
                                                    >
                                                        <TableSortLabel
                                                            active={this.state.sort === head.id}
                                                            direction={this.state.sortOrder}
                                                            onClick={(): void => this.handleRequestSort(head.id)}
                                                            className={styles.headerCell}
                                                        >
                                                            {head.label}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pageItems.map((installer: InstallerUserResponse, idx) => {
                                            const startDate = installer.startDate ? moment(installer.startDate) : null;
                                            const endDate = installer.endDate ? moment(installer.endDate) : null;
                                            const isActive = startDate && endDate && now.isBetween(startDate, endDate);

                                            return (
                                                <TableRow key={idx} className={styles.bodyRow}>
                                                    <TableCell className={styles.bodyCell}>
                                                        {installer.firstName} {installer.lastName}
                                                    </TableCell>
                                                    <TableCell className={styles.bodyCell}>{installer.email}</TableCell>
                                                    <TableCell className={styles.bodyCell}>
                                                        {installer.phoneNumber ? phone.formatPhoneNumber(installer.phoneNumber) : ''}
                                                    </TableCell>
                                                    <TableCell className={styles.bodyCell}>
                                                        {startDate ? DateFormatter.date(startDate) : ''}
                                                    </TableCell>
                                                    <TableCell className={styles.bodyCell}>
                                                        {endDate ? DateFormatter.date(endDate) : ''}
                                                    </TableCell>
                                                    <TableCell className={styles.bodyCell}>{isActive ? 'Active' : 'Inactive'}</TableCell>
                                                    <TableCell className={styles.bodyCell}>
                                                        <div className={styles.ellipsisAlign}>
                                                            <StyledTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <div
                                                                            className={styles.toolTipButton}
                                                                            onClick={() =>
                                                                                !disableEmail && this.toggleEditDialog(installer)
                                                                            }
                                                                        >
                                                                            Edit
                                                                        </div>
                                                                        {isActive && (
                                                                            <div
                                                                                className={
                                                                                    disableEmail
                                                                                        ? styles.disabledButton
                                                                                        : styles.toolTipButton
                                                                                }
                                                                                onClick={() => !disableEmail && this.onSendEmail(installer)}
                                                                            >
                                                                                Send Email
                                                                            </div>
                                                                        )}
                                                                    </React.Fragment>
                                                                }
                                                                placement="left-start"
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                open={this.state.isOpen === idx}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                            >
                                                                <div>
                                                                    {' '}
                                                                    <IconButton
                                                                        className={
                                                                            this.state.isOpen === idx
                                                                                ? styles.encIconButtonActive
                                                                                : styles.encIconButton
                                                                        }
                                                                        onClick={() => {
                                                                            this.setState({ isOpen: this.state.isOpen === idx ? -1 : idx });
                                                                        }}
                                                                        disableRipple={true}
                                                                        disableFocusRipple={true}
                                                                        size={'small'}
                                                                    >
                                                                        {this.state.isOpen !== idx ? (
                                                                            <FontAwesomeIcon
                                                                                className={styles.encEllipseIcon}
                                                                                icon={faEllipsisH}
                                                                            />
                                                                        ) : (
                                                                            <FontAwesomeIcon
                                                                                className={styles.ellipseIconActive}
                                                                                icon={faXmark}
                                                                                style={{ color: 'white' }}
                                                                            />
                                                                        )}
                                                                    </IconButton>
                                                                </div>
                                                            </StyledTooltip>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </InfiniteScroll>
                        </div>
                    ) : (
                        <Card>
                            <div className={styles.scrollWindow} ref={(ref): any => (this.scrollParentRef2 = ref)}>
                                <InfiniteScroll
                                    pageStart={this.state.currentPageNumber}
                                    loadMore={this.getPage}
                                    hasMore={this.state.pageItems.length < this.state.allItems.length}
                                    loader={
                                        <div className="loader" key={0}>
                                            Loading ...
                                        </div>
                                    }
                                    useWindow={false}
                                    getScrollParent={(): void => this.scrollParentRef2}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {rowHeaders.map((head) => {
                                                    return (
                                                        <TableCell
                                                            key={head.id}
                                                            sortDirection={this.state.sort === head.id ? this.state.sortOrder : false}
                                                            style={{ lineHeight: '1.2em' }}
                                                        >
                                                            <TableSortLabel
                                                                active={this.state.sort === head.id}
                                                                direction={this.state.sortOrder}
                                                                onClick={(): void => this.handleRequestSort(head.id)}
                                                                className={styles.sortLabel}
                                                            >
                                                                {head.label}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    );
                                                })}
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pageItems.map((installer: InstallerUserResponse, idx) => {
                                                const startDate = installer.startDate ? moment(installer.startDate) : null;
                                                const endDate = installer.endDate ? moment(installer.endDate) : null;
                                                const isActive = startDate && endDate && now.isBetween(startDate, endDate);

                                                return (
                                                    <TableRow key={idx}>
                                                        <TableCell>
                                                            {installer.firstName} {installer.lastName}
                                                        </TableCell>
                                                        <TableCell>{installer.email}</TableCell>
                                                        <TableCell>
                                                            {installer.phoneNumber ? phone.formatPhoneNumber(installer.phoneNumber) : ''}
                                                        </TableCell>
                                                        <TableCell>{startDate ? DateFormatter.date(startDate) : ''}</TableCell>
                                                        <TableCell>{endDate ? DateFormatter.date(endDate) : ''}</TableCell>
                                                        <TableCell>{isActive ? 'Active' : 'Inactive'}</TableCell>
                                                        <TableCell>
                                                            <StyledTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <div
                                                                            className={styles.toolTipButton}
                                                                            onClick={() =>
                                                                                !disableEmail && this.toggleEditDialog(installer)
                                                                            }
                                                                        >
                                                                            Edit
                                                                        </div>
                                                                        {isActive && (
                                                                            <div
                                                                                className={
                                                                                    disableEmail
                                                                                        ? styles.disabledButton
                                                                                        : styles.toolTipButton
                                                                                }
                                                                                onClick={() => !disableEmail && this.onSendEmail(installer)}
                                                                            >
                                                                                Send Email
                                                                            </div>
                                                                        )}
                                                                    </React.Fragment>
                                                                }
                                                                placement="left-start"
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                open={this.state.isOpen === idx}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                            >
                                                                <IconButton
                                                                    className={
                                                                        this.state.isOpen === idx
                                                                            ? styles.iconButtonActive
                                                                            : styles.iconButton
                                                                    }
                                                                    onClick={() => {
                                                                        this.setState({ isOpen: this.state.isOpen === idx ? -1 : idx });
                                                                    }}
                                                                    disableRipple={true}
                                                                    disableFocusRipple={true}
                                                                    size={'small'}
                                                                >
                                                                    {this.state.isOpen !== idx ? (
                                                                        <FontAwesomeIcon
                                                                            className={styles.ellipseIcon}
                                                                            icon={faEllipsisV}
                                                                        />
                                                                    ) : (
                                                                        <FontAwesomeIcon
                                                                            className={styles.ellipseIconActive}
                                                                            icon={faXmark}
                                                                            style={{ color: 'white' }}
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </StyledTooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </InfiniteScroll>
                            </div>
                        </Card>
                    )}
                </div>

                {showEditDialog && (
                    <EditInstallerDialog
                        errorMessage={editErrorMessage}
                        onSave={this.onSaveInstaller}
                        onCancel={() => this.toggleEditDialog(undefined)}
                        onDeactivate={this.handleNoPopupOnDeactivate}
                        installer={selectedInstaller}
                    />
                )}
            </div>
        );
    }
}

export const Installers = InstallersService.inject(_Installers);
