import { React } from 'Imports';
import { MenuItem } from 'MaterialUIComponents';
import { ClickAwayListener, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import * as scssStyles from '$CSS/settings.scss';
import { isEncompass } from '$Utilities/integrationPlatform';

const styles = require('$Pages/VideoEvents/components/DropdownSearchField.scss') as {
    searchBar: string;
};

interface IDropdownSearchFieldProps {
    onChange: (value: string) => void;
    searchFilter: string;
    onSearchFieldFocus: () => void;
    onSearchFieldFocusLost: () => void;
}

const DropdownSearchField = (props: IDropdownSearchFieldProps) => {
    const [searchFieldHasFocus, setSearchFieldHasFocus] = useState(false);

    const isFiltering = props.searchFilter.length > 0;
    const showSearchIcon = !isFiltering;
    const showNoAdornments = searchFieldHasFocus && !isFiltering;

    const baseInputProps = {
        sx: {
            textAlign: 'left',
            height: '28px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    };

    const notFilteringInputProps = {
        ...baseInputProps,

        startAdornment: (
            <InputAdornment position="start">
                <FontAwesomeIcon icon={faSearch} color={scssStyles.customColor2} />
            </InputAdornment>
        ),
    };

    const filteringInputProps = {
        ...baseInputProps,
        sx: {
            ...baseInputProps.sx,
            cursor: 'pointer',
        },
        endAdornment: (
            <InputAdornment position="end" onClick={() => props.onChange('')}>
                <FontAwesomeIcon icon={faXmark} color={scssStyles.searchFieldXmarkColor} />
            </InputAdornment>
        ),
    };

    const getInputProps = () => {
        if (isEncompass) {
            if (showNoAdornments) {
                return baseInputProps;
            } else if (showSearchIcon) {
                return notFilteringInputProps;
            } else if (isFiltering) {
                return filteringInputProps;
            } else {
                return baseInputProps;
            }
        } else {
            if (isFiltering) {
                return filteringInputProps;
            } else {
                return baseInputProps;
            }
        }
    };

    return (
        <MenuItem
            onKeyDown={(e) => e.stopPropagation()}
            disableRipple={true}
            sx={{
                '&.Mui-focusVisible': {
                    backgroundColor: 'transparent',
                },
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                paddingLeft: '8px',
                paddingRight: '8px',
            }}
        >
            <ClickAwayListener onClickAway={() => setSearchFieldHasFocus(false)}>
                <TextField
                    variant="outlined"
                    fullWidth
                    placeholder={searchFieldHasFocus ? '' : 'Search ...'}
                    value={props.searchFilter}
                    onChange={(e) => props.onChange(e.target.value)}
                    onFocus={() => {
                        setSearchFieldHasFocus(true);
                        props.onSearchFieldFocus();
                    }}
                    onBlur={() => {
                        setSearchFieldHasFocus(false);
                        props.onSearchFieldFocusLost();
                    }}
                    focused={searchFieldHasFocus}
                    className={styles.searchBar}
                    InputProps={getInputProps()}
                    sx={{
                        fontWeight: 400,
                        fontSize: isEncompass ? '14px' : '12px',
                        fieldset: {
                            border: searchFieldHasFocus
                                ? isEncompass
                                    ? '1px solid #0D7AC5 !important'
                                    : '1px solid #25477B !important'
                                : isEncompass
                                  ? '1px solid #C4CBD4 !important'
                                  : '1px solid #C4CBD7 !important',
                        },
                    }}
                />
            </ClickAwayListener>
        </MenuItem>
    );
};

export default DropdownSearchField;
