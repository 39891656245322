import { React } from 'Imports';
import { Link } from 'MaterialUIComponents';
import { ConfigService } from '$State/ConfigFreezerService';

const styles = require('./EventNotificationsListDescription.scss') as {
    eventNotificationListDescriptionGeotab: string;
    eventNotificationListDescriptionEncompass: string;
};

const supportEmailAddress = 'supportvideoprotects@jjkeller.com';
const mailtoSupportEmailAddress = 'mailto:' + supportEmailAddress;

export class EventNotificationsListDescription extends React.Component {
    render(): JSX.Element {
        const integrationPlatformStyle = ConfigService.isIntegrationPlatformEncompass()
            ? styles.eventNotificationListDescriptionEncompass
            : styles.eventNotificationListDescriptionGeotab;

        return (
            <div className={integrationPlatformStyle}>
                Turn event notifications on/off for email and text. Changing notification statuses on this page will NOT turn on/off the
                events on the camera.
                <br />
                To confirm which events are enabled, contact{' '}
                <Link target={'_blank'} href={mailtoSupportEmailAddress} underline="always" color="inherit">
                    {supportEmailAddress}
                </Link>
                .
            </div>
        );
    }
}
