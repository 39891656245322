import { _, FreezerService, IAjaxState, managedAjaxUtil } from "Imports";
import { DriverNotificationApiFactory, DriverCommentResponse, DriverCommentApiFactory } from "$Generated/api";
import { UserManagementService } from '$State/UserManagementFreezerService';

const InjectedPropName = "driverNotifications";

interface IDriverNotificationState {
    textResults: IAjaxState<any>;
    emailResults: IAjaxState<any>;
    driverCommentResults: IAjaxState<DriverCommentResponse[]>;
    hasError: boolean;
}

class DriverNotificationFreezerService extends FreezerService<IDriverNotificationState, typeof InjectedPropName> {
  constructor() {
    super({
      textResults: managedAjaxUtil.createInitialState(),
      emailResults: managedAjaxUtil.createInitialState(),
      driverCommentResults: managedAjaxUtil.createInitialState(),
      hasError: false
    }, InjectedPropName);
  }

  public async sendText(videoEventId: number, phoneNumber: string, timezoneOffsetHrs: number, timezoneName: string): Promise<void | any> {
    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "textResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params) => {
        if (params === undefined) {
          throw new Error("Parameters is undefined");
        }

        const VideoEventApi = DriverNotificationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return VideoEventApi.apiV1SenddrivertextPost(params, apiOptions.fetchOptions);
      },
      params: {
        ...UserManagementService.generateIntegrationUserParams(),
        ...{},
        body: {
          videoEventId,
          phoneNumber,
          timezoneOffsetHrs,
          timezoneName
        },
      },
      onOk: (data: any) => {
        return data;
      },
      onError: () => {

      }
    });
  }

  public async sendEmail(videoEventId: number, emailAddress: string, timezoneOffsetHrs: number, timezoneName: string): Promise<void | any> {
    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "emailResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params) => {
        if (params === undefined) {
          throw new Error("Parameters is undefined");
        }

        const VideoEventApi = DriverNotificationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return VideoEventApi.apiV1SenddriveremailPost(params, apiOptions.fetchOptions);
      },
      params: {
        ...UserManagementService.generateIntegrationUserParams(),
        ...{},
        body: {
          videoEventId,
          emailAddress,
          timezoneOffsetHrs,
          timezoneName
        },
      },
      onOk: (data: any) => {
        return data;
      },
      onError: () => {
      }
    });
  }

  public async getDriverComments(eventId: number): Promise<void | DriverCommentResponse[]> {
    return managedAjaxUtil.fetchResults({
        ajaxStateProperty: 'driverCommentResults',
        freezer: this.freezer,
        onExecute: (apiOptions, params) => {
            if (params === undefined) {
                throw new Error('Parameters is undefined');
            }

            const DriverCommentApi = DriverCommentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
            return DriverCommentApi.apiV1DriverCommentsEventIdGet(params, apiOptions.fetchOptions);
        },
        params: {
          ...UserManagementService.generateIntegrationUserParams(),
          ...{ eventId: eventId },
        },
        onOk: (data: DriverCommentResponse[]) => {
            if (data !== undefined) {
                this.freezer.get().set({ hasError: false });
            }

            return data;
        },
        onError: () => {
            this.freezer.get().set({ hasError: true });
        },
    });
}
}  

export const DriverNotificationService = new DriverNotificationFreezerService();
export type IDriverNotificationServiceInjectedProps = ReturnType<DriverNotificationFreezerService["getPropsForInjection"]>;