import { React } from 'Imports';

import { IPricingPlanServiceInjectedProps, PricingPlanService } from "$State/PricingPlanFreezerService";
import { PricingPlanFeatureTypeEnum } from '$Generated/api';

interface IFeatureToggleHOCProps {
    disabled?: boolean;
}

interface IFeatureToggleHOCState {
    disabled: boolean;
}

export function withFeatureToggle<P extends IFeatureToggleHOCProps>(WrappedComponent: typeof React.PureComponent, requiredFeature: PricingPlanFeatureTypeEnum) {
    return PricingPlanService.inject(class extends React.PureComponent<P & IPricingPlanServiceInjectedProps, IFeatureToggleHOCState, any> {
        state: IFeatureToggleHOCState = {
            disabled: false
        };

        async componentDidMount(): Promise<void> {
            if(!(await this.props.pricingPlan.hasFeatureType(requiredFeature))) {
                this.setState({ disabled: true });
            }
        }

        render() {
            const { disabled } = this.state;

            // don't want either the pricing plan service _or_ disabled (prop, not state) to be passed through
            const { pricingPlan: _, disabled: _2, ...passThroughProps } = this.props;

            return <WrappedComponent disabled={disabled} {...passThroughProps} />;
        }
    });
}
