import { DriverNotification } from '../../../../VideoEvents/VideoEventDetails/DriverNotification';
import { React } from '../../../../../Imports';
import { Section } from '../Section';
import { VideoEventResponse } from '$Generated/api';

interface SendToDriverSectionProps {
    canEdit: boolean;
    driverEmail: string;
    driverPhoneNumber: string;
    event: VideoEventResponse;
    onStatusChange: (status: string) => void;
    prevNextSplitOn: boolean;
    updateVideoEventHistory: () => void;
}

const _SendToDriverSection: React.FC<SendToDriverSectionProps> = ({
    canEdit,
    driverEmail,
    driverPhoneNumber,
    event,
    onStatusChange,
    prevNextSplitOn,
    updateVideoEventHistory,
}) => {
    const handleSendNotification = (): void => {
        if (!canEdit) return;

        onStatusChange('Sent_To_Driver');
        updateVideoEventHistory();
    };

    return (
        <div style={{ marginTop: '16px' }}>
            <Section
                title="Send To Driver"
                canEdit={canEdit}
                content={
                    <DriverNotification
                        event={event}
                        handleSendNotification={handleSendNotification}
                        driverEmail={driverEmail}
                        driverPhoneNumber={driverPhoneNumber}
                        prevNextSplitOn={prevNextSplitOn}
                    />
                }
            />
        </div>
    );
};

export const SendToDriverSection = _SendToDriverSection;
