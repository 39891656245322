import { React } from 'Imports';
import { Card, CardContent, Table, TableBody, TableCell, TableRow, Typography } from 'MaterialUIComponents';

import { EventStatistics } from '../../../generated/api';
import { sortBy } from 'lodash';

import { WidgetCard, WidgetCardContent, WidgetCardHeader } from '../../../components/Dashboards/StyledComponents';
import { PercentChangeTrend } from '../../../components/Shared/PercentChangeTrend';
import { getChangePercentage, getRawPercentage } from '../../../utilities/mathUtility';
import * as scssStyles from '../../../css/settings.scss';
import { useEffect, useState } from 'react';
import { WidgetTooltip } from '$Components/Dashboards/WidgetTooltip';

interface IEventStatisticsDriverData {
    name?: string;
    count?: number;
}

interface IEventStatisticsData {
    label?: string;
    count?: number;
    currentPercent?: number;
    changePercent?: number;
    drivers: IEventStatisticsDriverData[];
}

interface IEventStatisticsWidgetState {
    data: IEventStatisticsData[];
}

type EventStatisticsWidgetProps = {
    eventStatisticsRequested: EventStatistics;
    eventStatisticsPrevious: EventStatistics;
    shouldShowPercentage?: boolean;
};

const styles = require('../styles/EventStatisticsWidget.scss') as {
    windowSelector: string;
    windowSelectorContainer: string;
    windowSelectorMenu: string;
    windowSelectorItem: string;
    widgetContent: string;
    card: string;
    cardContent: string;
    header: string;
    count: string;
    percentChange: string;
    trendIcon: string;
    label: string;
    driverTable: string;
    driverName: string;
    driverCount: string;
    widgetCard: string;
    dropdownIcon: string;
};
// extends React.PureComponent<IEventStatisticsWidgetProps, IEventStatisticsWidgetState>
const _EventStatisticsWidget = (props: EventStatisticsWidgetProps) => {
    const { eventStatisticsRequested, eventStatisticsPrevious, shouldShowPercentage = true } = props;
    const [eventStatisticsData, setEventStatisticsData] = useState<IEventStatisticsWidgetState>({ data: [] });

    const aggregateEventStatistics = (currentEvents: EventStatistics, previousEvents: EventStatistics) => {
        if (!currentEvents) {
            setEventStatisticsData({ data: [] });
            return;
        }

        const environment = scssStyles.styleEnvironment;
        const currentDataset = currentEvents.data || {};
        const previousDataset = previousEvents.data || {};

        const eventStatisticsData: IEventStatisticsData[] = Object.keys(currentDataset || {}).map((value) => {
            const eventType = value;
            const currentEvent = currentDataset[eventType];
            const previousEvent = previousDataset[eventType];
            let drivers: IEventStatisticsDriverData[];

            // Collision is unique case - show only last date, not list of drivers
            if (eventType === 'CollisionDetected') {
                drivers = [
                    {
                        name: 'Unknown',
                        count: 0,
                    },
                ];
            } else {
                drivers = currentEvent.drivers!.map((x) => {
                    return {
                        name: x.name,
                        count: x.count,
                    };
                });
            }

            return {
                count: currentEvent.totalCount,
                drivers: drivers,
                label: currentEvent.label, //getEventTypeLabel(dataset.eventType!),
                currentPercent: getRawPercentage(currentEvent.totalCount || 0, currentEvents.totalCount || 0),
                changePercent: getChangePercentage(currentEvent.totalCount || 0, previousEvents.totalCount || 0),
            } as IEventStatisticsData;
        });

        setEventStatisticsData({ data: sortBy(eventStatisticsData, ['label']) });
    };

    useEffect(() => {
        aggregateEventStatistics(eventStatisticsRequested, eventStatisticsPrevious);
    }, [eventStatisticsRequested]);

    return (
        <>
            <WidgetTooltip
                prompt="How are event statistics calculated?"
                description={<div style={{width:'425px'}}>Event statistic percentages are calculated using the number of each event generated over the time period selected above. Percentages in green or red indicate the % change from the previous time period selected. Dismissed events are not included.
                    <br/><br/>
                    Example: Last 7 days compared to the 7 days before that period. If N/A, then we didn’t find a previous time period equal to the number of days selected in the time range above.</div>}
                contentPosition='bottomRight'
            />
            <WidgetCard>
                <WidgetCardHeader title="Event Statistics" />
                <WidgetCardContent className={styles.widgetContent}>
                    {eventStatisticsData.data.map((x, idx) => (
                        <Card key={idx} className={styles.card}>
                            <CardContent className={styles.cardContent}>
                                <div className={styles.header}>
                                    <Typography className={styles.count}>{x.count}</Typography>

                                    <PercentChangeTrend
                                        containerClassName={styles.percentChange}
                                        iconClassName={styles.trendIcon}
                                        value={x.changePercent}
                                        places={0}
                                        negativeQualifier="Positive"
                                        positiveQualifier="Negative"
                                        shouldShowPercentage={shouldShowPercentage}
                                    />

                                    <Typography className={styles.label}>{x.label}</Typography>
                                </div>

                                <div className={styles.driverTable}>
                                    <Table>
                                        <TableBody>
                                            {!x.count ? (
                                                <TableRow>
                                                    <TableCell className={styles.driverName}>N/A</TableCell>
                                                </TableRow>
                                            ) : x.label === 'Collision/Impact' ? (
                                                <TableRow>
                                                    <TableCell className={styles.driverName}>Last Reported</TableCell>
                                                    <TableCell className={styles.driverCount}>{x.drivers[0].name}</TableCell>
                                                </TableRow>
                                            ) : (
                                                x.drivers.map((x, idx) => (
                                                    <TableRow key={idx}>
                                                        <TableCell className={styles.driverName}>{x.name}</TableCell>
                                                        <TableCell className={styles.driverCount}>{x.count}</TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </WidgetCardContent>
            </WidgetCard>
        </>
    );
};

export const EventStatisticsWidget = _EventStatisticsWidget;
