import { React, bind } from '../../Imports';
import { VPDropdown, IDropdownItem } from '$Components/FilterComponents/VPDropdown';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService, IIntegrationPartnerDataState } from '$State/IntegrationPartnerDataFreezerService';
import { SortDropdownItems } from '../../utilities/dataModelUtilities';

interface IDriverDropdownProps {
    selectedDriverId: string;
    onSelectedDriverChange: (selectedDriver: any) => void;
    width?: number
};

interface IDriverDropdownState {
    drivers: any[];
}

class _DriverDropdown extends React.PureComponent<IDriverDropdownProps & 
IIntegrationPartnerDataInjectedProps & IDriverDropdownState> {
    state = {
        drivers: []
    };

    componentDidMount() {
        const { driversResults } = this.props.integrationPartnerData.getState();
        const drivers = driversResults.data ? driversResults.data : [];
        if (drivers.length === 0) {
            this.props.integrationPartnerData.on("update", (currentState: IIntegrationPartnerDataState) => {
                let drivers = [{ firstName: "Unknown", lastName: "Driver", id: null, email: "" }];
                drivers = drivers.concat(currentState.driversResults.data || []);
                this.setState({ drivers })
            });
            this.props.integrationPartnerData.getDrivers();
        } else {
            this.setState({drivers});
        }
    }

    @bind
    formatDriverList(drivers: any[]): IDropdownItem[] {
        const driverList: IDropdownItem[] = [];

        if (drivers !== undefined && drivers !== null) {
            for (const user of drivers.filter(dv => dv.isActive !== false)) {
                driverList.push({
                    label: user.firstName + ' ' + user.lastName,
                    value: user.id,
                });
            }
        }
        return SortDropdownItems(driverList);
    };

    @bind
    handleSelectedItemChange(selectedValue: any[]) {
        const selectedDriverId = selectedValue.length === 1 ? selectedValue[0] : '';
        const drivers = this.state.drivers;
        const selectedDriver: any = drivers.find((user: any) => user.id === selectedDriverId);

        this.props.onSelectedDriverChange(selectedDriver);
    }

    render(): JSX.Element {
        const { drivers } = this.state;

        return (
            <VPDropdown
                isAutocomplete={true}
                name="Driver"
                items={this.formatDriverList(drivers)}
                selectedValue={this.props.selectedDriverId}
                onSelectedItemChange={this.handleSelectedItemChange}
                width={this.props.width}
            />
        );
    }
}

export const DriverDropdown = IntegrationPartnerDataService.inject(
    _DriverDropdown);