import { React } from '../../../Imports';
import { Grid } from '../../../MaterialUIComponents';

const styles = require('./DetailsSection.scss') as {
    sidePanelMainGrid: string;
};

interface DetailsSectionProps {
    children: React.ReactNode;
    width: any;
}

const _DetailsSection: React.FC<DetailsSectionProps> = ({ children, width }) => {
    return (
        <Grid className={styles.sidePanelMainGrid} container>
            {children}
        </Grid>
    );
};

export const DetailsSection = _DetailsSection;
