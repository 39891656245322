import { UserManagementService } from "$State/UserManagementFreezerService";
import React = require("react");

enum RolesEnum {
  videoEventView = 5,
  videoEventEdit = 6,
  videoRecallEdit = 7,
  videoAdmin = 8,
  videoSuperAdmin = 9,
}

const canEditByRole = (allowedRole: RolesEnum[]) => {
  const userRoles = UserManagementService.getRoles().data || [];
  return checkHasRole(userRoles,allowedRole);
}

const canAccessUserRolesPage = (allowedRole: RolesEnum[]) => {
  const userRoles = UserManagementService.getRoles().data || [];
  return userRoles?.filter((role: RolesEnum) => allowedRole.find(i => i === role)).length > 0;
}

const checkHasRole = (userRoles: number[], allowedRole: RolesEnum[]) => {
  return userRoles?.filter(s => allowedRole.find(i => i === s) || // If user has any permission allowed
                      ((allowedRole.find(i => i !== RolesEnum.videoSuperAdmin)) && s === RolesEnum.videoAdmin) ||  // or if user is videoAdmin and is not required to be videoSuperAdmin
                      s === RolesEnum.videoSuperAdmin).length > 0; // or if the user is videoSuperadmin
};

type Props = {
  children: JSX.Element;
  allowedRole: RolesEnum[];
  userRoles?: number[];
  userManagement?: any;
  restrictedMessage?: boolean;
};

const restrictedMessge = (restrictedMessage?: boolean): JSX.Element => {
  return  (!restrictedMessage) ? <></> : <h1 style={{ textAlign: "center", paddingTop: "20px" }}>You do not have access to this functionality. <br/> Please contact your administrator to request access.</h1>;
}

const _VerifyRole = ({ children, userRoles, allowedRole, userManagement, restrictedMessage = false }: Props): JSX.Element => {
    const _userRoles = (userRoles) ? userRoles : userManagement.getRoles().data || [];
    return checkHasRole(_userRoles, allowedRole) ? children : restrictedMessge(restrictedMessage);
};

const VerifyRole = UserManagementService.inject( _VerifyRole );

export { VerifyRole, RolesEnum, checkHasRole, canEditByRole, canAccessUserRolesPage };