import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEventTypeLabels } from '$Utilities/videoEventTypeUtility';
import { GetImageUrl } from '$Utilities/dataModelUtilities';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { React } from 'Imports';
import { StyledEventType } from './VideoEventDescriptionsStyles';
import { VideoEventTypePairingResponse } from '$Generated/api';
const styles = require('./VideoEventDescription.scss') as {
    content: string;
    target: string;
    targetIcon: string;
    eventDescription: string;
    eventTypeTooltipContainer: string;
    eventTypeTooltipHoverTarget: string;
    eventTypeTooltipContent: string;
    eventType: string;
    wedge: string;
    wedgeTop: string;
    wedgeHover: string;
    wedgeAlignForDetails: string;
};

interface IVideoEventDescriptionProps {
    videoEventTypePairings: VideoEventTypePairingResponse[] | undefined;
    videoEventTypeString: string | undefined;
    videoEventFontSize?: string;
    isVideoEventDetails?: boolean;
}

type VideoEventDescriptionProps = IVideoEventDescriptionProps & IConfigServiceInjectedProps & HTMLAttributes<HTMLDivElement>;

const _VideoEventDescription = (props: VideoEventDescriptionProps) => {
    const currentImagesBucket = props.config.getState().clientConfigResults.data?.imagesBucket;
    const currentRegion = props.config.getState().clientConfigResults.data?.imagesBucketRegion;
    const eventTypeLabel = props.videoEventTypeString;
    const eventTypeLabels = getEventTypeLabels(props.videoEventTypePairings?.map((x) => x.videoEventTypeString));
    const wrapperRef = useRef<any>(null);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    useEffect(() => {
        function handleClickOutside(e: MouseEvent) {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target as HTMLDivElement)) {
                setIsTooltipOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef, isTooltipOpen ?? null]);

    return (
        <div className={`${styles.eventDescription} ${props.className ?? ''}`}>
            <StyledEventType fontSize={props.videoEventFontSize} className={styles.eventType}>
                {eventTypeLabel || 'Unknown'}
                {eventTypeLabels && eventTypeLabel == 'Combination' && (
                    <div className={props.isVideoEventDetails ? styles.target : styles.eventTypeTooltipContainer}>
                        <div ref={wrapperRef}>
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                className={props.isVideoEventDetails ? styles.targetIcon : styles.eventTypeTooltipHoverTarget}
                                onClick={() => (props.isVideoEventDetails ? setIsTooltipOpen(isTooltipOpen ? false : true) : {})}
                            />
                        </div>
                        {isTooltipOpen && props.isVideoEventDetails ? (
                            <div className={[styles.content, styles.wedgeAlignForDetails].join(' ')}>
                                {eventTypeLabels.map((x, idx) => (
                                    <div key={idx}>{x}</div>
                                ))}
                                <div className={styles.wedge}>
                                    <img
                                        src={GetImageUrl(
                                            currentImagesBucket ? currentImagesBucket : '',
                                            currentRegion ? currentRegion : '',
                                            'tooltip_wedge.svg',
                                        )}
                                    />
                                </div>
                            </div>
                        ) : (
                            !props.isVideoEventDetails && (
                                <div className={styles.eventTypeTooltipContent}>
                                    <ul>
                                        {eventTypeLabels.map((x, idx) => (
                                            <div key={idx}>{x}</div>
                                        ))}
                                    </ul>
                                    <div className={styles.wedgeHover}>
                                        <img
                                            src={GetImageUrl(
                                                currentImagesBucket ? currentImagesBucket : '',
                                                currentRegion ? currentRegion : '',
                                                'tooltip_wedge.svg',
                                            )}
                                        />
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                )}
            </StyledEventType>
        </div>
    );
};

export const VideoEventDescription = ConfigService.inject(_VideoEventDescription);
