import { React } from 'Imports';
import { useState } from 'react';
import { Alert, Button, Snackbar } from 'MaterialUIComponents';
import { AlertTypes } from '../../utilities/Enums/AlertTypes';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle, WarningRounded } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { ConfigService } from '$State/ConfigFreezerService';
import * as _ from 'lodash';
import * as scssStyles from '../../css/settings.scss';

interface IAlertMessageProps {
    alertType: AlertTypes;
    message?: string;
    showIcon?: boolean;
    openSnackbar: boolean;
    isUndo?: boolean;
    autoHide?: boolean;

    handleSnackClose: () => void;
    handleSubmit: (e: any, undo: boolean) => void;
}

const styles = require('./AlertMessage.scss') as {
    snackbar: string;
    containerAlert: string;
    alertIcon: string;
    alertBackground: string;
    undoButton: string;
    closeAlertIcon: string;

    success: string;
    danger: string;
};

type AlertMessageProps = IAlertMessageProps;

export const AlertMessage = (props: AlertMessageProps) => {
    const { alertType, message, openSnackbar, showIcon, isUndo, autoHide, handleSubmit, handleSnackClose } = props;

    const [requestUndo, setRequestUndo] = useState(false);

    const isEncompassIntegrated = !ConfigService.isRunningStandAlone() && scssStyles.styleEnvironment === 'encompass';

    const closeSnackBar = (e: any) => {
        e?.stopPropagation();
        handleSnackClose();
    };

    const submit = (e: any) => {
        e.stopPropagation();
        setRequestUndo(true);
        handleSubmit(e, true);
    };

    const defineIcon = (): JSX.Element => {
        if (showIcon)
            switch (alertType) {
                case AlertTypes.Alert:
                    return <CancelIcon className={styles.alertIcon}>add_circle</CancelIcon>;
                case AlertTypes.Success:
                    return <CheckCircle className={styles.alertIcon}>add_circle</CheckCircle>;
                case AlertTypes.Fail:
                    return <WarningRounded className={styles.alertIcon}>add_circle</WarningRounded>;
            }
        return <></>;
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openSnackbar}
            autoHideDuration={autoHide || alertType != AlertTypes.Success ? 5000 : null}
            onClose={closeSnackBar}
            className={styles.snackbar}
            style={isEncompassIntegrated ? { top: '68vh' } : { top: '90vh' } }
        >
            <Alert
                elevation={24}
                variant="filled"
                onClose={closeSnackBar}
                className={alertType != AlertTypes.Success ? styles.danger : styles.success}
                icon={showIcon ? defineIcon(): showIcon }
                action={<CloseIcon className={styles.closeAlertIcon} onClick={closeSnackBar}></CloseIcon>}
            >
                <div className={styles.containerAlert} style={{}}>
                    <span>{message}</span>
                    {alertType == AlertTypes.Success && !isUndo && (
                        <Button color="inherit" className={styles.undoButton} disabled={requestUndo} onClick={submit}>
                            Undo Delete
                        </Button>
                    )}
                </div>
            </Alert>
        </Snackbar>
    );
};
