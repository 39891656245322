import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox } from 'MaterialUIComponents';
import { React } from 'Imports';
import { isEncompass } from '$Utilities/integrationPlatform';

const styles = require('$Pages/VideoEvents/components/DropdownMenuItemCheckbox.scss') as {
    checkedEncompass: string;
    checkedGeotab: string;
    uncheckedEncompass: string;
    uncheckedGeotab: string;
};

const CheckboxIcon = (props: { checked: boolean }) => {
    return props.checked ? (
        <Box className={isEncompass ? styles.checkedEncompass : styles.checkedGeotab}>
            <FontAwesomeIcon icon={faCheck} color={'white'} size={'xs'} />
        </Box>
    ) : (
        <Box className={isEncompass ? styles.uncheckedEncompass : styles.uncheckedGeotab} />
    );
};

interface IDropdownMenuItemCheckboxProps {
    checked: boolean;
    onChange: () => void;
}

const DropdownMenuItemCheckbox = (props: IDropdownMenuItemCheckboxProps) => {
    return (
        <Checkbox
            checked={props.checked}
            sx={{
                padding: '0px 8px 0px 0px',
            }}
            icon={<CheckboxIcon checked={props.checked} />}
            checkedIcon={<CheckboxIcon checked={props.checked} />}
            onChange={props.onChange}
            disableRipple
        />
    );
};

export default DropdownMenuItemCheckbox;
