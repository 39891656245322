import { React } from 'Imports';
import { Button } from 'MaterialUIComponents';

import { VideoRequestForm } from '$Components/VideoRecall/VideoRequestForm';
import { useState } from 'react';

import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = require('./VideoRecallRequestForm.scss') as {
    main: string;
    mainMobile: string;
    trayClose: string;
    requestFormHeader: string;
    requestTitle: string;
    requestButton: string;
    requestButtonContainer: string;
    requestInfo: string;
    onlineInfo: string;
};

export const VideoRecallRequestForm = (props: any) => {
    const [submitRecall, setSubmitRecall] = useState('');

    const handleSubmitButton = () => {
        setSubmitRecall('submit');
        setInterval(() => setSubmitRecall(''), 600);
    };

    return (
        <>
            <div className={props.isMobileView ? styles.mainMobile : styles.main}>
                {props.isMobileView && <FontAwesomeIcon icon={faTimes} className={styles.trayClose} onClick={props.toggleRequestVideo} />}

                <div className={styles.requestFormHeader}>
                    <div className={styles.requestTitle}>Request a video</div>
                    <p className={styles.requestInfo}>
                        The system will attempt to wake up the camera to recover the video. If voltage to the camera is not sufficient, the
                        video will recall the next time the camera is <b className={styles.onlineInfo}>Online</b>.
                    </p>
                    <p className={styles.requestInfo}>Location information not available for recall.</p>
                </div>
                <VideoRequestForm
                    submitRecall={submitRecall}
                    toggleRequestVideo={props.toggleRequestVideo}
                    isMobileView={props.isMobileView}
                ></VideoRequestForm>

                <div className={styles.requestButtonContainer}>
                    <Button className={styles.requestButton} onClick={handleSubmitButton}>
                        Request Video
                    </Button>
                </div>
            </div>
        </>
    );
};
