import { React, bind, _ } from '../../../Imports';
import { cx } from '@videoplatform/css-helpers';
import { Tabs, Tab } from '../../../MaterialUIComponents';
import { EventComments } from './EventComments';
import { DriverComments } from './DriverComments';
import { CourtReporter } from './CourtReporter';
import { FeatureDisabledContent } from '../../Shared/FeatureDisabledOverlay';
import { VideoEventResponse } from '$Generated/api';
import { IVideoEventServiceInjectedProps, VideoEventService } from '../../../state/VideoEventFreezerService';
import { IPricingPlanServiceInjectedProps, PricingPlanService } from '../../../state/PricingPlanFreezerService';
import * as scssStyles from '../../../css/settings.scss';
import { canEditByRole, RolesEnum } from '../../../externals/VerifyRole';

const styles = require("./VideoEventHistoryDetails.scss") as {
    container: string;
    tab: string;
    tabDisabled: string;
    tabContentContainer: string;
    tabContentHidden: string;
    tabContentDisabled: string;
    selectedTab: string;
    tabIndicator: string;
    scrollButtons: string;
    tabDisabledByRole: string;
};

interface IVideoEventHistoryDetailsBaseProps {
    event: VideoEventResponse;
    updateVideoEventHistory?: () => void;
}

type IVideoEventHistoryDetailsProps = IVideoEventHistoryDetailsBaseProps
    & IVideoEventServiceInjectedProps
    & IPricingPlanServiceInjectedProps;

interface IVideoEventHistoryDetailsState {
    tabValue: number;
    hasDriverWorkflow: boolean;
    hasCourtReporter: boolean;
    canEdit: boolean;
}

const DisabledTabContent = (
    <div className={styles.tabContentDisabled}>
        { FeatureDisabledContent }
    </div>
)

class _VideoEventHistoryDetails extends React.Component<IVideoEventHistoryDetailsProps, IVideoEventHistoryDetailsState> {
    state: IVideoEventHistoryDetailsState = {
        tabValue: 0,
        hasDriverWorkflow: false,
        hasCourtReporter: false,
        canEdit: false,
    };

    async componentDidMount(): Promise<void> {
        if (this.props.event.id) {
            this.setState({
                hasDriverWorkflow: await this.props.pricingPlan.hasFeatureType('DriverCoachingWorkflowApp'),
                hasCourtReporter: await this.props.pricingPlan.hasFeatureType('CourtReporter')
            });

            // ensures history is loaded at the start if feature is enabled
            this.updateVideoEventHistory();
        }
        const canEdit = canEditByRole([RolesEnum.videoEventEdit, RolesEnum.videoRecallEdit]);
        this.setState( { canEdit: canEdit });
    }

    @bind
    handleTabChange(event: React.ChangeEvent<{}>, value: any) {
        this.setState({ tabValue: value });
    };

    @bind
    updateVideoEventHistory() {
        if (this.props.updateVideoEventHistory) {
            this.props.updateVideoEventHistory();
        }
    }

    render() {
        const { tabValue, hasDriverWorkflow, hasCourtReporter } = this.state;

        const videoEventsFreezer = this.props.videoEvents.getState();
        const { videoEventHistoryTableResults } = videoEventsFreezer;
        const historyData = hasCourtReporter && videoEventHistoryTableResults.data?.data || [];

        return (
            <div className={styles.container}>
                <Tabs
                    value={tabValue}
                    variant="fullWidth"
                    onChange={this.handleTabChange}
                    style={{ background: scssStyles.customColor1 }}
                    classes={{
                        indicator: styles.tabIndicator,
                        scrollButtons: styles.scrollButtons
                    }}
                >
                    <Tab
                        className={!this.state.canEdit ? styles.tabDisabledByRole : undefined }
                        label="Event Comments"
                        classes={{
                            root: styles.tab,
                            selected: styles.selectedTab
                        }}
                    />
                    <Tab
                        className={!hasDriverWorkflow ? styles.tabDisabled : undefined}
                        classes={{
                            root: styles.tab,
                            selected: styles.selectedTab
                        }}
                        label="Driver Comments"
                    />
                    <Tab
                        className={!hasCourtReporter ? styles.tabDisabled : undefined}
                        classes={{
                            root: styles.tab,
                            selected: styles.selectedTab
                        }}
                        label="Court Reporter"
                    />
                </Tabs>

                <div className={cx([styles.tabContentContainer, tabValue !== 0 ? styles.tabContentHidden : undefined ])}>
                    <EventComments event={this.props.event} videoEvents={this.props.videoEvents} updateVideoEventHistory={this.updateVideoEventHistory} />
                </div>

                <div className={cx([styles.tabContentContainer, tabValue !== 1 ? styles.tabContentHidden : undefined ])}>
                    { hasDriverWorkflow ? (
                        <DriverComments event={this.props.event} />
                    ) : DisabledTabContent }
                </div>

                <div className={cx([styles.tabContentContainer, tabValue !== 2 ? styles.tabContentHidden : undefined ])}>
                    { hasCourtReporter ? (
                        <CourtReporter event={this.props.event} data={historyData} />
                    ) : DisabledTabContent }
                </div>
            </div>
        );
    }
}

export const VideoEventHistoryDetails =
    PricingPlanService.inject(
    VideoEventService.inject(
        _VideoEventHistoryDetails
    ));