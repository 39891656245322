import { React } from '../../Imports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { GetImageUrl } from '$Utilities/dataModelUtilities';

interface IWidgetTooltipProps {
    prompt: string;
    description: string;
}

type WidgetTooltipProps = IWidgetTooltipProps & IConfigServiceInjectedProps;

const styles = require('./WidgetTooltip.scss') as {
    container: string;
    target: string;
    targetIcon: string;
    content: string;
    wedge: string;
};

class _WidgetTooltip extends React.PureComponent<WidgetTooltipProps, {}> {
    render(): JSX.Element {
        let currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        let currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;

        return (
            <div className={styles.container}>
                {this.props.prompt}

                <div className={styles.target}>
                    <FontAwesomeIcon icon={faInfoCircle} className={styles.targetIcon} />
                    <div className={styles.content}>
                        {this.props.description}

                        <div className={styles.wedge}>
                            <img
                                src={GetImageUrl(
                                    currentImagesBucket ? currentImagesBucket : '',
                                    currentRegion ? currentRegion : '',
                                    'tooltip_wedge.png',
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const WidgetTooltip = ConfigService.inject(_WidgetTooltip);
