import { React, bind, moment } from '../../Imports';
import { TextField, InputAdornment } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { VPDateRangePicker } from './VPDateRangePicker';
import * as DateFormatter from '../Shared/DateFormatter';
import * as scssStyles from '../../css/settings.scss';

interface IDateRangeProps {
    onSelectedItemChange: (value: any | string) => void;
    startDate?: Date;
    endDate?: Date;
    width?: string;
    IsMobileView: boolean;
    hideDateLabels?: boolean;
    minDate?: Date;
    maxDate?: Date;
    showMessage: boolean;
}

interface IDateRangeState {
    showPicker: boolean;
    fieldSelected: string;
}

const styles = require('./VPDateRange.scss') as {
    main: string;
    dateField: string;
    dateFieldEncompass: string;
    dateFieldInput: string;
    icon: string;
    dateFieldInputAdorment: string;
};

const inputStyles = {
    borderRadius: '4px',
    backgroundColor: '#FFFFF',
    border: '1px solid #ced4da',
    boxShadow: 'inset 0px 3px 3px 0px #e6e6e6',
    padding: '0px 8px',
};

export class VPDateRange extends React.Component<IDateRangeProps, IDateRangeState> {
    // Set to a default start and end date text of today, make sure the picker is hidden
    state: IDateRangeState = {
        showPicker: false,
        fieldSelected: 'endDate',
    };

    @bind
    onRangeChange(startDate: moment.Moment, endDate: moment.Moment, submit: boolean) {
        this.setState(
            {
                showPicker: !submit,
            },
            () => {
                const dateRange = {
                    startDate,
                    endDate,
                };
                const { onSelectedItemChange } = this.props;
                onSelectedItemChange(dateRange);
            },
        );
    }

    @bind
    onPickerClose() {
        this.setState({
            showPicker: false,
        });
    }

    @bind
    handleTextFieldChange(event: any) {
        const { showPicker } = this.state;
        this.setState({
            showPicker: !showPicker,
            fieldSelected: event.currentTarget.id,
        });
    }

    @bind
    calculateNumOfDays(startDate: moment.Moment, endDate: moment.Moment) {
        if (startDate.isValid() && endDate.isValid()) {
            return endDate.diff(startDate, 'days') + 1;
        }
        return undefined;
    }

    render() {
        const { showPicker, fieldSelected } = this.state;
        const { startDate, endDate, hideDateLabels = false, minDate, maxDate } = this.props;

        const startText = DateFormatter.date(moment(this.props.startDate));
        const endText = DateFormatter.date(moment(this.props.endDate));

        return (
            <div>
                <div className={styles.main}>
                    <TextField
                        id="startDate"
                        label={hideDateLabels ? '' : 'Start'}
                        type="text"
                        value={startText}
                        className={styles.dateField}
                        style={{ width: this.props.width ? this.props.width : '' }}
                        inputProps={{
                            onClick: this.handleTextFieldChange,
                            className: styles.dateFieldInput,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    id="startDate"
                                    onClick={this.handleTextFieldChange}
                                    className={
                                        scssStyles.styleEnvironment == 'encompass' ? styles.dateFieldInputAdorment : styles.dateFieldInput
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faCalendarAlt}
                                        className={styles.icon}
                                        size={scssStyles.styleEnvironment == 'encompass' ? 'lg' : '1x'}
                                    />
                                </InputAdornment>
                            ),
                            style: scssStyles.styleEnvironment == 'encompass' ? inputStyles : {},
                            disableUnderline: scssStyles.styleEnvironment == 'encompass',
                        }}
                    />
                    <TextField
                        id="endDate"
                        label={hideDateLabels ? '' : 'End'}
                        type="text"
                        value={endText}
                        className={styles.dateField}
                        style={{ width: this.props.width ? this.props.width : '' }}
                        inputProps={{
                            onClick: this.handleTextFieldChange,
                            className: styles.dateFieldInput,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    id="endDate"
                                    onClick={this.handleTextFieldChange}
                                    className={
                                        scssStyles.styleEnvironment == 'encompass' ? styles.dateFieldInputAdorment : styles.dateFieldInput
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faCalendarAlt}
                                        className={styles.icon}
                                        size={scssStyles.styleEnvironment == 'encompass' ? 'lg' : '1x'}
                                    />
                                </InputAdornment>
                            ),
                            style: scssStyles.styleEnvironment == 'encompass' ? inputStyles : {},
                            disableUnderline: scssStyles.styleEnvironment == 'encompass',
                        }}
                    />
                </div>
                <VPDateRangePicker
                    show={showPicker}
                    startDate={moment(startDate)}
                    endDate={moment(endDate)}
                    fieldSelected={fieldSelected}
                    onRangeChange={this.onRangeChange}
                    onPickerClose={this.onPickerClose}
                    isMobileView={this.props.IsMobileView}
                    minDate={minDate}
                    maxDate={maxDate}
                    showMessage={this.props.showMessage}
                    numDays={this.calculateNumOfDays(moment(startDate), moment(endDate))}
                />
            </div>
        );
    }
}
