import { React } from '../../Imports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import * as scssStyles from '../../css/settings.scss';
const styles = require('./FilterChip.scss') as {
    main: string;
    closeButton: string;
};

interface IFilterChipProps {
    children: React.ReactNode;
    onDelete?: () => void | undefined;
    itemKey?: React.Key | null | undefined;
}

export class FilterChip extends React.Component<IFilterChipProps> {
    render(): JSX.Element {
        return (
            <div className={styles.main} key={this.props.itemKey}>
                {scssStyles.styleEnvironment === 'encompass' && this.props.onDelete && (
                    <FontAwesomeIcon icon={faTimes} className={styles.closeButton} onClick={this.props.onDelete} />
                )}

                {this.props.children}

                {scssStyles.styleEnvironment !== 'encompass' && this.props.onDelete && (
                    <FontAwesomeIcon icon={faTimesCircle} className={styles.closeButton} onClick={this.props.onDelete} />
                )}
            </div>
        );
    }
}
