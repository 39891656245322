import * as scssStyles from '$CSS/settings.scss';
import { Button } from 'MaterialUIComponents';
import styled from '@emotion/styled';

const GoBackStyledButtonBase = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: none;
    margin: 20px auto;
`;

const GeotabGoBackStyledButton = `
    padding: 8px 10px;
    border: 1px solid ${scssStyles.lightGrey};    
    font-weight: ${scssStyles.fontWeight};
    & .button-text {
        margin: 0 4px 0 8px;
        color: black;
        font-size: 0.9em;
    }
`;

const EncompassGoBackStyledButton = `
    padding: 4px 6px;
    border: 1px solid #0d7ac5;
    font-weight: ${scssStyles.fontWeight};
    & .button-text {
        margin: 0 4px 0 4px;
        color: #0d7ac5;
        font-size: 1.1em;
    }

    &:hover {
        background-color: #0d7ac5;

        .button-text {
            color: white;
        }
    }
`;

const StyledGoBackButton = styled(GoBackStyledButtonBase)`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabGoBackStyledButton;
            case 'encompass':
                return EncompassGoBackStyledButton;
            default:
                return GeotabGoBackStyledButton;
        }
    }};
`;

export { StyledGoBackButton };
