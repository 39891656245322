import {
    React,
    _
} from "../../Imports";
import { PageNumberBlock } from "./PageNumberBlock";

// state and props
export interface IPaginatorProps {
    totalCount:number;
    itemsPerPage:number;
    curPageNumber: number;
    handlePageNumberChange: (newNum: number) => void;
}

const styles = require("./Paginator.scss") as {
    paginator: string;
};

export const getMaxPageNumber: (itemCount: number, itemsPerPage: number) => number = (itemCount, itemsPerPage) => {
    return Math.ceil(itemCount / itemsPerPage);
};

export const filterPageData: (currentPage: number, itemsPerPage: number, allItems: any[]) => any[] = (currentPage, itemsPerPage, allItems) => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = currentPage * itemsPerPage;
    return allItems.slice(start, end);
};

export class Paginator extends React.PureComponent<IPaginatorProps, {}> {
    render(): JSX.Element {
        const maxPageNumber = getMaxPageNumber(this.props.totalCount, this.props.itemsPerPage);
        const start: number = Math.max(2, this.props.curPageNumber - 3);
        const end: number = Math.min(maxPageNumber, this.props.curPageNumber + 3);
        const hasLeft: boolean = start > 2;
        const hasRight: boolean = (maxPageNumber - end) > 1;

        return (
            <div className={styles.paginator}>
                <PageNumberBlock
                    display="1"
                    current={this.props.curPageNumber}
                    goto={1}
                    handlePageNumberChange={this.props.handlePageNumberChange}
                    key={"1"}
                />
                {hasLeft ?
                    <PageNumberBlock
                        display="arrow_left"
                        current={this.props.curPageNumber}
                        goto={this.props.curPageNumber - 1}
                        handlePageNumberChange={this.props.handlePageNumberChange}
                        key={"<<"}
                    /> : ""
                }
                {end > start ? _.range(start, end).map((num: number) => {
                    return (
                        <PageNumberBlock
                            display={num.toString()}
                            current={this.props.curPageNumber}
                            goto={num}
                            handlePageNumberChange={this.props.handlePageNumberChange}
                            key={num}
                        />
                    );
                }) : ""}
                {hasRight ?
                    <PageNumberBlock
                        display="arrow_right"
                        current={this.props.curPageNumber}
                        goto={this.props.curPageNumber + 1}
                        handlePageNumberChange={this.props.handlePageNumberChange}
                        key={">>"}
                    /> : ""
                }
                {end > 1 ? <PageNumberBlock
                    display={maxPageNumber.toString()}
                    current={this.props.curPageNumber}
                    goto={maxPageNumber}
                    handlePageNumberChange={this.props.handlePageNumberChange}
                    key={maxPageNumber}
                /> : ""}
            </div>
        );
    }
}
