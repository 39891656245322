import { React, moment } from "../../../Imports";
import { VideoEventCommentResponse, VideoEventResponse } from '$Generated/api';
import { Card } from "../../../MaterialUIComponents";
import * as DateFormatter from "../../Shared/DateFormatter";
import { DriverNotificationService, IDriverNotificationServiceInjectedProps} from "../../../state/DriverNotificationFreezerService";

interface IDriverCommentsBaseProps {
    event: VideoEventResponse;
}

type IDriverCommentsProps = IDriverCommentsBaseProps & IDriverNotificationServiceInjectedProps;

const styles = require("./DriverComments.scss") as {
    main: string,
    sectionTitle: string,
    card: string,
    cardBody: string,
    commentContainer: string,
    comment: string,
    content: string,
    details: string,
};

class _DriverComments extends React.Component<IDriverCommentsProps, {}> {
    async componentDidMount(): Promise<void> {
        const eventId = this.props.event.id ? this.props.event.id : 0;
        await this.props.driverNotifications.getDriverComments(eventId);
    }

    render() {
        const freezer = this.props.driverNotifications.getState();
        const { driverCommentResults } = freezer;
        const coms = (driverCommentResults.data) ? driverCommentResults.data : [];

        return (
            <div className={styles.main}>
                <Card className={styles.card}>
                    <div className={styles.cardBody}>
                        <div className={styles.commentContainer}>
                            {coms.length > 0 ? coms.map((com: VideoEventCommentResponse, idx: number) => {
                                const comDate = moment(com.createdOn);
                                return (
                                    <div key={idx} className={styles.comment}>
                                        <div className={styles.content}>
                                            <div>{com.comment}</div>
                                        </div>
                                        <div className={styles.details}>
                                            <div><b>{DateFormatter.getDaysAgo(comDate)}</b></div>
                                            <div>{DateFormatter.dateWithMonthAbbr(comDate)}</div>
                                        </div>
                                    </div>
                                );
                            }) :
                                <div>No comments yet</div>
                            }
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

export const DriverComments = DriverNotificationService.inject(_DriverComments);