import { Button, Dialog, DialogActions, DialogTitle } from '../../../../../MaterialUIComponents';
import { Driver } from '../../../VideoEventDetails/DetailsSidePanel';
import { DriverDropdown } from '../../../../Shared/DriverDropdown';
import { IVideoEventServiceInjectedProps, VideoEventService } from '../../../../../state/VideoEventFreezerService';
import { React } from '../../../../../Imports';
import { useEffect, useState } from 'react';
import { VideoEventResponse } from '$Generated/api';

const styles = require('./EditDriverDialog.scss') as {
    actionButton: string;
    dialog: string;
    driverDropdownWrapper: string;
};

interface IEditDriverDialogBaseProps {
    canEdit: boolean;
    event: VideoEventResponse;
    setCurrentDriverEmail: (email: string) => void;
    setCurrentDriverName: (name: string) => void;
    setCurrentDriverPhoneNumber: (phoneNumber: string) => void;
    setShowEditDriver: (showEditDriver: boolean) => void;
    showEditDriver: boolean;
    updateVideoEventHistory: () => void;
}

type IEditDriverDialogProps = IEditDriverDialogBaseProps & IVideoEventServiceInjectedProps;

const _EditDriverDialog: React.FC<IEditDriverDialogProps> = ({
    canEdit,
    event,
    setCurrentDriverEmail,
    setCurrentDriverName,
    setCurrentDriverPhoneNumber,
    setShowEditDriver,
    showEditDriver,
    updateVideoEventHistory,
    videoEvents,
}) => {
    const [selectedDriver, setSelectedDriver] = useState<Driver>();
    const [currentDriverId, setCurrentDriverId] = useState('');

    useEffect(() => {
        setCurrentDriverId(event.driverIntegrationPartnerSourceId || '');
        setCurrentDriverName(event.driverName || '');

        setSelectedDriver({
            id: event.driverIntegrationPartnerSourceId || '',
            firstName: '',
            lastName: '',
            email: event.driverEmail || '',
            phoneNumber: '',
        });
    }, [event]);

    const toggleShowEditDriver = () => {
        if (!canEdit) return;
        setShowEditDriver(!showEditDriver);
    };

    const handleDriverDropdownChange = (driver: Driver) => {
        if (!driver) {
            setSelectedDriver({
                id: '',
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
            });
        } else {
            setSelectedDriver({
                id: driver.id,
                firstName: driver.firstName,
                lastName: driver.lastName,
                email: driver.email || '',
                phoneNumber: driver.phoneNumber || '',
            });
        }
    };

    const updateDriver = async () => {
        if (selectedDriver?.id !== currentDriverId) {
            if (selectedDriver?.id === null) await videoEvents.removeDriver(event.id || 0);
            else
                await videoEvents.updateDriver(event.id || 0, {
                    id: selectedDriver?.id,
                    firstName: selectedDriver?.firstName,
                    lastName: selectedDriver?.lastName,
                    email: selectedDriver?.email,
                    phoneNumber: selectedDriver?.phoneNumber,
                });

            updateVideoEventHistory();

            setCurrentDriverName(selectedDriver?.firstName + ' ' + selectedDriver?.lastName);
            setCurrentDriverEmail(selectedDriver?.email || '');
            setCurrentDriverId(selectedDriver?.id || '');
            setCurrentDriverPhoneNumber(selectedDriver?.phoneNumber || '');
        }

        toggleShowEditDriver();
    };

    return (
        <Dialog open={showEditDriver} maxWidth={'xs'} fullWidth={true} className={styles.dialog}>
            <DialogTitle>Edit Driver Name</DialogTitle>
            <div className={styles.driverDropdownWrapper}>
                <DriverDropdown
                    onSelectedDriverChange={handleDriverDropdownChange}
                    selectedDriverId={selectedDriver?.id || ''}
                    width={250}
                    drivers={[]}
                />
            </div>

            <DialogActions>
                <Button onClick={updateDriver} className={styles.actionButton}>
                    Update
                </Button>
                <Button onClick={toggleShowEditDriver} className={styles.actionButton}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const EditDriverDialog = VideoEventService.inject(_EditDriverDialog);
