import './MuiClassNameSetup';
import "./global.types";
import * as $ from "jquery";

const baseUrl = `${$("base").attr("href")}`;

declare var __webpack_public_path__: string;
__webpack_public_path__ = baseUrl;

import { Application } from "./Application";
import { SPA } from "@videoplatform/react-spa";

const el = document.getElementById("reactApp");

const app = (el == null) ? new SPA(baseUrl) : new SPA(baseUrl, el);
app.setRootComponent(Application).render();
