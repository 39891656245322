import { Accordion, AccordionDetails } from '../../../MaterialUIComponents';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '../../../state/IntegrationPartnerDataFreezerService';
import { PageHeader } from '$Components/Shared/PageHeader';
import { PageHeader_Split_Off } from '$Components/Shared/PageHeader_Split_Off';

import { TermsOfUse } from '$Components/Shared/TermsOfUse';
import { React } from '../../../Imports';
import { StyledAccordionSummary } from './VideoEventHeaderStyles';
import { useState } from 'react';
import { VideoEventStatisticsResponse } from '$Generated/api';
import VideoEventStatsCard from './VideoEventStatsCard';
import { sortBy } from 'lodash';
import { TypedSplitTreatments, splitTreatmentNames, getSplitUserAttributes, isSplitTreatmentOn } from '$Utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';

const styles = require('./VideoEventsHeader.scss') as {
    headerContainer: string;
    header: string;
    headerCardContainer: string;
    accordionSummary: string;
    accordionSummaryContent: string;
    expandIconCollapse: string;
    expandIconExpand: string;
};

const stylesSplitOff = require('./VideoEventsHeader_Split_Off.scss') as {
    headerContainer: string;
    header: string;
    headerCardContainer: string;
    accordionSummary: string;
    accordionSummaryContent: string;
    expandIconCollapse: string;
    expandIconExpand: string;
};

interface IVideoEventsHeaderBaseProps {
    eventHistory: VideoEventStatisticsResponse[];
    onChange: (val: boolean) => void;
}

type VideoEventsHeaderProps = IVideoEventsHeaderBaseProps & IIntegrationPartnerDataInjectedProps;

const _VideoEventsHeader = (props: VideoEventsHeaderProps) => {
    const [viewHeader, setViewHeader] = useState(false);
    const sortedEventHistory = sortBy(sortBy(props.eventHistory, ['videoEventType']), (item) => {
        return !item['videoEventType'] ? 0 : 1;
    });

    const handleClick = (): void => {
        props.onChange(!viewHeader);
        setViewHeader(!viewHeader);
    };
    const pageSubTitle = 'Each video event categorization represents a potential driving incident for your review.';
    return (
        <div className={styles.headerContainer}>
            <TermsOfUse />
            <TypedSplitTreatments names={[splitTreatmentNames.videoEventHeaderVerbiage]} attributes={getSplitUserAttributes()}>
                {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                    return isReady || isTimedout ? (
                        isSplitTreatmentOn(treatments[splitTreatmentNames.videoEventHeaderVerbiage]) ? (
                            <Accordion className={styles.header} expanded={viewHeader} onChange={handleClick}>
                                <StyledAccordionSummary
                                    aria-controls="VideoProtectsHeader"
                                    id="VideoProtectsHeader"
                                    className={styles.accordionSummary}
                                >
                                    <PageHeader pageTitle={'Video Events'} pageSubTitle={pageSubTitle} fixedHeight={false}>
                                        {' '}
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            color="white"
                                            className={viewHeader ? styles.expandIconCollapse : styles.expandIconExpand}
                                        />
                                    </PageHeader>
                                </StyledAccordionSummary>
                                <AccordionDetails className={styles.headerCardContainer}>
                                    {sortedEventHistory.map((card: VideoEventStatisticsResponse, idx: number) => (
                                        <VideoEventStatsCard key={idx} stats={card} />
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        ) : (
                            <Accordion className={stylesSplitOff.header} expanded={viewHeader} onChange={handleClick}>
                                <StyledAccordionSummary
                                    aria-controls="VideoProtectsHeader"
                                    id="VideoProtectsHeader"
                                    className={stylesSplitOff.accordionSummary}
                                >
                                    <PageHeader_Split_Off pageTitle={'Video Events'} fixedHeight={false}>
                                        {' '}
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            color="white"
                                            className={viewHeader ? stylesSplitOff.expandIconCollapse : stylesSplitOff.expandIconExpand}
                                        />
                                    </PageHeader_Split_Off>
                                </StyledAccordionSummary>
                                <AccordionDetails className={stylesSplitOff.headerCardContainer}>
                                    {sortedEventHistory.map((card: VideoEventStatisticsResponse, idx: number) => (
                                        <VideoEventStatsCard key={idx} stats={card} />
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        )
                    ) : (
                        <Accordion className={stylesSplitOff.header} expanded={viewHeader} onChange={handleClick}>
                            <StyledAccordionSummary
                                aria-controls="VideoProtectsHeader"
                                id="VideoProtectsHeader"
                                className={stylesSplitOff.accordionSummary}
                            >
                                <PageHeader_Split_Off pageTitle={'Video Events'} fixedHeight={false}>
                                    {' '}
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        color="white"
                                        className={viewHeader ? stylesSplitOff.expandIconCollapse : stylesSplitOff.expandIconExpand}
                                    />
                                </PageHeader_Split_Off>
                            </StyledAccordionSummary>
                            <AccordionDetails className={stylesSplitOff.headerCardContainer}>
                                {sortedEventHistory.map((card: VideoEventStatisticsResponse, idx: number) => (
                                    <VideoEventStatsCard key={idx} stats={card} />
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    );
                }}
            </TypedSplitTreatments>
        </div>
    );
};

export const VideoEventsHeader = IntegrationPartnerDataService.inject(_VideoEventsHeader);
