import { React } from "../../Imports";

const styles = require("./MultipleFleets.scss") as {
    appMessage: string;
    container: string;
    message: string;
    subMessage: string;
};

export class _MultipleFleets extends React.Component{
    render() {
        return (
            <>
                <div className={styles.appMessage}>
                    <div className={styles.container}>
                        <span className={styles.message}>
                            This company context has multiple Encompass Video fleets<br/>
                            <span className={styles.subMessage}>
                            Video events are displayed at company level.  To view video events, please change your company context in the upper right corner of this page.  
                            Instructions for this can be found <a href="https://support.jjkeller.com/Encompass%C2%AE_Suite/Encompass%C2%AE/Information_and_Setup_for_Administrators/Virtual_vs._Flat_Company_Structure/Virtual_Setup_-_Logging_into_Another_Location" target="_blank">here</a>. 
                            </span>
                        </span>
                    </div>
                </div>
            </>
        );
    }
}

export const MultipleFleets = _MultipleFleets;
