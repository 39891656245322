import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import { React } from 'Imports';
import { VideoEventStatisticsResponse } from '$Generated/api';

const styles = require('./VideoEventStatsCard.scss') as {
    headerCard: string;
    typeInfo: string;
    type: string;
    cardInfo: string;
    cardColumn: string;
    columnTitle: string;
    columnContent: string;
    increase: string;
    decrease: string;
    arrowicon: string;
};

const areEqualStats = (prevProps: VideoEventStatsCardProps, nextProps: VideoEventStatsCardProps) => {
    let result = prevProps.stats.videoEventType === nextProps.stats.videoEventType;
    result = result && prevProps.stats.eventChange === nextProps.stats.eventChange;
    result = result && prevProps.stats.percentChange === nextProps.stats.percentChange;
    result = result && prevProps.stats.daysInRange === nextProps.stats.daysInRange;
    result = result && prevProps.stats.totalDuringRange === nextProps.stats.totalDuringRange;

    return result;
};

interface IVideoEventStatsCardProps {
    stats: VideoEventStatisticsResponse;
}

type VideoEventStatsCardProps = IVideoEventStatsCardProps;

const VideoEventStatsCard = memo(function VideoEventStatsCard({ stats }: VideoEventStatsCardProps) {
    const eventTypesString = (videoEventType: string | undefined): string => {
        if (videoEventType?.includes('ACCAlarm')) {
            return 'ACC Alarm';
        }
        return String(videoEventType)
            .replace(/([A-Z])/g, ' $1')
            .trim();
    };

    const eventChange = stats.eventChange ? stats.eventChange : 0;
    const eventType = eventTypesString(stats.videoEventType);
    const percent = stats.percentChange || 0;

    return (
        <div className={styles.headerCard}>
            <div className={styles.typeInfo}>
                <div className={styles.type} title={eventType}>
                    {eventType == 'null' ? 'All Events' : eventType}
                </div>
            </div>
            <div className={styles.cardInfo}>
                <div className={styles.cardColumn}>
                    <div className={styles.columnTitle}>{stats.daysInRange} Day Total</div>
                    <div className={styles.columnContent}>{stats.totalDuringRange}</div>
                </div>
                <div className={styles.cardColumn}>
                    <div className={styles.columnTitle}>Change</div>
                    <div
                        className={
                            styles.columnContent + ' ' + (eventChange > 0 ? styles.decrease : eventChange < 0 ? styles.increase : '')
                        }
                    >
                        <div className={eventChange < 0 ? styles.decrease : eventChange > 0 ? styles.increase : ''}>{eventChange}</div>
                    </div>
                </div>
                <div className={styles.cardColumn}>
                    <div className={styles.columnTitle}>% of Change</div>
                    <div className={styles.columnContent}>
                        <div className={eventChange < 0 ? styles.decrease : eventChange > 0 ? styles.increase : ''}>
                            {percent !== 0 ? percent.toFixed(1) + '%' : 'N/A'}{' '}
                            {percent !== 0 ? (
                                eventChange < 0 ? (
                                    <FontAwesomeIcon icon={faArrowDown} className={styles.arrowicon} />
                                ) : eventChange > 0 ? (
                                    <FontAwesomeIcon icon={faArrowUp} className={styles.arrowicon} />
                                ) : (
                                    ''
                                )
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}, areEqualStats);

export default VideoEventStatsCard;
