import { User, UserManager } from 'oidc-client';
import { ConfigService} from '$State/ConfigFreezerService'

export class AuthProvider {
    public userManager: UserManager;

    constructor() {
        const baseUrl = ConfigService.getBaseUrl();
        const identityServerUrl = ConfigService.getIdentityServerUrl();
        const clientIdWebStandalone = ConfigService.getClientIdWebStandalone();
        const responseMode = 'query';
        const responseType = 'code';
        const clientScope = 'openid encompass.video.api';
        const loginRedirectUrl = baseUrl + '/loginResponse';
        const logoutRedirectUrl = baseUrl + '/logoutResponse';
        const silentRedirectUrl = baseUrl + '/silentRenew';

        //Settings values needed to run PKCE flow with Encompass Identity Server
        const settings = {
          authority: identityServerUrl,
          client_id: clientIdWebStandalone,
          redirect_uri: loginRedirectUrl,
          silent_redirect_uri: silentRedirectUrl,
          post_logout_redirect_uri: logoutRedirectUrl,
          response_type: responseType,
          response_mode: responseMode,
          scope: clientScope,
          automaticSilentRenew: false,
        };

        this.userManager = new UserManager(settings);
    }

    public async renewToken(): Promise<User> {
      // SigninSilent method create an Iframe to handle a silent token renovation
      return await this.userManager.signinSilent();
    }

    public async getUser(): Promise<User | null> {
      return await this.userManager.getUser();
    }
  
    public login(): Promise<void> {
      return this.userManager.signinRedirect();
    }

    public async loginCallback(): Promise<void | User> {
        return await this.userManager.signinRedirectCallback();
    }

    public async signinSilentCallback(): Promise<void> {
        await this.userManager.signinSilentCallback();
    };

    public async logout(): Promise<void> {
      return await this.userManager.signoutRedirect();
    }

    public async logoutCallback(): Promise<void> {
        await this.userManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            sessionStorage.clear();
        });
        return await this.userManager.clearStaleState();
    };
}