import { FreezerService, managedAjaxUtil, IAjaxState } from '../Imports';
import { UserManagementService } from '../state/UserManagementFreezerService';
import { FleetSettingsApiFactory , FleetSettingResponse } from '../generated/api';

const InjectedPropName = "FleetSettings"

interface IFleetSettingsState {
    getFleetSettingsByKeyResults: IAjaxState<FleetSettingResponse>;
    hasError: boolean;
}

class FleetSettingsFreezerService extends FreezerService<IFleetSettingsState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                getFleetSettingsByKeyResults: managedAjaxUtil.createInitialState(),
                hasError: false
            },
            InjectedPropName
        )
    }

    public async getFleetSettingsByKeyResults(key: string): Promise<FleetSettingResponse | void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'getFleetSettingsByKeyResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const fleetSettingsAPI = FleetSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return fleetSettingsAPI.apiV1FleetSettingsGet(params);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                key
            },            
            onOk: (data: FleetSettingResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }
}

export const FleetSettingsService = new FleetSettingsFreezerService();
export type IFleetSettingsServiceInjectedProps = ReturnType<FleetSettingsFreezerService['getPropsForInjection']>;
