import { React } from '../../Imports';
import { VPStandardDropdown } from './VPStandardDropdown';
import VPAutocompleteDropdown from './VPAutocompleteDropdown';
import { VPMultiselect } from './VPMultiselect';
import VPAutocompleteMultiSelect from './VPAutocompleteMultiselect';
import { TypedSplitTreatments, getSplitUserAttributes, isSplitTreatmentOnWithDefault, splitTreatmentNames } from '$Utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';

interface IDropdownProps {
    id?: string;
    name: string;
    items: IDropdownItem[];
    selectedValue?: any | undefined;
    selectedValues?: any[] | undefined;
    isCombined?: boolean;
    isAutocomplete?: boolean;
    isMultiselect?: boolean;
    error?: boolean;
    onSelectedItemChange: (value: any[]) => void;
    width?: number;
    filterInactives?: boolean;
    includeInactives?: boolean;
    onIncludeInactiveChange?: (selected: boolean) => void;
}

export interface IDropdownItem {
    label: string;
    value: any;
    iconUrl?: string;
    isActive?: boolean;
}

interface IState {}

export class VPDropdown extends React.PureComponent<IDropdownProps, IState> {
    render(): JSX.Element {
        const { isAutocomplete, isMultiselect, isCombined, filterInactives, includeInactives } = this.props;

        if (isMultiselect) {
            return (
                <VPMultiselect
                    onSelectedItemChange={this.props.onSelectedItemChange}
                    selectedValues={this.props.selectedValues}
                    name={this.props.name}
                    items={this.props.items}
                    id={this.props.id}
                />
            );
        }
        if (isAutocomplete) {
            return (
                <VPAutocompleteDropdown
                    selectedValue={this.props.selectedValue}
                    onSelectedItemChange={this.props.onSelectedItemChange}
                    name={this.props.name}
                    items={this.props.items}
                    width={this.props.width}
                />
            );
        }
        if (isCombined) {
            return (
                <VPAutocompleteMultiSelect
                    selectedValues={this.props.selectedValues}
                    onSelectedItemChange={this.props.onSelectedItemChange}
                    name={this.props.name}
                    items={this.props.items}
                    width={this.props.width}
                    filterInactives={filterInactives}
                    includeInactives={includeInactives}
                    onIncludeInactiveChange={this.props.onIncludeInactiveChange}
                />
            );
        } else {
            return (
                <VPStandardDropdown
                    selectedValue={this.props.selectedValue}
                    onSelectedItemChange={this.props.onSelectedItemChange}
                    name={this.props.name}
                    items={this.props.items}
                />
            );
        }
    }
}
