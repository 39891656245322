import { moment, React } from '../../../Imports';
import { VPDateRange } from '$Components/FilterComponents/VPDateRange';
import { useEffect, useState } from 'react';

interface IDateRangeSelectorProps {
    defaultDateRange: DateRange;
    handleDateRangeOnChange: (dateRange: DateRange) => void;
    minDate?: Date;
    maxDate?: Date;
    showMessage: boolean;
}

export type DateRange = {
    startDate: Date;
    endDate: Date;
};
const DateRangeSelector = (props: IDateRangeSelectorProps) => {
    const { defaultDateRange, handleDateRangeOnChange, minDate, maxDate, showMessage } = props;
    const handleDateRangeChange = (dateRangSelected: any) => {
        handleDateRangeOnChange({ startDate: dateRangSelected.startDate, endDate: dateRangSelected.endDate });
    };

    return (
        <>
            <VPDateRange
                onSelectedItemChange={handleDateRangeChange}
                startDate={defaultDateRange.startDate}
                endDate={defaultDateRange.endDate}
                IsMobileView={false}
                hideDateLabels={true}
                minDate={minDate}
                maxDate={maxDate}
                showMessage={showMessage}
            />
        </>
    );
};

export default DateRangeSelector;
