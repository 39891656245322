import { React, bind } from '../../Imports';
import { TextField, InputAdornment, Autocomplete, Popper } from 'MaterialUIComponents';
import { CutText } from '../../utilities/dataModelUtilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faTimes, faCircle } from '@fortawesome/pro-solid-svg-icons';

import * as scssStyles from '../../css/settings.scss';

interface IAutocompleteDropdownProps {
    name: string;
    items: any[];
    selectedValue: any | undefined;
    onSelectedItemChange: (value: any[]) => void;
    error?: boolean;
    width?: number;
    id?: string;
}

const styles = require('./VPAutocompleteDropdown.scss') as {
    menuItem: string;
    inputImg: string;
    dropdownIcon: string;
    clearIcon: string;
    onlineIndicator: string;
};

const inputStyles = {
    borderRadius: '4px',
    backgroundColor: '#FFFFF',
    border: '1px solid #ced4da',
    boxShadow: 'inset 0px 3px 3px 0px #e6e6e6',
    padding: '0px 8px',
    verticalAlign: 'baseline',
    fontFamily: scssStyles.fontFamily,
};

export default class VPAutocompleteDropdown extends React.PureComponent<IAutocompleteDropdownProps> {
    @bind
    handleChange(value: any): void {
        const { onSelectedItemChange } = this.props;
        const selectedItemArray = value ? [value.value] : [];
        onSelectedItemChange(selectedItemArray);
    }

    @bind
    getSelectedItem() {
        let selectedItem = this.props.items.find((i: any) => i.value == this.props.selectedValue);
        if (selectedItem === undefined)
            //undefined leads to the uncontrolled vs controlled behavior issues with mui Autocomplete, if no selectedItem pass in null
            selectedItem = null;

        return selectedItem;
    }

    render(): JSX.Element {
        const selectedItem = this.getSelectedItem();

        return (
            <Autocomplete
                PopperComponent={(props): JSX.Element => {
                    return <Popper {...props} style={{ width: 'fit-context' }} placement="bottom-start" />;
                }}
                id={this.props.name}
                options={this.props.items}
                getOptionLabel={(option): string => option.label}
                onChange={(event: any, newValue: any): void => {
                    this.handleChange(newValue);
                }}
                style={{ width: this.props.width ? this.props.width : 200 }}
                value={selectedItem}
                clearIcon={<FontAwesomeIcon icon={faTimes} className={styles.clearIcon} />}
                popupIcon={<FontAwesomeIcon icon={faAngleDown} className={styles.dropdownIcon} />}
                renderInput={(params): JSX.Element => {
                    return selectedItem && selectedItem.icon ? (
                        <TextField
                            {...params}
                            label={this.props.name}
                            id={this.props.id}
                            InputProps={{
                                style: scssStyles.styleEnvironment == 'encompass' ? inputStyles : { fontFamily: scssStyles.fontFamily },
                                disableUnderline: scssStyles.styleEnvironment == 'encompass',
                                ...params.InputProps,
                                className: '', //Without this empty class here when selecting an item when an icon is present causes the whole input to shift up a few pixels
                                startAdornment: selectedItem && selectedItem.icon && (
                                    <InputAdornment position="start">
                                        <img className={styles.inputImg} src={selectedItem.icon} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    ) : (
                        <TextField
                            {...params}
                            label={this.props.name}
                            id={this.props.id}
                            InputProps={{
                                style: scssStyles.styleEnvironment == 'encompass' ? inputStyles : { fontFamily: scssStyles.fontFamily },
                                disableUnderline: scssStyles.styleEnvironment == 'encompass',
                                ...params.InputProps,
                            }}
                            InputLabelProps={{
                                style: {
                                    fontSize:
                                        scssStyles.styleEnvironment == 'encompass' && this.props.name == 'Requested Timeframe *'
                                            ? '14px'
                                            : scssStyles.autocompleteFontSize,
                                    marginLeft: scssStyles.styleEnvironment == 'encompass' && !selectedItem ? 8 : 0,
                                    color: scssStyles.customColor9,
                                    fontFamily: scssStyles.fontFamily,
                                },
                            }}
                        />
                    );
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={props.id}>
                        <div className={styles.menuItem}>
                            <span>
                                {CutText(option.label, 50)}
                                {option.isOnline === 'Online' ? (
                                    <span style={{ marginLeft: '20px' }} className={styles.onlineIndicator}>
                                        <FontAwesomeIcon icon={faCircle} size="xs" /> Online
                                    </span>
                                ) : (
                                    <></>
                                )}
                            </span>
                            {option.icon && <img src={option.icon} />}
                        </div>
                    </li>
                )}
            />
        );
    }
}
