import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { faEllipsisH, faEllipsisV, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip, styled } from 'MaterialUIComponents';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { React } from 'Imports';
import { TypedSplitTreatments, getSplitUserAttributes, isSplitTreatmentOn, splitTreatmentNames } from '$Utilities/split-io-sdk';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const styles = require('../styles/OptionsColumn.scss') as {
    iconButton: string;
    toolTipButton: string;
    ellipseIcon: string;
    iconButtonActive: string;
    ellipseIconActive: string;
    encIconButtonActive: string;
    encIconButton: string;
    encEllipseIcon: string;
    ellipsisAlign: string;
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -14],
                        },
                    },
                ],
            },
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: '#000',
        boxShadow: theme.shadows[1],
        margin: '0',
        padding: '0',
        aligntText: 'center',
        borderRadius: '4px',
    },
}));
interface IOptionsColumnBaseProps {
    onLiveVideoClick: any;
    onDeletePairingClick: any;
    onEditPairingClick: any;
    onOpenTooltip: any;
    onRequestVideo: any;
    isOpen: boolean;
}

type IOptionsColumnProps = IOptionsColumnBaseProps & IConfigServiceInjectedProps;

class _OptionsColumn extends React.Component<IOptionsColumnProps> {
    render(): JSX.Element {
        const isEncompass = this.props.config.isIntegrationPlatformEncompass();

        return (
            <div>
                {isEncompass ? (
                    <div className={styles.ellipsisAlign}>
                        <StyledTooltip
                            title={
                                <React.Fragment>
                                    <TypedSplitTreatments
                                        names={[splitTreatmentNames.modularUIDeviceAssociation]}
                                        attributes={getSplitUserAttributes()}
                                    >
                                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                            return (
                                                (isReady || isTimedout) &&
                                                isSplitTreatmentOn(treatments[splitTreatmentNames.modularUIDeviceAssociation]) && (
                                                    <div className={styles.toolTipButton} onClick={() => this.props.onEditPairingClick()}>
                                                        Edit Pairing
                                                    </div>
                                                )
                                            );
                                        }}
                                    </TypedSplitTreatments>

                                    <TypedSplitTreatments
                                        names={[splitTreatmentNames.cameraLiveVideo]}
                                        attributes={getSplitUserAttributes()}
                                    >
                                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                            return (
                                                (isReady || isTimedout) &&
                                                isSplitTreatmentOn(treatments[splitTreatmentNames.cameraLiveVideo]) && (
                                                    <div className={styles.toolTipButton} onClick={() => this.props.onLiveVideoClick()}>
                                                        View Live Video
                                                    </div>
                                                )
                                            );
                                        }}
                                    </TypedSplitTreatments>

                                    <TypedSplitTreatments
                                        names={[splitTreatmentNames.videoRequestDeviceAssociation]}
                                        attributes={getSplitUserAttributes()}
                                    >
                                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                            return (
                                                (isReady || isTimedout) &&
                                                isSplitTreatmentOn(treatments[splitTreatmentNames.videoRequestDeviceAssociation]) && (
                                                    <div className={styles.toolTipButton} onClick={() => this.props.onRequestVideo()}>
                                                        Request Video
                                                    </div>
                                                )
                                            );
                                        }}
                                    </TypedSplitTreatments>
                                    <div className={styles.toolTipButton} onClick={() => this.props.onDeletePairingClick()}>
                                        Remove Pairing
                                    </div>
                                </React.Fragment>
                            }
                            placement="left-start"
                            PopperProps={{
                                disablePortal: true,
                            }}
                            open={this.props.isOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                        >
                            <IconButton
                                className={this.props.isOpen ? styles.encIconButtonActive : styles.encIconButton}
                                onClick={() => this.props.onOpenTooltip()}
                                disableRipple={true}
                                disableFocusRipple={true}
                                size={'small'}
                            >
                                {this.props.isOpen ? (
                                    <FontAwesomeIcon className={styles.ellipseIconActive} icon={faXmark} style={{ color: 'white' }} />
                                ) : (
                                    <FontAwesomeIcon className={styles.encEllipseIcon} icon={faEllipsisH} />
                                )}
                            </IconButton>
                        </StyledTooltip>
                    </div>
                ) : (
                    <>
                        <StyledTooltip
                            title={
                                <React.Fragment>
                                    <TypedSplitTreatments
                                        names={[splitTreatmentNames.modularUIDeviceAssociation]}
                                        attributes={getSplitUserAttributes()}
                                    >
                                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                            return (
                                                (isReady || isTimedout) &&
                                                isSplitTreatmentOn(treatments[splitTreatmentNames.modularUIDeviceAssociation]) && (
                                                    <div className={styles.toolTipButton} onClick={() => this.props.onEditPairingClick()}>
                                                        Edit Pairing
                                                    </div>
                                                )
                                            );
                                        }}
                                    </TypedSplitTreatments>
                                    <TypedSplitTreatments
                                        names={[splitTreatmentNames.cameraLiveVideo]}
                                        attributes={getSplitUserAttributes()}
                                    >
                                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                            return (
                                                (isReady || isTimedout) &&
                                                isSplitTreatmentOn(treatments[splitTreatmentNames.cameraLiveVideo]) && (
                                                    <div className={styles.toolTipButton} onClick={() => this.props.onLiveVideoClick()}>
                                                        View Live Video
                                                    </div>
                                                )
                                            );
                                        }}
                                    </TypedSplitTreatments>
                                    <TypedSplitTreatments
                                        names={[splitTreatmentNames.videoRequestDeviceAssociation]}
                                        attributes={getSplitUserAttributes()}
                                    >
                                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                            return (
                                                (isReady || isTimedout) &&
                                                isSplitTreatmentOn(treatments[splitTreatmentNames.videoRequestDeviceAssociation]) && (
                                                    <div className={styles.toolTipButton} onClick={() => this.props.onRequestVideo()}>
                                                        Request Video
                                                    </div>
                                                )
                                            );
                                        }}
                                    </TypedSplitTreatments>
                                    <div className={styles.toolTipButton} onClick={() => this.props.onDeletePairingClick()}>
                                        Remove Pairing
                                    </div>
                                </React.Fragment>
                            }
                            placement="left-start"
                            PopperProps={{
                                disablePortal: true,
                            }}
                            open={this.props.isOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                        >
                            <IconButton
                                className={this.props.isOpen ? styles.iconButtonActive : styles.iconButton}
                                onClick={() => this.props.onOpenTooltip()}
                                disableRipple={true}
                                disableFocusRipple={true}
                                size={'small'}
                            >
                                {this.props.isOpen ? (
                                    <FontAwesomeIcon className={styles.ellipseIconActive} icon={faXmark} style={{ color: 'white' }} />
                                ) : (
                                    <FontAwesomeIcon className={styles.ellipseIcon} icon={faEllipsisV} />
                                )}
                            </IconButton>
                        </StyledTooltip>
                    </>
                )}
            </div>
        );
    }
}

export const OptionsColumn = ConfigService.inject(_OptionsColumn);
