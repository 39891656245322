import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEventTypeLabels } from '$Utilities/videoEventTypeUtility';
import { HTMLAttributes } from 'react';
import { React } from 'Imports';
import { StyledEventType } from './VideoEventDescriptionsStyles';
import { VideoEventTypePairingResponse } from '$Generated/api';
import { WidgetTooltip } from '$Components/Dashboards/WidgetTooltip';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

const styles = require('./VideoEventDescription.scss') as {
    iconPlacementEventDetails: string;
    eventDescription: string;
    iconPlacement: string;
    eventType: string;
};

interface IVideoEventDescriptionProps {
    videoEventTypePairings: VideoEventTypePairingResponse[] | undefined;
    videoEventTypeString: string | undefined;
    videoEventFontSize?: string;
    isVideoEventDetails?: boolean;
}

type VideoEventDescriptionProps = IVideoEventDescriptionProps & IConfigServiceInjectedProps & HTMLAttributes<HTMLDivElement>;

const _VideoEventDescription = (props: VideoEventDescriptionProps) => {
    const eventTypeLabel = props.videoEventTypeString;
    const eventTypeLabels = getEventTypeLabels(props.videoEventTypePairings?.map((x) => x.videoEventTypeString));

    return (
        <div className={`${styles.eventDescription} ${props.className ?? ''}`}>
            <StyledEventType fontSize={props.videoEventFontSize} className={styles.eventType}>
                {eventTypeLabel || 'Unknown'}
                {eventTypeLabels && eventTypeLabel == 'Combination' && (
                    <div className={props.isVideoEventDetails ? styles.iconPlacementEventDetails : styles.iconPlacement}>
                    <WidgetTooltip
                        prompt={<FontAwesomeIcon icon={faCircleInfo}/>}
                        description={<div>
                        {eventTypeLabels.map((x, idx) => (
                            <div key={idx}>{x}</div>
                        ))} </div>}
                        contentPosition='topCenter'
                        />

                    </div>
                )}
            </StyledEventType>
        </div>
    );
};

export const VideoEventDescription = ConfigService.inject(_VideoEventDescription);
