import { React, bind, moment, _ } from '../../../Imports';
import { Card, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '../../../MaterialUIComponents';
import * as DateFormatter from '../../Shared/DateFormatter';
import { VideoEventHistoryExport } from "./VideoEventHistoryExport";
import { VideoEventHistoryTableResponse, VideoEventResponse } from '$Generated/api';

interface ICourtReporterProps {
    event: VideoEventResponse;
    data: VideoEventHistoryTableResponse[];
}

type SortOrder = "asc" | "desc";

interface ICourtReporterState {
    sort: string;
    sortOrder: SortOrder;
}

const styles = require('./CourtReporter.scss') as {
    container: string;
    tableHeadCell: string;
};

const rowHeaders = [
    {
        label: 'Date/Time',
        id: 'actionDate',
    },
    {
        label: 'Created By',
        id: 'createdByName',
    },
    {
        label: 'Description',
        id: 'actionDescription',
    }
];

class _CourtReporter extends React.PureComponent<ICourtReporterProps, ICourtReporterState> {
    state = {
        sort: 'actionDate',
        sortOrder: 'desc' as SortOrder
    };

    @bind
    handleRequestSort(field: string): void {
        const { sort, sortOrder } = this.state;
        const isAsc = sort === field && sortOrder === "asc";
        this.setState({ sort: field, sortOrder: isAsc ? 'desc' : 'asc' });
    }

    render(): JSX.Element {
        const { sort, sortOrder } = this.state;
        const data = this.props.data || [] as VideoEventHistoryTableResponse[];
        const orderedResults = _.orderBy(data, sort, sortOrder);

        return (
            <Card className={styles.container}>
                {orderedResults.length > 0 && (
                    <VideoEventHistoryExport data={orderedResults} videoEvent={this.props.event} />
                )}

                <Table>
                    <TableHead>
                        <TableRow>
                            {rowHeaders.map((head) => {
                                return (
                                    <TableCell
                                        key={head.id}
                                        sortDirection={sort === head.id ? sortOrder : false}
                                        className={styles.tableHeadCell}
                                    >
                                        <TableSortLabel
                                            active={sort === head.id}
                                            direction={sortOrder}
                                            onClick={() => this.handleRequestSort(head.id)}
                                        >
                                            {head.label}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    {orderedResults.length > 0 && (
                        <TableBody>
                            {orderedResults.map((row: VideoEventHistoryTableResponse, idx: number) => {

                                return (
                                    <TableRow key={idx}>
                                        <TableCell>
                                            {DateFormatter.dateAndTimezone(moment(row.actionDate))}
                                        </TableCell>
                                        <TableCell>
                                            {row.createdByName}
                                        </TableCell>
                                        <TableCell>
                                            {row.actionDescription}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    )}

                </Table>
            </Card>
        );
    }
}

export const CourtReporter = _CourtReporter;