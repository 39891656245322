import { React, bind } from '../../../Imports';
import ReactPlayer from 'react-player';
import { CircularProgress } from '../../../MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/pro-solid-svg-icons';
import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons';

interface IVideoEventCardThumbnailProps {
    eventId: number;
    displayEventId: string;
    thumbnailURL: string;
    videoURL: string;
    showDetailsNavigator: boolean;
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    handleVideoPlayed: (eventid: number | undefined) => void;
}

interface IVideoEventCardThumbnailState {
    playing: boolean;
    displayPlay: boolean;
    loadVideo: boolean;
    loadedVideo: boolean;
    hasPlayed: boolean;
    expandIconHovered: boolean;
}

const styles = require('./VideoEventCardThumbnail.scss') as {
    main: string;
    thumbnail: string;
    videoPlayer: string;
    hidePlayer: string;
    playOverlay: string;
    playIcon: string;
    fullscreenOverlay: string;
    fullscreen: string;
    fullscreenOffset: string;
    fullscreenOffsetPlayed: string;
};

export class VideoEventCardThumbnail extends React.Component<IVideoEventCardThumbnailProps, IVideoEventCardThumbnailState> {
    state = {
        playing: false,
        displayPlay: false,
        loadVideo: false,
        loadedVideo: false,
        hasPlayed: false,
        expandIconHovered: false,
    };

    @bind
    handlePlayPause() {
        if (!this.state.hasPlayed) {
            this.props.handleVideoPlayed(this.props.eventId);
            this.setState({ hasPlayed: true });
        }

        if (!this.state.loadedVideo) {
            this.setState({
                playing: true,
                loadVideo: true,
                displayPlay: false,
            });
        } else {
            this.setState({
                playing: !this.state.playing,
                displayPlay: this.state.playing,
                hasPlayed: true,
            });
        }
    }

    @bind
    hideControls() {
        this.setState({
            displayPlay: false,
        });
    }

    @bind
    displayControls() {
        this.setState({
            displayPlay: true,
        });
    }

    @bind
    openDetails() {
        this.setState({
            playing: false,
            loadVideo: false,
        });
        this.props.onSelectVideoEvent(this.props.displayEventId, false);
    }

    @bind
    videoLoadComplete() {
        this.setState({
            loadedVideo: true,
        });
    }

    @bind
    expandIconHovered(hovered: boolean) {
        this.setState({
            expandIconHovered: hovered,
        });
    }

    @bind
    videoEnded() {
        this.setState({
            playing: false,
            loadVideo: true,
            displayPlay: true,
        });
    }

    render() {
        const videoUrl = this.props.videoURL;
        const imageUrl = this.props.thumbnailURL;
        return (
            <div className={styles.main} onMouseEnter={this.displayControls} onMouseLeave={this.hideControls}>
                {this.state.loadVideo ? (
                    <div onClick={this.handlePlayPause} title={this.state.playing ? 'Pause Video' : 'Play Video'}>
                        <ReactPlayer
                            url={videoUrl}
                            controls={false}
                            width="100%"
                            playing={this.state.playing}
                            className={styles.videoPlayer + (!this.state.loadedVideo ? ' ' + styles.hidePlayer : '')}
                            onReady={this.videoLoadComplete}
                            muted={true}
                            onEnded={this.videoEnded}
                        />
                        {!this.state.loadedVideo && (
                            <div>
                                <img className={styles.thumbnail} src={imageUrl} />
                                <span className={styles.playOverlay}>
                                    <CircularProgress />
                                </span>
                            </div>
                        )}
                    </div>
                ) : (
                    <img className={styles.thumbnail} src={imageUrl} onClick={this.handlePlayPause} />
                )}
                {this.props.showDetailsNavigator && (
                    <div
                        className={
                            styles.fullscreenOverlay +
                            ' ' +
                            (this.state.loadVideo && !this.state.loadedVideo
                                ? styles.fullscreenOffset
                                : this.state.hasPlayed
                                ? styles.fullscreenOffsetPlayed
                                : '')
                        }
                    >
                        <FontAwesomeIcon
                            title="View Details"
                            icon={faExpandAlt}
                            className={styles.fullscreen}
                            onClick={this.openDetails}
                            onMouseEnter={() => this.expandIconHovered(true)}
                            onMouseLeave={() => this.expandIconHovered(false)}
                        />
                    </div>
                )}
                <div onClick={this.handlePlayPause} className={styles.playOverlay} title="Play Video">
                    {this.state.displayPlay && !this.state.playing ? (
                        <FontAwesomeIcon icon={faPlayCircle} className={styles.playIcon} />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
}
