import { IDropdownItem } from '$Components/FilterComponents/VPDropdown';
import { VideoEventWorkflowStatusIdEnum } from '$Generated/api';
import { IEnum, getLabel, getLabels } from './enumUtility';

interface IVideoEventStatusEnum extends IEnum<VideoEventWorkflowStatusIdEnum> {
    isDefault: boolean;
    isOpen: boolean;
}

const statuses: IVideoEventStatusEnum[] = [{
    label: 'New',
    value: 'New',
    isDefault: true,
    isOpen: true
}, {
    label: 'In Progress',
    value: 'In_Progress',
    isDefault: true,
    isOpen: true
}, {
    label: 'Sent To Driver',
    value: 'Sent_To_Driver',
    isDefault: true,
    isOpen: true,
}, {
    label: 'Driver Accepted',
    value: 'Driver_Accepted',
    isDefault: true,
    isOpen: true,
}, {
    label: 'Driver Disputed',
    value: 'Driver_Disputed',
    isDefault: true,
    isOpen: true,
}, {
    label: 'No Response',
    value: 'No_Response',
    isDefault: true,
    isOpen: true,
}, {
    label: 'Completed',
    value: 'Completed',
    isDefault: false,
    isOpen: false,
}, {
    label: 'Dismissed',
    value: 'Dismissed',
    isDefault: false,
    isOpen: false,
}];

// In the future we are looking to add a tiered dropdown, this will need to change to define the tiers
export function getVideoEventStatusDropdownList(): IDropdownItem[] {
    return statuses;
}

export function getVideoEventStatusDefaults(): VideoEventWorkflowStatusIdEnum[] {
    return statuses
        .filter(x => x.isDefault)
        .map(x => x.value);
}

export function getStatusLabels(statusIds: VideoEventWorkflowStatusIdEnum[]): string[] {
    return getLabels(statuses, statusIds);
}

export function getStatusLabel(id: VideoEventWorkflowStatusIdEnum): string {
    return getLabel(statuses, id);
}
