import { FreezerService, managedAjaxUtil, IAjaxState } from 'Imports';
import { ConfigApiFactory, ClientConfigResponse } from '$Generated/api';
import { UserManagementService } from '$State/UserManagementFreezerService'; 
import { IsNullOrEmptyString } from '$Utilities/dataModelUtilities';

const InjectedPropName = 'config';

export interface IConfigState {
    clientConfigResults: IAjaxState<ClientConfigResponse>;
    fleetIdResults: IAjaxState<string>;
    hasError: boolean;
    isRunningStandAlone: boolean;
    integrationPlatform: string;
    deployEnvironment: string;
    fleetId: string;
    SplitClientKey: string;
    baseUrl: string;
    clientIdWebStandalone: string;
    identityServerUrl: string;
    apmServiceUrl: string;
    apmServiceName: string;
    pendoApiKey: string;
}

enum FleetIdStatus {
    MissingFleet = 0,
    MultipleFleets = -1,
    InactiveFleet = -2,
}

class ConfigFreezerService extends FreezerService<IConfigState, typeof InjectedPropName> {
   constructor(){
        super(
            {
                clientConfigResults: managedAjaxUtil.createInitialState(),
                fleetIdResults: managedAjaxUtil.createInitialState(),
                hasError: false,
                isRunningStandAlone: true,
                integrationPlatform: '',
                deployEnvironment: '',
                fleetId: '',
                SplitClientKey: '',
                baseUrl: "https://" + location.hostname,
                clientIdWebStandalone: '',
                identityServerUrl: '',
                apmServiceUrl: '',
                apmServiceName: '',
                pendoApiKey:'',

            },
            InjectedPropName            
        );
    }

    // Making this "private" on purpose to favor the more common "isRunning*" in all of our checks for this
    private getIsRunningStandAlone(): boolean {
        return this.freezer.get().isRunningStandAlone;
    }

    public setIsRunningStandAlone(isRunningStandAlone: boolean) {
        this.freezer.get().set({ isRunningStandAlone: isRunningStandAlone});
    }

    public isRunningStandAlone(): boolean { return this.getIsRunningStandAlone(); }

    public getIntegrationPlatform(): string {
        return this.freezer.get().integrationPlatform;
    }

    public setIntegrationPlatform(environmentIntegration: string) {
        this.freezer.get().set({ integrationPlatform: environmentIntegration });
    }

    public isIntegrationPlatformGeotab(): boolean { return this.getIntegrationPlatform() === 'geotab'; }
    public isIntegrationPlatformEncompass(): boolean { return this.getIntegrationPlatform() === 'encompass'; }

    public isRunningGeotabIntegrated(): boolean { return this.isIntegrationPlatformGeotab() && !this.getIsRunningStandAlone(); }
    public isRunningGeotabStandAlone(): boolean { return this.isIntegrationPlatformGeotab() && this.getIsRunningStandAlone(); }
    public isRunningEncompassIntegrated(): boolean { return this.isIntegrationPlatformEncompass() && !this.getIsRunningStandAlone(); }
    public isRunningEncompassStandAlone(): boolean { return this.isIntegrationPlatformEncompass() && this.getIsRunningStandAlone(); }

    public getBaseUrl(): string {
        return this.freezer.get().baseUrl;
    }
    
    public setBaseUrl(url: string) {
        if (!IsNullOrEmptyString(url)) {
          this.freezer.get().set({ baseUrl: url });
        }
    }

    public getGoogleMapsAPIKey() : string {
        return this.freezer.get().clientConfigResults.data?.googleMapsAPIKey || '';
    }
    public getApmServiceName() : string {
        return this.freezer.get().clientConfigResults.data?.apmServiceName || '';
    }
    public getApmServiceUrl() : string {
        return this.freezer.get().clientConfigResults.data?.apmServiceUrl || '';
    }
    public getDeployEnvironment(): string {
        return this.freezer.get().deployEnvironment;
    }

    public setDeployEnvironment(deployEnvironment: string) {
        this.freezer.get().set({ deployEnvironment: deployEnvironment });
    }
    public getPendoApiKey() : string {
        return this.freezer.get().clientConfigResults.data?.pendoApiKey || '';
    }
    public setPendoApiKey(pendoApiKey: string) {
        this.freezer.get().set({ pendoApiKey: pendoApiKey });
    }
    public isDeployEnvironmentDev(): boolean { return this.getDeployEnvironment() == 'dev'; }
    public isDeployEnvironmentQas(): boolean { return this.getDeployEnvironment() == 'qa'; }
    public isDeployEnvironmentStage(): boolean { return this.getDeployEnvironment() == 'stage'; }
    public isDeployEnvironmentProd(): boolean { return this.getDeployEnvironment() == 'prod'; }

    public getIdentityServerUrl() : string {
        return this.freezer.get().identityServerUrl;
    }

    public setIdentityServerUrl(identityServerUrl: string)  {
        return this.freezer.get().set({ identityServerUrl: identityServerUrl });
    }

    public getClientIdWebStandalone() : string {
        return this.freezer.get().clientIdWebStandalone;
    }

    public setClientIdWebStandalone(clientIdWebStandalone: string)  {
        return this.freezer.get().set({ clientIdWebStandalone: clientIdWebStandalone });
    }
    public setApmServiceName(apmServiceName: string)  {
        return this.freezer.get().set({ apmServiceName: apmServiceName });
    }
    public setApmServiceUrl(apmServiceUrl: string)  {
        return this.freezer.get().set({ apmServiceUrl: apmServiceUrl });
    }

    public getIsEncompassApiConfigReady(): boolean {
        return !IsNullOrEmptyString(this.freezer.get().clientIdWebStandalone) && !IsNullOrEmptyString(this.freezer.get().identityServerUrl);
    }

    public getFleetIdString(): string {
        return this.freezer.get().fleetId;
    }

    public setFleetId(fleetId: string) {
        return this.freezer.get().set({ fleetId: fleetId });
    }

    public isFleetIdErrorMultiple() { return this.getFleetIdString() == FleetIdStatus.MultipleFleets.toString(); }
    public isFleetIdErrorMissing() { return this.getFleetIdString() == FleetIdStatus.MissingFleet.toString(); }
    public isFleetIdErrorInactive() { return this.getFleetIdString() == FleetIdStatus.InactiveFleet.toString(); }
    
    public isFleetIdValid(): boolean {
        return !this.isFleetIdErrorMultiple() && !this.isFleetIdErrorMissing() && !this.isFleetIdErrorInactive();
    }

    public getSplitClientKey(): string {
        return this.freezer.get().SplitClientKey;
    }

    public setSplitClientKey(splitClientKey: string){
        return this.freezer.get().set({ SplitClientKey: splitClientKey });
    }

    public async getFleetId(): Promise<void | string> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'fleetIdResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }
                const AdministrativeApi = ConfigApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);                
                return AdministrativeApi.apiV1ConfigGetFleetIdGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{},
                body: {},
            },
            onOk: (data: string) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                    this.setFleetId(data); 
                }
                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getClientConfig(): Promise<void | ClientConfigResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'clientConfigResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }
                const AdministrativeApi = ConfigApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);                
                return AdministrativeApi.apiV1ConfigGetClientConfigGet(params, apiOptions.fetchOptions);
            },
            params: {
                xIntegrationUserCredential: "",
            },
            onOk: (data: ClientConfigResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                    if(data.integrationPlatform)
                        this.setIntegrationPlatform(data.integrationPlatform);
                    if(data.deployEnvironment)
                        this.setDeployEnvironment(data.deployEnvironment);
                    if(data.identityServerUrl)
                        this.setIdentityServerUrl(data.identityServerUrl);
                    if(data.clientIdWebStandalone)
                        this.setClientIdWebStandalone(data.clientIdWebStandalone);
                    if(data.apmServiceName)
                        this.setApmServiceName(data.apmServiceName);
                    if(data.apmServiceUrl)
                        this.setApmServiceUrl(data.apmServiceUrl);
                    if(data.pendoApiKey)
                        this.setPendoApiKey(data.pendoApiKey);
                }
                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }
}

export const ConfigService = new ConfigFreezerService();
export type IConfigServiceInjectedProps = ReturnType<ConfigFreezerService['getPropsForInjection']>;