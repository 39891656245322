const InfiniteScroll: any = require('react-infinite-scroller');
import { calcRequestStatus } from '$Utilities/dataModelUtilities';
import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { faCalendar, faTruck } from '@fortawesome/pro-solid-svg-icons';
import { faTimer } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React, bind, moment } from 'Imports';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from 'MaterialUIComponents';
import { VideoEventResponse } from '$Generated/api';
import { VideoRecallEventCardActionButtons } from '$Components/VideoRecall/VideoRecallEventCardActionButtons';
import * as DateFormatter from '../Shared/DateFormatter';
import * as VideoAssets from '$Components/Shared/VideoAssetsUrls';

interface IVideoEventListProps {
    items: VideoEventResponse[];
    onGridSort: (sortBy: string, sortAsc: boolean) => void;
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    loadNextPage: () => void;
    deleteVideoRecall?: (eventId: number) => void;
    promoteVideo: (eventId: number) => void;
    morePagesToLoad: boolean;
    showEventId?: boolean;
}

interface IVideoEventListState {
    sortProperty: string;
    sortDirection: 'asc' | 'desc';
}

const styles = require('./VideoEventList.scss') as {
    main: string;
    newIndicator: string;
    videoEventList: string;
    row: string;
    boxValue: string;
    videoImage: string;
    eventType: string;
    eventTypeIcon: string;
    driver: string;
    date: string;
    vehicle: string;
    displayId: string;
    duration: string;
    actions: string;
    actionContainer: string;
    scrollWindow: string;
    pointer: string;
    columnIcon: string;
    columnSmPadding: string;
    dataColumnPadding: string;
    extraMargin: string;
    headerLabel: string;
};

class _VideoEventList extends React.Component<IVideoEventListProps & IConfigServiceInjectedProps, IVideoEventListState> {
    state: IVideoEventListState = {
        sortProperty: 'date',
        sortDirection: 'desc',
    };
    private scrollParentRefVideoRecall: any;
    private rowHeaders: any[] = [];

    componentDidMount(): void {
        this.rowHeaders = [
            {
                label: 'Date/Time',
                id: 'date',
                icon: faCalendar,
            },
            {
                label: 'Vehicle',
                id: 'vehicle',
                icon: faTruck,
            },
            {
                label: 'Duration',
                id: 'duration',
                icon: faTimer,
            },
            {
                label: 'Request Status',
                id: 'status',
            },
            {
                label: 'Requested By',
                id: 'requestedBy',
            },
            {
                label: 'Requested On',
                id: 'requestedOn',
            },
            {
                label: 'Completed On',
                id: 'completedOn',
            },
        ];
        if (this.props.showEventId) {
            this.rowHeaders.splice(1, 0, { label: 'ID', id: 'displayId' });
        }
    }

    @bind
    goToDetails(eventId: string): void {
        this.props.onSelectVideoEvent(eventId, false);
    }

    handleRequestSort = (property: string): void => {
        if (!property.includes('mph') && !property.includes('kph')) {
            const { onGridSort } = this.props;
            const { sortProperty, sortDirection } = this.state;

            let newSortDir: 'asc' | 'desc' = 'asc';
            let isAsc = true;

            if (property === sortProperty) {
                newSortDir = sortDirection === 'desc' ? 'asc' : 'desc';
                isAsc = newSortDir === 'asc';
            }

            this.setState(
                {
                    sortProperty: property,
                    sortDirection: newSortDir,
                },
                () => {
                    onGridSort(property, isAsc);
                },
            );
        }
    };

    @bind
    loadVideoEventsOnScroll(): void {
        this.props.loadNextPage();
    }

    @bind
    calcCompletionDate(videoEvent: VideoEventResponse | undefined): Date | null {
        if (videoEvent) {
            const request = videoEvent.cameraRequest;
            if (!request || !request.completionDate) {
                return null;
            }
            return request.completionDate;
        }
        return null;
    }

    getDurationText(duration: number) {
        const minutes = Math.floor(duration / 60);
        const remainingSeconds = duration % 60;
        let text = '';
        if (minutes > 0) {
            text = `${minutes} MIN`;
        }
        if (remainingSeconds > 0) {
            text = `${text} ${remainingSeconds} SEC`;
        }
        return text;
    }

    getHeaderStyles(id: string): string {
        let resultStyle = styles.columnSmPadding + ' ' + styles.headerLabel;
        if (id == 'duration' || id == 'displayId') {
            resultStyle += ` ${styles[id]}`;
        }

        return resultStyle;
    }

    getColumnData(id: string, row: VideoEventResponse) {
        let tableCellStyle = styles.driver;
        if (id == 'vehicle' || id == 'date') {
            tableCellStyle = styles[id];
        }
        let tableCellValue: string | string[] = '';

        switch (id) {
            case 'duration':
                tableCellValue = this.getDurationText(row.eventDurationSeconds || 0);
                break;
            case 'displayId':
                tableCellValue = row.displayId || '';
                break;
            case 'date':
                tableCellValue = DateFormatter.dateAndTimezoneWithSeconds(moment(row.eventStartDate) || moment()).split(' ');
                break;
            case 'vehicle':
                tableCellValue = row.vehicleDescription || 'Unknown';
                break;
            case 'requestedBy':
                tableCellValue = row.requestedBy || 'Unknown';
                break;
            case 'requestedOn':
                tableCellValue = DateFormatter.dateAndTimezone(moment(row.requestedOn) || moment()).split(' ');
                break;
            case 'status':
                tableCellValue = calcRequestStatus(row);
                break;
            case 'completedOn':
                const completionDate = this.calcCompletionDate(row);
                tableCellValue =
                    completionDate !== null ? DateFormatter.dateAndTimezone(moment(completionDate || moment())).split(' ') : '';
                break;
        }

        const valueToDisplay =
            typeof tableCellValue === 'string' ? [tableCellValue] : [tableCellValue[0], tableCellValue.slice(1).join(' ')];

        return (
            <TableCell key={`${row.id}-${id}`} className={`${tableCellStyle} ${styles.dataColumnPadding}`}>
                {valueToDisplay.map((value) => {
                    return (
                        <>
                            {value}
                            <br></br>
                        </>
                    );
                })}
            </TableCell>
        );
    }

    render(): JSX.Element {
        return (
            <div className={`${styles.main}`}>
                <div className={styles.scrollWindow} ref={(ref): any => (this.scrollParentRefVideoRecall = ref)}>
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={this.loadVideoEventsOnScroll}
                        hasMore={this.props.morePagesToLoad}
                        loader={<div className="loader">Loading ...</div>}
                        useWindow={false}
                        getScrollParent={(): void => this.scrollParentRefVideoRecall}
                    >
                        <Table className={`${styles.videoEventList} ${styles.extraMargin}`}>
                            <TableHead>
                                <TableRow>
                                    {this.rowHeaders.map((head) => {
                                        return (
                                            <TableCell
                                                className={this.getHeaderStyles(head.id)}
                                                key={head.id}
                                                sortDirection={this.state.sortProperty === head.id ? this.state.sortDirection : false}
                                            >
                                                <TableSortLabel
                                                    className={styles.headerLabel}
                                                    active={this.state.sortProperty === head.id}
                                                    direction={this.state.sortDirection}
                                                    onClick={(): void => this.handleRequestSort(head.id)}
                                                >
                                                    {head.icon && (
                                                        <FontAwesomeIcon icon={head.icon} className={styles.columnIcon}></FontAwesomeIcon>
                                                    )}
                                                    {head.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.items.map((row, idx) => {
                                    const assets = row.videoAsset || [];

                                    const videoUrls = VideoAssets.findPreviewUrls(assets, this.props.config.getBaseUrl());
                                    const { DownloadUrl: downloadUrl } = videoUrls;
                                    const eventId = row.id ? row.id : 0;
                                    const requestStatus = calcRequestStatus(row);
                                    const displayEventId = row.displayId || '';
                                    return (
                                        <TableRow
                                            className={[styles.row, requestStatus === 'Completed' ? styles.pointer : ''].join(' ')}
                                            key={idx}
                                            onClick={(): void => {
                                                if (requestStatus === 'Completed') this.goToDetails(displayEventId);
                                            }}
                                        >
                                            {this.rowHeaders.map((head) => {
                                                return this.getColumnData(head.id, row);
                                            })}
                                            <TableCell className={styles.actions}>
                                                <VideoRecallEventCardActionButtons
                                                    downloadUrl={downloadUrl}
                                                    id={eventId}
                                                    workflowStep={row.workflowStep}
                                                    promoteVideo={this.props.promoteVideo}
                                                    deleteVideoRecall={this.props.deleteVideoRecall}
                                                ></VideoRecallEventCardActionButtons>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </InfiniteScroll>
                </div>
            </div>
        );
    }
}

export const VideoEventList = ConfigService.inject(_VideoEventList);
