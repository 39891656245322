import { React, bind } from 'Imports';
import { VideoEventResponse } from '$Generated/api';
import { VideoEventRecallCard } from '$Components/VideoRecall/VideoEventRecallCard';
import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import * as VideoAssets from '$Components/Shared/VideoAssetsUrls';

const InfiniteScroll: any = require('react-infinite-scroller');
interface IVideoEventCardListProps {
    items: VideoEventResponse[];
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    loadNextPage: () => void;
    morePagesToLoad: boolean;
    updateVideoEventWorkflowStatus: (eventId: number | undefined) => void;
    deleteVideoRecall?: (eventId: number) => void;
    promoteVideo: (eventId: number) => void;
}

const styles = require('$Components/VideoRecall/VideoEventCardList.scss') as {
    main: string;
    card: string;
    scrollWindow: string;
};

class _VideoEventCardList extends React.Component<IVideoEventCardListProps & IConfigServiceInjectedProps, {}> {
    private scrollParentRefRecall1: any;
    @bind
    loadVideoEventsOnScroll(): void {
        this.props.loadNextPage();
    }

    @bind
    deleteVideoRecall(eventId: number): void {
        if (this.props.deleteVideoRecall) {
            this.props.deleteVideoRecall(eventId);
        }
    }

    render(): JSX.Element {
        return (
            <div className={styles.scrollWindow} ref={(ref) => (this.scrollParentRefRecall1 = ref)}>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={this.loadVideoEventsOnScroll}
                    hasMore={this.props.morePagesToLoad}
                    loader={<div className="loader">Loading ...</div>}
                    useWindow={false}
                    getScrollParent={() => this.scrollParentRefRecall1}
                >
                    <div className={styles.main}>
                        {this.props.items.map((card: VideoEventResponse, idx: number) => {
                            const assets = card.videoAsset || [];

                            const videoUrls = VideoAssets.findPreviewUrls(assets, this.props.config.getBaseUrl());
                            return (
                                <div key={idx} className={styles.card}>
                                    <VideoEventRecallCard
                                        key={idx}
                                        onSelectVideoEvent={this.props.onSelectVideoEvent}
                                        updateVideoEventWorkflowStatus={this.props.updateVideoEventWorkflowStatus}
                                        deleteVideoRecall={this.deleteVideoRecall}
                                        promoteVideo={this.props.promoteVideo}
                                        videoEvent={card}
                                        videoEventUrls={videoUrls}
                                    ></VideoEventRecallCard>
                                </div>
                            );
                        })}
                    </div>
                </InfiniteScroll>
            </div>
        );
    }
}

export const VideoEventCardList = ConfigService.inject(_VideoEventCardList);
