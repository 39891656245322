function kphToMph(kph: number | undefined): number {
    return (kph || 0) * 0.621371;
}

export function formatSpeedString(speed: number | undefined): string {
    return speed || speed == 0 ? Math.round(speed).toString() : 'Unknown';
}

export function getSpeedValue(speed: number | undefined, isMetric: boolean) {
    return isMetric ? speed ?? 0 : kphToMph(speed)
}

export function getSpeedUnitString(isMetric: boolean, toLower?: boolean) {
    var x = isMetric ? "KPH" : "MPH";
    return toLower ? x.toLowerCase() : x;
}

export function getSpeedUnitStringEncompass(isMetric: boolean) {
    var x = isMetric ? "KILOMETERS" : "MILES";
    return x;
}

export function mphToKph(mph: number | undefined): number {
    return (mph || 0) * 1.60934
}
