import { ConfigService } from "$State/ConfigFreezerService"
import { EncompassManager } from 'integrationPartner/encompassManager';
import { GeotabManager } from 'integrationPartner/geotabManager';
import { VideoEventResponse } from '$Generated/api';

export interface IIntegrationPartnerManager
{
    configureApplicationForIntegratedMode(onDestroyCallback:() => void): void;
    configureApplicationForStandAloneMode(): void;
    handleCredentialExpiration(): Promise<void>;
    tryLogin(): Promise<void>;
    logout(): void;
    getMapUrl(data:VideoEventResponse): any;
    getLinkUrl(standaloneUrl: string): string;
}

export class IntegrationPartnerManagerFactory {
    public static getIntegrationPartnerService() : IIntegrationPartnerManager {
        if (ConfigService.isIntegrationPlatformGeotab() || GeotabManager.isIntegrated()) {
            return new GeotabManager();
        }
        else if (ConfigService.isIntegrationPlatformEncompass() || EncompassManager.isIntegrated()) {
            return new EncompassManager();
        }
        else {
            throw "Invalid integration platform";
        }
    }
}