import { React, moment, unit } from 'Imports';
import { scaleLinear } from 'd3-scale';
import * as d3 from 'd3';
import { line } from 'd3-shape';
import { SpeedGraphPointResponse, VideoEventTypePairingResponse } from '$Generated/api';
import * as DateFormatter from '$Components/Shared/DateFormatter';
import { IntegrationPartnerDataService, IIntegrationPartnerDataInjectedProps } from '$State/IntegrationPartnerDataFreezerService';
import { Tooltip, styled } from 'MaterialUIComponents';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import * as scssStyles from '$CSS/settings.scss';

const styles = require('$Components/Charts/SpeedGraph.scss') as {
    xTick: string;
    yTick: string;
    yAxisLabel: string;
    xAxisLabel: string;
    dataPointInfo: string;
    eventTypeLineLabel: string;
    main: string;
    svgStyle: string;
};

interface IGraphDataPoint {
    xValue: number; // time
    yValue?: number; // speed
    description?: string;
    pointType: string; // determines point/line color
}

interface _ISpeedGraphProps {
    width: number;
    height: number;
    speedGraphData: SpeedGraphPointResponse[];
    eventTypeData: VideoEventTypePairingResponse[]; // includes the primary event type and any sub-event types (for combined events)
    eventStartDate?: Date;
    liveTime: number;
}

type ISpeedGraphProps = _ISpeedGraphProps & IIntegrationPartnerDataInjectedProps;

interface ISpeedGraphState {
    showHoverDetails: boolean;
    hoverDetailDataItem: IGraphDataPoint;
    toolTipXPos: number;
    toolTipYPos: number;
    xDomain: number[];
    yDomain: number[];
    speedData: IGraphDataPoint[];
    eventTypeData: IGraphDataPoint[];
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.white,
            '&:before': {
                boxShadow: theme.shadows[1],
            },
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: scssStyles.lightGreyTypography,
            boxShadow: theme.shadows[1],
            textAlign: 'start',
        },
    }),
);

const leftMargin = 40; // these numbers are likely to change with other layouts. We'll need to test them out
const rightMargin = 10;
const topMargin = 10;
const bottomMargin = 30;

const eventTypeColors: { [key: string]: string } = {
    Speed: scssStyles.customColor1, // standard speed datapoint
    'Harsh Acceleration': '#E36822',
    'Harsh Braking': '#78B53B',
    'Harsh Cornering': '#AD19C6',
    'Collision Detected': '#A41F4F',
    'Cell Phone': '#9D9D9D',
    'Distracted Driver': '#13548F',
    'Driver Fatigue': '#D0D312',
    'Forward Collision Warning': '#19BBC6',
    Multiple: scssStyles.darkTypography, // multiple of a combined event's subevents overlap, not the Combined event itself,
    Default: '#303030',
};

class _SpeedGraph extends React.Component<ISpeedGraphProps, ISpeedGraphState> {
    state = {
        showHoverDetails: false,
        hoverDetailDataItem: { xValue: 0, yValue: undefined, description: '', pointType: 'Default' } as IGraphDataPoint,
        toolTipXPos: 0,
        toolTipYPos: 0,
        xDomain: [] as number[],
        yDomain: [] as number[],
        speedData: [] as IGraphDataPoint[],
        eventTypeData: [] as IGraphDataPoint[],
    };

    componentDidMount(): void {
        // get normalized X range, in number of seconds - first date over all speed data + event data is the "0" mark
        const zeroTime =
            d3.min([
                ...this.props.speedGraphData.filter((x) => !!x.time).map((x) => DateFormatter.getUnixTimeSeconds(x.time!)),
                ...this.props.eventTypeData.filter((x) => !!x.startDate).map((x) => DateFormatter.getUnixTimeSeconds(x.startDate!)),
            ]) || 0;

        // calculate speed and event data - should not change between render cycles
        const speedData = this.calculateSpeedGraphData(zeroTime, this.props.speedGraphData);
        const eventTypeData = this.calculateEventTypeGraphData(zeroTime, this.props.eventTypeData, this.props.eventStartDate);

        // both speed data and event data determines X axis domain
        const times = [...speedData.map((d) => d.xValue), ...eventTypeData.map((d) => d.xValue)];

        // only speed data determines Y axis domain
        const speeds = speedData.map((d) => d.yValue!);

        this.setState({
            xDomain: [d3.min(times), d3.max(times)] as number[],
            yDomain: [d3.min(speeds), d3.max(speeds)] as number[],
            speedData: speedData,
            eventTypeData: eventTypeData,
        });
    }

    //Method that returns an SVG 'g'/group that contains the tick and label for the x axis
    getXTicks(domain: number[], xRange: number[], yRange: number[]) {
        const xScale = scaleLinear().domain(domain).range(xRange);

        const width = xRange[1] - xRange[0];
        const pixelsPerTick = 30;
        const numberOfTicksTarget = Math.max(1, Math.floor(width / pixelsPerTick));

        const ticks = xScale.ticks(numberOfTicksTarget).map((value) => ({
            value,
            xOffset: xScale(value),
        }));

        const startTime = Math.floor(ticks[0].value) % 60;

        return ticks.map(({ value, xOffset }) => {
            const secs = (value - startTime) % 60;
            if (Math.floor(secs) === secs) {
                return (
                    <g key={value} transform={`translate(${xOffset}, ${yRange[0]})`}>
                        <line y2="6" stroke="currentColor" />
                        <text key={value} className={styles.xTick}>
                            {secs}
                        </text>
                    </g>
                );
            } else {
                return <g key={value} />;
            }
        });
    }

    //Method that returns an SVG 'g'/group that contains the tick and label for the y axis
    getYTicks(domain: number[], range: number[]) {
        const yScale = scaleLinear().domain(domain).range(range);

        const width = range[0] - range[1];
        const pixelsPerTick = 10;
        const numberOfTicksTarget = Math.max(1, Math.floor(width / pixelsPerTick));

        const ticks = yScale.ticks(numberOfTicksTarget).map((value) => ({
            value,
            xOffset: yScale(value),
        }));

        const tic = ticks.map(({ value, xOffset }) => (
            <g key={value} transform={`translate(${leftMargin - 7}, ${xOffset})`}>
                <line x2="6" stroke="currentColor" />
                <text key={value} className={styles.yTick}>
                    {value}
                </text>
            </g>
        ));

        return tic;
    }

    //Method that returns a path which represents the x axis line
    getXAxisPath(xRange: number[], yRange: number[]) {
        return <path d={['M', xRange[0], yRange[0], 'H', xRange[1]].join(' ')} fill="none" stroke="currentColor" />;
    }

    //Method that returns a path which represents the y axis line
    getYAxisPath(xRange: number[], yRange: number[]) {
        return <path d={['M', xRange[0], yRange[1], 'V', yRange[0]].join(' ')} fill="none" stroke="currentColor" />;
    }

    getYAxisLabel(label: string, xRange: number[], yRange: number[]) {
        return (
            <text
                key={label}
                className={styles.yAxisLabel}
                transform={`rotate(270) translate(${-(yRange[0] - yRange[1]) / 2}, ${leftMargin - 20})`}
            >
                {label}
            </text>
        );
    }

    getXAxisLabel(label: string, xRange: number[], yRange: number[]) {
        return (
            <text key={label} x={(xRange[1] - xRange[0]) / 2} y={yRange[0] + bottomMargin} className={styles.xAxisLabel}>
                {label}
            </text>
        );
    }

    //Returns a set of circles that represent the actual data points that we have
    getSpeedPoints(data: IGraphDataPoint[], xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleLinear<number, number>) {
        const isMetric = this.props.integrationPartnerData.getUserIsMetric();

        return (
            <g>
                {data.map((d: IGraphDataPoint, idx: number) => {
                    const description = d.description !== '' ? d.description : d.xValue.toString();
                    return (
                        <StyledTooltip
                            key={'graphPointTooltip' + idx}
                            title={
                                <React.Fragment>
                                    {
                                        <div className={styles.dataPointInfo}>
                                            {d.yValue && (
                                                <div>
                                                    Speed: {d.yValue} {isMetric ? 'kph' : 'mph'}
                                                </div>
                                            )}
                                            {(description || d.xValue) && <div>Time: {description || d.xValue}</div>}
                                        </div>
                                    }
                                </React.Fragment>
                            }
                            arrow
                            placement="right"
                        >
                            <circle
                                key={idx}
                                fill={'white'}
                                stroke={eventTypeColors['Speed']}
                                strokeWidth={1}
                                r={3}
                                cy={yScale(d.yValue!)} // speed data points must have a y-value
                                cx={xScale(d.xValue!)} // ...and an x value
                            />
                        </StyledTooltip>
                    );
                })}
            </g>
        );
    }

    getLiveLine(percentProgress: number, xScale: d3.ScaleLinear<number, number>, xDomain: number[], yRange: number[]) {
        const valueProgress = (xDomain[1] - xDomain[0]) * percentProgress;
        const xVal = xScale(xDomain[0] + valueProgress);
        return <line x1={xVal} y1={yRange[0]} x2={xVal} y2={yRange[1]} stroke="grey" strokeWidth="1" strokeDasharray="3,3" />;
    }

    getEventTypeLines(events: IGraphDataPoint[], xScale: d3.ScaleLinear<number, number>, yRange: number[]): JSX.Element[] {
        const isMetric = this.props.integrationPartnerData.getUserIsMetric();
        return events.map((d, index) => {
            const xVal = xScale(d.xValue); // normalize the time across the entire range
            const color = eventTypeColors[d.pointType || ''] || eventTypeColors['Default']; // if map is missing key, will use default instead of undefined (transparent)
            const description = d.description !== '' ? d.description : d.xValue.toString();

            return (
                <StyledTooltip
                    key={'graphEventTooltip' + index}
                    title={
                        <React.Fragment>
                            {
                                <div className={styles.dataPointInfo}>
                                    {d.yValue && (
                                        <div>
                                            Speed: {d.yValue} {isMetric ? 'kph' : 'mph'}
                                        </div>
                                    )}
                                    {(description || d.xValue) && <div>Time: {description || d.xValue}</div>}
                                </div>
                            }
                        </React.Fragment>
                    }
                    arrow
                    placement="right"
                >
                    <g key={index}>
                        <line x1={xVal} y1={yRange[0]} x2={xVal} y2={yRange[1]} stroke={color} strokeWidth="2" />
                        <circle fill={color} stroke={color} strokeWidth={1} r={3} cy={yRange[0]} cx={xVal} />
                        {
                            // for non-combined events, label the line as "Event"
                            events.length === 1 && (
                                <text x={xVal + 6} y={(yRange[0] + yRange[1]) / 2 - 10} className={styles.eventTypeLineLabel}>
                                    Event
                                </text>
                            )
                        }
                    </g>
                </StyledTooltip>
            );
        });
    }

    getCurve(data: IGraphDataPoint[], xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleLinear<number, number>) {
        let l = line()
            .curve(d3.curveMonotoneX)
            .x((d) => {
                return xScale(d[0]);
            })
            .y((d) => {
                return yScale(d[1]);
            });

        let convertedData: [number, number][] = [];
        data.forEach((point) => {
            convertedData.push([point.xValue!, point.yValue!]); // all points on the curve will have x and y values
        });

        let p = l(convertedData);
        return <path d={p as string} stroke={scssStyles.customColor1} fill={'none'} strokeWidth={2} />;
    }

    private calculateSpeedGraphData(zeroTime: number, points: SpeedGraphPointResponse[]): IGraphDataPoint[] {
        const isMetric = this.props.integrationPartnerData.getUserIsMetric();

        const final: IGraphDataPoint[] = points.map((point: SpeedGraphPointResponse) => {
            let xValue: number = 0;
            let description: string = '0';

            if (point.time) {
                xValue = DateFormatter.getUnixTimeSeconds(point.time) - zeroTime;
                description = DateFormatter.timeWithMilliseconds(moment(point.time));
            }

            const speed = unit.getSpeedValue(point.speed, isMetric);

            return {
                xValue: xValue,
                yValue: speed,
                description: description,
                pointType: 'Speed',
            } as IGraphDataPoint;
        });

        return final;
    }

    private calculateEventTypeGraphData(
        zeroTime: number,
        events: VideoEventTypePairingResponse[],
        eventStartDate?: Date,
    ): IGraphDataPoint[] {
        const final: IGraphDataPoint[] = [];
        const coalesceTimeThreshold = 0.1; // subevents closer than this are coalesced
        let previousEventTime: number;
        let coalescedTimeSum: number = 0;
        let coalescedEventCount: number = 0;

        // event type pairings are not guaranteed to have ascending start date order, even with ascending ID order
        const orderedEvents = events.sort((a, b) => {
            if (!a.startDate || !b.startDate) {
                return 0;
            }

            return a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0;
        });

        orderedEvents.forEach((event) => {
            let shouldCoalesce: boolean = false;

            // use subevent's (start) date if defined, or the event's start date if not a combined event
            const subeventDate = event.startDate || (events.length === 1 ? eventStartDate : undefined);

            const eventTime = subeventDate ? DateFormatter.getUnixTimeSeconds(subeventDate) - zeroTime : 0;
            const eventLabel = subeventDate
                ? `${DateFormatter.timeWithMilliseconds(moment(subeventDate))} (${event.videoEventTypeString})`
                : '';

            if (eventTime && previousEventTime) {
                shouldCoalesce = eventTime - previousEventTime <= coalesceTimeThreshold;

                coalescedTimeSum += eventTime;
                coalescedEventCount += 1;
            }

            if (shouldCoalesce) {
                // current event within coalesce threshold - adjust averaged event time and append label
                final[final.length - 1].xValue = coalescedTimeSum / coalescedEventCount;
                final[final.length - 1].description += `, ${eventLabel}`;
                final[final.length - 1].pointType = 'Multiple';
                return;
            }

            // reset coalesce, and treat current event as a new, distinct point
            coalescedTimeSum = eventTime;
            coalescedEventCount = 1;
            previousEventTime = eventTime;

            // add distinct non-coalesced event
            final.push({
                xValue: eventTime,
                description: eventLabel,
                pointType: event.videoEventTypeString || 'Default',
            });
        });

        return final;
    }

    render() {
        const { xDomain, yDomain, speedData, eventTypeData } = this.state;
        const { height: graphHeight, width: graphWidth, liveTime } = this.props;

        if (!xDomain.length || !yDomain.length) {
            return <div>Loading graph...</div>;
        }

        //Time min/max
        const xRange = [leftMargin, graphWidth - rightMargin];
        const xScale = scaleLinear().domain(xDomain).range(xRange);

        //speed min/max
        const yRange = [graphHeight - bottomMargin, topMargin];
        const yScale = scaleLinear().domain(yDomain).range(yRange);

        const isMetric = this.props.integrationPartnerData.getUserIsMetric();

        return (
            <div className={styles.main}>
                <svg className={styles.svgStyle}>
                    {this.getYAxisLabel(isMetric ? 'kph' : 'mph', xRange, yRange)}
                    {this.getYTicks(yDomain, yRange)}
                    <svg viewBox={`0 0 ${graphWidth} ${graphHeight}`}>
                        {this.getCurve(speedData, xScale, yScale)}
                        {this.getXAxisPath(xRange, yRange)}
                        {this.getYAxisPath(xRange, yRange)}
                        {this.getSpeedPoints(speedData, xScale, yScale)}
                        {this.getEventTypeLines(eventTypeData, xScale, yRange)}
                        {liveTime && this.getLiveLine(liveTime, xScale, xDomain, yRange)}
                    </svg>
                    {this.getXTicks(xDomain, xRange, yRange)}
                    {this.getXAxisLabel('seconds', xRange, yRange)}
                </svg>
            </div>
        );
    }
}

export const SpeedGraph = IntegrationPartnerDataService.inject(_SpeedGraph);
