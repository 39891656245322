import { React } from 'Imports';
import videojs from 'video.js';

// Styles
import 'video.js/dist/video-js.css';

interface ILiveVideoPlayerProps {
    src: string;
    onError: any;
    setVideoLoadedSuccess: () => void;
}

interface ILiveVideoPlayerState {
    player?: videojs.Player;
}

export default class LiveVideoPlayer extends React.Component<ILiveVideoPlayerProps, ILiveVideoPlayerState> {
    state: ILiveVideoPlayerState = {
        player: undefined,
    };
    private videoNode: Element | string;

    constructor(props: ILiveVideoPlayerProps) {
        super(props);
        this.videoNode = '';
    }

    componentDidMount() {
        this.setState(
            {
                player: videojs(this.videoNode, {
                    autoplay: true,
                    controls: false,
                    sources: [{ src: this.props.src, type: 'application/x-mpegURL' }],
                }),
            },
            () => {
                (this.videoNode as Element).addEventListener('error', this.props.onError);

                if (this.state.player) {
                    this.state.player.on('playing', this.props.setVideoLoadedSuccess);
                }
            },
        );
    }

    componentWillUnmount() {
        // destroy player on unmount
        if (this.state.player) {
            this.state.player.pause();
            this.state.player.trigger('ended');
            this.state.player.reset();
            this.state.player.dispose();
        }
        (this.videoNode as Element).removeEventListener('error', this.props.onError);
    }
    render() {
        const videoStyle = {
            width: '100%',
            height: '100%',
        };

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <div data-vjs-player style={{ width: '100%', height: '100%' }}>
                    <video
                        style={videoStyle}
                        ref={(node: HTMLVideoElement) => (this.videoNode = node)}
                        className="video-js"
                        disablePictureInPicture
                    />
                </div>
            </div>
        );
    }
}
