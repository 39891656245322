import * as React from 'react';

const styles = require('$Pages/InvalidRoute/InvalidRoute.scss') as {
    main: string;
};

export const InvalidRoute:React.FC = () => {
    return (
        <div className={styles.main}>

        </div>
    );
}