import { React } from 'Imports';
import { AlertTypes } from '$Utilities/Enums/AlertTypes';
import { AlertMessage } from './AlertMessage';
import { ConfirmDialog } from './ConfirmDialog';
import { useState } from 'react';
import { VideoEventService } from '$State/VideoEventFreezerService';
import * as scssStyles from '../../css/settings.scss';

interface IDismissConfirmationProps {
    eventIds?: number[];
    showDeletePopup: boolean;
    toggleDeletePopup: (selectedId?: number) => void;
    changeToDisabled: (disabled: boolean) => void;
    refreshVideoEvent?: () => void;
    onDismissVideo?: (eventId: number | undefined) => void;
    isBulk?: boolean;
}

export const DismissConfirmation = (props: IDismissConfirmationProps) => {
    const { eventIds = [], showDeletePopup, toggleDeletePopup, refreshVideoEvent } = props;
    const [resultMessage, setResultMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const isEncompass = scssStyles.styleEnvironment === 'encompass';
    const isAlert = isEncompass;
    const [alertType, setAlertType] = useState(AlertTypes.Alert);
    const acceptButton = isEncompass ? 'Yes, Dismiss' : 'Dismiss';
    const cancelButton = isEncompass ? 'No, Go back' : 'Cancel';
    const showIcon = isEncompass;
    const contentMessage = `Are you sure you want to dismiss this video event?`;

    const handleToggleDeletePopup = (e: any) => {
        e.stopPropagation();

        if (toggleDeletePopup) toggleDeletePopup();
    };

    const handleSubmit = async (e: any): Promise<void> => {
        e.stopPropagation();
        setOpenSnackbar(false);
        const ev: number = eventIds[0];

        try {
            await VideoEventService.dismissVideoEvent(ev);

            setResultMessage(`Event(s) dismissed successfully`);
            setAlertType(AlertTypes.Success);

            setTimeout(() => {
                if (refreshVideoEvent) {
                    refreshVideoEvent();
                }
            }, 2000);
        } catch {
            setResultMessage(`Unable to dismiss event(s).`);
            setAlertType(AlertTypes.Fail);
        }

        if (toggleDeletePopup) toggleDeletePopup();
        setOpenSnackbar(true);
    };

    const handleSnackClose = () => setOpenSnackbar(false);

    const defineTitle = () => (isEncompass ? `Dismiss Video Event?` : 'Confirm Dismiss');

    return (
        <>
            <ConfirmDialog
                isOpen={showDeletePopup}
                title={defineTitle()}
                contentMessage={contentMessage}
                cancelButton={cancelButton}
                acceptButton={acceptButton}
                isAlert={isAlert}
                alertType={AlertTypes.Alert}
                showIcon={showIcon}
                handleModalClosed={(e) => handleToggleDeletePopup(e)}
                handleSubmit={(e) => handleSubmit(e)}
                isButtonAlert={false}
            />

            {openSnackbar && (
                <AlertMessage
                    openSnackbar={openSnackbar}
                    alertType={alertType}
                    message={resultMessage}
                    showIcon={isEncompass}
                    isUndo={true}
                    handleSnackClose={handleSnackClose}
                    handleSubmit={(e) => handleSubmit(e)}
                />
            )}
        </>
    );
};
