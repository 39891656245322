// where T is a string-converted enum from the API / Swagger
export interface IEnum<T> {
    value: T;
    label: string;
}

export function getLabel<T>(lookup: IEnum<T>[], value: T): string {
    return lookup.find(x => x.value === value)?.label || '-';
}

export function getLabels<T>(lookup: IEnum<T>[], values: T[]): string[] {
    return values.map((x) => getLabel(lookup, x));
}
