import { React, _ } from '../Imports';
import { AuthProvider } from './AuthProvider';
import { RouteComponentProps, withRouter } from "react-router";
import { UserManagementService } from '$State/UserManagementFreezerService';

const styles = require('./Auth.scss') as {
  main: string;
  authMessage: string;
};

interface ILoginResponseState {
  authProvider: AuthProvider;
}

interface ILoginResponseProps {

} 

export default class _LoginResponse extends React.Component<ILoginResponseProps & RouteComponentProps<string>, ILoginResponseState> {
  state = {
    authProvider: new AuthProvider(),
  };

  async componentDidMount():  Promise<void> {
    if (UserManagementService.isLoggedIn()) {
      this.props.history.push('/videoevents');
    }
    else {
      this.props.history.push('/login');
    }
  }

  render(){
      return (
        <div className={styles.main}>
          <div className={styles.authMessage}>
              <div>Response in process... Completing login and redirecting...</div>
          </div>
        </div>
    );
  }
}

export const LoginResponse = withRouter(_LoginResponse);