import { FreezerService, managedAjaxUtil, IAjaxState } from 'Imports';
import { EventNotificationResponse, EventNotificationSettingsApiFactory, SubscriberResponse, SubscriberRequest } from '$Generated/api';
import { UserManagementService } from '$State/UserManagementFreezerService'; 

const InjectedPropName = 'eventNotificationSettings';

interface IEventNotificationSettingsState {
    getEventNotificationSettingsResults: IAjaxState<EventNotificationResponse[]>;
    getSubscribersResults: IAjaxState<SubscriberResponse[]>;
    createSubscriberResult: IAjaxState<void>;
    updateSubscriberResult: IAjaxState<void>;
    deleteSubscriberResult: IAjaxState<void>;
    updateEventNotificationSettingsResult: IAjaxState<void>;
    hasError: boolean;
}

class EventNotificationSettingsFreezerService extends FreezerService<IEventNotificationSettingsState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                getEventNotificationSettingsResults: managedAjaxUtil.createInitialState(),
                getSubscribersResults: managedAjaxUtil.createInitialState(),
                createSubscriberResult: managedAjaxUtil.createInitialState(),
                updateSubscriberResult: managedAjaxUtil.createInitialState(),
                deleteSubscriberResult: managedAjaxUtil.createInitialState(),
                updateEventNotificationSettingsResult: managedAjaxUtil.createInitialState(),
                hasError: false
            },
            InjectedPropName
        )
    }

    public async getEventNotificationSettings(): Promise<void | EventNotificationResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'getEventNotificationSettingsResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const eventNotificationSettingsApi = EventNotificationSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return eventNotificationSettingsApi.apiV1EventNotificationSettingsGet(params);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{},
            },            
            onOk: (data: EventNotificationResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async getSubscribers(): Promise<void | SubscriberResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'getSubscribersResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const eventNotificationSettingsApi = EventNotificationSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return eventNotificationSettingsApi.apiV1EventNotificationSettingsSubscribersGet(params);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{},
            },            
            onOk: (data: SubscriberResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async createSubscriber(model?: SubscriberRequest): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'createSubscriberResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const eventNotificationSettingsApi = EventNotificationSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return eventNotificationSettingsApi.apiV1EventNotificationSettingsSubscriberPost(params);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{},
                body: model,
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: (err, msg) => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async deleteSubscriber(id: number): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'deleteSubscriberResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const eventNotificationSettingsApi = EventNotificationSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return eventNotificationSettingsApi.apiV1EventNotificationSettingsSubscriberIdDelete(params);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ...{ id: id },
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: (err, msg) => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async updateSubscriber(id: number, model?: SubscriberRequest): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'updateSubscriberResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const eventNotificationSettingsApi = EventNotificationSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return eventNotificationSettingsApi.apiV1EventNotificationSettingsSubscriberIdPut(params);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ... { id: id },
                body: model,
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: (err, msg) => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async updateEventNotificationSettings(id: number, allowNotification?: boolean): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'updateEventNotificationSettingsResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const eventNotificationSettingsApi = EventNotificationSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return eventNotificationSettingsApi.apiV1EventNotificationSettingsIdPut(params);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
                ... { id: id },
                body: allowNotification,
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: (err, msg) => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }
}

export const EventNotificationSettingsService = new EventNotificationSettingsFreezerService();
export type IEventNotificationSettingsServiceInjectedProps = ReturnType<
    EventNotificationSettingsFreezerService['getPropsForInjection']
>;