import { React, _, InputMask, bind, moment, phone } from '../../Imports';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextValidator,
    ValidatorForm,
    FormControlLabel,
    Grid,
    InputAdornment,
} from 'MaterialUIComponents';

import { InstallerUserResponse, InstallerUserRequest } from '$Generated/api';

import * as DateFormatter from '../../components/Shared/DateFormatter';
import { isMoment } from 'moment-timezone';

import * as scssStyles from '../../css/settings.scss';
import { createTheme, ThemeProvider } from 'MaterialUIComponents';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import StyledSwitch from '$Components/Shared/MaterialUIComponents/StyledSwitch';


import ReactDateRange = require('react-date-range');

const styles = require('./EditInstallerDialog.scss') as {
    errorMessages: string;
    container: string;
    dateField: string;
    deactivateToggle: string;
    deactivateToggleEncompass: string;
    datePicker: string;
    hiddenDatePicker: string;
    dialog: string;
};



const buttonTheme = createTheme({ palette: { primary: { main: scssStyles.customColor5 } } });

type InstallerFieldsType = 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'startDate' | 'endDate';

interface IEditInstallerDialogProps {
    errorMessage: string;
    installer?: InstallerUserResponse;
    onSave: (installer: InstallerUserRequest) => Promise<boolean>;
    onCancel: () => void;
    onDeactivate: () => void;
}

interface IEditInstallerDialogState {
    installer: InstallerUserRequest;
    canEditDatesOnly: boolean;
    willDeactivate: boolean;
    errorMessages: string[];
    startDate: moment.Moment;
    startDateText: string;
    endDate: moment.Moment;
    endDateText: string;
    showStartDatePicker: boolean;
    showEndDatePicker: boolean;
    wasActiveInitially: boolean;
    isSaveDisabled: boolean;
}

class _EditInstallerDialog extends React.Component<IEditInstallerDialogProps, IEditInstallerDialogState> {
    private _validateStartDate: boolean = false;

    state = {
        installer: {
            id: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            startDate: moment().startOf('day').toDate(),
            endDate: moment().add(1, 'day').startOf('day').toDate(),
        },
        willDeactivate: false,
        canEditDatesOnly: false,
        errorMessages: [],
        startDate: moment().startOf('day'),
        startDateText: DateFormatter.date(moment().startOf('day')),
        endDate: moment().add(1, 'day').startOf('day'),
        endDateText: DateFormatter.date(moment().add(1, 'day').startOf('day')),
        showStartDatePicker: false,
        showEndDatePicker: false,
        wasActiveInitially: true,
        isSaveDisabled: false,
    };

    node: HTMLDivElement | null = null;

    componentDidMount(): void {
        const { installer } = this.props;
        if (installer) {
            this.setState({
                installer: installer as InstallerUserRequest,
                canEditDatesOnly: true,
                startDate: moment(installer.startDate),
                startDateText: DateFormatter.date(moment(installer.startDate)),
                endDate: moment(installer.endDate),
                endDateText: DateFormatter.date(moment(installer.endDate)),
                wasActiveInitially: installer.endDate ? moment(installer.endDate).isAfter(moment()) : true,
            });

            // existing installers - do not validate start date until it is changed
            ValidatorForm.addValidationRule('startDateIsTodayOrLater', () => true);
        } else {
            // new installers should always validate start date
            this.enableStartDateValidation();
        }

        ValidatorForm.addValidationRule('endDateIsAfterStartDate', (value: string) => {
            const now = moment().startOf('day');
            const startDate = moment(this.state.installer.startDate);
            const endDate = moment(value);

            if (!endDate.isValid || !endDate.isSameOrAfter(now) || !endDate.isSameOrAfter(startDate)) {
                return false;
            }

            return true;
        });
        ValidatorForm.addValidationRule('basicPhoneNumber', (value: string) => {
            const phoneNormal = phone.normalizePhoneNumber(value);

            if (value && phoneNormal === null) {
                return false;
            }

            return true;
        });

        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount(): void {
        ValidatorForm.removeValidationRule('startDateIsTodayOrLater');
        ValidatorForm.removeValidationRule('endDateIsAfterStartDate');
        ValidatorForm.removeValidationRule('basicPhoneNumber');
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    @bind
    handleClick(e: MouseEvent): void {
        if (this.node && this.node.contains(e.target as any)) {
            return;
        }
        this.setState({ showStartDatePicker: false, showEndDatePicker: false });
    }

    @bind
    cancel(): void {
        this.props.onCancel();
    }

    @bind
    async create(): Promise<void> {
        this.setState({
            errorMessages: [],
            isSaveDisabled: true,
        });
        const result = await this.props.onSave(this.state.installer);
        // Enable the button save if there is an error to allow the user correct info and try to save again
        if (!result) {
            this.setState({
                isSaveDisabled: false,
            });
        }
    }

    @bind
    handleFieldChange(field: InstallerFieldsType, event: React.ChangeEvent<HTMLInputElement>): void {
        const newInstaller: InstallerUserRequest = _.clone(this.state.installer);

        switch (field) {
            case 'firstName':
                newInstaller[field] = event.target.value.trim().length == 0 ? '' : event.target.value;
                break;
            case 'lastName':
                newInstaller[field] = event.target.value.trim().length == 0 ? '' : event.target.value;
                break;
            case 'email':
            case 'phoneNumber':
                newInstaller[field] = event.target.value;
                break;
        }

        this.setState({ installer: newInstaller });
    }

    @bind
    handleDeactivateToggle(): void {
        const newInstaller: InstallerUserRequest = _.clone(this.state.installer);

        this.props.onDeactivate();

        if (this.state.willDeactivate) {
            // set to end of today when reactivating
            newInstaller.endDate = moment().endOf('day').toDate();
        } else {
            // set to now when deactivating
            newInstaller.endDate = moment().toDate();
        }

        this.setState({
            installer: newInstaller,
            willDeactivate: !this.state.willDeactivate,
        });
    }

    @bind
    handleDateTextChange(e: any, field: string): void {
        if (field === 'startDate') {
            if (!this._validateStartDate) {
                this.enableStartDateValidation();
            }
            this.setState({
                startDateText: e.target.value,
                showStartDatePicker: false,
            });
            if (isMoment(moment(e.target.value))) {
                const newDate = moment(e.target.value);
                this.setState({
                    startDate: newDate,
                });
                const installer = { ...this.state.installer };
                installer.startDate = newDate.toDate();
                this.setState({ installer });
            }
        } else if (field === 'endDate') {
            this.setState({
                endDateText: e.target.value,
                showEndDatePicker: false,
            });
            if (isMoment(moment(e.target.value))) {
                const newDate = moment(e.target.value);
                this.setState({
                    endDate: newDate,
                });
                const installer = { ...this.state.installer };
                installer.endDate = newDate.toDate();
                this.setState({ installer });
            }
        }
    }

    @bind
    handleShowDatePicker(field: string): void {
        if (field === 'startDate') {
            this.setState({
                showStartDatePicker: true,
                showEndDatePicker: false,
            });
        } else if (field === 'endDate') {
            this.setState({
                showEndDatePicker: true,
                showStartDatePicker: false,
            });
        }
    }

    @bind
    handleDatePickerChange(date: Date, field: string): void {
        if (field === 'startDate') {
            if (!this._validateStartDate) {
                this.enableStartDateValidation();
            }
            this.setState({
                startDate: moment(date),
                startDateText: DateFormatter.date(moment(date)),
                showStartDatePicker: false,
            });
            const installer = { ...this.state.installer };
            installer.startDate = moment(date).toDate();
            this.setState({ installer });
        } else if (field === 'endDate') {
            this.setState({
                endDate: moment(date),
                endDateText: DateFormatter.date(moment(date)),
                showEndDatePicker: false,
            });
            const installer = { ...this.state.installer };
            installer.endDate = moment(date).toDate();
            this.setState({ installer });
        }
    }

    render(): JSX.Element {
        const { canEditDatesOnly, willDeactivate } = this.state;

        // native date pickers expect string, not a Date object
        const startDate = moment(this.state.installer.startDate).format('yyyy-MM-DD');
        const endDate = moment(this.state.installer.endDate).format('yyyy-MM-DD');

        const errorMessages = this.state.errorMessages.length
            ? this.state.errorMessages
            : this.props.errorMessage
            ? [this.props.errorMessage]
            : [];

        return (
            <Dialog open={true} className={styles.dialog}>
                <ValidatorForm
                    ref="form"
                    onSubmit={this.create}
                    onError={(errors: any[]) => {
                        this.setState({
                            errorMessages: errors.map((x) => {
                                return `${x.props.label}: ${x.getErrorMessage()}`;
                            }),
                        });
                    }}
                    className={styles.container}
                >
                    <DialogTitle>{canEditDatesOnly ? 'Edit' : 'Add'} Installer</DialogTitle>
                    <DialogContent>
                        {errorMessages.length ? (
                            <ul className={styles.errorMessages}>
                                {errorMessages.map((x, index) => (
                                    <li key={index}>{x}</li>
                                ))}
                            </ul>
                        ) : null}

                        <TextValidator
                            label="First Name"
                            margin="dense"
                            fullWidth={true}
                            variant="outlined"
                            onChange={(e: any): void => this.handleFieldChange('firstName', e)}
                            name="firstName"
                            value={this.state.installer.firstName}
                            validators={canEditDatesOnly ? [] : ['required']}
                            errorMessages={['This field is required']}
                            required={!canEditDatesOnly}
                            disabled={canEditDatesOnly}
                            size="small"
                        />
                        <TextValidator
                            label="Last Name"
                            margin="dense"
                            fullWidth={true}
                            variant="outlined"
                            onChange={(e: any): void => this.handleFieldChange('lastName', e)}
                            name="lastName"
                            value={this.state.installer.lastName}
                            validators={canEditDatesOnly ? [] : ['required']}
                            errorMessages={['This field is required']}
                            required={!canEditDatesOnly}
                            disabled={canEditDatesOnly}
                            size="small"
                        />
                        <TextValidator
                            label="Email Address"
                            margin="dense"
                            fullWidth={true}
                            variant="outlined"
                            onChange={(e: any): void => this.handleFieldChange('email', e)}
                            name="emailAddress"
                            value={this.state.installer.email}
                            validators={['required', 'isEmail']}
                            errorMessages={['This field is required', 'Email is not valid']}
                            required={true}
                            disabled={canEditDatesOnly}
                            size="small"
                        />

                        {canEditDatesOnly ? (
                            <TextValidator
                                label="Phone Number"
                                margin="dense"
                                fullWidth={true}
                                variant="outlined"
                                name="phoneDisabled"
                                value={this.state.installer.phoneNumber}
                                disabled={true}
                                size="small"
                            />
                        ) : (
                            <InputMask
                                maskChar=""
                                mask="999-999-9999"
                                onChange={(e: any): void => this.handleFieldChange('phoneNumber', e)}
                                name="phone"
                                value={this.state.installer.phoneNumber}
                                type="text"
                                disabled={canEditDatesOnly}
                            >
                                {(inputProps: any) => (
                                    <TextValidator
                                        label="Phone Number"
                                        margin="dense"
                                        fullWidth={true}
                                        variant="outlined"
                                        validators={['basicPhoneNumber']}
                                        errorMessages={['Phone number is not valid']}
                                        {...inputProps}
                                        size="small"
                                    />
                                )}
                            </InputMask>
                        )}
                        <Grid ref={(node) => (this.node = node)} container spacing={1}>
                            <Grid item xs={6}>
                                <InputMask
                                    value={this.state.startDateText}
                                    onChange={(e: any) => this.handleDateTextChange(e, 'startDate')}
                                    mask="19/39/2299"
                                    maskChar={' '}
                                    formatChars={{
                                        1: '[0-1]',
                                        2: '[0-2]',
                                        3: '[0-3]',
                                        9: '[0-9]',
                                    }}
                                    disabled={willDeactivate}
                                >
                                    {(inputProps: any) => (
                                        <TextValidator
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            required
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={inputProps}
                                            type={'text'}
                                            value={this.state.startDateText}
                                            name={'date'}
                                            onClick={() => this.handleShowDatePicker('startDate')}
                                            validators={['required', 'startDateIsTodayOrLater']}
                                            errorMessages={['This field is required', 'Start date must be today or later']}
                                            label={'Start Date'}
                                            containerProps={{ className: styles.dateField }}
                                            fullWidth={true}
                                            size="small"
                                        />
                                    )}
                                </InputMask>
                                <div className={this.state.showStartDatePicker ? styles.datePicker : styles.hiddenDatePicker}>
                                    <ReactDateRange.Calendar
                                        onChange={(date: Date) => this.handleDatePickerChange(date, 'startDate')}
                                        date={this.state.startDate.isValid() ? this.state.startDate.toDate() : null}
                                    />
                                </div>
                            </Grid>
                            <Grid ref={(node) => (this.node = node)} item xs={6}>
                                <InputMask
                                    value={this.state.endDateText}
                                    onChange={(e: any) => this.handleDateTextChange(e, 'endDate')}
                                    mask="19/39/2299"
                                    maskChar={' '}
                                    formatChars={{
                                        1: '[0-1]',
                                        2: '[0-2]',
                                        3: '[0-3]',
                                        9: '[0-9]',
                                    }}
                                    disabled={willDeactivate}
                                >
                                    {(inputProps: any) => (
                                        <TextValidator
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            required
                                            variant="outlined"
                                            margin="dense"
                                            inputProps={inputProps}
                                            type={'text'}
                                            value={this.state.endDateText}
                                            name={'date'}
                                            onClick={() => this.handleShowDatePicker('endDate')}
                                            validators={['required', 'endDateIsAfterStartDate']}
                                            errorMessages={[
                                                'This field is required',
                                                'End date must be today or later and after start date',
                                            ]}
                                            label={'End Date'}
                                            containerProps={{ className: styles.dateField }}
                                            fullWidth={true}
                                            size="small"
                                        />
                                    )}
                                </InputMask>
                                <div className={this.state.showEndDatePicker ? styles.datePicker : styles.hiddenDatePicker}>
                                    <ReactDateRange.Calendar
                                        onChange={(date: Date) => this.handleDatePickerChange(date, 'endDate')}
                                        date={this.state.endDate.isValid() ? this.state.endDate.toDate() : null}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        {this.state.wasActiveInitially ? (
                            <div>
                                <FormControlLabel
                                    className={
                                        scssStyles.styleEnvironment == 'encompass'
                                            ? styles.deactivateToggleEncompass
                                            : styles.deactivateToggle
                                    }
                                    value="Include Inactive"
                                    control={
                                        <StyledSwitch
                                            name="willDeactiate"
                                            title="Deactivate Installer"
                                            checked={willDeactivate}
                                            onChange={this.handleDeactivateToggle}
                                        />
                                    }
                                    label="Deactivate? "
                                    labelPlacement="start"
                                />
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <ThemeProvider theme={buttonTheme}>
                            <Button color="primary" variant="contained" type="submit" disabled={this.state.isSaveDisabled}>
                                Save
                            </Button>
                            <Button onClick={this.cancel} variant="text">
                                Cancel
                            </Button>
                        </ThemeProvider>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }

    // #region

    // remove dummy rule and re-add with actual validation
    private enableStartDateValidation(): void {
        this._validateStartDate = true;

        ValidatorForm.removeValidationRule('startDateIsTodayOrLater');

        ValidatorForm.addValidationRule('startDateIsTodayOrLater', (value: string) => {
            const now = moment().startOf('day');
            const startDate = moment(value);

            if (!startDate.isValid || !startDate.isSameOrAfter(now)) {
                return false;
            }

            return true;
        });
    }

    // #endregion
}

export const EditInstallerDialog = _EditInstallerDialog;
