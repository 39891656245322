import { React } from '../../../Imports';
import * as scssStyles from '../../../css/settings.scss';
import { Switch, styled } from 'MaterialUIComponents';
import { switchClasses, SwitchProps } from '@mui/material/Switch';

const StyledSwitchGeoTab: React.FC<SwitchProps> = styled(Switch)(({ theme }) => ({
    [`& .${switchClasses.switchBase}`]: {
        color: theme.palette.common.white,
        '&.Mui-checked': {
            color: scssStyles.customColor1,
            '&:hover': {
                color: scssStyles.customColor1,
                backgroundColor: scssStyles.mainBlue,
            },
        },
        '&:hover': {
            backgroundColor: scssStyles.mainBlue,
        },
    },
}));

const StyledSwitchEncompass: React.FC<SwitchProps> = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 21,
    padding: 0,
    [`& .${switchClasses.switchBase}`]: {
        padding: 2,
        color: theme.palette.common.white,
        [`&.${switchClasses.checked}`]: {
            transform: 'translateX(19px)',
            color: theme.palette.common.white,
            [`& + .${switchClasses.track}`]: {
                backgroundColor: scssStyles.customColor1,
                opacity: 2,
                border: 'none',
            },
        },
    },
    [`& .${switchClasses.thumb}`]: {
        width: 17,
        height: 17,
    },
    [`& .${switchClasses.track}`]: {
        borderRadius: 26 / 2,
        border: `1px solid '#52d869'`,
        backgroundColor: scssStyles.lightGrey,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
}));

const StyledSwitch: React.FC<SwitchProps> = (props) => {
    return scssStyles.styleEnvironment === 'encompass' ? <StyledSwitchEncompass {...props} /> : <StyledSwitchGeoTab {...props} />;
};

export default StyledSwitch;
