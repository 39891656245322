import { PopoverCalendar } from '$Components/Shared/PopoverCalendar';
import { CameraDeviceEarliestFootageResponse, VehicleCameraPairingResponse, VideoEventResponse } from '$Generated/api';
import { DeviceAssociationService, IDeviceAssociationServiceInjectedProps } from '$State/DeviceAssociationFreezerService';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { IVideoRecallFilterServiceInjectedProps, VideoRecallFilterService } from '$State/VideoRecallEventFilterFreezerService';
import { IVideoRecallServiceInjectedProps, VideoRecallService } from '$State/VideoRecallFreezerService';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React, bind, moment } from 'Imports';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    InputAdornment,
    Popover,
    TextField,
} from 'MaterialUIComponents';
import { isMoment } from 'moment-timezone';
import { IDropdownItem, VPDropdown } from '../../components/FilterComponents/VPDropdown';
import * as DateFormatter from '../../components/Shared/DateFormatter';
import * as scssStyles from '../../css/settings.scss';
import { SortDropdownItems } from '../../utilities/dataModelUtilities';
import { TimeField } from './TimeField';
import { TimeSelector } from './TimeSelector';

const styles = require('./VideoRequestForm.scss') as {
    inputContainer: string;
    smallContainer: string;
    requestButton: string;
    hiddenDateRange: string;
    input: string;
    borderedInput: string;
    calendarOpen: string;
    inputIcon: string;
    inputLabel: string;
    dateInputLabel: string;
    dateRangeWrapper: string;
    monthWrapper: string;
    boldText: string;
    showLeft: string;
};

interface IVideoRecallValidationResults {
    vehicle: IValidationResult;
    eventDate: IValidationResult;
    timeSelector: IValidationResult;
}

interface IValidationResult {
    error: boolean;
    errorMessage?: string;
}

const initialValidationState: IVideoRecallValidationResults = {
    vehicle: { error: false },
    eventDate: { error: false },
    timeSelector: { error: false },
};

interface IVideoRequestFormState {
    selectedVehicle: string[];
    eventDate: moment.Moment;
    eventDateText: string;
    timeSelector?: ITimeSelector;
    showDatePicker: boolean;
    showError: boolean;
    showFailure: boolean;
    showSuccess: boolean;
    showTimeSelector: boolean;
    videoRecallValidationResults: IVideoRecallValidationResults;
    triggerTimeSelector: boolean;
}

interface ITimeSelector {
    time?: string;
    ampm?: string;
    timeBefore?: number;
    timeBeforeText?: string;
    timeAfter?: number;
    timeAfterText?: string;
    timezone?: string;
    timezoneOffset?: number;
}

interface IVideoRequestFormProps {
    toggleRequestVideo: any;
    isMobileView: boolean;
    submitRecall: string;
    vehicle?: any;
    onSuccess?: any;
    onSuccessMessage?: string;
}

class _VideoRequestForm extends React.PureComponent<
    IVideoRequestFormProps &
        IVideoRecallServiceInjectedProps &
        IIntegrationPartnerDataInjectedProps &
        IDeviceAssociationServiceInjectedProps &
        IVideoRecallFilterServiceInjectedProps,
    IVideoRequestFormState
> {
    state: IVideoRequestFormState = {
        selectedVehicle: [],
        eventDate: moment(),
        eventDateText: '',
        showDatePicker: false,
        showError: false,
        showSuccess: false,
        showFailure: false,
        showTimeSelector: false,
        videoRecallValidationResults: initialValidationState,
        triggerTimeSelector: false,
    };

    eventDateRef: HTMLDivElement | null = null;
    timeRef: HTMLDivElement | null = null;

    async componentDidMount(): Promise<any> {
        await this.props.deviceAssociation.getDeviceAssociations();

        // After getting associations, call handlePairingResults get earliest footage date for each camera
        const deviceAssociationState = this.props.deviceAssociation.getState();
        const deviceAssociations = deviceAssociationState.deviceAssociationResults;
        if (deviceAssociations && deviceAssociations.data) this.handlePairingResults(deviceAssociations.data);
    }

    async UNSAFE_componentWillReceiveProps(newProps: IVideoRequestFormProps): Promise<any> {
        if (this.props.submitRecall != newProps.submitRecall && newProps.submitRecall === 'submit') {
            if (!this.props.vehicle) {
                await this.requestVideo();
            } else {
                this.setState({ triggerTimeSelector: true });
                setInterval(() => this.setState({ triggerTimeSelector: false }), 600);
            }
        }

        if (this.props.vehicle != newProps.vehicle && newProps.vehicle) {
            this.setState({ selectedVehicle: newProps.vehicle });
        }
    }

    @bind
    handlePairingResults(pairings: void | VehicleCameraPairingResponse[]) {
        if (!pairings) return;

        // Get earliest footage date for all online cameras
        pairings.forEach((pairing) => {
            if (pairing.cameraDevice && pairing.cameraDevice.serialNumber && pairing.cameraDevice.onlineStatus === 'Online') {
                // Call earliest footage endpoint, saving result in freezer for use if the user selects this vehicle.
                if (!this.props.vehicle || this.props.vehicle == pairing.vehicle?.integrationPartnerVehicleId)
                    this.props.deviceAssociation.getCameraEarliestFootage(pairing.cameraDevice.serialNumber);
            }
        });
    }

    @bind
    handleDateTextChange(e: any): void {
        this.setState({
            eventDateText: e.target.value,
            showDatePicker: false,
        });
        if (isMoment(moment(e.target.value))) {
            const newDate = moment(e.target.value);
            this.setState({
                eventDate: newDate,
            });
        }
    }

    @bind
    handleDatePickerChange(date: Date | null): void {
        if (date) {
            const { videoRecallValidationResults } = this.state;
            videoRecallValidationResults.eventDate.error = false;
            videoRecallValidationResults.eventDate.errorMessage = '';

            this.setState({
                eventDate: moment(date),
                eventDateText: DateFormatter.date(moment(date)),
                showDatePicker: false,
                videoRecallValidationResults,
            });
        } else {
            this.setState({
                showDatePicker: false,
            });
        }
    }

    @bind
    handleShowDatePicker(): void {
        const { showDatePicker } = this.state;
        this.setState({
            showDatePicker: !showDatePicker,
        });
    }

    @bind
    handleVehicleChange(newVal: any[]): void {
        const { videoRecallValidationResults } = this.state;
        videoRecallValidationResults.vehicle.error = false;
        videoRecallValidationResults.vehicle.errorMessage = '';

        this.setState({
            selectedVehicle: newVal,
            videoRecallValidationResults,
        });
    }

    @bind
    openTimeSelector(open: boolean): void {
        this.setState({
            showTimeSelector: open,
        });
    }

    @bind
    async handleTimeSelectorChange(timeSelector: ITimeSelector) {
        const { videoRecallValidationResults } = this.state;
        videoRecallValidationResults.timeSelector.error = false;
        videoRecallValidationResults.timeSelector.errorMessage = '';

        this.setState(
            {
                showTimeSelector: false,
                timeSelector,
                videoRecallValidationResults,
            },
            async () => {
                if (this.props.vehicle) {
                    await this.requestVideo();
                }
            },
        );
    }

    validateVideoRecallRequest(
        selectedVehicle: string[],
        date: moment.Moment,
        dateText: string,
        timeSelector?: ITimeSelector,
    ): IVideoRecallValidationResults {
        // initialize to successful validation
        const result: IVideoRecallValidationResults = {
            vehicle: { error: false },
            eventDate: { error: false },
            timeSelector: { error: false },
        };

        // check required vehicle
        if (selectedVehicle.length < 1) {
            result.vehicle = {
                error: true,
                errorMessage: 'Please select a vehicle',
            };
        }

        // Check required/valid date
        if (!date || !dateText || !moment(dateText, 'MM/DD/YYYY', true).isValid()) {
            result.eventDate = { error: true, errorMessage: 'Please enter a valid date' };
        }

        // check required time
        if (!timeSelector) {
            result.timeSelector = { error: true, errorMessage: 'Please select a time' };
        }

        // Check for future date
        if (!result.eventDate.error && !result.timeSelector.error) {
            const requestDates = this.getStartAndEndTime();
            if (requestDates.endDate.toDate() > moment().toDate()) {
                result.eventDate = { error: true, errorMessage: 'Date/time in future' };
                result.timeSelector = { error: true, errorMessage: 'Date/time in future' };
            }
        }

        return result;
    }

    getStartAndEndTime(): { startDate: moment.Moment; endDate: moment.Moment } {
        const { eventDate, timeSelector } = this.state;

        const startDate = eventDate.clone().startOf('day');
        const endDate = startDate.clone();

        if (timeSelector && timeSelector.time) {
            const timeSplit = timeSelector.time.split(':');
            const hour = parseInt(timeSplit[0]);
            const minute = parseInt(timeSplit[1]);
            const second = parseInt(timeSplit[2]);

            if (hour === 12) {
                startDate.hours(timeSelector.ampm === 'PM' ? 12 : 0);
                endDate.hours(timeSelector.ampm === 'PM' ? 12 : 0);
            } else {
                startDate.hours(timeSelector.ampm === 'PM' ? hour + 12 : hour);
                endDate.hours(timeSelector.ampm === 'PM' ? hour + 12 : hour);
            }

            startDate.minutes(minute);
            startDate.seconds(second);

            endDate.minutes(minute);
            endDate.seconds(second);

            startDate.add(-1 * (timeSelector.timeBefore || 0), 'seconds');
            endDate.add(timeSelector.timeAfter || 0, 'seconds');

            startDate.utcOffset(timeSelector.timezoneOffset || 0, true);
            endDate.utcOffset(timeSelector.timezoneOffset || 0, true);
        }

        return { startDate, endDate };
    }

    @bind
    toggleSuccess() {
        const { showSuccess } = this.state;
        this.setState({
            showSuccess: !showSuccess,
        });
        if (showSuccess) {
            this.props.onSuccess && this.props.onSuccess();
        }
    }

    @bind
    toggleFailure() {
        const { showFailure } = this.state;
        this.setState({
            showFailure: !showFailure,
        });
    }

    @bind
    async requestVideo() {
        const { selectedVehicle, eventDate, eventDateText, timeSelector } = this.state;
        const result = this.validateVideoRecallRequest(selectedVehicle, eventDate, eventDateText, timeSelector);
        this.setState({ videoRecallValidationResults: result });

        if (result.vehicle.error || result.eventDate.error || result.timeSelector.error) {
            return;
        }

        const requestDates = this.getStartAndEndTime();

        const videoRequest: VideoEventResponse = {
            integrationPartnerVehicleId: selectedVehicle[0],
            eventStartDate: requestDates.startDate.toDate(),
            eventEndDate: requestDates.endDate.toDate(),
        };

        // using try catch here because if server fails and returns 500 an exception is thrown from our freezer implementation
        try {
            await this.props.videoRecall.requestVideoRecall(videoRequest);
            const freezer = this.props.videoRecall.getState();
            if (freezer.requestVideoRecallResult.error) {
                this.toggleFailure();
            } else {
                this.toggleSuccess();
                // Reset Form
                this.setState({
                    selectedVehicle: [],
                    eventDate: moment(),
                    eventDateText: '',
                    timeSelector: undefined,
                });
            }
        } catch (ex) {
            this.toggleFailure();
            // If we get here just return, no reason to reload videos
            return;
        }
        VideoRecallService.clearVideoRecallVideoEvents();
        this.props.videoRecallEventFilter.setPageNumber(1);
        const eventFilter = this.props.videoRecallEventFilter.getVideoRecallEventFilter();
        this.props.videoRecall.getFilteredVideoRecalls(eventFilter);
    }

    getDevicesDrowpdownList = (pairedDevices: any[], deviceAssociations: any[]): IDropdownItem[] => {
        const devicesList: IDropdownItem[] = pairedDevices.map((d) => {
            return {
                label: d.name,
                value: d.id,
                isOnline:
                    deviceAssociations.filter((association) => association.vehicle.name === d.name)[0] != undefined
                        ? deviceAssociations.filter((association) => association.vehicle.name === d.name)[0].cameraDevice.onlineStatus
                        : 'Offline',
            };
        });

        return SortDropdownItems(devicesList);
    };

    getVehicleEarliestFootageDate = (vehicleId: string): string => {
        const deviceEarliestFootageDates = this.props.deviceAssociation.getState().cameraEarliestFootageDates;
        const { deviceAssociationResults } = this.props.deviceAssociation.getState();
        const deviceAssociations = deviceAssociationResults.data ? deviceAssociationResults.data : [];
        const selectedAssociation = deviceAssociations.filter(
            (association) => association.vehicle?.integrationPartnerVehicleId === vehicleId,
        )[0];

        // When no vehicle selected, or camera is offline, display unknown.
        if (
            !selectedAssociation ||
            !selectedAssociation.cameraDevice ||
            !selectedAssociation.cameraDevice.serialNumber ||
            !(selectedAssociation.cameraDevice.onlineStatus === 'Online')
        )
            return 'Unknown';

        const cameraSerial = selectedAssociation.cameraDevice.serialNumber;
        if (cameraSerial && deviceEarliestFootageDates[cameraSerial]) return deviceEarliestFootageDates[cameraSerial];
        else return 'Loading...';
    };

    render(): JSX.Element {
        const { deviceAssociationResults } = this.props.deviceAssociation.getState();
        const deviceAssociations = deviceAssociationResults.data ? deviceAssociationResults.data : [];

        const { deviceResults } = this.props.integrationPartnerData.getState();
        const devices = deviceResults.data ? deviceResults.data : [];

        const pairedDevices = devices.filter(
            (d) => deviceAssociations.findIndex((pairing) => pairing.vehicle?.integrationPartnerVehicleId === d.id) >= 0,
        );
        const pairedDevicesDropdownList = this.getDevicesDrowpdownList(pairedDevices, deviceAssociations);
        const eventDateLabel = scssStyles.styleEnvironment === 'encompass' || this.props.vehicle ? 'Date*' : 'Event Date*';
        const inputWidths = scssStyles.styleEnvironment == 'encompass' ? 220 : 180;

        return (
            <div>
                <div>
                    {!this.props.vehicle && (
                        <div className={styles.inputContainer}>
                            <VPDropdown
                                name={'Vehicle*'}
                                items={pairedDevicesDropdownList}
                                selectedValue={this.state.selectedVehicle}
                                isAutocomplete={true}
                                onSelectedItemChange={this.handleVehicleChange}
                                error={this.state.videoRecallValidationResults.vehicle.error}
                                width={inputWidths}
                            />
                            {this.state.videoRecallValidationResults.vehicle.errorMessage && (
                                <FormHelperText error={this.state.videoRecallValidationResults.vehicle.error}>
                                    {this.state.videoRecallValidationResults.vehicle.errorMessage}
                                </FormHelperText>
                            )}
                        </div>
                    )}
                    <div
                        ref={(ref) => (this.eventDateRef = ref)}
                        className={`${styles.inputContainer} ${this.props.vehicle && styles.smallContainer}`}
                    >
                        {this.props.vehicle && (
                            <>
                                <div className={styles.dateInputLabel}>Select the Date*</div>
                            </>
                        )}
                        <TextField
                            error={this.state.videoRecallValidationResults.eventDate.error}
                            helperText={this.state.videoRecallValidationResults.eventDate.errorMessage}
                            InputProps={{
                                className:
                                    this.state.showDatePicker && scssStyles.styleEnvironment == 'encompass'
                                        ? styles.calendarOpen
                                        : this.props.vehicle && scssStyles.styleEnvironment == 'geotab'
                                        ? styles.borderedInput
                                        : styles.input,
                                disableUnderline: scssStyles.styleEnvironment == 'encompass' || this.props.vehicle,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <FontAwesomeIcon icon={faCalendarAlt} className={styles.inputIcon} />
                                    </InputAdornment>
                                ),
                                style: { width: inputWidths },
                            }}
                            type={'text'}
                            value={this.state.eventDateText}
                            InputLabelProps={{
                                className:
                                    (scssStyles.styleEnvironment == 'encompass' || this.props.vehicle) && this.state.eventDateText !== ''
                                        ? styles.inputLabel
                                        : '',
                            }}
                            name={'eventDate'}
                            onClick={this.handleShowDatePicker}
                            {...(!this.props.vehicle && { label: eventDateLabel })}
                            {...(this.props.vehicle && { placeholder: eventDateLabel })}
                            autoComplete="off"
                        />
                        <PopoverCalendar
                            handleDatePickerChange={this.handleDatePickerChange}
                            date={this.state.eventDate.isValid() ? this.state.eventDate.toDate() : null}
                            maxDate={moment().toDate()}
                            elementRef={this.eventDateRef}
                            open={this.state.showDatePicker}
                            isVehicle={this.props.vehicle}
                            isMobileView={this.props.isMobileView}
                            earliestFootageDate={this.getVehicleEarliestFootageDate(this.state.selectedVehicle[0])}
                        ></PopoverCalendar>
                    </div>
                    {!this.props.vehicle ? (
                        <>
                            <div
                                className={styles.inputContainer}
                                ref={(ref) => (this.timeRef = ref)}
                                onClick={() => this.openTimeSelector(true)}
                            >
                                <TimeField {...this.state.timeSelector} />
                                {this.state.videoRecallValidationResults.timeSelector.errorMessage && (
                                    <FormHelperText error={this.state.videoRecallValidationResults.timeSelector.error}>
                                        {this.state.videoRecallValidationResults.timeSelector.errorMessage}
                                    </FormHelperText>
                                )}
                            </div>
                            <Popover
                                id={'timeSelector'}
                                open={this.state.showTimeSelector}
                                anchorEl={this.timeRef}
                                onClose={(event, reason) => {
                                    if (reason !== 'backdropClick') {
                                        this.openTimeSelector(false);
                                    }
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                container={() => document.getElementById('videoRecallPage')}
                            >
                                <TimeSelector
                                    {...this.state.timeSelector}
                                    onCancel={() => this.openTimeSelector(false)}
                                    onConfirm={this.handleTimeSelectorChange}
                                />
                            </Popover>
                        </>
                    ) : (
                        <>
                            <TimeSelector
                                {...this.state.timeSelector}
                                onCancel={() => this.openTimeSelector(false)}
                                onConfirm={this.handleTimeSelectorChange}
                                hideActionButtons={true}
                                triggerValidation={this.state.triggerTimeSelector}
                            />
                        </>
                    )}
                    <br />
                </div>
                <Dialog open={this.state.showSuccess} maxWidth="lg">
                    <DialogTitle className={this.props.vehicle && styles.boldText}>
                        {this.props.onSuccessMessage || 'Video Recall has been requested'}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleSuccess}>Ok</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showFailure}>
                    <DialogTitle>Oops! Something went wrong...</DialogTitle>
                    <DialogContent>There was an error on the server, please contact us for assistance.</DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleFailure}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export const VideoRequestForm = VideoRecallFilterService.inject(
    IntegrationPartnerDataService.inject(DeviceAssociationService.inject(VideoRecallService.inject(_VideoRequestForm))),
);
