import { canEditByRole, RolesEnum, VerifyRole } from '$Externals/VerifyRole';
import { cx } from '@videoplatform/css-helpers';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { IconButton, Menu, Grow, PopoverOrigin } from 'MaterialUIComponents';
import { React, bind } from '../../Imports';
import { StyledIconButton } from './MaterialUIComponents/StyledIconButton';
import { VideoEventResponse } from '$Generated/api';
import { withFeatureToggle } from '$Components/Shared/FeatureToggleHOC';

interface IVideoRatingButtonProps {
    event: VideoEventResponse;
    onRateVideo: (eventId: number | undefined, rating: number, status: string) => void;
    onDismissVideo: (eventId: number | undefined) => void;
    onClearRating: (eventid: number | undefined) => void;
    onReopenEvent: (eventid: number | undefined) => void;
    selectedRating: number | undefined | null;
    isDismissed: boolean;
    canRate: boolean;
    clearIsEnabled: boolean;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    anchor?: Element;
    disabled?: boolean;
    widthOverride?: string;
    heightOverride?: string;
}

interface IVideoRatingButtonState {
    anchor?: Element;
    ratingsMenuOpen: boolean;
    positiveAnchor?: Element;
    positiveRatingsMenuOpen: boolean;
    disabled: boolean;
}

const styles = require('./VideoRatingButton.scss') as {
    button: string;
    ratingMenu: string;
    ratingButton: string;
    ratingLink: string;
    positiveMenu: string;
    positiveButton: string;
    positivePopoverPaper: string;
    positivePopoverList: string;
    starIcon: string;
};

const defaultRatingClass = 'score-selector';
const ratingClassMap = new Map([
    ['D', 'score-dismissed'],
    ['+', 'score-positive'],
    ['-1', 'score-negative-1'],
    ['-2', 'score-negative-2'],
    ['-3', 'score-negative-3'],
    ['-4', 'score-negative-4'],
    ['1', 'score-positive'],
    ['2', 'score-positive'],
    ['3', 'score-positive'],
]);

class _VideoRatingButton extends React.PureComponent<IVideoRatingButtonProps, IVideoRatingButtonState> {
    state: IVideoRatingButtonState = {
        anchor: undefined,
        ratingsMenuOpen: false,
        positiveRatingsMenuOpen: false,
        positiveAnchor: undefined,
        disabled: false,
    };

    canEdit = false;
    componentDidMount(): void {
        this.canEdit = canEditByRole([RolesEnum.videoEventEdit]);
        this.setState({ disabled: !this.canEdit });
    }

    @bind
    showRatingsMenu(e: any): void {
        e.stopPropagation();
        this.setState({ ratingsMenuOpen: true, anchor: this.props.anchor || e.currentTarget });
    }

    @bind
    hideRatingsMenu(): void {
        this.setState({ ratingsMenuOpen: false, positiveRatingsMenuOpen: false });
    }

    @bind
    showPositiveRatingsMenu(event: any) {
        this.setState({ positiveAnchor: event.currentTarget, positiveRatingsMenuOpen: true });
    }

    @bind
    hidePositiveRatingsMenu(event: any) {
        this.setState({ positiveRatingsMenuOpen: false });
    }

    @bind
    onRateVideo(rating: number): void {
        if (this.props.canRate) {
            this.hideRatingsMenu();
            this.props.onRateVideo(
                this.props.event.id,
                rating,
                this.props.event.videoEventStatus ? this.props.event.videoEventStatus : 'New',
            );
        }
    }

    @bind
    onClearRating(): void {
        this.hideRatingsMenu();
        this.props.onClearRating(this.props.event.id);
    }

    @bind
    onDismiss(): void {
        if (this.props.canRate) {
            this.hideRatingsMenu();
            this.props.onDismissVideo(this.props.event.id);
        }
    }

    @bind
    onReopenEvent(): void {
        this.hideRatingsMenu();
        this.props.onReopenEvent(this.props.event.id);
    }

    @bind
    ratingButton(
        rating: string,
        buttonClass: string,
        onClick: (event: any) => void,
        title: string = '',
        disableable: boolean = true,
    ): JSX.Element {
        const ratingClass = ratingClassMap.get(rating) || defaultRatingClass;
        const selectedClass = this.props.selectedRating?.toString() === rating ? 'selected' : undefined;

        return (
            <IconButton
                key={rating}
                onClick={onClick}
                className={cx(['score', 'score-button', 'score-icon', ratingClass, selectedClass, styles.button, buttonClass])}
                disabled={disableable ? !this.props.canRate : false}
                title={title}
                style={rating === '+' ? { paddingTop: '0.25rem' } : {}} // hacky vertical position adjustment - "+" button only
            >
                <span>{rating}</span>
            </IconButton>
        );
    }

    @bind
    displayButton(): JSX.Element {
        let ratingClass: string;
        let buttonContent: JSX.Element | undefined;
        let buttonTitle: string;

        if (this.props.selectedRating !== null && this.props.selectedRating !== undefined) {
            const selectedRating = this.props.selectedRating.toString();

            ratingClass = ratingClassMap.get(selectedRating) || defaultRatingClass;
            buttonTitle = selectedRating;
            buttonContent = <span>{this.props.selectedRating}</span>;
        } else {
            ratingClass = defaultRatingClass;
            buttonTitle = 'Select score';
            buttonContent = undefined;
        }

        return (
            <StyledIconButton
                className={
                    !this.state.disabled && cx(['score', 'score-button', 'score-icon', ratingClass, styles.button, styles.ratingButton])
                }
                isDisabled={this.state.disabled}
                size="small"
                title={buttonTitle}
                handleOnClick={this.showRatingsMenu}
                icon={faStar}
                heightOverride={this.props.heightOverride}
                widthOverride={this.props.widthOverride}
            >
                {buttonContent}
            </StyledIconButton>
        );
    }

    render(): JSX.Element {
        const { anchor } = this.state;

        const anchorOrigin: PopoverOrigin = this.props.anchorOrigin || { horizontal: 'center', vertical: 'top' };
        const transformOrigin: PopoverOrigin = this.props.transformOrigin || { horizontal: 'center', vertical: 'bottom' };
        const containerTitle = this.props.disabled ? 'Not Available at Your Plan Level' : undefined;

        return (
            <div title={containerTitle}>
                {this.displayButton()}
                <VerifyRole allowedRole={[RolesEnum.videoEventEdit]}>
                    <>
                        <Menu
                            className={styles.ratingMenu}
                            open={this.state.ratingsMenuOpen}
                            anchorEl={anchor}
                            anchorOrigin={anchorOrigin}
                            transformOrigin={transformOrigin}
                            TransitionComponent={Grow}
                            onClose={this.hideRatingsMenu}
                            onClick={(e: any) => e.stopPropagation()}
                        >
                            {this.ratingButton('+', styles.ratingButton, this.showPositiveRatingsMenu, 'Positive')}
                            {this.ratingButton('-1', styles.ratingButton, (): void => this.onRateVideo(-1), 'Low Risk')}
                            {this.ratingButton('-2', styles.ratingButton, (): void => this.onRateVideo(-2), 'Moderate Risk')}
                            {this.ratingButton('-3', styles.ratingButton, (): void => this.onRateVideo(-3), 'High Risk')}
                            {this.ratingButton('-4', styles.ratingButton, (): void => this.onRateVideo(-4), 'Critical/Collision')}
                            <br />
                            {this.props.selectedRating !== undefined && this.props.selectedRating !== null && this.props.clearIsEnabled && (
                                <span className={styles.ratingLink} onClick={(): void => this.onClearRating()}>
                                    Clear Score
                                </span>
                            )}
                        </Menu>

                        <Menu
                            className={styles.positiveMenu}
                            open={this.state.positiveRatingsMenuOpen}
                            anchorEl={this.state.positiveAnchor}
                            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                            transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                            TransitionComponent={Grow}
                            onClose={this.hidePositiveRatingsMenu}
                            onClick={(e: any) => e.stopPropagation()}
                            PopoverClasses={{
                                paper: styles.positivePopoverPaper,
                            }}
                            MenuListProps={{
                                className: styles.positivePopoverList,
                            }}
                        >
                            {this.ratingButton('1', styles.positiveButton, (): void => this.onRateVideo(1))}
                            {this.ratingButton('2', styles.positiveButton, (): void => this.onRateVideo(2))}
                            {this.ratingButton('3', styles.positiveButton, (): void => this.onRateVideo(3))}
                        </Menu>
                    </>
                </VerifyRole>
            </div>
        );
    }
}

export const VideoRatingButton = withFeatureToggle<IVideoRatingButtonProps>(_VideoRatingButton, 'VideoEventScoring');
