import { React } from '../../Imports';
import { cx } from '@videoplatform/css-helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faMinus } from '@fortawesome/pro-solid-svg-icons';

export type PercentChangeTypeQualifier = 'Positive' | 'Negative' | 'Neutral';

interface IPercentChangeTrendProps {
    containerClassName?: string;
    iconClassName?: string;
    labelClassName?: string;
    places?: number;
    value: number;
    negativeQualifier?: PercentChangeTypeQualifier;
    neutralQualifier?: PercentChangeTypeQualifier;
    positiveQualifier?: PercentChangeTypeQualifier;
    shouldShowPercentage?: boolean;
}

// standard CSS class mapping, while allowing direct reference via qualifier props
const styles = require('./PercentChangeTrend.scss') as {
    container: string;
    icon: string;
    label: string;
    trendPositive: string;
    trendNegative: string;
    trendNeutral: string;
} & { [key: string]: string };

class _PercentChangeTrend extends React.PureComponent<IPercentChangeTrendProps, {}> {
    static defaultProps = {
        places: 0,
        value: 0,
        positiveQualifier: 'Positive',
        negativeQualifier: 'Negative',
        neutralQualifier: 'Neutral',
        shouldShowPercentage: true,
    } as IPercentChangeTrendProps;

    render(): JSX.Element {
        const containerClass = cx([styles.container, this.props.containerClassName]);

        let icon: JSX.Element;
        let qualifierClass: string;

        if (this.props.value > 0) {
            qualifierClass = styles[`trend${this.props.positiveQualifier}`];
            icon = (
                <FontAwesomeIcon
                    icon={faAngleUp}
                    className={cx([styles.icon, qualifierClass, this.props.iconClassName])}
                />
            );
        } else if (this.props.value < 0) {
            qualifierClass = styles[`trend${this.props.negativeQualifier}`];
            icon = (
                <FontAwesomeIcon
                    icon={faAngleDown}
                    className={cx([styles.icon, qualifierClass, this.props.iconClassName])}
                />
            );
        } else {
            qualifierClass = styles[`trend${this.props.neutralQualifier}`];
            icon = (
                <FontAwesomeIcon
                    icon={faMinus}
                    className={cx([styles.icon, qualifierClass, this.props.iconClassName])}
                />
            );
        }

        const labelClass = cx([styles.label, qualifierClass, this.props.labelClassName]);

        // icon / color scheme indicate trend direction w/o sign
        const value = Math.abs(this.props.value).toFixed(this.props.places);

        return (
            <div className={containerClass}>
                {icon}
                <div className={labelClass}>{this.props.shouldShowPercentage ? value : 'NA'}%</div>
            </div>
        );
    }
}

export const PercentChangeTrend = _PercentChangeTrend;
