import { React } from 'Imports';

const styles = require('./FeatureDisabledOverlay.scss') as {
    container: string;
    content: string;
    centered: string;
};

export const FeatureDisabledContent = (
    <div className={styles.content}>
        Not Available at Your Plan Level
    </div>
);

class _FeatureDisabledOverlay extends React.PureComponent<{}, {}> {
    render(): JSX.Element {
        return (
            <div className={styles.container}>
                <div className={styles.centered}>
                    { FeatureDisabledContent }
                </div>
            </div>
        );
    }
}

export const FeatureDisabledOverlay = _FeatureDisabledOverlay;
