import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from 'MaterialUIComponents';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { React } from 'Imports';
import * as scssStyles from '$CSS/settings.scss';
import styled from '@emotion/styled';

type StyledButtonContainerProps = {
    height: string;
    width: string;
};

const StyledButtonContainer = styled('div')<StyledButtonContainerProps>`
    ${(props) => props.height && `height: ${props.height}`};
    ${(props) => props.width && `width: ${props.width}`};
    text-align: center;
`;

interface ThemeColors {
    activeBackgroundColor: string;
    inactiveBackgroundColor: string;
    hoverColor: string;
    activeIconColor: string;
    inactiveIconColor: string;
    transparentLayer: string;
}

const themeColors: ThemeColors = {
    activeBackgroundColor: scssStyles.customColor1,
    inactiveBackgroundColor: scssStyles.inactiveBackgroundColor,
    hoverColor: scssStyles.activeButtonHoverBackgroundColor,
    activeIconColor: 'white',
    inactiveIconColor: scssStyles.inactiveIconColor,
    transparentLayer: scssStyles.buttonHoverBackgroundColor,
};

interface Theme {
    hoverColor: (isDisabled: boolean, transparent: boolean) => string;
    backgroundColor: (isDisabled: boolean, transparent: boolean) => string;
    iconColor: (isDisabled: boolean) => string;
}

function createTheme(): Theme {
    const colors = themeColors;
    const { activeBackgroundColor, inactiveBackgroundColor, activeIconColor, inactiveIconColor, hoverColor, transparentLayer } = colors;
    return {
        hoverColor: (isDisabled: boolean, transparent: boolean) => (isDisabled ? inactiveBackgroundColor : (transparent) ? transparentLayer : hoverColor),
        backgroundColor: (isDisabled: boolean, transparent: boolean) => (isDisabled ? inactiveBackgroundColor : (transparent) ? "" : activeBackgroundColor ),
        iconColor: (isDisabled: boolean) => (isDisabled ? inactiveIconColor : activeIconColor),
    };
}

type SytledIconButtonProps = {
    icon: IconDefinition;
    title: string;
    handleOnClick: any;
    isDisabled: boolean;
    widthOverride?: string;
    heightOverride?: string;
    children?: any;
    className?: any;
    iconStyles?: any;
    transparent?: any;
    size?: 'small' | 'medium';
};

export const StyledIconButton = ({
    icon,
    title,
    isDisabled,
    handleOnClick,
    widthOverride,
    heightOverride,
    children,
    className,
    iconStyles,
    transparent,
    size
}: SytledIconButtonProps) => {
    const width = widthOverride || '32px';
    const height = heightOverride || '32px';
    const theme = createTheme();

    const IconButtonStyled = styled(IconButton)`
        background-color: ${theme.backgroundColor(isDisabled, transparent)};
        cursor: ${isDisabled ? 'default' : 'pointer'};
        height: ${height};
        width: ${width};
        padding: 3px;

        &:hover {
            background-color: ${theme.hoverColor(isDisabled, transparent)};
        }
    `;

    const StyledFontAwsomeIcon = styled(FontAwesomeIcon)`
        color: ${theme.iconColor(isDisabled)};
        font-size: 16px;
    `;

    const StyledChildrenContainer = styled('span')`
        > * {
            color: ${theme.iconColor(isDisabled)};
            font-size: 16px;
        }
    `;

    const getChildrenData = () => {
        if (isDisabled) {
            return <StyledChildrenContainer>{children}</StyledChildrenContainer>;
        }

        return <>{children}</>;
    };

    return (
        <StyledButtonContainer onClick={handleOnClick} height={height} width={width}>
            <IconButtonStyled title={title} disableRipple={isDisabled} className={className} size={size}>
                {children ? getChildrenData() : <StyledFontAwsomeIcon icon={icon} className={iconStyles} />}
            </IconButtonStyled>
        </StyledButtonContainer>
    );
};
