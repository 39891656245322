import { IConfigServiceInjectedProps, ConfigService } from "$State/ConfigFreezerService";
import { GetImageUrl } from "$Utilities/dataModelUtilities";
import { React } from "../../Imports";

interface IGeotabConfigurationProps { }

interface IGeotabConfigurationState { }

export class _GeotabConfiguration extends React.Component<
    IGeotabConfigurationProps & IConfigServiceInjectedProps,
    IGeotabConfigurationState
    > {
    constructor(props: IConfigServiceInjectedProps) {
        super(props);
    }

    render() {
        let currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        let currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;

        const baseUrl = this.props.config.getBaseUrl();
        const geotabConfigObject: any = {
            name: "VidLocate",
            supportEmail: "gmautz@yaharasoftware.com",
            version: "1.0",
            items: [
                {
                    path: "ActivityLink",
                    menuId: "VidLocateLink",
                    menuName: {
                        en: "Video Platform"
                    },
                    icon: GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'ic_camera.png'),
                    svgIcon: GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'ic_camera.svg')
                },
                {
                    path: "VidLocateLink/",
                    menuName: {
                        en: "Video Events"
                    },
                    url: baseUrl + "/VideoEvents",
                    icon: GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'ic_video_events.png'),
                    svgIcon: GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'ic_video_events.svg')
                },
                {
                    url: baseUrl + "/VideoRecall",
                    path: "VidLocateLink/",
                    menuName: {
                        en: "Video Recall"
                    },
                    icon: GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'ic_video_recall.png'),
                    svgIcon: GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'ic_video_recall.svg')

                },
                {
                    path: "VidLocateLink/",
                    menuId: "ConfigurationLink",
                    menuName: {
                        en: "Configuration"
                    },
                    icon: GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'ic_settings.png'),
                    iconSvg: GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'ic_settings.svg')
                },
                {
                    url: baseUrl + "/Installers",
                    path: "ConfigurationLink/",
                    menuName: {
                        en: "Installers"
                    }
                },
                {
                    url: baseUrl + "/DeviceAssociations",
                    path: "ConfigurationLink/",
                    menuName: {
                        en: "Device Association"
                    }
                },
                {
                    url: baseUrl + "/Settings",
                    path: "ConfigurationLink/",
                    menuName: {
                        en: "Settings"
                    }
                },
                {
                    url: baseUrl + "/VideoDashboard",
                    path: "VidLocateLink/",
                    menuName: {
                        en: "Video Dashboard"
                    },
                    icon: GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'ic_video_dashboard.svg'),
                    svgIcon: GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'ic_video_dashboard.svg')
                }
            ],
            isSigned: false
        };

        return (
            <div>
                {JSON.stringify(geotabConfigObject)}
            </div>
        );
    }
}

export const GeotabConfiguration = ConfigService.inject(_GeotabConfiguration);
