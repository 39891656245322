import { Button, IconButton, ThemeProvider, createTheme } from 'MaterialUIComponents';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React, bind, moment } from '../../Imports';
import * as scssStyles from '../../css/settings.scss';
import styled from '@emotion/styled';

const ReactDateRange: any = require('react-date-range');

interface IDateRangePickerProps {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    show: boolean;
    fieldSelected: string;
    onRangeChange: (startDate: moment.Moment, endDate: moment.Moment, submit: boolean) => void;
    onPickerClose: () => void;
    isMobileView: boolean;
    minDate?: Date;
    maxDate?: Date;
    showMessage: boolean;
    numDays?: number;
}

const styles = require('./VPDateRangePicker.scss') as {
    main: string;
    cancelButton: string;
    quickRangeButtonContainer: string;
    quickRangeButton: string;
    mobile: string;
    picker: string;
    textArea: string;
    textAreaTitle: string;
    textAreaVerbiage: string;
};

const StyledIconButton = styled(IconButton)`
    font-size: 16px;
    color: ${scssStyles.closeIconColor};
    padding: 4px 10px;
    margin-bottom: ${scssStyles.closeIconMarginBottom};

    &:hover {
        background-color: transparent;
    }
`;

const buttonTheme = createTheme({ palette: { primary: { main: scssStyles.customColor5 }, secondary: { main: scssStyles.buttonDarkTypography } } });

export class VPDateRangePicker extends React.PureComponent<IDateRangePickerProps> {
    node: HTMLDivElement | null = null;

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    @bind
    setTodayClick() {
        const today = moment();
        today.startOf('day');

        const { onRangeChange } = this.props;

        onRangeChange(today, today, true);
    }

    // Method to check if the supplied date range spans justtoday.
    // This means the start and end day are the same and are today.
    @bind
    isToday(startDate?: moment.Moment, endDate?: moment.Moment): boolean {
        const today = moment().startOf('day');

        return moment(startDate).isSame(today) && moment(endDate).isSame(today);
    }

    @bind
    setThisWeekClick() {
        const { onRangeChange } = this.props;
        const endDate = moment().startOf('day');
        const beginDate = moment().subtract(6, 'day').startOf('day');

        onRangeChange(beginDate, endDate, true);
    }

    // Method to check if the supplied date range covers the current week.
    // This means the 1st day of week through then end of week
    @bind
    isThisWeek(startDate?: moment.Moment, endDate?: moment.Moment): boolean {
        const thisWeekEndDate = moment().startOf('day');
        const thisWeekBeginDate = moment().subtract(6, 'day').startOf('day');

        return moment(startDate).isSame(thisWeekBeginDate) && moment(endDate).isSame(thisWeekEndDate);
    }

    @bind
    setThisMonthClick() {
        const { onRangeChange } = this.props;
        const endDate = moment().startOf('day');
        const beginDate = moment().subtract(29, 'day').startOf('day');

        onRangeChange(beginDate, endDate, true);
    }

    // Method to check if the supplied date range covers the current month.
    // This means the 1st through the end of the month, no more.
    @bind
    isThisMonth(startDate?: moment.Moment, endDate?: moment.Moment): boolean {
        const thisMonthEndDate = moment().startOf('day');
        const thisMonthBeginDate = moment().subtract(29, 'day').startOf('day');

        return moment(startDate).isSame(thisMonthBeginDate) && moment(endDate).isSame(thisMonthEndDate);
    }

    @bind
    handleRangeChange(payload: any) {
        const { onRangeChange } = this.props;
        const start: moment.Moment = moment(payload.selection.startDate);
        const end: moment.Moment = moment(payload.selection.endDate);
        onRangeChange(start, end, end.isAfter(start));
    }

    @bind
    handleClick(e: MouseEvent) {
        const { onPickerClose } = this.props;
        if (this.node && this.node.contains(e.target as any)) {
            return;
        }
        onPickerClose();
    }

    @bind
    toggleShow() {
        const { onPickerClose } = this.props;
        onPickerClose();
    }

    render() {
        const { startDate, endDate, show, fieldSelected, minDate, maxDate, showMessage, numDays } = this.props;

        const selectionRange = {
            startDate: startDate?.toDate(),
            endDate: endDate?.toDate(),
            key: 'selection',
        };

        const mainClass = [styles.main];

        return (
            <div
                ref={(node) => (this.node = node)}
                className={`${styles.main} ${this.props.isMobileView ? styles.mobile : ''} ${showMessage ? styles.picker : undefined}`}
                style={{ display: show ? 'flex' : 'none', flexWrap: 'wrap' }}
            >
                <ReactDateRange.DateRange
                    onChange={this.handleRangeChange}
                    direction="vertical"
                    retainEndDateOnFirstSelection={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={[selectionRange]}
                    focusedRange={[0, fieldSelected === 'endDate' ? 1 : 0]}
                    showPreview={scssStyles.styleEnvironment == 'encompass' ? false : true}
                    {...(minDate && { minDate: minDate })}
                    {...(maxDate && { maxDate: maxDate })}
                />
                <div className={styles.quickRangeButtonContainer}>
                    <div className={styles.cancelButton}>
                        <StyledIconButton aria-label="cancel" onClick={this.toggleShow} size="small">
                            <FontAwesomeIcon icon={faTimes} />
                        </StyledIconButton>
                    </div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button
                            color={this.isToday(startDate, endDate) ? 'primary' : 'secondary'}
                            variant="outlined"
                            aria-label="Today"
                            onClick={this.setTodayClick}
                            className={styles.quickRangeButton}
                        >
                            Today
                        </Button>
                        <Button
                            color={this.isThisWeek(startDate, endDate) ? 'primary' : 'secondary'}
                            variant="outlined"
                            aria-label="Today"
                            onClick={this.setThisWeekClick}
                            className={styles.quickRangeButton}
                        >
                            Last 7 Days
                        </Button>
                        <Button
                            color={this.isThisMonth(startDate, endDate) ? 'primary' : 'secondary'}
                            variant="outlined"
                            aria-label="Today"
                            onClick={this.setThisMonthClick}
                            className={styles.quickRangeButton}
                        >
                            Last 30 Days
                        </Button>
                    </ThemeProvider>
                </div>
                {showMessage && numDays && (
                    <div className={styles.textArea}>
                        <div className={styles.textAreaTitle}>{numDays} days selected</div>
                        <div className={styles.textAreaVerbiage}>
                            A max of 90 days can be selected as the date range from the start to end date.
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
