import { useState, useEffect, useRef, useCallback } from 'react';
import { React, moment, unit } from '../../../Imports';

import { CSVLink } from 'react-csv';
import { Button, Dialog, DialogActions, DialogContent, IconButton } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons';
import * as DateFormatter from '../../Shared/DateFormatter';
import * as scssStyles from '../../../css/settings.scss';
import { canEditByRole, RolesEnum } from '../../../externals/VerifyRole';
import { getStatusLabel } from '../../../utilities/videoEventStatusUtility';
import { VideoEventService } from '../../../state/VideoEventFreezerService';
import { VideoEventFilterServiceMultiselect } from '../../../state/VideoEventFilterFreezerServiceMultiselect';
import { IntegrationPartnerDataService } from '../../../state/IntegrationPartnerDataFreezerService';

import styles = require('./VideoEventExport.scss');
import { VideoEventFilterMultiselectRequest, VideoEventResponse } from '$Generated/api';

interface CsvData {
    EventType: string | undefined;
    DisplayId: string | undefined;
    Status: string | undefined;
    FullName: string | undefined;
    FirstName: string | undefined;
    LastName: string | undefined;
    Vehicle: string | undefined;
    EventDate: string | undefined;
    EventTime: string | undefined;
    StreetNumber: string | undefined;
    StreetName: string | undefined;
    City: string | undefined;
    Region: string | undefined;
    Start: string | undefined;
    End?: string | undefined;
    Change?: string | undefined;
    EventScore: string | undefined;
    Flagged: string | undefined;
    Dismissed: string | undefined;
}

const VideoEventExportComponent: React.FC = () => {
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [videoEventExportData, setVideoEventExportData] = useState<CsvData[]>([]);

    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    useEffect(() => {
        setCanEdit(canEditByRole([RolesEnum.videoEventView, RolesEnum.videoEventEdit]));
    }, []);

    const getCsvTitle = (filter: VideoEventFilterMultiselectRequest): string => {
        if (filter && filter.startDate && filter.endDate) {
            let formattedStartDate = moment(filter.startDate).utc().format('YYYY_MM_DD');
            let formattedEndDate = moment(filter.endDate).utc().format('YYYY_MM_DD');
            let title = 'Video Events';
            title += '_' + formattedStartDate;

            if (formattedStartDate !== formattedEndDate) {
                title += '-' + formattedEndDate;
            }
            return title;
        }
        return 'Video Events';
    };
    const showConfirmDialog = useCallback(
        (show: boolean) => {
            if (!canEdit) return;
            setConfirmDialogOpen(show);
        },
        [canEdit, setConfirmDialogOpen],
    );

    const formatEventTypeToCommaSeparatedValue = (eventTypes: string[]) => {
        if (!eventTypes || eventTypes.length === 0) return 'Unknown';

        return eventTypes
            .map((x) => {
                if (['forwardcollisionwarning', 'forward collision warning'].includes(x.toLowerCase())) {
                    return 'FCW';
                }
                if (x.includes('Gforce')) {
                    x = x.replace('Gforce', '');
                }
                if (x.includes('RightTurn')) {
                    x = x.replace('RightTurn', 'Cornering');
                }
                return x.replace(/([A-Z])/g, ' $1').trim();
            })
            .join(', ');
    };

    useEffect(() => {
        if (videoEventExportData.length) {
            csvLinkRef.current?.link?.click();
            setVideoEventExportData([]);
        }
    }, [videoEventExportData]);

    const downloadCsv = useCallback(
        async (isEncompass: boolean, isMetric: boolean) => {
            if (!canEdit) return;
            const filter = VideoEventFilterServiceMultiselect.getVideoEventFilter();
            filter.itemsPerPage = undefined;
            showConfirmDialog(false);
            await VideoEventService.getEventExportDataMultiSelect(filter);
            const freezer = VideoEventService.getState();
            const { videoEventExportResults } = freezer;
            const myData = videoEventExportResults.data?.data || [];

            const csvData: CsvData[] = myData.map((val: VideoEventResponse) => {
                const eventTypes = val.videoEventTypePairings?.map((eventType: any) => eventType?.videoEventTypeString ?? '');
                const nameArray =
                    val.driverName === '' || val.driverName === ' ' || !val.driverName
                        ? ['Unknown', 'Unknown']
                        : val.driverName?.split(' ');
                const speedStart = unit.getSpeedValue(val.kphStart, isMetric);

                if (isEncompass)
                    return {
                        EventType: formatEventTypeToCommaSeparatedValue(eventTypes ?? []),
                        DisplayId: val?.displayId ?? '',
                        Status: val.videoEventStatus !== undefined ? getStatusLabel(val.videoEventStatus) : 'Unknown',
                        FullName: val.driverName?.trim() || 'Unknown',
                        FirstName: nameArray[0],
                        LastName: nameArray[nameArray.length - 1],
                        Vehicle: val.vehicleDescription || 'Unknown',
                        EventDate: DateFormatter.date(moment(val.eventStartDate) || moment()) + ' ',
                        EventTime: DateFormatter.timeWithSecondsAndTimezone(moment(val.eventStartDate) || moment()) + ' ',
                        StreetNumber: val.addressStreetNumber || '',
                        StreetName: val.addressStreetName || '',
                        City: val.addressCityName || '',
                        Region: val.addressRegion || '',
                        Start: unit.formatSpeedString(speedStart),
                        EventScore: val.score?.toString() || '',
                        Flagged: val.isFlagged ? 'Flagged' : 'Not Flagged',
                        Dismissed: val.videoEventStatus !== 'Dismissed' ? 'False' : 'True',
                    };
                else {
                    const speedEnd = unit.getSpeedValue(val.kphEnd, isMetric);
                    const change = Math.abs(Math.round(speedEnd) - Math.round(speedStart)).toString();
                    return {
                        EventType: formatEventTypeToCommaSeparatedValue(eventTypes ?? []),
                        DisplayId: val?.displayId ?? '',
                        Status: val.videoEventStatus !== undefined ? getStatusLabel(val.videoEventStatus) : 'Unknown',
                        FullName: val.driverName?.trim() || 'Unknown',
                        FirstName: nameArray[0],
                        LastName: nameArray[nameArray.length - 1],
                        Vehicle: val.vehicleDescription || 'Unknown',
                        EventDate: DateFormatter.date(moment(val.eventStartDate) || moment()) + ' ',
                        EventTime: DateFormatter.timeWithSecondsAndTimezone(moment(val.eventStartDate) || moment()) + ' ',
                        StreetNumber: val.addressStreetNumber || '',
                        StreetName: val.addressStreetName || '',
                        City: val.addressCityName || '',
                        Region: val.addressRegion || '',
                        Start: unit.formatSpeedString(speedStart),
                        End: unit.formatSpeedString(speedEnd),
                        Change: change,
                        EventScore: val.score?.toString() || '',
                        Flagged: val.isFlagged ? 'Flagged' : 'Not Flagged',
                        Dismissed: val.videoEventStatus !== 'Dismissed' ? 'False' : 'True',
                    };
                }
            });

            setVideoEventExportData(csvData);
            getCsvTitle(filter);
        },
        [canEdit, setVideoEventExportData],
    );

    const getExportHeaders = (isEncompass: boolean, isMetric: boolean): { key: string; label: string }[] => {
        if (isEncompass) {
            let speedLabel = isMetric ? 'Kilometers Per Hour' : 'Miles Per Hour';
            const csvHeaders = [
                { label: 'Event Type', key: 'EventType' },
                { label: 'ID', key: 'DisplayId' },
                { label: 'Status', key: 'Status' },
                { label: 'Driver Full Name', key: 'FullName' },
                { label: 'Driver First Name', key: 'FirstName' },
                { label: 'Driver Last Name', key: 'LastName' },
                { label: 'Vehicle', key: 'Vehicle' },
                { label: 'Event Date', key: 'EventDate' },
                { label: 'Event Time', key: 'EventTime' },
                { label: 'Street Number', key: 'StreetNumber' },
                { label: 'Street Name', key: 'StreetName' },
                { label: 'City', key: 'City' },
                { label: 'Region', key: 'Region' },
                { label: speedLabel, key: 'Start' },
                { label: 'Event Score', key: 'EventScore' },
                { label: 'Flagged', key: 'Flagged' },
                { label: 'Dismissed', key: 'Dismissed' },
            ];
            return csvHeaders;
        } else {
            let speedLabel = isMetric ? 'KPH' : 'MPH';
            const csvHeaders = [
                { label: 'Event Type', key: 'EventType' },
                { label: 'ID', key: 'DisplayId' },
                { label: 'Status', key: 'Status' },
                { label: 'Driver Full Name', key: 'FullName' },
                { label: 'Driver First Name', key: 'FirstName' },
                { label: 'Driver Last Name', key: 'LastName' },
                { label: 'Vehicle', key: 'Vehicle' },
                { label: 'Event Date', key: 'EventDate' },
                { label: 'Event Time', key: 'EventTime' },
                { label: 'Street Number', key: 'StreetNumber' },
                { label: 'Street Name', key: 'StreetName' },
                { label: 'City', key: 'City' },
                { label: 'Region', key: 'Region' },
                { label: speedLabel + ' Start', key: 'Start' },
                { label: speedLabel + ' End', key: 'End' },
                { label: (speedLabel = ' Change'), key: 'Change' },
                { label: 'Event Score', key: 'EventScore' },
                { label: 'Flagged', key: 'Flagged' },
                { label: 'Dismissed', key: 'Dismissed' },
            ];
            return csvHeaders;
        }
    };

    const isMetric = IntegrationPartnerDataService.getUserIsMetric();
    const isEncompass = scssStyles.styleEnvironment === 'encompass';
    const csvHeaders = getExportHeaders(isEncompass, isMetric);
    const csvTitle: string = getCsvTitle(VideoEventFilterServiceMultiselect.getVideoEventFilter());

    return (
        <span>
            <span className={styles.fileDownload} onClick={() => showConfirmDialog(true)}>
                <IconButton
                    className={
                        !canEdit
                            ? styles.disabled
                            : scssStyles.styleEnvironment == 'encompass'
                              ? styles.downloadBtnEncompass
                              : styles.downloadBtn
                    }
                    title={'Export'}
                    disabled={!canEdit}
                >
                    <FontAwesomeIcon icon={faFileExport} size="lg" />
                </IconButton>
            </span>

            <CSVLink
                ref={csvLinkRef}
                data={videoEventExportData}
                headers={csvHeaders}
                className={styles.fileDownload}
                filename={`${csvTitle}.csv`}
                target="_blank"
            />

            <Dialog open={isConfirmDialogOpen} className={`${styles.confirmationDialog} ${styles.dialog}`}>
                <DialogContent className={styles.confirmMessage}>Are you sure you want to export these events?</DialogContent>
                <DialogActions className={styles.dialogAction}>
                    <Button className={styles.confirmButton} onClick={() => downloadCsv(isEncompass, isMetric)}>
                        Yes, Export
                    </Button>
                    <Button className={styles.cancelButton} onClick={() => showConfirmDialog(false)}>
                        No, Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
};

export default VideoEventExportComponent;
