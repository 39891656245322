import { React } from '../../../Imports';
import { BulkRatingRequest, VideoEventScoreResponse } from '$Generated/api';
import { ConfirmDialog } from '$Components/Shared/ConfirmDialog';
import { FormControl, FormControlLabel, Grid, RadioGroup } from '@mui/material';
import { progressLoaderEnum, loaderDetailInfo } from '$Components/Shared/MaterialUIComponents/StyledProgressLoader';
import { StyledRadio } from './VideoEventListStyles';
import { useState, useEffect } from 'react';
import * as scssStyles from '$CSS/settings.scss';

type VideoEventListBulkScoreModalProps = {
    isOpen: boolean;
    handleModalClosed: () => void;
    selectedEventsCount: number;
    videoEventRatings: VideoEventScoreResponse[];
    handleSubmit: (body: BulkRatingRequest) => Promise<boolean>;
    getSelectedEvents: () => Promise<number[]>;
    refreshVideoEventsList: () => void;
};

const _VideoEventListBulkScoreModal = (props: VideoEventListBulkScoreModalProps) => {
    const { isOpen, handleModalClosed, selectedEventsCount, videoEventRatings, handleSubmit, getSelectedEvents, refreshVideoEventsList } =
        props;
    const [score, setScore] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [loaderDetail, setLoaderDetail] = useState<loaderDetailInfo>({
        message: 'Please wait while all events are being scored. \n This could take a few minutes',
        color: 'black',
        progressLoaderState: progressLoaderEnum.loading,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScore((event.target as HTMLInputElement).value);
    };

    const handleOnSubmit = async () => {
        setLoaderDetail({
            message: 'Please wait while all events are being scored. \n This could take a few minutes',
            color: 'black',
            progressLoaderState: progressLoaderEnum.loading,
        });
        setShowLoader(true);
        const videoEventIdList = await getSelectedEvents();
        if (videoEventIdList.length == 0) {
            setLoaderDetail({
                message: "There's been an issue scoring the events. \n Please select a shorter date range and try again.",
                color: scssStyles.styleEnvironment === 'encompass' ? '#F8333C' : '#DE3030',
                progressLoaderState: progressLoaderEnum.error,
            });

            return;
        }
        const callSuccess = await handleSubmit({
            ratingValue: parseInt(score),
            videoEventIdList: videoEventIdList,
        });

        if (callSuccess) {
            setLoaderDetail({
                message: 'All events have been scored.',
                color: scssStyles.styleEnvironment === 'encompass' ? '#00A878' : '#4CAF50',
                progressLoaderState: progressLoaderEnum.success,
            });

            setTimeout(() => {
                refreshVideoEventsList();
                handleOnClose();
            }, 5000);
        } else {
            setLoaderDetail({
                message: "There's been an issue scoring the events. \n Please select a shorter date range and try again.",
                color: scssStyles.styleEnvironment === 'encompass' ? '#F8333C' : '#DE3030',
                progressLoaderState: progressLoaderEnum.error,
            });
        }
    };

    const handleOnClose = () => {
        setScore('');
        setDisabledSubmit(true);
        if (loaderDetail.progressLoaderState === progressLoaderEnum.success) {
            refreshVideoEventsList();
        }
        handleModalClosed();
    };

    const radioOption = (rating: VideoEventScoreResponse, idx: any) => (
        <FormControlLabel
            key={idx}
            value={rating?.value?.toString()}
            control={<StyledRadio color={'primary'} size={'small'} />}
            label={rating.displayName}
            style={{ marginBottom: -10 }}
            onChange={() => setDisabledSubmit(false)}
        />
    );

    const contentMessage = () => (
        <FormControl>
            <RadioGroup name="video-event-rating" onChange={handleChange} value={score?.toString()} defaultValue={score}>
                <Grid container style={{ width: '365px' }}>
                    <Grid item xs={8}>
                        {videoEventRatings.filter((x) => x.value && x.value <= 0).map((rating, idx) => radioOption(rating, idx))}
                    </Grid>
                    <Grid item xs={4}>
                        {videoEventRatings.filter((x) => x.value && x.value > 0).map((rating, idx) => radioOption(rating, idx))}
                    </Grid>
                </Grid>
            </RadioGroup>
        </FormControl>
    );

    useEffect(() => {
        if (isOpen) setShowLoader(false);
    }, [isOpen]);

    return (
        <ConfirmDialog
            isOpen={isOpen}
            title={`Score ${selectedEventsCount} Event${selectedEventsCount > 1 ? 's' : ''}`}
            contentMessage={contentMessage()}
            isLoader={showLoader}
            loaderContainerWidth={'400px'}
            loaderDetail={loaderDetail}
            cancelButton={'Cancel'}
            acceptButton={'Score'}
            handleModalClosed={handleOnClose}
            handleSubmit={handleOnSubmit}
            disabledSubmit={disabledSubmit}
        />
    );
};

export const VideoEventListBulkScoreModal = _VideoEventListBulkScoreModal;
