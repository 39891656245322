import { AccordionSummary } from 'MaterialUIComponents';
import * as scssStyles from '$CSS/settings.scss';
import styled from '@emotion/styled';

const GeotabAccordionSummary = `
    padding: 0;
    > div {
        margin: 0;
    }
`;
const EncompasssAccordionSummary = `
    min-height: 90px !important;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabAccordionSummary;
            case 'encompass':
                return EncompasssAccordionSummary;
            default:
                return GeotabAccordionSummary;
        }
    }}
`;
