import { React } from 'Imports';
import { AlertTypes } from '../../utilities/Enums/AlertTypes';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import { Dialog, Paper } from '../../MaterialUIComponents';
import { faLoader } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    StyledDialogTitle,
    StyledDialogContent,
    StyledDialogActions,
    StyledConfirmDialogActions,
    StyledPlainSubmitButton,
    StyledPlainCancelButton,
} from '../VideoEvents/VideoEventList/VideoEventListStyles';
import StyledProgressLoader from '$Components/Shared/MaterialUIComponents/StyledProgressLoader';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import * as scssStyles from '../../css/settings.scss';

const styles = require('./ConfirmDialog.scss') as {
    title: string;
    closeIcon: string;
    alertIcon: string;
    containerMessage: string;
    plainContent: string;
    alertButton: string;
    footer: string;

    danger: string;
    success: string;
};

type ConfirmDialogProps = {
    isOpen: boolean;
    title: string;
    contentMessage: any;
    undoButton?: string;

    isAlert?: boolean;
    isButtonAlert?: boolean;
    alertType?: string;
    showIcon?: boolean;

    paperWidth?: string;
    isLoader?: boolean;
    loaderContainerWidth?: string;
    loaderDetail?: any;

    acceptButton: string;
    cancelButton: string;
    disabledSubmit?: boolean;
    showCloseIcon?: boolean;

    handleModalClosed: (e: any | undefined) => void;
    handleSubmit: (event: any, undo: boolean) => Promise<any> | void;
};


export const ConfirmDialog = (props: ConfirmDialogProps) => {
    const {
        isOpen,
        title,
        contentMessage,
        cancelButton,
        acceptButton,
        undoButton = '',
        isAlert,
        isButtonAlert,
        alertType = AlertTypes.Fail,
        isLoader = false,
        loaderContainerWidth,
        loaderDetail,
        handleSubmit,
        handleModalClosed,
        disabledSubmit,
        showCloseIcon = true,
        paperWidth,
    } = props;
    const [showLoader, setShowLoader] = useState(false);
    const [showUndo, setShowUndo] = useState(false);
    const isEncompass = scssStyles.styleEnvironment === 'encompass';
    const submitColor = isEncompass && isButtonAlert ? '#c62930' : '#0078d3';
    const borderColor = isEncompass && isButtonAlert ? '#a82329' : '#0b68a7';

    const handleOnClose = (e: any) => {
        handleModalClosed(e);
    };

    const handleOnSubmit = async (e: any) => {
        e.stopPropagation();

        setShowLoader(true);

        setTimeout(() => {
            handleSubmit(e, showUndo);
        }, 1000);
    };

    useEffect(() => {
        if (isOpen) setShowLoader(false);
    }, [isOpen]);

    useEffect(() => {
        setShowLoader(false);
    }, [contentMessage, alertType]);

    const defineActionButtons = () => (
        <>
            <StyledPlainCancelButton variant={'outlined'} onClick={handleOnClose}>
                {cancelButton}
            </StyledPlainCancelButton>
            <StyledPlainSubmitButton
                onClick={handleOnSubmit}
                disabled={showLoader || disabledSubmit}
                className={isButtonAlert && isEncompass && !showUndo && !showLoader ? styles.alertButton : ''}
                style={
                    showLoader
                        ? {
                              backgroundColor: submitColor,
                              border: '1px solid ' + submitColor,
                              borderBottom: isEncompass ? '5px solid' + borderColor : '',
                              color: '#ffffff',
                          }
                        : {}
                }
            >
                {showLoader && !isLoader ? <FontAwesomeIcon icon={faLoader} fixedWidth /> : showUndo ? undoButton : acceptButton}
            </StyledPlainSubmitButton>
        </>
    );

    const defineAlertStyle = (): string =>
        styles.containerMessage + ' ' + (alertType === AlertTypes.Success ? styles.success : styles.danger);

    return (
        <Dialog open={isOpen} onClick={(e) => e.stopPropagation()}>
            {isLoader ? (
                <StyledDialogContent>
                    <StyledProgressLoader
                        isVisible={true}
                        loaderContainerWidth={loaderContainerWidth}
                        loaderDetail={loaderDetail}
                        handleModalClose={handleOnClose}
                    />
                </StyledDialogContent>
            ) : (
                <Paper style={isEncompass ? { minWidth: '350px' } : paperWidth ? { minWidth: paperWidth } : {}}>
                    <StyledDialogTitle>
                        <p className={styles.title}> {title} </p>
                        {showCloseIcon && (
                            <div>
                                <CloseIcon className={styles.closeIcon} onClick={handleOnClose}></CloseIcon>
                            </div>
                        )}
                    </StyledDialogTitle>

                    <StyledDialogContent>
                        {isAlert ? (
                            <div className={defineAlertStyle()}>
                                <CancelIcon className={styles.alertIcon}>add_circle</CancelIcon>
                                <p> {contentMessage} </p>
                            </div>
                        ) : (
                            <div>
                                <div style={{ fontSize: '16px' }}>{contentMessage}</div>
                            </div>
                        )}
                    </StyledDialogContent>

                    {isEncompass ? (
                        <StyledDialogActions>{defineActionButtons()}</StyledDialogActions>
                    ) : (
                        <StyledConfirmDialogActions>{defineActionButtons()}</StyledConfirmDialogActions>
                    )}
                </Paper>
            )}
        </Dialog>
    );
};
