import { Button, Dialog, DialogActions, DialogTitle, ValidatorForm, makeStyles, Slide } from 'MaterialUIComponents';
import { TransitionProps } from '@mui/material/transitions/transition';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React } from 'Imports';
import { useRef } from 'react';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} mountOnEnter unmountOnExit timeout={500} />;
});

interface ISliderDialogProps {
    title: string;
    open: boolean;
    onClose: () => void;
    submitButtonText?: string;
    cancelButtonText?: string;
    children?: any;
    submitButtonDisabled?: boolean;
    onSubmit: any;
    isEncompass: boolean;
    isRunningStandAlone: boolean;
}

type SliderDialogProps = ISliderDialogProps;

const useStyles = makeStyles({
    customScrollPaper: {
        alignItems: 'stretch',
        justifyContent: 'flex-end',
    },
    customPaper: {
        margin: 0,
        borderRadius: 0,
    },
    customMaxHeight: {
        maxHeight: '100%',
    },
    customActionButtons: {
        boxShadow: '10px 10px 10px 10px grey !important',
        marginTop: 'auto',
    },
    customTitle: {
        fontWeight: 'bold',
        boxShadow: '0px 0px 10px 0px grey !important',
    },
    customWidthXs: {
        maxWidth: '355px',
    },
});

const styles = require('./SliderDialog.scss') as {
    cancelButton: string;
    saveButton: string;
    dialogActions: string;
    genericForm: string;
    closeIcon: string;
    dialogTitleRootIntegrated: string;
    dialogTitleRoot: string;
};

const SliderDialog = ({
    title,
    open,
    onClose,
    children,
    submitButtonText,
    cancelButtonText,
    submitButtonDisabled,
    onSubmit,
    isEncompass,
    isRunningStandAlone,
}: SliderDialogProps) => {
    const classes = useStyles();
    const formRef = useRef(null);

    return (
        <>
            <Dialog
                open={open}
                maxWidth="xs"
                fullWidth={true}
                scroll="paper"
                classes={{
                    scrollPaper: classes.customScrollPaper,
                    paper: classes.customPaper,
                    paperScrollPaper: classes.customMaxHeight,
                    paperWidthXs: classes.customWidthXs,
                }}
                TransitionComponent={Transition}
            >
                <ValidatorForm ref={formRef} onSubmit={onSubmit} className={styles.genericForm}>
                    <DialogTitle
                        classes={{
                            root: classes.customTitle,
                        }}
                    >
                        <div className={!isEncompass && !isRunningStandAlone ? styles.dialogTitleRootIntegrated : styles.dialogTitleRoot}>
                            <strong>{title}</strong>
                            <span className={styles.closeIcon}>
                                <FontAwesomeIcon icon={faTimes} onClick={onClose} />
                            </span>
                        </div>
                    </DialogTitle>
                    <div>{children}</div>
                    <DialogActions
                        classes={{
                            root: classes.customActionButtons,
                        }}
                    >
                        <div className={styles.dialogActions}>
                            <Button onClick={onClose} className={styles.cancelButton}>
                                {cancelButtonText || 'Cancel'}
                            </Button>
                            <Button disabled={submitButtonDisabled} className={styles.saveButton} type="submit">
                                {submitButtonText || 'Save'}
                            </Button>
                        </div>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </>
    );
};

export default SliderDialog;
