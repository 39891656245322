import { React } from 'Imports';
import { ListItemText, MenuItem } from 'MaterialUIComponents';
import DropdownMenuItemCheckbox from '$Pages/VideoEvents/components/DropdownMenuItemCheckbox';
import { isEncompass } from '$Utilities/integrationPlatform';
import * as scssStyles from '$CSS/settings.scss';

interface IDropdownMenuItemProps {
    value: string;
    checked: boolean;
    onChange: () => void;
    label: string;
    width: string;
}

const DropdownMenuItem = (props: IDropdownMenuItemProps) => (
    <MenuItem
        value={props.value}
        disableRipple={true}
        onChange={props.onChange}
        sx={{
            padding: '4px 8px 4px 8px',
        }}
    >
        <DropdownMenuItemCheckbox checked={props.checked} onChange={props.onChange} />
        <ListItemText
            primary={props.label}
            primaryTypographyProps={{
                noWrap: true,
                fontSize: isEncompass ? '14px' : '12px',
                fontWeight: isEncompass ? (props.checked ? 700 : 400) : 400,
                textOverflow: 'ellipsis',
                color: scssStyles.customColor2,
            }}
            sx={{
                textOverflow: 'ellipsis',
                width: props.width,
            }}
            onClick={props.onChange}
        />
    </MenuItem>
);

export default DropdownMenuItem;
