import { React } from 'Imports';
import { DriverDropdown } from '$Components/Shared/DriverDropdown';
import { IDropdownItem } from '$Components/FilterComponents/VPDropdown';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { getVideoEventStatusDropdownList } from '$Utilities/videoEventStatusUtility';
import { VehicleDropdownMultiselect } from '$Components/Shared/VehicleDropdownMultiselect';
import { VideoEventFilterRequest, VideoEventTypeResponse, VideoEventWorkflowStatusIdEnum } from '$Generated/api';
import { VideoEventService, IVideoEventServiceInjectedProps } from '$State/VideoEventFreezerService';
import { VPDateRange } from '$Components/FilterComponents/VPDateRange';
import { VPDropdown } from '$Components/FilterComponents/VPDropdown';
import * as scssStyles from '$CSS/settings.scss';
import { GetImageUrl, SortDropdownItems } from '$Utilities/dataModelUtilities';
import * as _ from 'lodash';

interface IFilterBarProps {
    hideEventType?: boolean;
    hideDrivers?: boolean;
    onFilterOptionsChange: (value: IFilterOptions) => void;
    eventFilter: VideoEventFilterRequest;
    isMobileView: boolean;
    isVideoRecallPage: boolean;
    includeInactiveVehicles?: boolean;
    onChangeIncludeInactiveVehicles: (includeInactives?: boolean) => void;
}

const styles = require('$Components/FilterComponents/FilterBar.scss') as {
    main: string;
    mainMobile: string;
    filter: string;
    switchLabel: string;
    hidden: string;
};

export interface IFilterOptions {
    eventType?: number;
    driverName?: string[];
    startDate?: Date;
    endDate?: Date;
    vehicleId?: string[];
    status?: VideoEventWorkflowStatusIdEnum[];
}

type FilterBarProps = IFilterBarProps &
    IIntegrationPartnerDataInjectedProps &
    IVideoEventServiceInjectedProps &
    IConfigServiceInjectedProps;

export class _FilterBar extends React.Component<FilterBarProps> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
        this.props.videoEvents.getVideoEventTypes();
    }

    getIcon(iconFileName: any): string {
        const currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        const currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;

        if (iconFileName == null || iconFileName == '') {
            // Set default image if none is provided for that event type
            return GetImageUrl(currentImagesBucket ? currentImagesBucket : '', currentRegion ? currentRegion : '', 'ic_car.png');
        }

        //Return icon image found in VideoEventType table.
        return GetImageUrl(currentImagesBucket ? currentImagesBucket : '', currentRegion ? currentRegion : '', iconFileName);
    }

    mapEventTypeList = (videoEventTypes: VideoEventTypeResponse[]): IDropdownItem[] => {
        const eventTypeList: IDropdownItem[] = videoEventTypes.map((et) => ({
            label: et.displayName ? et.displayName : '',
            value: et.id ? et.id : 0,
            icon: this.getIcon(et.iconFileName ? et.iconFileName : ''),
        }));
        eventTypeList.unshift({ label: 'All events', value: 0 });
        return SortDropdownItems(eventTypeList);
    };

    mapDeviceList = (devices: any[]): IDropdownItem[] => {
        const devicesList: IDropdownItem[] = devices.map((d) => ({
            label: d.name,
            value: d.id,
            isActive: d.isActive,
        }));
        return SortDropdownItems(devicesList);
    };

    /*
        Be sure to use the cloneDeep function when
        copying objects to avoid mutating the 
        original object.
    */

    handleEventDropdownChange = (selectedValue: any[]): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        eventFilter.eventType = selectedValue.length > 0 ? selectedValue[0] : undefined;
        this.filterEvents(eventFilter);
    };

    handleDriverDropdownChange = (selectedValue: any): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        const selectedDriver = selectedValue ? [selectedValue.id] : [];
        eventFilter.driverName = selectedDriver;
        this.filterEvents(eventFilter);
    };

    handleVehicleDropdownChange = (selectedValue: any[]): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        eventFilter.vehicleId = selectedValue;
        this.filterEvents(eventFilter);
    };

    handleDateRangeChange = (dateRange: any): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        eventFilter.startDate = dateRange.startDate;
        eventFilter.endDate = dateRange.endDate;
        this.filterEvents(eventFilter);
    };

    handleStatusDropdownChange = (selectedValues: any[]): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        eventFilter.status = selectedValues;
        this.filterEvents(eventFilter);
    };

    filterEvents = (eventFilter: IFilterOptions): void => {
        const { onFilterOptionsChange } = this.props;
        onFilterOptionsChange(eventFilter);
    };

    render(): JSX.Element {
        const { deviceResults } = this.props.integrationPartnerData.getState();
        const devices = deviceResults.data ? deviceResults.data : [];

        const deviceDropdownList = this.mapDeviceList(devices);

        const { videoEventTypeResults } = this.props.videoEvents.getState();
        const videoEventTypes = videoEventTypeResults.data ? videoEventTypeResults.data : [];
        const eventTypeDropdownList = this.mapEventTypeList(videoEventTypes);
        const eventStatusDropdownList = getVideoEventStatusDropdownList();

        const { hideEventType, hideDrivers, eventFilter, isMobileView, isVideoRecallPage } = this.props;

        //Default to 0 if the eventType is null/undefined - 0 is the 'All Events' dropdown value
        const selectedEventTypeArray = eventFilter.eventType ? [eventFilter.eventType] : [0];
        const selectedDriverId = eventFilter.driverName && eventFilter.driverName.length > 0 ? eventFilter.driverName[0] : '';
        const selectedVehicleIdArray = eventFilter.vehicleId ? eventFilter.vehicleId : [];
        const selectedStatusArray = eventFilter.status ? eventFilter.status : [];

        return (
            <div className={isMobileView ? styles.mainMobile : styles.main}>
                <div className={styles.filter + (hideEventType === true ? ' ' + styles.hidden : '')}>
                    <VPDropdown
                        isAutocomplete={true}
                        name="Events"
                        items={eventTypeDropdownList}
                        selectedValue={selectedEventTypeArray}
                        onSelectedItemChange={this.handleEventDropdownChange}
                        width={280}
                    />
                </div>
                <div className={styles.filter + (hideDrivers === true ? ' ' + styles.hidden : '')}>
                    <DriverDropdown
                        selectedDriverId={selectedDriverId}
                        onSelectedDriverChange={this.handleDriverDropdownChange}
                        drivers={[]}
                    />
                </div>
                <div className={styles.filter}>
                    <VehicleDropdownMultiselect
                        selectedVehicleIds={selectedVehicleIdArray}
                        onSelectedVehicleChange={this.handleVehicleDropdownChange}
                        includeInactives={this.props.includeInactiveVehicles}
                        onChangeIncludeInactives={this.props.onChangeIncludeInactiveVehicles}
                        vehicles={[]}
                    />
                </div>
                <div className={styles.filter + (isVideoRecallPage === true ? ' ' + styles.hidden : '')}>
                    <VPDropdown
                        name="Event Status"
                        isMultiselect={true}
                        items={eventStatusDropdownList}
                        selectedValues={selectedStatusArray}
                        onSelectedItemChange={this.handleStatusDropdownChange}
                    />
                </div>
                <div className={styles.filter}>
                    <VPDateRange
                        onSelectedItemChange={this.handleDateRangeChange}
                        startDate={eventFilter.startDate}
                        endDate={eventFilter.endDate}
                        width={scssStyles.styleEnvironment === 'encompass' && isVideoRecallPage ? '200px' : ''}
                        IsMobileView={isMobileView}
                        showMessage={false}
                    />
                </div>
            </div>
        );
    }
}

export const FilterBar = VideoEventService.inject(ConfigService.inject(IntegrationPartnerDataService.inject(_FilterBar)));
