import { React } from 'Imports';
import { faBan } from '@fortawesome/pro-solid-svg-icons';
import { RolesEnum, canEditByRole } from '$Externals/VerifyRole';
import { StyledIconButton } from './MaterialUIComponents/StyledIconButton';

interface IDismissButtonProps {
    eventIds?: number;
    isDismissed?: boolean;
    toggleDismissPopup: (selectedId?: number) => void;
    widthOverride?: string;
    heightOverride?: string;
}

type DismissButtonProps = IDismissButtonProps;

export const DismissButton = (props: DismissButtonProps) => {
    const { eventIds = 0, toggleDismissPopup } = props;

    const isDismissed = props.isDismissed || !canEditByRole([RolesEnum.videoAdmin, RolesEnum.videoEventEdit, RolesEnum.videoSuperAdmin]);

    const handleToggleDeletePopup = (e: any) => {
        e.stopPropagation();
        if (isDismissed) return;

        toggleDismissPopup(eventIds);
    };

    return (
        <StyledIconButton
            handleOnClick={handleToggleDeletePopup}
            title="Dismiss Video"
            icon={faBan}
            isDisabled={isDismissed}
            heightOverride={props.heightOverride}
            widthOverride={props.widthOverride}
        />
    );
};
