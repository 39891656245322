// #region Imports
import {
    Divider,
    List,
    ListItem,
    Button,
    ListItemSecondaryAction,
    Popover,
    ListItemText,
    styled,
} from 'MaterialUIComponents';
import { RefObject } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { bind, React } from "../Imports";
import { IUserManagementServiceInjectedProps, UserManagementService } from '$State/UserManagementFreezerService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { VerifyRole, RolesEnum } from 'externals/VerifyRole';
import { ListItemTextProps, listItemTextClasses } from '@mui/material/ListItemText';
// #endregion Imports

const styles = require("./MainMenu.scss") as {
    menuActive: string;
    active: string;
    link: string;
    listItemText: string;
    logo: string;
    main: string;
    navbar: string;
    page: string;
    pageOuter: string;
    subMenuItem: string;
    toolbar: string;
};

const StyledListItemText = styled(({ ...props }: ListItemTextProps) => <ListItemText {...props} />)(({ theme }) => ({
    [`& .${listItemTextClasses.primary}`]: {
        fontWeight: 'inherit',
    },
}));

type IMainMenuProps  = IUserManagementServiceInjectedProps;

interface IMainMenuState {
    isConfigurationOpen: boolean;
    configurationRef: RefObject<HTMLDivElement>;
    isLogoutInProcess: boolean;
}

export class _MainMenu extends React.Component<IMainMenuProps & RouteComponentProps<string>, IMainMenuState> {
    state: IMainMenuState = {
        isConfigurationOpen: false,
        configurationRef: React.createRef<HTMLDivElement>(),
        isLogoutInProcess: false
    };

    @bind
    toggleConfigurationOpen(e?: React.MouseEvent<Element>) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        this.setState((curState) => {
            return {
                isConfigurationOpen: !curState.isConfigurationOpen,
            };
        });
    }

    @bind
    closeConfiguration(e?: React.MouseEvent<Element>) {
        this.setState({
            isConfigurationOpen: false
        });
    }

    @bind
    navigateTo(target: string) {
        return (e: React.MouseEvent<Element>) => {
            e.preventDefault();
            e.stopPropagation();
            this.props.history.push(target);
        };
    }

    @bind
    logout() {
        this.setState({isLogoutInProcess: true});
        this.props.userManagement.logout();
    }

    render() {
        const { isConfigurationOpen, configurationRef, isLogoutInProcess } = this.state;

        return (
            <div className={styles.main}>
                <List>
                    <VerifyRole allowedRole={[RolesEnum.videoEventView, RolesEnum.videoEventEdit]}>
                        <NavLink
                            activeClassName={styles.menuActive}
                            className={styles.link}
                            exact={true}
                            to="/videoevents"
                        >
                            <ListItem button={true}>
                                <StyledListItemText inset={true}>
                                    Video Events
                                </StyledListItemText>
                            </ListItem>
                        </NavLink>
                    </VerifyRole>

                    <VerifyRole allowedRole={[RolesEnum.videoRecallEdit]}>
                        <NavLink
                            activeClassName={styles.menuActive}
                            className={styles.link}
                            exact={true}
                            to="/videorecall"
                        >
                            <ListItem button={true}>
                                <StyledListItemText inset={true}>
                                    Video Recall
                                </StyledListItemText>
                            </ListItem>
                        </NavLink>
                    </VerifyRole>

                    <VerifyRole allowedRole={[RolesEnum.videoAdmin]}>
                    {true /** change to make sure user is administrator */ &&
                        <div ref={configurationRef}>
                            <ListItem button={true} onClick={this.toggleConfigurationOpen}>
                                <StyledListItemText inset={true}>
                                    Configuration
                                </StyledListItemText>

                                <ListItemSecondaryAction onClick={this.toggleConfigurationOpen}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </ListItemSecondaryAction>
                            </ListItem>

                            <Popover
                                anchorEl={configurationRef.current}
                                anchorOrigin={{
                                    horizontal: 'right',
                                    vertical: 'top'
                                }}
                                PaperProps={{
                                    style: { background: '#C6DEF2' }
                                }}
                                transformOrigin={{
                                    horizontal: 'left',
                                    vertical: 'top'
                                }}
                                onClose={() => this.toggleConfigurationOpen()}
                                open={isConfigurationOpen}
                            >
                                <List>
                                    <NavLink
                                        activeClassName={styles.menuActive}
                                        className={styles.link}
                                        exact={true}
                                        to="/installers"
                                    >
                                        <ListItem button={true} onClick={this.closeConfiguration}>
                                            <StyledListItemText>
                                                Installers
                                            </StyledListItemText>
                                        </ListItem>
                                    </NavLink>

                                    <NavLink
                                        activeClassName={styles.menuActive}
                                        className={styles.link}
                                        exact={true}
                                        to="/deviceassociation"
                                    >
                                        <ListItem button={true} onClick={this.closeConfiguration}>
                                            <StyledListItemText>
                                                Device Associations
                                            </StyledListItemText>
                                        </ListItem>
                                    </NavLink>

                                    <NavLink
                                        activeClassName={styles.menuActive}
                                        className={styles.link}
                                        exact={true}
                                        to="/settings"
                                    >
                                        <ListItem button={true} onClick={this.closeConfiguration}>
                                            <StyledListItemText>
                                                Settings
                                            </StyledListItemText>
                                        </ListItem>
                                    </NavLink>
                                </List>
                            </Popover>
                        </div>
                    }
                    </VerifyRole>

                    <VerifyRole allowedRole={[RolesEnum.videoEventView, RolesEnum.videoEventEdit]}>
                        <NavLink
                            activeClassName={styles.menuActive}
                            className={styles.link}
                            exact={true}
                            to="/videodashboard"
                        >
                            <ListItem button={true}>
                                <StyledListItemText inset={true}>
                                    Video Dashboard
                                </StyledListItemText>
                            </ListItem>
                        </NavLink>
                    </VerifyRole>
                </List>
                <Divider />
                {this.props.userManagement.isLoggedIn() && 
                    <div style={{ textAlign: "center", paddingTop: "20px" }}>
                        <Button size="small" disabled={isLogoutInProcess} onClick={() => this.logout()}>Logout</Button>
                    </div>}
            </div>
        );
    }
}

const MainMenu = withRouter(UserManagementService.inject(_MainMenu));

export { MainMenu }