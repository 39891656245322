// TODO: extremely naive phone number normalization / validation - trim to digits only, expect 10

export function normalizePhoneNumber(value: string): string | null {
    const normalized = value.replace(/[^0-9]+/g, '');

    if(normalized.length !== 10) {
        return null;
    }

    return normalized;
}

export function formatPhoneNumber(value: string): string | null {
    const normalized = normalizePhoneNumber(value);

    if(normalized === null)
    {
        return null;
    }

    return `${normalized.slice(0,3)}-${normalized.slice(3,6)}-${normalized.slice(6)}`;
}
