import { React } from 'Imports';
import { FormControl, Select } from 'MaterialUIComponents';
import { IDropdownItem } from '$Components/FilterComponents/VPDropdown';
import { Box, ClickAwayListener, MenuProps, SxProps } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import DropdownSwitch from '$Pages/VideoEvents/components/DropdownSwitch';
import DropdownSearchField from '$Pages/VideoEvents/components/DropdownSearchField';
import DropdownMenuItem from '$Pages/VideoEvents/components/DropdownMenuItem';
import { VideoEventFilterMultiselectRequest } from '$Generated/api';
import { isEncompass } from '$Utilities/integrationPlatform';

const styles = require('$Pages/VideoEvents/components/VideoEventFilterBarMultiSelect.scss') as {
    dropdownLabel: string;
    dropdownSelectedCount: string;
    hasSelectedElementsGeotab: string;
    hasSelectedElementsEncompass: string;
    noSelectedElementsGeotab: string;
    noSelectedElementsEncompass: string;
    selectIcon: string;
    noResultsMessage: string;
    scrollAreaGeotab: string;
    scrollAreaEncompass: string;
};

interface IDropdownLabelProps {
    labelText: string;
    selectedCount: number;
}

const DropdownLabel = (props: IDropdownLabelProps) => {
    const hasItemsSelected = props.selectedCount > 0;
    const noItemsClassName = isEncompass ? styles.noSelectedElementsEncompass : styles.noSelectedElementsGeotab;
    const hasItemsClassName = isEncompass ? styles.hasSelectedElementsEncompass : styles.hasSelectedElementsGeotab;

    return (
        <div className={styles.dropdownLabel}>
            <div>{props.labelText}</div>
            <div className={styles.dropdownSelectedCount}>
                <div className={hasItemsSelected ? hasItemsClassName : noItemsClassName}>{props.selectedCount}</div>
            </div>
        </div>
    );
};

const defaultSxProps: SxProps = {
    height: '36px',
};

export type FilterObjectType = 'eventTypes' | 'driverNames' | 'vehicleIds' | 'status';

interface IVideoEventFilterBarMultiSelectProps {
    label: string;
    items: IDropdownItem[];
    disabled: boolean;
    sx?: SxProps;
    searchable?: boolean;
    toggleable?: boolean;
    toggleText?: string;
    applyFilter: ((selectedItems: IDropdownItem[], filterKey: FilterObjectType) => void) | (() => void);
    filterKey: FilterObjectType;
    menuItemWidth?: string;
    filter: VideoEventFilterMultiselectRequest;
}

const VideoEventFilterBarMultiSelect = (props: IVideoEventFilterBarMultiSelectProps) => {
    const dropdownMenuProps: Partial<MenuProps> = {
        transformOrigin: { horizontal: 'left', vertical: 'top' },
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        disablePortal: true,
        slotProps: {
            paper: {
                sx: {
                    overflowY: 'hidden !important',
                    maxHeight: 320,
                    width: props.menuItemWidth || '100%',
                    paddingBottom: '8px',
                },
            },
        },
    };

    const initialSelectedItems = (props.filter[props.filterKey] || []).map((item) => {
        return props.items.find((i) => i.value === item) as IDropdownItem;
    });

    const [selectedItems, setSelectedItems] = useState<IDropdownItem[]>(initialSelectedItems);
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownHasFocus, setDropdownHasFocus] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [includeInactive, setIncludeInactive] = useState(false);

    const activeItems = props.items.filter((item) => item.isActive);
    const dropdownItems = props.toggleable ? (includeInactive ? props.items : activeItems) : props.items;
    const filteredDropdownItems = props.searchable
        ? dropdownItems.filter((item) => item.label.toLowerCase().includes(searchFilter.toLowerCase()))
        : dropdownItems;

    const isFiltering = searchFilter.length > 0;

    const handleSearchFilterChange = (value: string) => {
        setSearchFilter(value);
    };

    const handleOpen = () => {
        setIsOpen(true);
        setSearchFilter('');
        setDropdownHasFocus(true);
    };

    const handleClose = () => {
        setIsOpen(false);
        setSearchFilter('');
        setDropdownHasFocus(false);

        props.applyFilter && props.applyFilter(selectedItems, props.filterKey);
    };

    const handleMenuItemClick = (item: IDropdownItem) => {
        if (selectedItems.includes(item)) {
            const filteredSelectedItems = selectedItems.filter((selectedItem) => selectedItem !== item);
            setSelectedItems(filteredSelectedItems);
        } else {
            const newSelectedItems = [...selectedItems, item];
            setSelectedItems(newSelectedItems);
        }
    };

    const handleSelectDeselectAll = () => {
        let newSelectedItems = [] as IDropdownItem[];
        if (props.toggleable) {
            if (selectedItems.length < activeItems.length) {
                newSelectedItems = dropdownItems;
                setSelectedItems(newSelectedItems);
            } else {
                setSelectedItems([]);
            }
            return;
        }

        if (selectedItems.length < props.items.length) {
            newSelectedItems = props.items;
            setSelectedItems(newSelectedItems);
        } else {
            setSelectedItems([]);
        }
    };

    const handleClickAway = () => {
        if (isOpen) {
            handleClose();
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <FormControl sx={{ ...defaultSxProps, ...props.sx }} focused={dropdownHasFocus}>
                <Select
                    labelId={props.label + '-select-label'}
                    id={props.label + '-select'}
                    multiple
                    inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty
                    value={[]}
                    disabled={false}
                    MenuProps={dropdownMenuProps}
                    renderValue={() => <DropdownLabel labelText={props.label} selectedCount={selectedItems.length || 0} />}
                    IconComponent={() => (
                        <Box className={styles.selectIcon}>
                            <FontAwesomeIcon
                                icon={isOpen ? faCaretUp : faCaretDown}
                                color={isEncompass ? '#373E40' : '#4E677E'}
                                style={{ fontWeight: isEncompass ? 900 : 400 }}
                            />
                        </Box>
                    )}
                    open={isOpen}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    autoFocus={false}
                    sx={{
                        height: isEncompass ? '32px' : '40px',
                        '&:focus': { backgroundColor: 'transparent' },
                        fieldset: {
                            border: dropdownHasFocus
                                ? isEncompass
                                    ? '1px solid #0D7AC5 !important'
                                    : '1px solid #25477B !important'
                                : isEncompass
                                  ? '1px solid #C4CBD4 !important'
                                  : '1px solid #C4CBD7 !important',
                        },
                    }}
                >
                    {props.searchable && (
                        <DropdownSearchField
                            onChange={handleSearchFilterChange}
                            searchFilter={searchFilter}
                            onSearchFieldFocus={() => setDropdownHasFocus(false)}
                            onSearchFieldFocusLost={() => setDropdownHasFocus(true)}
                        />
                    )}
                    {props.toggleable && !isFiltering && (
                        <DropdownSwitch toggleText={props.toggleText || ''} onChange={() => setIncludeInactive(!includeInactive)} />
                    )}
                    <Box className={isEncompass ? styles.scrollAreaEncompass : styles.scrollAreaGeotab}>
                        {!isFiltering && (
                            <DropdownMenuItem
                                value={'select-deselect-all'}
                                checked={selectedItems.length === props.items.length && props.items.length != 0}
                                onChange={handleSelectDeselectAll}
                                label={'Select/Deselect All'}
                                width={props.menuItemWidth || '100%'}
                            />
                        )}
                        {(filteredDropdownItems || [])
                            .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
                            .map((item, index) => (
                                <DropdownMenuItem
                                    key={index}
                                    value={item.value}
                                    checked={selectedItems.map((i) => i.value).includes(item.value)}
                                    label={item.label}
                                    onChange={() => handleMenuItemClick(item)}
                                    width={props.menuItemWidth || '100%'}
                                />
                            ))}
                    </Box>
                    {isFiltering && filteredDropdownItems.length === 0 && <div className={styles.noResultsMessage}>No results found</div>}
                </Select>
            </FormControl>
        </ClickAwayListener>
    );
};

export default VideoEventFilterBarMultiSelect;