import { React, moment, bind } from 'Imports';
import * as DateFormatter from '$Components/Shared/DateFormatter';
import { CSVLink } from 'react-csv';
import { Button, Dialog, DialogActions, DialogContent, IconButton } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons';
import * as scssStyles from '$CSS/settings.scss';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { IDeviceAssociationServiceInjectedProps, DeviceAssociationService } from '$State/DeviceAssociationFreezerService';
import { canEditByRole, RolesEnum } from '$Externals/VerifyRole';
import { VehicleCameraPairingResponse } from '$Generated/api';

interface IDeviceAssociationExportBaseProps {
    csvTitle: string;
    filteredItems: VehicleCameraPairingResponse[];
}
type IDeviceAssociationExportProps = IDeviceAssociationExportBaseProps &
    IDeviceAssociationServiceInjectedProps &
    IIntegrationPartnerDataInjectedProps;

const styles = require('./DeviceAssociationExport.scss') as {
    fileDownload: string;
    downloadBtn: string;
    downloadIcon: string;
    downloadBtnEncompass: string;
    confirmationDialog: string;
    dialogAction: string;
    confirmMessage: string;
    confirmButton: string;
    cancelButton: string;
    disabled: string;
    dialog: string;
};

interface IDeviceAssociationExportState {
    isConfirmDialogOpen: boolean;
    data?: any[];
    canEdit: boolean;
}

class _DeviceAssociationExport extends React.PureComponent<IDeviceAssociationExportProps, IDeviceAssociationExportState> {
    state: IDeviceAssociationExportState = {
        isConfirmDialogOpen: false,
        canEdit: false,
    };

    private csvRef = React.createRef<HTMLSpanElement>();
    canEdit = false;

    componentDidMount(): void {
        this.canEdit = canEditByRole([RolesEnum.videoEventView, RolesEnum.videoEventEdit]);
        this.setState({ canEdit: this.canEdit });
    }

    @bind
    showConfirmDialog(show: boolean) {
        if (!this.state.canEdit) return;
        this.setState({ isConfirmDialogOpen: show });
    }

    @bind
    async downloadCsv(isEncompass: boolean) {
        if (!this.state.canEdit) return;
        this.showConfirmDialog(false);
        this.setDataState(isEncompass);
    }

    @bind
    setDataState(isEncompass: boolean): void {
        const csvData: any[] = this.props.filteredItems.map((val) => {
            if (isEncompass) {
                return {
                    CameraSerial: val.cameraDevice?.serialNumber ?? 'Unknown',
                    CameraDeviceType: val.cameraDevice?.deviceType?.displayName ?? 'Unknown',
                    Vehicle: val.vehicle?.name ?? 'Unknown',
                    StartDate: val.startDate ? DateFormatter.date(moment(val.startDate) || moment()) : 'Unknown',
                    LastHeartBeat:
                        val.cameraDevice?.lastHeartbeatTime != null
                            ? DateFormatter.dateAndTimezone(moment(val.cameraDevice?.lastHeartbeatTime) || moment())
                            : '',
                    LastProcessedEvent:
                        val.cameraDevice?.lastProcessedEventTime != null
                            ? DateFormatter.dateAndTimezone(moment(val.cameraDevice?.lastProcessedEventTime) || moment())
                            : '',
                    Status: val.cameraDevice?.deviceStatus ?? 'Unknown',
                    ConnectionStatus: val.cameraDevice?.onlineStatus ?? 'Unknown',
                    isGpsTrackingEnabled: val.isGpsTrackingEnabled ? 'On' : 'Off',
                };
            } else {
                return {
                    CameraSerial: val.cameraDevice?.serialNumber ?? 'Unknown',
                    CameraDeviceType: val.cameraDevice?.deviceType?.displayName ?? 'Unknown',
                    GoSerial: val.vehicle?.telematicDevice?.serialNumber ?? 'Unknown',
                    Vehicle: val.vehicle?.name ?? 'Unknown',
                    StartDate: val.startDate ? DateFormatter.date(moment(val.startDate) || moment()) : 'Unknown',
                    LastHeartBeat:
                        val.cameraDevice?.lastHeartbeatTime != null
                            ? DateFormatter.dateAndTimezone(moment(val.cameraDevice?.lastHeartbeatTime) || moment())
                            : '',
                    LastProcessedEvent:
                        val.cameraDevice?.lastProcessedEventTime != null
                            ? DateFormatter.dateAndTimezone(moment(val.cameraDevice?.lastProcessedEventTime) || moment())
                            : '',
                    Status: val.cameraDevice?.deviceStatus ?? 'Unknown',
                    ConnectionStatus: val.cameraDevice?.onlineStatus ?? 'Unknown',
                };
            }
        });
        this.setState({ data: csvData }, this.clickCsvLink);
    }

    @bind
    clickCsvLink(): void {
        const csvLink = this.csvRef.current;
        if (csvLink) csvLink.click();
    }

    @bind
    getExportHeaders(isEncompass: boolean): { key: string; label: string }[] {
        if (isEncompass) {
            const csvHeaders = [
                { label: 'Camera Serial', key: 'CameraSerial' },
                { label: 'Camera Device Type', key: 'CameraDeviceType' },
                { label: 'Vehicle', key: 'Vehicle' },
                { label: 'GPS Tracker', key: 'isGpsTrackingEnabled' },
                { label: 'Date Added', key: 'StartDate' },
                { label: 'Last Heartbeat', key: 'LastHeartBeat' },
                { label: 'Last Processed Event', key: 'LastProcessedEvent' },
                { label: 'Pairing Status', key: 'Status' },
                { label: 'Connection Status', key: 'ConnectionStatus' },
            ];
            return csvHeaders;
        } else {
            const csvHeaders = [
                { label: 'Camera Serial', key: 'CameraSerial' },
                { label: 'Camera Device Type', key: 'CameraDeviceType' },
                { label: 'GO Serial', key: 'GoSerial' },
                { label: 'Vehicle', key: 'Vehicle' },
                { label: 'Date Added', key: 'StartDate' },
                { label: 'Last Heartbeat', key: 'LastHeartBeat' },
                { label: 'Last Processed Event', key: 'LastProcessedEvent' },
                { label: 'Pairing Status', key: 'Status' },
                { label: 'Connection Status', key: 'ConnectionStatus' },
            ];
            return csvHeaders;
        }
    }

    render(): JSX.Element {
        const isEncompass = scssStyles.styleEnvironment === 'encompass';
        const csvHeaders = this.getExportHeaders(isEncompass);

        return (
            <span>
                <span className={styles.fileDownload} onClick={() => this.showConfirmDialog(true)}>
                    <IconButton
                        className={
                            !this.state.canEdit
                                ? styles.disabled
                                : scssStyles.styleEnvironment == 'encompass'
                                ? styles.downloadBtnEncompass
                                : styles.downloadBtn
                        }
                        title={'Export'}
                        disabled={!this.state.canEdit}
                    >
                        <FontAwesomeIcon icon={faFileExport} size="lg" />
                    </IconButton>
                </span>
                <CSVLink
                    data={this.state.data ? this.state.data : []}
                    headers={csvHeaders}
                    className={styles.fileDownload}
                    filename={this.props.csvTitle + '.csv'}
                    hidden
                >
                    <span ref={this.csvRef}></span>
                </CSVLink>
                <Dialog open={this.state.isConfirmDialogOpen} className={`${styles.confirmationDialog} ${styles.dialog}`}>
                    <DialogContent className={styles.confirmMessage}>Are you sure you want to export these associations?</DialogContent>
                    <DialogActions className={styles.dialogAction}>
                        <Button className={styles.confirmButton} onClick={() => this.downloadCsv(isEncompass)}>
                            Yes, Export
                        </Button>
                        <Button className={styles.cancelButton} onClick={() => this.showConfirmDialog(false)}>
                            No, Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        );
    }
}

export const DeviceAssociationExport = IntegrationPartnerDataService.inject(DeviceAssociationService.inject(_DeviceAssociationExport));
