import { React, bind } from '../../../Imports';
import { Button, Snackbar, Alert } from '../../../MaterialUIComponents';
import { IDropdownItem, VPDropdown } from '../../FilterComponents/VPDropdown';
import { FeatureDisabledOverlay } from '../../Shared/FeatureDisabledOverlay';
import { withFeatureToggle } from '../../Shared/FeatureToggleHOC';
import { VideoEventAdminResolutionEnum } from '../../../generated/api';
import * as scssStyles from '$CSS/settings.scss';
import { IVideoEventServiceInjectedProps, VideoEventService } from '$State/VideoEventFreezerService';
import {
    IVideoEventFilterServiceMultiselectInjectedProps,
    VideoEventFilterServiceMultiselect,
} from '$State/VideoEventFilterFreezerServiceMultiselect';

interface ICompleteFormState {
    snackOpen: boolean;
    snackMessage: string;
    action: VideoEventAdminResolutionEnum | undefined;
    error: string;
}

const styles = require('./CompleteForm.scss') as {
    main: string;
    flex: string;
    button: string;
    actionTaken: string;
    actionTakenEnc: string;
    errorMessage: string;

    dismiss: string;
    complete: string;
    disabled: string;
    buttons: string;
};

interface ICompleteFormProps {
    eventId: number | undefined;
    actionTaken: VideoEventAdminResolutionEnum | undefined;
    canComplete: boolean;
    handleDismiss: (eventId: number | undefined) => void;
    handleComplete: (eventId: number | undefined, action: string) => void;
    handleReopen: (eventId: number | undefined) => void;
    disabled?: boolean;
    prevNextSplitOn?: boolean;
}

type IVideoEventsCompleteFormProps = ICompleteFormProps &
    IVideoEventServiceInjectedProps &
    IVideoEventFilterServiceMultiselectInjectedProps;

class _CompleteForm extends React.Component<IVideoEventsCompleteFormProps, ICompleteFormState> {
    constructor(props: IVideoEventsCompleteFormProps) {
        super(props);
    }

    state: ICompleteFormState = {
        snackOpen: false,
        snackMessage: '',
        action: this.props.actionTaken,
        error: '',
    };

    @bind
    handleSnackClose(): void {
        this.setState({ snackOpen: false, snackMessage: '' });
    }

    @bind
    handleActionChange(selectedValue: any[]): void {
        this.setState({
            action: selectedValue.length > 0 ? selectedValue[0] : undefined,
            error: '',
        });
    }

    @bind
    refreshEventIds() {
        const videoEventFilter = this.props.videoEventFilterMultiselect.getVideoEventFilter();
        this.props.videoEvents.getListOfEventIds(videoEventFilter);
    }

    @bind
    async handleComplete(): Promise<void> {
        if (this.state.action) {
            this.setState({
                snackOpen: true,
                snackMessage: (this.state.action as string) !== 'Dismissed' ? 'The event is completed.' : 'The event is dismissed.',
            });
            await this.props.handleComplete(this.props.eventId, this.state.action);
            this.refreshEventIds();
        } else {
            this.setState({
                error: 'Action Taken is required to complete.',
            });
        }
    }

    @bind
    async handleDismiss(): Promise<void> {
        this.setState({
            snackOpen: true,
            snackMessage: 'The event is dismissed.',
            action: undefined,
        });
        await this.props.handleDismiss(this.props.eventId);
        this.refreshEventIds();
    }

    @bind
    async handleReopen(): Promise<void> {
        this.setState({
            snackOpen: true,
            snackMessage: 'The event is in progress.',
            action: undefined,
        });
        await this.props.handleReopen(this.props.eventId);
        this.refreshEventIds();
    }

    @bind
    getActionTakenItems(): IDropdownItem[] {
        const items: IDropdownItem[] = [];

        items.push({ label: 'Reviewed with Driver', value: 'ReviewedWithDriver' });
        items.push({ label: 'Disciplined Driver', value: 'DisciplinedDriver' });
        items.push({ label: 'Submitted to insurance', value: 'SubmittedToInsurance' });
        items.push({ label: 'Saved for future reference', value: 'SavedForFutureReference' });
        items.push({ label: 'Terminated driver', value: 'TerminatedDriver' });
        items.push({ label: 'Mark as Dismissed', value: 'Dismissed' });
        items.push({ label: 'Other', value: 'Other' });

        return items;
    }

    render(): JSX.Element {
        return (
            <div className={styles.main}>
                {this.props.disabled && <FeatureDisabledOverlay />}

                <div className={this.props.disabled ? 'feature-disabled' : undefined}>
                    <Snackbar
                        open={this.state.snackOpen}
                        onClose={this.handleSnackClose}
                        autoHideDuration={4000}
                        style={{ position: 'absolute', whiteSpace: 'nowrap' }}
                    >
                        <Alert
                            elevation={24}
                            variant="filled"
                            onClose={this.handleSnackClose}
                            severity="success"
                            color="success"
                            sx={{ width: '100%' }}
                        >
                            {this.state.snackMessage}
                        </Alert>
                    </Snackbar>
                    {(this.props.canComplete || this.state.action !== undefined) && (
                        <div className={scssStyles.styleEnvironment === 'encompass' ? styles.actionTakenEnc : styles.actionTaken}>
                            <VPDropdown
                                isAutocomplete
                                name={'Choose Action'}
                                items={this.getActionTakenItems()}
                                selectedValue={this.state.action}
                                onSelectedItemChange={this.handleActionChange}
                            />
                            <div className={styles.errorMessage}>{this.state.error}</div>
                        </div>
                    )}
                    {this.props.canComplete ? (
                        <div className={styles.flex}>
                            <Button
                                color={'primary'}
                                onClick={(): Promise<void> => this.handleComplete()}
                                className={
                                    this.props.prevNextSplitOn ? (this.state.action ? styles.complete : styles.disabled) : styles.buttons
                                }
                            >
                                Complete Review
                            </Button>
                        </div>
                    ) : (
                        <div className={styles.flex}>
                            <Button
                                color={'primary'}
                                onClick={(): Promise<void> => this.handleReopen()}
                                className={this.props.prevNextSplitOn ? styles.complete : ''}
                            >
                                Reopen
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const CompleteForm = VideoEventFilterServiceMultiselect.inject(
    VideoEventService.inject(withFeatureToggle<ICompleteFormProps>(_CompleteForm, 'VideoEventStatusWorkflow')),
);
