import { React, bind } from '../../Imports';
import { Select, MenuItem, InputLabel, FormControl } from 'MaterialUIComponents';
import { IDropdownItem } from './VPDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import * as scssStyles from '../../css/settings.scss';

interface IStandardDropdownProps {
    name: string;
    items: IDropdownItem[];
    selectedValue: any | undefined;
    onSelectedItemChange: (value: any[]) => void;
}

interface IStandardDropdownState {
    selectedValue: any | undefined;
}

const styles = require('./VPStandardDropdown.scss') as {
    main: string;
    menuItemImage: string;
    renderedValue: string;
    menuItem: string;
    dropdownIcon: string; 
};

const inputStyles = {
    borderRadius: '4px',
    backgroundColor: '#FFFFF',
    border: '1px solid #ced4da',
    boxShadow: 'inset 0px 3px 3px 0px #e6e6e6',
    padding: '0px 8px',
    fontFamily: scssStyles.fontFamily,
};

export class VPStandardDropdown extends React.PureComponent<IStandardDropdownProps, IStandardDropdownState> {
    state = {
        selectedValue: this.props.selectedValue,
    };

    @bind
    getRenderedValue(selectedItem: any | undefined): React.ReactNode {
        if (selectedItem) {
            let selectItem: IDropdownItem | undefined;
            this.props.items.forEach((item) => {
                if (item.value == selectedItem) {
                    selectItem = item;
                }
            });
            if (selectItem) {
                return (
                    <div className={styles.renderedValue}>
                        {selectItem.label}
                        {selectItem.iconUrl && <img src={selectItem.iconUrl} />}
                    </div>
                );
            }
        }

        return '';
    }

    @bind
    handleChange(event: any): void {
        const { onSelectedItemChange } = this.props;
        const value: any | undefined = event.target.value;
        this.setState({
            selectedValue: value,
        });
        onSelectedItemChange([value]);
    }

    render(): JSX.Element {
        const { items, name } = this.props;

        return (
            <FormControl>
                <InputLabel id={name}>{name}</InputLabel>
                <Select
                    className={styles.main}
                    labelId={name}
                    id={name + '-select'}
                    value={this.state.selectedValue}
                    onChange={this.handleChange}
                    renderValue={(): React.ReactNode => {
                        return this.getRenderedValue(this.state.selectedValue);
                    }}
                    inputProps={{
                        style: scssStyles.styleEnvironment == "encompass" ? inputStyles : {}, 
                    }}
                    MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        }
                      }}
                    IconComponent={(props) => <FontAwesomeIcon icon={faAngleDown} {...props} className={[props.className, styles.dropdownIcon].join(' ')} />}
                >
                    {items.map((item, idx) => {
                        return (
                            <MenuItem
                                key={idx}
                                value={item.value}
                                selected={this.state.selectedValue == item.value}
                                className={styles.menuItem}
                            >
                                {item.label}
                                {item.iconUrl && <img src={item.iconUrl} className={styles.menuItemImage} />}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }
}
